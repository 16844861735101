import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { Tooltip } from "antd";
import { twMerge } from "tailwind-merge";
export default function TextArea({
  label,
  required = false,
  infoText = "",
  placeholder = "",
  value,
  onChange,
  error = "",
  parentClasses = "",
  disabled = false,
  shouldBeHighLighted = false,
  labelClasses = "text-gray-700",
  ...props
}) {
  return (
    <div className={`relative ${parentClasses}`}>
      {label && (
        <div className="mb-2 flex items-center">
          <div className={labelClasses}>
            {label}
            {required && <span className="text-red-500">*</span>}
            {infoText && (
              <Tooltip title={infoText}>
                <ExclamationCircleIcon className="ml-2 inline-block h-4 w-4 text-gray-400" />
              </Tooltip>
            )}
          </div>
        </div>
      )}
      <textarea
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={twMerge(
          `block w-full rounded border bg-white p-2 text-gray-700 transition-colors duration-300 focus:border-primaryAccent focus:ring-primaryAccent ${
            error && shouldBeHighLighted ? "border-red-500" : "border-gray-400"
          } ${disabled ? "pointer-events-none bg-gray-200 opacity-50" : ""}`,
        )}
        {...props}
      />
      {error && <div className="mt-1 text-sm text-red-600">{error}</div>}
    </div>
  );
}
