import React, { useRef } from "react";
import { v4 as uuidv4 } from "uuid";

const AUDIT_ACTIVITY_MESSAGE_MAP = {
  "backend.crmCustomer.shippingAddressUpdated": "Shipping Address",
  "backend.crmCustomer.detailsUpdated": "Customer",
};

const AuditLogTable = (props) => {
  // if (props.inModal) {
  const observer = useRef();
  const { auditUserInfo } = props;
  // Function to observe when the last element (sentinel) comes into view
  const lastLogElementRef = (node) => {
    if (props.loading) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && props.hasMore && props.inModal) {
        props.setPage((prevPage) => prevPage + 1); // Increment page and trigger data fetching
      }
    });

    if (node) observer.current.observe(node);
  };
  // }

  // This functions returns a list of keys that are different between two objects
  // Sample output:
  /*
   {
    'accountAddress.contactNumber': { oldValue: '8989898922', newValue: '8989898923' },
    'shippingAddresses[0].line1': { oldValue: '10301 Burnet Road', newValue: '10302 Burnet Road' },
    } 
  */
  function diffOldAndNew(oldObj, newObj, parentKey = "") {
    let diffs = {};
    const keys = new Set([...Object.keys(oldObj), ...Object.keys(newObj)]);

    keys.forEach((key) => {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
      const formattedKey = formatKey(fullKey);
      const oldValue = oldObj[key];
      const newValue = newObj[key];

      if (
        typeof oldValue === "object" &&
        !Array.isArray(oldValue) &&
        oldValue !== null &&
        typeof newValue === "object" &&
        !Array.isArray(newValue) &&
        newValue !== null
      ) {
        const nestedDiff = diffOldAndNew(oldValue, newValue, fullKey);
        Object.assign(diffs, nestedDiff);
      } else if (Array.isArray(oldValue) && Array.isArray(newValue)) {
        oldValue.forEach((oldItem, index) => {
          const newItem = newValue[index];
          if (typeof oldItem === "object" && typeof newItem === "object") {
            const nestedDiff = diffOldAndNew(
              oldItem,
              newItem,
              `${fullKey}[${index}]`,
            );
            Object.assign(diffs, nestedDiff);
          } else if (oldItem !== newItem) {
            diffs[`${fullKey}[${index}]`] = {
              oldValue: oldItem,
              newValue: newItem,
            };
          }
        });
      } else if (oldValue !== newValue) {
        diffs[formattedKey] = { oldValue, newValue };
      }
    });

    return diffs;
  }

  // This function takes a key like 'accountAddress.contactNumber' and formats it to 'Account Address - Contact Number'
  function formatKey(key) {
    return key
      .replace(/([A-Z])/g, " $1") // Add space before capital letters
      .replace(/\./g, " - ") // Replace dots with slashes
      .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize each word
      .trim(); // Remove any leading/trailing whitespace
  }

  function getAuditDescription(oldVal, newVal, activityType) {
    if (oldVal && !newVal) {
      return {
        oldVal: [`Removed ${AUDIT_ACTIVITY_MESSAGE_MAP[activityType]}`],
        newVal: ["N/A"],
      };
    } else if (!oldVal && newVal) {
      return {
        newVal: [`Added ${AUDIT_ACTIVITY_MESSAGE_MAP[activityType]}`],
        oldVal: ["N/A"],
      };
    } else {
      const diff = diffOldAndNew(oldVal, newVal);
      oldVal = [];
      newVal = [];
      for (const [key, value] of Object.entries(diff)) {
        oldVal.push(
          `${key}: ${value.oldValue !== null ? value.oldValue : "null"}`,
        );
        newVal.push(
          `${key}: ${value.newValue !== null ? value.newValue : "null"}`,
        );
      }
    }
    return { oldVal, newVal };
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white">
        <thead className="bg-gray-100">
          <tr>
            <th className="p-6 text-left text-sm font-semibold text-gray-500">
              Name of User
            </th>
            <th className="p-6 text-left text-sm font-semibold text-gray-500">
              Date & Time
            </th>
            <th className="p-6 text-left text-sm font-semibold text-gray-500">
              Customer ID
            </th>
            <th className="p-6 text-left text-sm font-semibold text-gray-500">
              From Value
            </th>
            <th className="p-6 text-left text-sm font-semibold text-gray-500">
              To Value
            </th>
          </tr>
        </thead>
        <tbody>
          {props.auditData.map((log, index) => {
            const { oldVal, newVal } = getAuditDescription(
              log.fromValue,
              log.toValue,
              log.activityType,
            );
            if (props.auditData.length === index + 1) {
              return (
                <tr ref={lastLogElementRef} key={index}>
                  <td className="border-b p-8 font-bold">
                    {auditUserInfo[log.userId]?.name}
                  </td>
                  <td className="border-b p-8">{log.createdAt}</td>
                  <td className="border-b p-8">{log.crmCustomerId}</td>
                  <td className="border-b p-8">
                    <ul>
                      {oldVal.map((val) => (
                        <li key={uuidv4()}>{val}</li>
                      ))}
                    </ul>
                  </td>
                  <td className="border-b p-8">
                    <ul>
                      {newVal.map((val) => (
                        <li key={uuidv4()}>{val}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
              );
            } else {
              return (
                <tr key={index}>
                  <td className="border-b p-8 font-bold">
                    {auditUserInfo[log.userId]?.name}
                  </td>
                  <td className="border-b p-8">{log.createdAt}</td>
                  <td className="border-b p-8">{log.crmCustomerId}</td>
                  <td className="border-b p-8">
                    <ul>
                      {oldVal.map((val) => (
                        <li key={uuidv4()}>{val}</li>
                      ))}
                    </ul>
                  </td>
                  <td className="border-b p-8">
                    <ul>
                      {newVal.map((val) => (
                        <li key={uuidv4()}>{val}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
      {props.inModal && props.loading && <p>Loading more logs...</p>}
    </div>
  );
};

export default AuditLogTable;
