import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import { GET_WAREHOUSES, GET_WAREHOUSE, GET_WAREHOUSE_FIELDS } from "#queries";
import {
  SAVE_WAREHOUSE,
  DELETE_WAREHOUSE,
  BULK_UPLOAD_WAREHOUSE,
  GET_PAST_BULK_UPLOADS,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";
import { BulkValidationQueryGenerator } from "../../queries/bulkValidationQueryGenerator";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "#constants/feature-flags";
import { ModalContext } from "#newUiComponents/bulkUploadV2/useReducer";
import { BANNER_TYPE_ENUM } from "#newUiComponents/commons/ExportBanner";
import {
  ACTION_TYPES,
  ACTIONS_TYPE_ENUM,
  POLLING_TIMER,
  STATUS_ENUM,
  BULK_UPLOAD_KEYS,
  BULK_UPLOAD_ENTITY_TYPES,
  BULK_UPLOAD_FILE_NAMES,
} from "#newUiComponents/bulkUploadV2/useReducer";
import { getBulkUploadTemplateURL } from "../../utils/helper-functions";

export const BULK_UPLOAD_DUPLICATE_VALIDATION = ["Name*", "Code*"];
export const WAREHOUSE_ACTION_TYPES = [
  { name: "Add New", value: "ADD_NEW", title: "Add New", subTitle: "" },
];
export const WAREHOUSE_ACTIONS_TYPE_ENUM = {
  ADD_NEW: "ADD_NEW",
};

const withWarehousesLogic = (WrappedComponent) => {
  return (props) => {
    const bulkUploadEntity = useContext(ModalContext);
    const appState = useContext(AppStateContext);
    const entity = useContext(EntityContext);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const warehousesQuery = useQuery(GET_WAREHOUSES);
    const getWarehouseQuery = useQuery(GET_WAREHOUSE);
    const saveWarehouseQuery = useQuery(SAVE_WAREHOUSE);
    const deleteWarehouseQuery = useQuery(DELETE_WAREHOUSE);
    const getWarehouseFields = useQuery(GET_WAREHOUSE_FIELDS);
    const [showFilters, setShowFilters] = useState(false);
    const [dashboardFields, setDashboardFields] = useState(null);
    const [newCreatedWarehouseId, setNewCreatedWarehouseId] = useState(null);
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [updateConfirmation, setUpdateConfirmation] = useState(false);
    const bulkUploadValidation = useQuery(
      BulkValidationQueryGenerator({ keyword: "Warehouses" }),
    );
    const [validationResult, setValidationResult] = useState(null);
    const [finalError, setFinalError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const uploadBulk = useQuery(BULK_UPLOAD_WAREHOUSE);
    const listPastBulkUploadQuery = useQuery(GET_PAST_BULK_UPLOADS);
    const ldClient = useLDClient();
    const bulkUploadV2Enabled =
      ldClient?.variation(featureFlags.BULK_UPLOAD_V2_ENABLED, false) ?? false;
    const [showBulkUpload, setShowBulkUpload] = useState(false);
    const [selectedActionType, setSelectedActionType] = useState(null);
    const [showBanner, setShowBanner] = useState(false);
    const bulkUploadId = localStorage.getItem(BULK_UPLOAD_KEYS?.WAREHOUSE_ID);
    const selectedBulkUploadFile = localStorage.getItem(
      BULK_UPLOAD_KEYS?.WAREHOUSE_FILE,
    );
    const [typeOfBanner, setTypeOfBanner] = useState(null);
    const [warehouseActionTypes, setWarehouseActionTypes] = useState(null);
    const [warehouseActionTypesEnum, setWarehouseActionTypesEnum] =
      useState(null);

    useEffect(() => {
      warehousesQuery.fetchData({
        perPage: entity.perPage,
        filters: {
          includeInactive: true,
        },
        paginated: false,
        pageNumber: 1,
        sort: entity.sort,
      });
      getWarehouseFields.fetchData();
      setWarehouseManageActionType();
    }, []);

    useEffect(() => {
      if (getWarehouseFields.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }

      if (getWarehouseFields.data) {
        setDashboardFields(getWarehouseFields.data.warehouseFields);
      }
    }, [
      getWarehouseFields.loading,
      getWarehouseFields.data,
      getWarehouseFields.error,
    ]);

    useEffect(() => {
      if (warehousesQuery.data) {
        entity.setEntities({
          ...warehousesQuery.data.warehouses,
          ...warehousesQuery.variables,
        });
        appState.removeLoading();
      }
    }, [warehousesQuery.loading, warehousesQuery.error, warehousesQuery.data]);

    useEffect(() => {
      if (getWarehouseQuery.data) {
        setUpdateConfirmation(true);
        setSelectedWarehouse(getWarehouseQuery.data.warehouse);
      }

      if (getWarehouseQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      getWarehouseQuery.loading,
      getWarehouseQuery.data,
      getWarehouseQuery.error,
    ]);

    useEffect(() => {
      if (saveWarehouseQuery.data) {
        setConfirmationVisible(true);
        setSelectedWarehouse(null);
        warehousesQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });
      }
      if (saveWarehouseQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveWarehouseQuery.error) {
        appState.setAlert(saveWarehouseQuery.error.message, "error", 5000);
      }
    }, [
      saveWarehouseQuery.loading,
      saveWarehouseQuery.data,
      saveWarehouseQuery.error,
    ]);

    useEffect(() => {
      if (deleteWarehouseQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (deleteWarehouseQuery.data) {
        appState.hideConfirmation();
        warehousesQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });
        appState.setAlert(
          deleteWarehouseQuery.data.deleteWarehouse.message,
          "success",
          5000,
        );
      }

      if (deleteWarehouseQuery.error) {
        appState.setAlert(deleteWarehouseQuery.error.message, "error", 5000);
        appState.hideConfirmation();
      }
    }, [
      deleteWarehouseQuery.loading,
      deleteWarehouseQuery.data,
      deleteWarehouseQuery.error,
    ]);

    useEffect(() => {
      if (uploadBulk.data) {
        warehousesQuery.fetchData({
          perPage: entity.perPage,
          filters: {},
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
        setSuccessMessage(uploadBulk.data.bulkUploadWarehouses.message);
      }
      if (uploadBulk.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (uploadBulk.error) {
        appState.removeLoading();
        setFinalError(uploadBulk.error.message);
      }
    }, [uploadBulk.loading, uploadBulk.data, uploadBulk.error]);

    useEffect(() => {
      if (bulkUploadValidation.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }

      if (bulkUploadValidation.data) {
        setValidationResult(
          bulkUploadValidation.data.validateBulkUploadWarehouses
            .inputValidationErrors,
        );
      }
    }, [
      bulkUploadValidation.loading,
      bulkUploadValidation.data,
      bulkUploadValidation.error,
    ]);

    const deleteButtonClicked = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this warehouse?",
        () => {
          deleteWarehouseQuery.fetchData({ id });
        },
        appState.hideConfirmation,
      );
    };
    const roleWise = (warehouses, role) =>
      _.filter(warehouses, (e) => e.role.name === role);

    const onChange = (e) => {
      const warehouse = {
        ...selectedWarehouse,
      };

      warehouse[e.target.name] = e.target.value;
      setSelectedWarehouse(warehouse);
    };

    const onChangeAdress = (e) => {
      const warehouse = {
        ...selectedWarehouse,
      };
      warehouse.address = { ...warehouse.address };
      warehouse.address[e.target.name] = e.target.value;
      setSelectedWarehouse(warehouse);
    };

    const onChangeDropdown = (field, value) => {
      const warehouse = {
        ...selectedWarehouse,
      };

      warehouse[field] = value;
      setSelectedWarehouse(warehouse);
    };

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          sort: entity.sort,
        };
        return warehousesQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    const handleManageActionClick = (actionType) => {
      const selectedActionType =
        actionType && warehouseActionTypes
          ? warehouseActionTypes.filter(
              (action) => action["value"] === actionType,
            )
          : [];
      switch (actionType) {
        case warehouseActionTypesEnum?.ADD_NEW:
          setSelectedWarehouse({ active: true });
          setShowBulkUpload(false);
          break;
        case warehouseActionTypesEnum?.DOWNLOAD_TEMPLATE:
          const WAREHOUSE_TEMPLATES = getBulkUploadTemplateURL(
            BULK_UPLOAD_FILE_NAMES?.WAREHOUSE,
            appState?.subdomain,
          );
          if (WAREHOUSE_TEMPLATES) {
            window.location = WAREHOUSE_TEMPLATES;
          }
          break;
        case warehouseActionTypesEnum?.UPLOAD_FILE:
          bulkUploadEntity?.setModuleContentDetails({
            bulkUploadEntityType: BULK_UPLOAD_ENTITY_TYPES?.WAREHOUSE,
          });
          setSelectedActionType(
            selectedActionType && selectedActionType.length !== 0
              ? selectedActionType[0]
              : null,
          );
          setShowBulkUpload(true);
          break;
        case warehouseActionTypesEnum?.PAST_UPLOADS:
          bulkUploadEntity?.setModuleContentDetails({
            bulkUploadEntityType: BULK_UPLOAD_ENTITY_TYPES?.WAREHOUSE,
          });
          setSelectedActionType(
            selectedActionType && selectedActionType.length !== 0
              ? selectedActionType[0]
              : null,
          );
          setShowBulkUpload(true);
          break;
        default:
          break;
      }
    };

    const startWarehousePolling = (bulkUploadId) => {
      const autoRefreshInterval = setInterval(async () => {
        try {
          const listBulkUploadInput = {
            entityType: BULK_UPLOAD_ENTITY_TYPES?.WAREHOUSE,
            id: bulkUploadId,
          };
          const getPastBulkUploadsResponse =
            await listPastBulkUploadQuery.fetchData({
              listBulkUploadInput: listBulkUploadInput,
            });
          if (getPastBulkUploadsResponse?.data) {
            const pastUploadEntities =
              getPastBulkUploadsResponse?.data?.listBulkUpload?.entities;
            if (pastUploadEntities && pastUploadEntities?.length !== 0) {
              if (
                pastUploadEntities[0]?.processingStatus ===
                  STATUS_ENUM.COMPLETED ||
                pastUploadEntities[0]?.processingStatus ===
                  STATUS_ENUM.FAILED ||
                pastUploadEntities[0]?.processingStatus ===
                  STATUS_ENUM.PARTIAL_COMPLETE
              ) {
                clearInterval(autoRefreshInterval);
                setShowBanner(true);
                if (
                  pastUploadEntities[0]?.processingStatus ===
                  STATUS_ENUM.COMPLETED
                ) {
                  setTypeOfBanner(BANNER_TYPE_ENUM?.COMPLETED);
                } else if (
                  pastUploadEntities[0]?.processingStatus === STATUS_ENUM.FAILED
                ) {
                  setTypeOfBanner(BANNER_TYPE_ENUM?.FAILED);
                } else if (
                  pastUploadEntities[0]?.processingStatus ===
                  STATUS_ENUM.PARTIAL_COMPLETE
                ) {
                  setTypeOfBanner(BANNER_TYPE_ENUM?.PARTIAL_COMPLETE);
                }
                localStorage.removeItem(BULK_UPLOAD_KEYS?.WAREHOUSE_ID);
                localStorage.removeItem(BULK_UPLOAD_KEYS?.WAREHOUSE_FILE);
                warehousesQuery.fetchData({
                  perPage: entity.perPage,
                  filters: entity.filters,
                  paginated: false,
                  pageNumber: entity.pageNumber,
                  sort: entity.sort,
                });
              }
            }
          }
        } catch (error) {
          clearInterval(autoRefreshInterval);
          setTypeOfBanner(null);
          setShowBanner(false);
        }
      }, POLLING_TIMER);
    };

    const setWarehouseManageActionType = () => {
      bulkUploadEntity?.resetModal();
      setWarehouseActionTypes([...WAREHOUSE_ACTION_TYPES, ...ACTION_TYPES]);
      setWarehouseActionTypesEnum({
        ...WAREHOUSE_ACTIONS_TYPE_ENUM,
        ...ACTIONS_TYPE_ENUM,
      });
    };

    useEffect(() => {
      if (bulkUploadId) {
        setShowBanner(true);
        setTypeOfBanner(BANNER_TYPE_ENUM?.INITIATED);
        startWarehousePolling(bulkUploadId);
      }
    }, [bulkUploadId]);

    return (
      <WrappedComponent
        warehouses={entity.displayEntities}
        total={entity.total}
        fetchWarehouse={(id) => getWarehouseQuery.fetchData({ id })}
        selectedWarehouse={selectedWarehouse}
        setSelectedWarehouse={setSelectedWarehouse}
        confirmationVisible={confirmationVisible}
        setConfirmationVisible={setConfirmationVisible}
        updateConfirmation={updateConfirmation}
        setUpdateConfirmation={setUpdateConfirmation}
        newCreatedWarehouseId={newCreatedWarehouseId}
        setNewCreatedWarehouseId={setNewCreatedWarehouseId}
        saveWarehouse={(warehouse) => {
          setNewCreatedWarehouseId(warehouse.name);
          saveWarehouseQuery.fetchData({
            ...warehouse,
            code: warehouse.code.toUpperCase(),
          });
        }}
        dashboardFields={dashboardFields}
        saveBulkUpload={(rows) => {
          uploadBulk.fetchData({ rows });
        }}
        errorMessage={finalError}
        successMessage={successMessage}
        roleWise={roleWise}
        onChange={onChange}
        onChangeAdress={onChangeAdress}
        onChangeDropdown={onChangeDropdown}
        deleteButtonClicked={deleteButtonClicked}
        writable={props.writable}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          warehousesQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        submitFilters={() => {
          setShowFilters(false);
          warehousesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          warehousesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        onChangeFilter={(field, value, autoSubmit = false) => {
          entity.setFilters({
            ...entity.filters,
            [field]: value,
          });
          if (autoSubmit) {
            warehousesQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,

                [field]: value,
              },
              sort: entity.sort,
            });
          }
        }}
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          warehousesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={() => {
          entity.setFilters({});
          warehousesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {},
            sort: entity.sort,
          });
        }}
        validate={(rows) => {
          bulkUploadValidation.fetchData({ rows });
        }}
        validationResult={validationResult}
        handleManageActionClick={handleManageActionClick}
        showBulkUpload={showBulkUpload}
        setShowBulkUpload={setShowBulkUpload}
        setSelectedActionType={setSelectedActionType}
        selectedActionType={selectedActionType}
        bulkUploadV2Enabled={bulkUploadV2Enabled}
        pastUploadsList={bulkUploadEntity?.pastUploadsEntities}
        showBanner={showBanner}
        typeOfBanner={typeOfBanner}
        bulkuploadFileName={selectedBulkUploadFile}
        closeBanner={() => {
          localStorage.removeItem(BULK_UPLOAD_KEYS?.WAREHOUSE_ID);
          localStorage.removeItem(BULK_UPLOAD_KEYS?.WAREHOUSE_FILE);
          setShowBanner(false);
        }}
        actionTypes={warehouseActionTypes}
      />
    );
  };
};

export default withWarehousesLogic;
