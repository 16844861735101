import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import _ from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import ModalButton from "#components/utils/ModalButton";

const BundleFilters = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  onSubmit,
  negativeAction,
  selectedCombinedOrder,
  masterData,
  onChangeFilter,
  filters,
  outboundExceptions,
  customers,
  warehouses,
  clearFilters,
}) => (
  <Modal
    title={"Filters"}
    negativeAction={negativeAction}
    positiveAction={onSubmit}
    initialFocus={null}>
    <div className="mt-4 flex space-x-6">
      <ModalButton
        onClick={clearFilters}
        text={`Reset Filters`}
        className="bg-red-500"
      />
    </div>
    <div className="mt-4 flex space-x-6">
      <div className="flex-1">
        <label className="mb-2 block text-left">
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Start Date
          </span>
        </label>
        <TextField
          type="date"
          label="Select Date"
          placeholder=" "
          onChange={(e) => onChangeFilter("startDate", e.target.value)}
          value={filters["startDate"]}
        />
      </div>
      <div className="flex-1">
        <label className="mb-2 block text-left">
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            End Date
          </span>
        </label>
        <TextField
          type="date"
          label="Select Date"
          placeholder=" "
          onChange={(e) => onChangeFilter("endDate", e.target.value)}
          value={filters["endDate"]}
        />
      </div>
    </div>
    <div className="mt-4 flex justify-between space-x-6">
      {/* <>
        <div className="flex-1">
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Status
            </span>
          </label>
          <MultiSelectAutoComplete
            options={[
              { name: "UNPROCESSED" },
              { name: "RECEIVING_STARTED" },
              { name: "RECEIVING_COMPLETED" },
              { name: "COMPLETED" },
            ]}
            labelKey="name"
            valueKey="name"
            setValues={(e) => onChangeFilter("status", e)}
            values={filters["status"]}
            rounded={true}
          />
        </div>
      </> */}
      <>
        <div className="flex-1">
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              Client(s)
            </span>
          </label>
          <MultiSelectAutoComplete
            options={customers}
            labelKey="name"
            valueKey="id"
            setValues={(e) => onChangeFilter("customers", e)}
            values={filters["customers"]}
            rounded={true}
          />
        </div>
        {/* <div className="flex-1">
            <label className="block text-left mb-2">
              <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                Warehouse(s)
              </span>
            </label>
            <MultiSelectAutoComplete
              options={warehouses}
              labelKey="name"
              valueKey="id"
              setValues={(e) => onChangeFilter("warehouse", e)}
              values={filters["warehouse"]}
              rounded={true}
            />
          </div> */}
      </>
    </div>
  </Modal>
);

export default BundleFilters;
