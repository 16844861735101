import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_PRODUCTS,
  GET_SKU,
  UPLOAD_PRODUCTS,
  GET_MARKETPLACE_PRODUCTS,
  GET_PRODUCT_FIELDS,
  GET_ENTITY_TYPES,
  GET_INVENTORY,
  GET_BUNDLES_FIELDS,
  GET_INTEGRATIONS,
  GET_SKU_BIN_MAPPINGS,
  GET_CATEGORIES,
  GET_PARENT_SKU,
} from "#queries";
import {
  SAVE_PRODUCT,
  DELETE_PRODUCT,
  BULK_UPLOAD_PRODUCTS,
  SAVE_ENTITY_TYPE,
  SAVE_BUNDLE_AS_PRODUCT,
  DELETE_BUNDLE_AS_PRODUCT,
  BULK_UPLOAD_BUNDLES,
  UPSERT_CATEGORY,
  DELETE_PARENT_PRODUCT,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";
import { AuthContext } from "#contexts/auth";
import readXlsxFile from "read-excel-file";
import { BulkValidationQueryGenerator } from "#queries/bulkValidationQueryGenerator";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "#constants/feature-flags";
import productConstants from "#constants/product";
import { printThirtyUpLabel } from "#utils/printThirtyUpLabel";
import { printCanvasLabelPdf } from "#utils/printCanvasLabelPdf";
import { initialCategoryFormValues } from "#components/catalogs/CatalogManageCategories";

const DEFAULT_QUERY_OPERATOR = "all";
const ALERT_TIMEOUT_IN_MS = 5000;

const withProductsLogic = (WrappedComponent) => {
  return (props) => {
    const ldClient = useLDClient();
    const [addNewCategory, setAddNewCategory] = useState(null);
    const [fileIndex, setFileIndex] = useState(Math.random() * 100);
    const [entityTypes, setEntityTypes] = useState([]);
    const [entityAttributes, setEntityAttributes] = useState({});
    const [addCustomEntity, setAddCustomEntity] = useState(null);
    const entity = useContext(EntityContext);
    const auth = useContext(AuthContext);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showProductForm, setShowProductForm] = useState(false);
    const [productCategories, setProductCategories] = useState([]);
    const [showBundleAsProductForm, setShowBundleAsProductForm] =
      useState(false);
    const [fetchedSku, setFetchedSku] = useState(null);
    const [fetchedSkuAvailable, setFetchedSkuAvailable] = useState(false);
    //To check whether the getSku query is called for expanding the product or for downloading label//
    const [forDownloadingLabel, setForDownloadingLabel] = useState(false);
    const appState = useContext(AppStateContext);
    const getParentSku = useQuery(GET_PARENT_SKU);
    const getParentSkuForEdit = useQuery(GET_PARENT_SKU);
    const getSku = useQuery(GET_SKU);
    const getSkuForPrinting = useQuery(GET_SKU);
    const getSpecificProduct = useQuery(GET_SKU);
    const productsQuery = useQuery(GET_PRODUCTS);
    const getAmazonProductsQuery = useQuery(GET_PRODUCTS);
    const categoriesQuery = useQuery(GET_CATEGORIES);
    const uploadProductsQuery = useQuery(UPLOAD_PRODUCTS);
    const saveProductQuery = useQuery(SAVE_PRODUCT);
    const saveBundleAsProductQuery = useQuery(SAVE_BUNDLE_AS_PRODUCT);
    const [inventorySearchKeyword, setInventorySearchKeyword] = useState("");
    const inventoryQuery = useQuery(GET_INVENTORY);
    const [availableInventory, setAvailableInventory] = useState([]);
    const deleteProductQuery = useQuery(DELETE_PRODUCT);
    const deleteParentProductQuery = useQuery(DELETE_PARENT_PRODUCT);
    const deleteBundleAsProduct = useQuery(DELETE_BUNDLE_AS_PRODUCT);
    const entityTypesQuery = useQuery(GET_ENTITY_TYPES);
    const saveEntityTypeQuery = useQuery(SAVE_ENTITY_TYPE);
    const getMarketplaceProductsQuery = useQuery(GET_MARKETPLACE_PRODUCTS);
    const [validationResult, setValidationResult] = useState(null);
    const getProductsFields = useQuery(GET_PRODUCT_FIELDS);
    const getBundlesFields = useQuery(GET_BUNDLES_FIELDS);
    const bulkUploadProductsQuery = useQuery(BULK_UPLOAD_PRODUCTS);
    const bulkUploadBundlesQuery = useQuery(BULK_UPLOAD_BUNDLES);
    const integrationsQuery = useQuery(GET_INTEGRATIONS);
    const getSkuBinMappingsQuery = useQuery(GET_SKU_BIN_MAPPINGS);
    const [productDashboardFields, setProductDashboardFields] = useState(null);
    const [bundleDashboardFields, setBundleDashboardFields] = useState(null);
    const [finalError, setFinalError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [showFilters, setShowFilters] = useState(false);
    const [productHasInventory, setProductHasInventory] = useState(true);
    const bulkUploadValidationForProducts = useQuery(
      BulkValidationQueryGenerator({ keyword: "Products" }),
    );
    const bulkUploadValidationForBundles = useQuery(
      BulkValidationQueryGenerator({ keyword: "Bundles" }),
    );
    const [filterQueryOperator, setFilterQueryOperator] = useState(
      DEFAULT_QUERY_OPERATOR,
    );
    const [integrations, setIntegrations] = useState([]);
    const [sellerIds, setSellerIds] = useState([]);
    const [customAttributes, setCustomAttributes] = useState([]);
    const [multiAccountSupportEnabled, setMultiAccountSupportEnabled] =
      useState(false);
    const [fnSkuOptions, setFnSkuOptions] = useState(null);
    const [isPrintLabel, setPrintLabel] = useState(false);
    const upsertCategoryQuery = useQuery(UPSERT_CATEGORY);
    const [newParentProduct, setNewParentProduct] = useState(null);
    const [selectedParentProduct, setSelectedParentProduct] = useState(null);

    useEffect(() => {
      const tenantSettings = appState?.tenant?.settings;
      if (
        tenantSettings?.multiAccountIntegrationSupportEnabled &&
        !multiAccountSupportEnabled
      ) {
        setMultiAccountSupportEnabled(true);
      }
    }, [appState]);

    useEffect(() => {
      if (getSkuForPrinting.data) {
        setFetchedSku(getSkuForPrinting.data.specificInventory);
        setFnSkuOptions({
          print: true,
          numberOfLabels: 1,
          fnSkus: [
            { productName: getSkuForPrinting.data.specificInventory?.name },
          ],
        });
      }
    }, [
      getSkuForPrinting.data,
      getSkuForPrinting.error,
      getSkuForPrinting.loading,
    ]);

    useEffect(() => {
      const customAttrs = selectedProduct?.customAttributes;
      if (customAttrs) {
        setCustomAttributes([...customAttrs]);
      } else {
        setCustomAttributes([]);
      }
      if (
        selectedProduct &&
        selectedProduct.customer &&
        selectedProduct.warehouse &&
        selectedProduct.sellerId
      ) {
        integrationsQuery.fetchData({
          filters: {
            customer: selectedProduct.customer,
            warehouse: selectedProduct.warehouse,
          },
        });
      } else if (
        selectedProduct &&
        selectedProduct.customer &&
        selectedProduct.warehouse
      ) {
        integrationsQuery.fetchData({
          filters: {
            customer: selectedProduct.customer,
            warehouse: selectedProduct.warehouse,
          },
        });
      }
    }, [selectedProduct]);

    useEffect(() => {
      fetchEntityTypes();
    }, []);

    useEffect(() => {
      if (integrationsQuery.data) {
        const sellerIdsFromIntegrations = integrationsQuery.data.integrations
          .map((integration) => integration.sellerId)
          .filter((sellerId) => sellerId);
        setIntegrations(integrationsQuery.data.integrations);
        setSellerIds(sellerIdsFromIntegrations);
      }
    }, [
      integrationsQuery.data,
      integrationsQuery.loading,
      integrationsQuery.error,
    ]);

    useEffect(() => {
      if (getProductsFields.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }
      if (getProductsFields.data) {
        setProductDashboardFields(getProductsFields.data.productFields);
      }
    }, [
      getProductsFields.loading,
      getProductsFields.data,
      getProductsFields.error,
    ]);

    useEffect(() => {
      if (getBundlesFields.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }
      if (getBundlesFields.data) {
        setBundleDashboardFields(getBundlesFields.data.bundleFields);
      }
    }, [
      getBundlesFields.loading,
      getBundlesFields.data,
      getBundlesFields.error,
    ]);

    useEffect(() => {
      if (bulkUploadValidationForProducts.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }

      if (bulkUploadValidationForProducts.data) {
        setValidationResult(
          bulkUploadValidationForProducts.data.validateBulkUploadProducts
            .inputValidationErrors,
        );
      }
    }, [
      bulkUploadValidationForProducts.loading,
      bulkUploadValidationForProducts.data,
      bulkUploadValidationForProducts.error,
    ]);

    useEffect(() => {
      if (bulkUploadValidationForBundles.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }

      if (bulkUploadValidationForBundles.data) {
        setValidationResult(
          bulkUploadValidationForBundles.data.validateBulkUploadBundles
            .inputValidationErrors,
        );
      }
    }, [
      bulkUploadValidationForBundles.loading,
      bulkUploadValidationForBundles.data,
      bulkUploadValidationForBundles.error,
    ]);

    useEffect(() => {
      if (bulkUploadProductsQuery.data) {
        productsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
          queryOperator: filterQueryOperator,
        });
        setSuccessMessage(
          bulkUploadProductsQuery.data.bulkUploadProducts.message,
        );
      }

      if (bulkUploadProductsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (bulkUploadProductsQuery.error) {
        appState.removeLoading();
        setFinalError(bulkUploadProductsQuery.error.message);
      }
    }, [
      bulkUploadProductsQuery.loading,
      bulkUploadProductsQuery.data,
      bulkUploadProductsQuery.error,
    ]);

    useEffect(() => {
      if (bulkUploadBundlesQuery.data) {
        productsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
          queryOperator: filterQueryOperator,
        });
        setSuccessMessage(
          bulkUploadBundlesQuery.data.bulkUploadBundles.message,
        );
      }

      if (bulkUploadBundlesQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (bulkUploadBundlesQuery.error) {
        appState.removeLoading();
        setFinalError(bulkUploadBundlesQuery.error.message);
      }
    }, [
      bulkUploadBundlesQuery.loading,
      bulkUploadBundlesQuery.data,
      bulkUploadBundlesQuery.error,
    ]);

    useEffect(() => {
      if (getMarketplaceProductsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getMarketplaceProductsQuery.data) {
        appState.setAlert(
          getMarketplaceProductsQuery.data.marketplaceProducts.message,
          "success",
          5000,
        );
      }

      if (getMarketplaceProductsQuery.error) {
        appState.setAlert(
          getMarketplaceProductsQuery.error.message,
          "error",
          5000,
        );
      }
    }, [
      getMarketplaceProductsQuery.data,
      getMarketplaceProductsQuery.error,
      getMarketplaceProductsQuery.loading,
    ]);

    useEffect(() => {
      if (deleteProductQuery.data) {
        appState.hideConfirmation();
        appState.setAlert(
          deleteProductQuery.data.deleteProduct.message,
          "success",
          5000,
        );
        productsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
          queryOperator: filterQueryOperator,
        });
      }

      if (deleteProductQuery.error) {
        appState.setAlert(deleteProductQuery.error.message, "error", 5000);
        appState.hideConfirmation();
      }
    }, [
      deleteProductQuery.loading,
      deleteProductQuery.data,
      deleteProductQuery.error,
    ]);

    useEffect(() => {
      if (deleteBundleAsProduct.data) {
        appState.hideConfirmation();
        appState.setAlert(
          deleteBundleAsProduct.data.deleteBundleAsProduct.message,
          "success",
          5000,
        );
        productsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
          queryOperator: filterQueryOperator,
        });
      }

      if (deleteBundleAsProduct.error) {
        appState.setAlert(deleteBundleAsProduct.error.message, "error", 5000);
        appState.hideConfirmation();
      }
    }, [
      deleteBundleAsProduct.loading,
      deleteBundleAsProduct.data,
      deleteBundleAsProduct.error,
    ]);

    useEffect(() => {
      return () => {
        entity.setFilters({});
        entity.resetEntities();
      };
    }, []);

    const fetchEntityTypes = () => {
      const filtersSet = {
        entityParent: [
          "PRODUCTSIZE",
          "PRODUCTSHAPE",
          "PRODUCTCOLOR",
          "PRODUCTCATEGORY",
          "PRODUCTTYPE",
        ],
      };
      entityTypesQuery.fetchData({ filters: filtersSet });
    };

    useEffect(() => {
      if (entityTypesQuery.data) {
        setEntityTypes(entityTypesQuery.data.entityTypes);
      }
      if (entityTypesQuery.error) {
        setEntityTypes([]);
      }
    }, [entityTypesQuery.data, entityTypesQuery.error]);

    useEffect(() => {
      const entityAttribute = { ...entityAttributes };
      const entitySizes = entityTypes.filter(
        (obj) => obj["entityParent"] === "PRODUCTSIZE",
      );
      entityAttribute["sizes"] = entitySizes;
      const entityShapes = entityTypes.filter(
        (obj) => obj["entityParent"] === "PRODUCTSHAPE",
      );
      entityAttribute["shapes"] = entityShapes;
      const entityColors = entityTypes.filter(
        (obj) => obj["entityParent"] === "PRODUCTCOLOR",
      );
      entityAttribute["colors"] = entityColors;
      const entityCategories = entityTypes.filter(
        (obj) => obj["entityParent"] === "PRODUCTCATEGORY",
      );
      entityAttribute["categories"] = entityCategories;
      const entityProductTypes = entityTypes.filter(
        (obj) => obj["entityParent"] === "PRODUCTTYPE",
      );
      entityAttribute["types"] = entityProductTypes;
      setEntityAttributes(entityAttribute);
    }, [entityTypes]);

    useEffect(() => {
      if (saveEntityTypeQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveEntityTypeQuery.data) {
        const filtersSet = {
          entityParent: [
            "PRODUCTSIZE",
            "PRODUCTSHAPE",
            "PRODUCTCOLOR",
            "PRODUCTCATEGORY",
            "PRODUCTTYPE",
          ],
        };
        entityTypesQuery.fetchData({ filters: filtersSet });
        setAddCustomEntity(null);
      }

      if (saveEntityTypeQuery.error) {
        appState.setAlert(saveEntityTypeQuery.error.message, "error", 5000);
      }
    }, [
      saveEntityTypeQuery.loading,
      saveEntityTypeQuery.data,
      saveEntityTypeQuery.error,
    ]);

    useEffect(() => {
      if (categoriesQuery.data) {
        setProductCategories(categoriesQuery.data.categories.entities);
      }

      if (categoriesQuery.error) {
        setProductCategories([]);
      }
    }, [categoriesQuery.data, categoriesQuery.error]);

    useEffect(() => {
      loadFirstTimeData();
      getProductsFields.fetchData();
      getBundlesFields.fetchData();
    }, []);

    const loadFirstTimeData = () => {
      if (
        auth &&
        auth.user &&
        auth.user.warehousesList &&
        auth.user.customersList
      ) {
        let warehouses = auth.user.warehousesList;
        let customers = auth.user.customersList;
        if (warehouses.length === 0 || customers.length === 0) {
          return appState.setAlert(
            `You don't have necessary permission to execute this action.`,
            "error",
            5000,
          );
        }
        const filtersToApply = {
          customer: auth.user.customers,
          includeAliases: true,
        };

        productsQuery.fetchData({
          perPage: entity.perPage,
          filters: filtersToApply,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
          queryOperator: DEFAULT_QUERY_OPERATOR,
        });
        categoriesQuery.fetchData();
      }
    };

    useEffect(() => {
      if (productsQuery.data && productsQuery.data.products) {
        productsQuery.data.products.entities =
          productsQuery.data.products.entities.filter((i) => !i.parentProduct);
        entity.setEntities({
          ...productsQuery.data.products,
          ...productsQuery.variables,
        });
      }
    }, [productsQuery.loading, productsQuery.error, productsQuery.data]);

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
          queryOperator: filterQueryOperator,
        };
        return productsQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    useEffect(() => {
      if (productsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [productsQuery.loading]);

    useEffect(() => {
      if (getParentSku.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getParentSku.data) {
        setSelectedParentProduct(getParentSku.data.parentSku);
      }

      if (getParentSku.error) {
        appState.setAlert(
          getParentSku.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        setSelectedParentProduct(null);
      }
    }, [getParentSku.loading, getParentSku.data, getParentSku.error]);

    useEffect(() => {
      if (getParentSkuForEdit.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getParentSkuForEdit.data) {
        setNewParentProduct({
          ...getParentSkuForEdit.data.parentSku,
          hasVariants: true,
        });
      }

      if (getParentSkuForEdit.error) {
        appState.setAlert(
          getParentSkuForEdit.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        setNewParentProduct(null);
      }
    }, [
      getParentSkuForEdit.data,
      getParentSkuForEdit.loading,
      getParentSkuForEdit.error,
    ]);

    useEffect(() => {
      if (getSku.data && getSku.data.specificInventory) {
        const aliasData = entity.entities.find(
          (product) => product.id === getSku.data.specificInventory.id,
        )?.aliases;
        setFetchedSku({ ...getSku.data.specificInventory, aliases: aliasData });
        setFetchedSkuAvailable(true);
      }

      if (getSku.error) {
        appState.setAlert(getSku.error.message, "error", 5000);
      }
    }, [getSku.loading, getSku.error, getSku.data]);

    useEffect(() => {
      if (
        getSpecificProduct.data &&
        getSpecificProduct.data.specificInventory
      ) {
        const product = {
          ...getSpecificProduct.data.specificInventory,
          uomConfiguration: getSpecificProduct.data.specificInventory
            .uomConfiguration
            ? getSpecificProduct.data.specificInventory.uomConfiguration.filter(
                (i) => i.isActive,
              )
            : [],
        };
        const typeOfProduct = product.typeOfProduct || "STANDALONE";
        if (typeOfProduct === "BUNDLE" && product.products?.length > 0) {
          product.products = product.products.map((product) => {
            return {
              ...product,
              productId: product.id,
              formFactors: [{ name: product.uom, quantity: product.quantity }],
            };
          });
          setSelectedProduct(product);
          setShowBundleAsProductForm(true);
        } else {
          setSelectedProduct(product);
          setShowProductForm(true);
        }
      }

      if (getSpecificProduct.error) {
        appState.setAlert(getSpecificProduct.error.message, "error", 5000);
      }
    }, [
      getSpecificProduct.loading,
      getSpecificProduct.error,
      getSpecificProduct.data,
    ]);

    useEffect(() => {
      if (uploadProductsQuery.loading) {
        appState.setLoading();
      }
      if (uploadProductsQuery.error) {
        appState.setAlert(uploadProductsQuery.error.message, "error", 5000);
        appState.removeLoading();
      }

      if (uploadProductsQuery.data) {
        setTimeout(() => {
          appState.setAlert(
            uploadProductsQuery.data.uploadProducts.message,
            "success",
            5000,
          );
          entity.setFilters({});
          productsQuery.fetchData({
            perPage: entity.perPage,
            filters: {},
            paginated: false,
            pageNumber: 1,
            sort: entity.sort,
            queryOperator: filterQueryOperator,
          });
          appState.removeLoading();
        }, 4000);
      }
    }, [
      uploadProductsQuery.data,
      uploadProductsQuery.loading,
      uploadProductsQuery.error,
    ]);

    useEffect(() => {
      if (saveProductQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveProductQuery.error) {
        appState.setAlert(saveProductQuery.error.message, "error", 5000);
        if (selectedProduct?.id) {
          getSpecificProduct.fetchData({ id: selectedProduct?.id });
        }
      }

      if (saveProductQuery.data) {
        appState.setAlert(saveProductQuery.data.saveProduct.message);
        setShowProductForm(false);
        setShowBundleAsProductForm(false);
        setSelectedProduct(null);
        productsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
          queryOperator: filterQueryOperator,
        });
      }
    }, [
      saveProductQuery.data,
      saveProductQuery.loading,
      saveProductQuery.error,
    ]);

    useEffect(() => {
      if (saveBundleAsProductQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveBundleAsProductQuery.error) {
        appState.setAlert(
          saveBundleAsProductQuery.error.message,
          "error",
          5000,
        );
      }

      if (saveBundleAsProductQuery.data) {
        appState.setAlert(
          saveBundleAsProductQuery.data.saveBundleAsProduct.message,
        );
        setShowProductForm(false);
        setShowBundleAsProductForm(false);
        setSelectedProduct(null);
        productsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
          queryOperator: filterQueryOperator,
        });
      }
    }, [
      saveBundleAsProductQuery.data,
      saveBundleAsProductQuery.loading,
      saveBundleAsProductQuery.error,
    ]);

    const addNewProduct = ({ typeOfProduct = "STANDALONE" }) => {
      const warehouses = auth.user.warehousesList;
      const customers = auth.user.customersList;
      if (warehouses.length === 0 || customers.length === 0) {
        return appState.setAlert(
          `You don't have necessary permission to execute this action.`,
          "error",
          5000,
        );
      }
      let selectedCustomer;
      let warehouseToBeSelected = true;
      let customerToBeSelected = true;
      if (customers.length === 1) {
        selectedCustomer = customers[0].id;
        customerToBeSelected = false;
      }

      let newProduct = {
        customer: selectedCustomer || "",
        warehouseToBeSelected,
        customerToBeSelected,
        baseUom: "Each",
        source: "Hopstack",
      };
      if (typeOfProduct === "STANDALONE") {
        newProduct = {
          ...productConstants.NEW_PRODUCT,
          ...newProduct,
        };
        setSelectedProduct(newProduct);
        setShowProductForm(true);
      } else if (typeOfProduct === "BUNDLE") {
        newProduct = {
          ...productConstants.NEW_BUNDLE_AS_PRODUCT,
          ...newProduct,
        };
        setSelectedProduct(newProduct);
        setShowBundleAsProductForm(true);
      }
    };

    const onChange = (event) => {
      const product = {
        ...selectedProduct,
      };

      if (event.target.type === "number") {
        product[event.target.name] = parseInt(event.target.value);
      } else if (
        event.target.name === "upc" ||
        event.target.name === "ean" ||
        event.target.name === "lpn"
      ) {
        product[event.target.name] = event.target.value.split(",");
      } else if (event.target.type === "attribute") {
        if (!product.attributes) {
          product.attributes = {};
        }
        product.attributes[event.target.name] = event.target.value;
      } else {
        product[event.target.name] = event.target.value;
      }
      setSelectedProduct(product);
    };

    const onChangeDropdown = (field, value, entity = "selectedProduct") => {
      const product = {
        ...selectedProduct,
      };
      if (field === "size") {
        if (value === "Add Custom") {
          return setAddCustomEntity({
            entityParent: "PRODUCTSIZE",
            name: "",
          });
        }
        if (!product.attributes) {
          product.attributes = {};
        }
        product.attributes[field] = value;
        setSelectedProduct(product);
      }
      if (field === "shape") {
        if (value === "Add Custom") {
          return setAddCustomEntity({
            entityParent: "PRODUCTSHAPE",
            name: "",
          });
        }
        if (!product.attributes) {
          product.attributes = {};
        }
        product.attributes[field] = value;
        setSelectedProduct(product);
      }
      if (field === "color") {
        if (value === "Add Custom") {
          return setAddCustomEntity({
            entityParent: "PRODUCTCOLOR",
            name: "",
          });
        }
        if (!product.attributes) {
          product.attributes = {};
        }
        product.attributes[field] = value;
        setSelectedProduct(product);
      }

      if (field === "type") {
        if (value === "Add Custom") {
          return setAddCustomEntity({
            entityParent: "PRODUCTTYPE",
            name: "",
          });
        }
        product[field] = value;
        setSelectedProduct(product);
      }

      if (field === "category") {
        if (value === "addNewCategory") {
          return setAddNewCategory(initialCategoryFormValues);
        }
        product[field] = value;
        setSelectedProduct(product);
      }
      product[field] = value;
      setSelectedProduct(product);
    };

    const AttributeValues = (name, value) => {
      const product = {
        ...selectedProduct,
      };
      if (!product.attributes) {
        product.attributes = {};
      }

      product.attributes[name] = value;
      setSelectedProduct(product);
    };

    const deleteAttributes = (name) => {
      const product = {
        ...selectedProduct,
      };
      const attributes = product.attributes;
      if (attributes[name]) delete attributes[name];
      if (Object.keys(attributes).length === 0) delete product.attributes;
      setSelectedProduct(product);
    };

    const uploadProducts = async (files) => {
      appState.setLoading();
      const rows = await readXlsxFile(files);
      setFileIndex(Math.random() * 100);
      for (let i = 0; i < rows.length; i++) {
        if (i === 0 || i === 1) {
          continue;
        }
        const row = rows[i];
        let parentRow = rows[1];

        if (!row[0]) {
          continue;
        }
        const tenant = auth.user.customersList.find(
          (i) =>
            i.name.toLowerCase() === row[0].toString().trim().toLowerCase() ||
            i.code.toString().toLowerCase() ===
              row[0].toString().trim().toLowerCase(),
        );
        if (!tenant) {
          appState.setAlert(`Invalid client ${row[0]}`);
          continue;
        } else {
          const productData = {
            sku: row[2]?.toString().trim(),
            name: row[1]?.toString().trim(),
            customer: tenant.id,
          };
          if (tenant.warehouses && tenant.warehouses.length > 0) {
            productData.warehouse = tenant.warehouses[0];
          }
          if (row[3]) {
            productData.upc = row[3]
              .toString()
              .split(" ")
              .map((item) => item.trim());
          }
          if (row[4] && row[4].toString().trim() !== "") {
            productData.asin = row[4].toString().trim();
          }
          if (row[5] && row[5].toString().trim() !== "") {
            productData.fnSku = row[5].toString().trim();
          }
          if (row[6] && row[6].toString().trim() !== "") {
            productData.ean = row[6]
              .toString()
              .split(" ")
              .map((item) => item.trim());
          }

          productData.attributes = {};
          for (let j = 7; j < row.length; j++) {
            if (
              parentRow[j] &&
              parentRow[j].toString().trim() !== "" &&
              row[j] &&
              row[j].toString().trim() !== ""
            ) {
              productData.attributes[parentRow[j].toString().trim()] = row[j]
                .toString()
                .trim();
            } else {
              break;
            }
          }

          saveProductQuery.fetchData({
            ...productData,
            source: "Hopstack",
          });
        }
      }
    };

    useEffect(() => {
      if (inventoryQuery.data) {
        setAvailableInventory(inventoryQuery.data.inventory.entities);
      }

      if (inventoryQuery.error) {
        setAvailableInventory([]);
      }
    }, [inventoryQuery.loading, inventoryQuery.error, inventoryQuery.data]);

    useEffect(() => {
      // If new Product or prodduct has not been selected
      if (!selectedProduct || !selectedProduct.id) {
        setProductHasInventory(false);
        return;
      }
      getSkuBinMappingsQuery.fetchData({
        filters: {
          product: [selectedProduct.id],
          customer: [selectedProduct.customer],
        },
      });
    }, [selectedProduct]);

    useEffect(() => {
      if (getSkuBinMappingsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (getSkuBinMappingsQuery.data) {
        //If no bin mappings found, product has no inventory
        if (getSkuBinMappingsQuery.data.skuBinMappings?.entities.length === 0) {
          setProductHasInventory(false);
          return;
        }
        // Calculate total inventory
        let totalInventory = 0;
        for (const skuBinMapping of getSkuBinMappingsQuery.data.skuBinMappings
          ?.entities) {
          totalInventory +=
            skuBinMapping.allocatedQuantity + skuBinMapping.unallocatedQuantity;
        }
        if (totalInventory > 0) {
          setProductHasInventory(true);
        } else {
          setProductHasInventory(false);
        }
      }
      if (getSkuBinMappingsQuery.error) {
        appState.setAlert(getSkuBinMappingsQuery.error.message, "error", 5000);
      }
    }, [
      getSkuBinMappingsQuery.data,
      getSkuBinMappingsQuery.error,
      getSkuBinMappingsQuery.loading,
    ]);

    const onChangeInventorySearch = (keyword) => {
      inventoryQuery.fetchData({
        perPage: 10,
        pageNumber: 1,
        filters: {
          keyword,
          customer: [selectedProduct.customer],
        },
        paginated: false,
      });
    };
    const debouncedInventorySearch = _.debounce(onChangeInventorySearch, 1000);

    const deleteParentProductClicked = (id) => {
      const product = entity.parentProducts?.find(
        (product) => product.id === id,
      );
      if (!product) {
        return appState.setAlert(
          "Product not found",
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this product? Any variants for this product will be disassociated as well.",
        () => {
          deleteParentProductQuery.fetchData({ id });
        },
        appState.hideConfirmation,
      );
    };

    useEffect(() => {
      if (deleteParentProductQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (deleteParentProductQuery.data) {
        appState.setAlert(
          deleteParentProductQuery.data.deleteParentProduct.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        submitFilters();
        appState.hideConfirmation();
      }

      if (deleteParentProductQuery.error) {
        appState.setAlert(
          deleteParentProductQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
    }, [
      deleteParentProductQuery.loading,
      deleteParentProductQuery.error,
      deleteParentProductQuery.data,
    ]);

    const deleteButtonClicked = (id) => {
      const product = entity.entities.find((product) => product.id === id);
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this product?",
        () => {
          if (
            product.typeOfProduct === "BUNDLE" &&
            product.products?.length > 0
          ) {
            deleteBundleAsProduct.fetchData({ id });
          } else {
            deleteProductQuery.fetchData({ id });
          }
        },
        appState.hideConfirmation,
      );
    };

    const deleteImage = (idx) => {
      if (selectedProduct && selectedProduct.images) {
        const images = selectedProduct.images.filter(
          (item, index) => index !== idx,
        );
        setSelectedProduct({
          ...selectedProduct,
          images,
        });
      }
    };

    const addBundleProduct = (addedProduct) => {
      let selectedBundleAsProduct = selectedProduct;
      if (
        !selectedBundleAsProduct.products ||
        selectedBundleAsProduct.products.length === 0
      ) {
        selectedBundleAsProduct.products = [];
      }

      // check if this product is already present in the selected products.
      const existingProduct = selectedBundleAsProduct.products.find(
        (product) => product.productId === addedProduct.productId,
      );
      if (existingProduct) {
        return appState.setAlert(
          `This product is already present in the bundle.`,
          "error",
          5000,
        );
      }

      if (addedProduct.idx >= 0) {
        selectedBundleAsProduct.products[addedProduct.idx] = {
          ...addedProduct,
          idx: null,
          editing: false,
        };
      } else {
        selectedBundleAsProduct.products.push({ ...addedProduct });
      }

      setSelectedProduct({ ...selectedBundleAsProduct });
    };

    const removeBundleProduct = (idx) => {
      let selectedBundleAsProduct = selectedProduct;
      if (
        !selectedBundleAsProduct.products ||
        selectedBundleAsProduct.products.length === 0
      ) {
        return;
      }

      selectedBundleAsProduct.products =
        selectedBundleAsProduct.products.filter(
          (product, index) => index !== idx,
        );
      setSelectedProduct({ ...selectedBundleAsProduct });
    };

    const validateOnSubmit = () => {
      // Check if all attributes have keys and some values
      for (let attr of customAttributes) {
        if (!attr.key || attr.key.trim() === "") {
          alert("All custom attributes must have a name.");
          return false;
        }

        // Check for duplicate keys
        const keys = customAttributes.map((attr) => attr.key);
        const uniqueKeys = new Set(keys);
        if (keys.length !== uniqueKeys.size) {
          alert(
            "Duplicate name detected for custom attribute.\
 Each custom attribute name must be unique.",
          );
          return false;
        }

        if (
          attr.value === null ||
          attr.value === undefined ||
          attr.value === ""
        ) {
          alert(`Value for custom attribute "${attr.key}" is missing.`);
          return false;
        }
      }

      if (
        ["FBA", "FBM"].includes(selectedProduct.source) &&
        multiAccountSupportEnabled &&
        !selectedProduct.id &&
        (!selectedProduct.marketplace || !selectedProduct.sellerId)
      ) {
        appState.setAlert(
          "Please select a marketplace and seller id for this product.",
          "error",
          5000,
        );
        return false;
      }

      return true;
    };

    const saveProduct = async () => {
      if (!validateOnSubmit()) {
        return;
      }

      if (selectedProduct.typeOfProduct === "BUNDLE") {
        const bundleAsProduct = { ...selectedProduct };
        bundleAsProduct.products = bundleAsProduct.products.map((product) => {
          return {
            id: product.productId,
            quantity: product.formFactors[0].quantity,
            uom: product.formFactors[0].name,
          };
        });

        const isAmazonProduct = ["FBA", "FBM"].includes(bundleAsProduct.source);
        let confirmatinText = "";
        if (isAmazonProduct) {
          // Get all Amazon products with the same ASIN for this customer to show a confirmation message
          const getAmazonProductsQueryResponse =
            await getAmazonProductsQuery.fetchData({
              perPage: 1,
              pageNumber: 1,
              filters: {
                asin: [bundleAsProduct.asin],
                customer: bundleAsProduct.customer,
              },
            });

          // If error occurs while fetching Amazon products, show an alert
          if (getAmazonProductsQueryResponse.error) {
            appState.setAlert(
              getAmazonProductsQueryResponse.error.message,
              "error",
              5000,
            );
            return;
          }

          const totalProducts =
            getAmazonProductsQueryResponse?.data?.products?.total;

          if (bundleAsProduct.products.length === 0) {
            confirmatinText = `This will convert ${totalProducts} Amazon bundle products with the same ASIN back to standalone products.`;
          } else {
            confirmatinText = `This will update components for ${totalProducts} Amazon products \
that have the same ASIN. If any product is currently defined as standalone, it \
will be automatically converted into a bundle`;
          }
        }

        if (isAmazonProduct) {
          appState.showConfirmation(
            "Save Bundle as Product",
            confirmatinText,
            () => {
              saveBundleAsProductQuery.fetchData({
                ...bundleAsProduct,
              });
              appState.hideConfirmation();
            },
            appState.hideConfirmation,
          );
        } else {
          saveBundleAsProductQuery.fetchData({
            ...bundleAsProduct,
          });
        }
      } else {
        saveProductQuery.fetchData({
          ...selectedProduct,
          typeOfProduct: "STANDALONE",
          products: null,
          customAttributes,
        });
      }
    };

    const submitFnSkuOptions = () => {
      if (fnSkuOptions.printSize === "30up") {
        console.log("yaha hai hum");
        appState.setLoading();
        printThirtyUpLabel(
          fnSkuOptions.fnSkus.map((i) => i.fnSku),
          fnSkuOptions.numberOfLabels || 1,
          fnSkuOptions.printerName,
          appState,
          fnSkuOptions.print,
        );
        setPrintLabel(false);
        setForDownloadingLabel(false);
        setFetchedSkuAvailable(false);
      } else {
        for (const code of fnSkuOptions.fnSkus) {
          console.log("doosri jagah hai hum");

          appState.setLoading();
          printCanvasLabelPdf(
            `${code.fnSku}`,
            fnSkuOptions.numberOfLabels || 1,
            null,
            fnSkuOptions?.printerName,
            fnSkuOptions?.printSize,
            appState,
            fnSkuOptions.print,
            code?.productName,
            null,
          );
          setPrintLabel(false);
          setForDownloadingLabel(false);
          setFetchedSkuAvailable(false);
        }
      }
    };

    /**
     * Generates an array of product identifiers from a given product object.
     * Identifiers include SKU, ASIN, FNSKU, and optionally UPC and EAN if they are arrays.
     *
     * @param {Object} product - The product with potential sku, asin, fnSku, upc, and ean properties.
     * @returns {Array<Object>} An array of identifier objects, each with `name` and `value`.
     */
    const createProductIdentifiers = (product) => {
      if (!product) return [];
      const identifiers = [
        { name: `SKU :  ${product.sku}`, value: product.sku },
        { name: `ASIN :  ${product.asin}`, value: product.asin },
        { name: `FNSKU :  ${product.fnSku}`, value: product.fnSku },
        ...(Array.isArray(product.upc)
          ? product.upc.map((upc, idx) => ({
              name: `UPC-${idx + 1} :  ${upc}`,
              value: upc,
            }))
          : []),
        ...(Array.isArray(product.ean)
          ? product.ean.map((ean, idx) => ({
              name: `EAN-${idx + 1} :  ${ean}`,
              value: ean,
            }))
          : []),
      ].filter(
        (identifier) =>
          identifier && identifier.value != null && identifier.value !== "",
      );

      return identifiers;
    };

    const submitCategoryForm = async (e) => {
      e.preventDefault();
      const response = await upsertCategoryQuery.fetchData(addNewCategory);
      if (response.error) {
        appState.setAlert(response.error.message, "error", ALERT_TIMEOUT_IN_MS);
      }

      if (response.data) {
        appState.setAlert(
          response.data.upsertCategory.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        categoriesQuery.fetchData();
        setAddNewCategory(null);
      }

      if (response.error) {
        appState.setAlert(response.error.message, "error", ALERT_TIMEOUT_IN_MS);
      }
    };

    const convertToBundle = (id) => {
      const product = entity.entities.find((product) => product.id === id);
      const bundleProduct = {
        ...product,
        typeOfProduct: "BUNDLE",
        listingStatus: "ACTIVE",
        products: [],
      };
      setSelectedProduct(bundleProduct);
      setShowBundleAsProductForm(true);
    };

    const downloadFnSkuLabels = (id) => {
      setPrintLabel(true);
      setForDownloadingLabel(true);
      getSku.fetchData({ id });

      setFnSkuOptions({
        print: false,
        numberOfLabels: 1,
        fnSkus: [{ productName: fetchedSku?.name }],
      });
    };

    const submitFilters = () => {
      setShowFilters(false);
      if (entity.filters["typeOfProduct"] === "") {
        delete entity.filters["typeOfProduct"];
      }
      productsQuery.fetchData({
        perPage: entity.perPage,
        pageNumber: 1,
        filters: { ...entity.filters },
        sort: entity.sort,
        queryOperator: filterQueryOperator,
      });
    };

    return (
      <WrappedComponent
        {...props}
        products={entity.entities}
        displayProducts={entity.displayEntities}
        total={productsQuery.data ? productsQuery.data.products?.total : 0}
        getSku={(id) => {
          getSku.fetchData({ id });
        }}
        getParentSku={(id) => {
          getParentSku.fetchData({ id });
        }}
        getParentSkuForEdit={(id) => {
          getParentSkuForEdit.fetchData({ id });
        }}
        getSpecificProduct={(id) => {
          getSpecificProduct.fetchData({ id });
        }}
        productDashboardFields={productDashboardFields}
        bundleDashboardFields={bundleDashboardFields}
        saveBulkUpload={(rows, typeOfProduct = "STANDALONE") => {
          if (typeOfProduct === "STANDALONE") {
            bulkUploadProductsQuery.fetchData({ rows });
          } else if (typeOfProduct === "BUNDLE") {
            bulkUploadBundlesQuery.fetchData({ rows });
          }
        }}
        errorMessage={finalError}
        successMessage={successMessage}
        validate={(rows, typeOfProduct = "STANDALONE") => {
          if (typeOfProduct === "STANDALONE") {
            bulkUploadValidationForProducts.fetchData({ rows });
          } else if (typeOfProduct === "BUNDLE") {
            bulkUploadValidationForBundles.fetchData({ rows });
          }
        }}
        validationResult={validationResult}
        fetchedSku={fetchedSku}
        fetchedSkuAvailable={fetchedSkuAvailable}
        setFetchedSkuAvailable={setFetchedSkuAvailable}
        forDownloadingLabel={forDownloadingLabel}
        setForDownloadingLabel={setForDownloadingLabel}
        addCustomEntity={addCustomEntity}
        setFetchedSku={setFetchedSku}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          productsQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
            queryOperator: filterQueryOperator,
          });
        }}
        submitFilters={submitFilters}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          productsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
            queryOperator: filterQueryOperator,
          });
        }}
        filters={entity.filters}
        productSizes={entityAttributes?.["sizes"]}
        productShapes={entityAttributes?.["shapes"]}
        productColors={entityAttributes?.["colors"]}
        productCategories={productCategories}
        productsTypes={entityAttributes?.["types"]}
        AttributeValues={AttributeValues}
        deleteAttributes={deleteAttributes}
        onChangeFilter={(field, value, autoSubmit = false) => {
          entity.setFilters({
            ...entity.filters,
            [field]: value,
          });
          if (autoSubmit) {
            productsQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,
                [field]: value,
              },
              sort: entity.sort,
            });
          }
        }}
        onChangeAttributes={(field, value) => {
          const entityFilters = { ...entity.filters };
          if (!entityFilters.attributes) entityFilters.attributes = {};
          entityFilters.attributes = {
            ...entityFilters.attributes,
            [field]: value,
          };
          entity.setFilters({ ...entityFilters });
        }}
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          productsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={loadFirstTimeData}
        uploadProducts={uploadProducts}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        addNewProduct={addNewProduct}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        onSubmitCustomEntity={() =>
          saveEntityTypeQuery.fetchData({
            entityTypeInput: {
              ...addCustomEntity,
            },
          })
        }
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        saveProduct={saveProduct}
        setAddCustomEntity={setAddCustomEntity}
        inventorySearchKeyword={inventorySearchKeyword}
        setInventorySearchKeyword={setInventorySearchKeyword}
        deleteParentProduct={deleteParentProductClicked}
        deleteProduct={deleteButtonClicked}
        fileIndex={fileIndex}
        setFileIndex={setFileIndex}
        filterQueryOperator={filterQueryOperator}
        setFilterQueryOperator={setFilterQueryOperator}
        deleteImage={deleteImage}
        showProductForm={showProductForm}
        setShowProductForm={setShowProductForm}
        showBundleAsProductForm={showBundleAsProductForm}
        setShowBundleAsProductForm={setShowBundleAsProductForm}
        addBundleProduct={addBundleProduct}
        removeBundleProduct={removeBundleProduct}
        availableInventory={availableInventory}
        onChangeInventorySearch={debouncedInventorySearch}
        getMarketplaceProducts={() =>
          getMarketplaceProductsQuery.fetchData({
            customer: entity.filters.customer[0],
          })
        }
        customAttributes={customAttributes}
        setCustomAttributes={setCustomAttributes}
        multiAccountSupportEnabled={multiAccountSupportEnabled}
        getMarketplaces={(sellerId) => {
          return integrations.find((i) => i.sellerId === sellerId).marketplaces;
        }}
        sellerIds={sellerIds}
        printFnSkuLabels={(id) => {
          setPrintLabel(true);
          getSkuForPrinting.fetchData({ id });
        }}
        downloadFnSkuLabels={downloadFnSkuLabels}
        fnSkuOptions={fnSkuOptions}
        setFnSkuOptions={setFnSkuOptions}
        submitFnSkuOptions={submitFnSkuOptions}
        isPrintLabel={isPrintLabel}
        setPrintLabel={setPrintLabel}
        createProductIdentifiers={createProductIdentifiers}
        productHasInventory={productHasInventory}
        submitCategoryForm={submitCategoryForm}
        addNewCategory={addNewCategory}
        setAddNewCategory={setAddNewCategory}
        tenant={appState.tenant}
        convertToBundle={convertToBundle}
        newParentProduct={newParentProduct}
        setNewParentProduct={setNewParentProduct}
        parentProducts={entity.parentProducts}
        selectedParentProduct={selectedParentProduct}
        setSelectedParentProduct={setSelectedParentProduct}
      />
    );
  };
};

export default withProductsLogic;
