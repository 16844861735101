import { useState, useEffect, useContext, useCallback } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_INTEGRATIONS,
  GET_INTEGRATION_PROVIDERS,
  TEST_INTEGRATION,
} from "#queries";
import { SAVE_INTEGRATION, DELETE_INTEGRATION } from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { MasterDataContext } from "#contexts/masterData";

const withGlobalIntegrationsLogic = (WrappedComponent) => {
  return (props) => {
    const [testResults, setTestResults] = useState(null);
    const [selectedIntegration, setSelectedIntegration] = useState(null);
    const [selectedIntegrationToManage, setSelectedIntegrationToManage] =
      useState(null);
    const [selectedIntegrationToConfigure, setSelectedIntegrationToConfigure] =
      useState(null);

    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);
    const masterData = useContext(MasterDataContext);

    const integrationsQuery = useQuery(GET_INTEGRATIONS);
    const providersQuery = useQuery(GET_INTEGRATION_PROVIDERS);
    const saveIntegrationQuery = useQuery(SAVE_INTEGRATION);
    const deleteIntegrationQuery = useQuery(DELETE_INTEGRATION);
    const testIntegrationQuery = useQuery(TEST_INTEGRATION);

    useEffect(() => {
      loadData();
    }, []);

    const loadData = () => {
      integrationsQuery.fetchData({
        filters: { customer: null, warehouse: null },
      });
      providersQuery.fetchData();
    };

    const globalIntegrations = integrationsQuery.data
      ? integrationsQuery.data.integrations.filter(
          (integration) =>
            integration.customer === null && integration.warehouse === null,
        )
      : [];

    const globalProviders = providersQuery.data
      ? providersQuery.data.integrationProviders.filter(
          (provider) => provider.scope === "GLOBAL",
        )
      : [];

    const connectIntegration = (integrationType) => {
      setSelectedIntegration({
        integrationType,
        customer: null,
        warehouse: null,
        username: "",
        password: "",
        storeName: "",
        marketplaces: [],
        region: null,
      });
    };

    const onChange = (e) => {
      let integration = { ...selectedIntegration };
      const nestedkey = e?.target?.attributes?.getNamedItem("nestedkey")?.value;

      if (nestedkey != null) {
        integration = {
          ...integration,
          [nestedkey]: {
            ...integration[nestedkey],
            [e?.target?.name]: e?.target?.value,
          },
        };
      } else {
        integration[e.target.name] = e.target.value;
      }
      setSelectedIntegration(integration);
    };

    const onChangeDropdown = (field, value) => {
      const integration = { ...selectedIntegration };
      integration[field] = value;
      setSelectedIntegration(integration);
    };

    const saveIntegration = () => {
      saveIntegrationQuery.fetchData({ ...selectedIntegration });
    };

    useEffect(() => {
      if (saveIntegrationQuery.data) {
        appState.setAlert(
          saveIntegrationQuery.data.saveIntegration.message,
          "success",
          5000,
        );
        setSelectedIntegration(null);
        setSelectedIntegrationToConfigure(null);
        loadData();
      }
      if (saveIntegrationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (saveIntegrationQuery.error) {
        console.log(saveIntegrationQuery.error);
        appState.setAlert(saveIntegrationQuery.error.message, "error", 5000);
      }
    }, [
      saveIntegrationQuery.loading,
      saveIntegrationQuery.data,
      saveIntegrationQuery.error,
    ]);

    useEffect(() => {
      if (deleteIntegrationQuery.error) {
        appState.setAlert(
          `Could not logout of integration. Please contact support.`,
          "error",
          5000,
        );
      }
      if (deleteIntegrationQuery.data) {
        appState.setAlert(`Successfully logged out`, "success", 5000);
        loadData();
      }

      if (deleteIntegrationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      deleteIntegrationQuery.loading,
      deleteIntegrationQuery.error,
      deleteIntegrationQuery.data,
    ]);

    const logoutIntegration = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to logout?",
        () => {
          deleteIntegrationQuery.fetchData({ id });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    useEffect(() => {
      if (testIntegrationQuery.data) {
        appState.setAlert(
          testIntegrationQuery.data.testIntegration.message,
          "success",
          5000,
        );
        setTestResults(testIntegrationQuery.data.testIntegration.testData);
        appState.removeLoading();
      }
      if (testIntegrationQuery.error) {
        appState.setAlert(testIntegrationQuery.error.message, "error", 5000);
        setTestResults(null);
        appState.removeLoading();
      }
    }, [
      testIntegrationQuery.loading,
      testIntegrationQuery.error,
      testIntegrationQuery.data,
    ]);

    const testIntegration = (id) => {
      appState.setLoading();
      appState.setAlert(`Testing connection`, "success", 3000);
      testIntegrationQuery.fetchData({ id });
    };

    const getProducts = (id) => {
      // Implement getProducts logic here
      console.log("Getting products for integration:", id);
    };

    const configureIntegrationSubmit = () => {
      if (selectedIntegrationToConfigure) {
        saveIntegrationQuery.fetchData({
          ...selectedIntegrationToConfigure,
        });
      }
    };

    return (
      <WrappedComponent
        integrationProviders={globalProviders}
        getProducts={getProducts}
        connectIntegration={connectIntegration}
        integrations={globalIntegrations}
        selectedIntegration={selectedIntegration}
        setSelectedIntegration={setSelectedIntegration}
        selectedIntegrationToManage={selectedIntegrationToManage}
        setSelectedIntegrationToManage={setSelectedIntegrationToManage}
        selectedIntegrationToConfigure={selectedIntegrationToConfigure}
        setSelectedIntegrationToConfigure={setSelectedIntegrationToConfigure}
        configureIntegrationSubmit={configureIntegrationSubmit}
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        saveIntegration={saveIntegration}
        logoutIntegration={logoutIntegration}
        testIntegration={testIntegration}
        testResults={testResults}
        setTestResults={setTestResults}
        writable={props.writable}
      />
    );
  };
};

export default withGlobalIntegrationsLogic;
