export const renderStatus = (status) => {
  switch (status) {
    case "PARTIAL":
    case "AWAITING_LABEL":
      return (
        <div className="rounded-full bg-lightBlueBackground px-2 py-1 text-center text-lg text-gray-600">
          {status}
        </div>
      );
    case "UNPROCESSED":
      return (
        <div className="rounded-full bg-gray-500 px-2 py-1 text-center text-lg text-white">
          {status}
        </div>
      );
    case "MISMATCH":
      return (
        <div className="rounded-full bg-red-500 px-2 py-1 text-center text-lg text-white">
          {status}
        </div>
      );
    case "IN-PROCESS":
    case "IN_PROGRESS":
    case "STARTED":
      return (
        <div className="rounded-full bg-blue-500 px-2 py-1 text-center text-lg text-white">
          {status}
        </div>
      );
    case "COMPLETED":
    case "CONFIRMED_DROPOFF":
    case "CONFIRMED":
    case "PICKED":
    case "PREPPED":
    case "PACKED":
    case "COMPLETE":
      return (
        <div className="rounded-full bg-green-500 px-2 py-1 text-center text-lg text-white">
          {status}
        </div>
      );
    default:
      return (
        <div className="rounded-full bg-gray-500 px-2 py-1 text-center text-lg text-white">
          {status}
        </div>
      );
  }
};
