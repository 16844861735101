import { useState, useEffect, useContext } from "react";
import { GET_SHIPMENT_RATES } from "#queries";
import { useQuery } from "#hooks/useQuery";
import { AppStateContext } from "#contexts/appState";
import PropTypes from "prop-types";
import _ from "lodash";
import { getCurrencySymbol } from "../../utils/getCurrencySymbol";
import RateShoppingLoader from "./RateShoppingLoader";
import RateShoppingLtlPallet from "./RateShoppingLtlPallet";
import { getPreferredMetricsUnit } from "../../utils/Metrics";

const ALERT_VISIBILITY_IN_MS = 3000;

/**
 * Represents a shipment rate.
 *
 * @typedef {Object} ShipmentRate
 * @property {string} id - The ID of the shipment rate.
 * @property {"CARRIER" | "AGGREGATOR"} type - The type of the shipment rate (carrier or aggregator).
 * @property {string} carrier - The carrier associated with the shipment rate.
 * @property {string|null} source - The source of the shipment rate, or null if not applicable.
 * @property {string} service - The service associated with the shipment rate.
 * @property {number} price - The price of the shipment rate.
 * @property {string} currency - The currency of the shipment rate.
 * @property {string} estimated_delivery_date - The estimated delivery date of the shipment rate.
 * @property {number} estimated_delivery_days - The estimated delivery days of the shipment rate.
 */

/**
 * Finds the cheapest rate from an array of shipment rates.
 *
 * @param {Array} shipmentRates - An array of shipment rate objects.
 * @return {ShipmentRate|null} The cheapest shipment rate, or null if no rates are available.
 */
const getCheapestRate = (shipmentRates) => {
  if (shipmentRates.length === 0) return null;
  return shipmentRates.reduce((cheapestRate, currentRate) => {
    if (!currentRate.price) return cheapestRate;

    if (cheapestRate) {
      if (currentRate.price < cheapestRate.price) {
        return currentRate;
      }
    } else {
      return currentRate;
    }

    return cheapestRate;
  }, null);
};

/**
 * Finds the fastest rate (based on estimated delivery days) from an array of shipment rates.
 *
 * @param {Array} shipmentRates - An array of shipment rate objects.
 * @return {ShipmentRate|null} The fastest shipment rate, or null if no rates are available.
 */
const getFastestRate = (shipmentRates) => {
  if (shipmentRates.length === 0) return null;
  return shipmentRates.reduce((fastestRate, currentRate) => {
    if (!currentRate.estimated_delivery_days) return fastestRate;
    if (fastestRate) {
      if (
        currentRate.estimated_delivery_days <
        fastestRate.estimated_delivery_days
      ) {
        return currentRate;
      } else if (
        currentRate.estimated_delivery_days ===
        fastestRate.estimated_delivery_days
      ) {
        if (currentRate.price < fastestRate.price) {
          return currentRate;
        } else {
          return fastestRate;
        }
      }
      return fastestRate;
    } else {
      return currentRate;
    }
  }, null);
};

/**
 * Combines the cheapest and fastest rates into an array, marking each rate with appropriate labels.
 *
 * @param {Array} shipmentRates - An array of shipment rate objects.
 * @return {Array<ShipmentRate>} shipmentRates - An array containing the cheapest and fastest shipment rates with labels.
 */
const getCheapestAndFastestRates = (shipmentRates) => {
  if (shipmentRates.length === 0) return [];
  const cheapestRate = getCheapestRate(shipmentRates);
  const fastestRate = getFastestRate(shipmentRates);
  if (cheapestRate && fastestRate && cheapestRate.id === fastestRate.id) {
    cheapestRate.labels = ["Low Price", "Fastest"];
    return [cheapestRate];
  }
  const recommendedRates = [];

  if (cheapestRate) {
    cheapestRate.labels = ["Low Price"];
    recommendedRates.push(cheapestRate);
  }

  if (fastestRate) {
    fastestRate.labels = ["Fastest"];
    recommendedRates.push(fastestRate);
  }

  return recommendedRates;
};

/**
 * Merges a pre-selected carrier rate with a list of shipment rates based on specific criteria.
 *
 * @param {Array} shipmentRates - An array of shipment rates, each representing a potential shipping option.
 * @param {Object|null} preSelectedCarrierRate - The pre-selected carrier rate to be merged with the shipment rates.
 * @returns {Object} An object containing merged rates and the pre-selected rate, if applicable.
 *   - mergedRates: An array of merged shipment rates.
 *   - preSelectedRate: The pre-selected carrier rate, or null if none was provided or matched.
 */
const mergePreSelectedRate = (shipmentRates, preSelectedCarrierRate) => {
  if (!preSelectedCarrierRate) {
    return {
      mergedRates: shipmentRates,
      preSelectedRate: null,
    };
  }

  const foundPreselectedRate = _.find(
    shipmentRates,
    _.pick(preSelectedCarrierRate, ["type", "carrier", "source", "service"]),
  );
  if (foundPreselectedRate) {
    return {
      mergedRates: shipmentRates,
      preSelectedRate: foundPreselectedRate,
    };
  }
  return {
    mergedRates: [...shipmentRates, preSelectedCarrierRate],
    preSelectedRate: preSelectedCarrierRate,
  };
};
/**
 * Separates recommended shipment rates from other rates.
 *
 * @param {Array<ShipmentRate>} - An array of shipment rate objects.
 * @return {Object} An object containing recommended and other shipment rates.
 */
const getRecommendedRates = (shipmentRates, preSelectedCarrierRate) => {
  const mergedRatesResponse = mergePreSelectedRate(
    shipmentRates,
    preSelectedCarrierRate,
  );
  const cheapestAndFastest = getCheapestAndFastestRates(
    mergedRatesResponse.mergedRates,
  );

  const recommendedIds = cheapestAndFastest.map((rate) => rate.id);
  const otherRates = mergedRatesResponse.mergedRates.filter(
    (rate) => !recommendedIds.includes(rate.id),
  );

  if (mergedRatesResponse.preSelectedRate) {
    return {
      preSelectedRate: mergedRatesResponse.preSelectedRate,
      recommended: cheapestAndFastest.filter(
        (rate) =>
          !(
            rate.type === mergedRatesResponse.preSelectedRate.type &&
            rate.carrier === mergedRatesResponse.preSelectedRate.carrier &&
            rate.source === mergedRatesResponse.preSelectedRate.source &&
            rate.service === mergedRatesResponse.preSelectedRate.service
          ),
      ),
      otherRates: otherRates.filter(
        (rate) =>
          !(
            rate.type === mergedRatesResponse.preSelectedRate.type &&
            rate.carrier === mergedRatesResponse.preSelectedRate.carrier &&
            rate.source === mergedRatesResponse.preSelectedRate.source &&
            rate.service === mergedRatesResponse.preSelectedRate.service
          ),
      ),
    };
  }
  return {
    preSelectedRate: mergedRatesResponse.preSelectedRate,
    recommended: cheapestAndFastest,
    otherRates,
  };
};

/**
 * Group the other rates based on the carrier and the provider, keepting the sort order for the first rate
 *
 * @param {Array<ShipmentRate>} - An array of shipment rate objects.
 * @return {Array<Object>} An object containing grouped shipment rates.
 */
const groupOtherRates = (otherRates) => {
  const otherRatesCopy = _.cloneDeep(otherRates);
  const groupedRates = [];
  while (otherRatesCopy.length > 0) {
    const cheapestOtherRate = otherRatesCopy.shift();
    const otherSameCarrierAndSourceRates = _.remove(
      otherRatesCopy,
      (rate) =>
        rate.carrier === cheapestOtherRate.carrier &&
        rate.source === cheapestOtherRate.source,
    );
    groupedRates.push({
      carrier: cheapestOtherRate.carrier,
      source: cheapestOtherRate.source,
      rates: [cheapestOtherRate, ...otherSameCarrierAndSourceRates],
    });
  }

  return groupedRates;
};

const labelClass = {
  "Low Price": "text-green-800 bg-green-100",
  Fastest: "text-blue-800 bg-blue-100",
};

/**
 * Updates boxes' dimension and weight units to the preferred units.
 *
 * @param {Array<Object>} boxes - Array of box objects. Each box object should contain properties that may be overwritten.
 * @param {string} preferredDimensionUnit - The desired dimension unit to set for each box.
 * @param {string} preferredWeightUnit - The desired weight unit to set for each box.
 *
 * @returns {Array<Object>} Updated array of box objects with modified dimension and weight units.
 */
const updateBoxes = (boxes, preferredDimensionUnit, preferredWeightUnit) => {
  return boxes.map((box) => ({
    ...box,
    dimensionUnit: preferredDimensionUnit,
    weightUnit: preferredWeightUnit,
  }));
};

/**
 * Updates pallets' dimension and weight units to the preferred units.
 *
 * @param {Array<Object> | undefined} palletInfo - Array of pallet objects. Each pallet object should contain properties that may be overwritten. Can be undefined.
 * @param {string} preferredDimensionUnit - The desired dimension unit to set for each pallet.
 * @param {string} preferredWeightUnit - The desired weight unit to set for each pallet.
 *
 * @returns {Array<Object> | undefined} Updated array of pallet objects with modified dimension and weight units or undefined if palletInfo is undefined.
 */
const updatePallets = (
  palletInfo,
  preferredDimensionUnit,
  preferredWeightUnit,
) => {
  return palletInfo?.map((pallet) => ({
    ...pallet,
    dimensionUnit: preferredDimensionUnit,
    weightUnit: preferredWeightUnit,
  }));
};

const RateShopping = ({
  preSelectedCarrierRate,
  customer,
  warehouse,
  pallets,
  shippingAddress,
  boxes,
  orderSource,
  storedTransportMode,
  shipmentReference,
  onPalletSubmit,
  onRateSelect,
  selectedRate,
  showLTLRates = true,
  setIsFetchingRates,
  validateAddress,
  carrierIntegration,
}) => {
  const shipmentRatesQuery = useQuery(GET_SHIPMENT_RATES);
  const appState = useContext(AppStateContext);
  const [recommendedRates, setRecommendedRates] = useState([]);
  const [preSelectedRate, setPreSelectedRate] = useState(null);
  const [transportMode, setTransportMode] = useState(
    storedTransportMode || "SP",
  );
  const [otherRates, setOtherRates] = useState([]);
  const [errors, setErrors] = useState([]);
  const [ratesApiError, setRatesApiError] = useState(null);
  const [palletInfo, setPalletInfo] = useState(pallets || []);

  const { preferredDimensionUnit, preferredWeightUnit } =
    getPreferredMetricsUnit();

  const segregateAndUpdateRates = (rates, preSelectedCarrierRateObj) => {
    const { recommended, otherRates, preSelectedRate } = getRecommendedRates(
      rates,
      storedTransportMode === transportMode ? preSelectedCarrierRateObj : null,
    );

    setRecommendedRates(recommended);
    setPreSelectedRate(preSelectedRate);
    setOtherRates(
      groupOtherRates(
        otherRates.sort((rateA, rateB) => rateA.price - rateB.price),
      ),
    );

    const defaultSelectedrate = preSelectedRate || recommended?.[0] || null;

    onRateSelect({
      selectedShipmentRateId: defaultSelectedrate?.id || null,
      selectedShipmentSource: defaultSelectedrate?.source || null,
      transportMode,
      selectedCarrierRate: defaultSelectedrate,
      carrier:
        (defaultSelectedrate &&
          `${defaultSelectedrate?.carrier} - ${defaultSelectedrate?.service}`) ||
        null,
    });
  };

  const fetchShipmentRates = () => {
    setRatesApiError(null);
    setRecommendedRates([]);
    setOtherRates([]);
    setErrors([]);
    setPreSelectedRate(null);

    if (transportMode === "LTL" && (palletInfo || []).length === 0) {
      segregateAndUpdateRates([], preSelectedCarrierRate);
      // need pallet info to get shipment rates
      return;
    }

    if (boxes.length === 0) {
      return;
    }

    shipmentRatesQuery.fetchData({
      customer,
      warehouse,
      toAddress: shippingAddress,
      boxes: updateBoxes(boxes, preferredDimensionUnit, preferredWeightUnit),
      pallets: updatePallets(
        pallets || palletInfo,
        preferredDimensionUnit,
        preferredWeightUnit,
      ),
      orderSource,
      transportMode: transportMode,
      shipmentReference,
      validateAddress,
    });
  };

  useEffect(() => {
    fetchShipmentRates();
  }, [transportMode, palletInfo]);

  useEffect(() => {
    if (shipmentRatesQuery.loading) {
      appState.setLoading();
      setIsFetchingRates && setIsFetchingRates(true);
    } else {
      appState.removeLoading();
      setIsFetchingRates && setIsFetchingRates(false);
    }

    if (shipmentRatesQuery.error) {
      setRatesApiError(shipmentRatesQuery.error);
      appState.setAlert(
        shipmentRatesQuery.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
      setIsFetchingRates && setIsFetchingRates(false);
      appState.removeLoading();
    }

    if (shipmentRatesQuery.data) {
      segregateAndUpdateRates(
        shipmentRatesQuery.data.getShipmentRates.rates,
        preSelectedCarrierRate,
      );
      setErrors(shipmentRatesQuery.data.getShipmentRates.errors);
    }
  }, [
    shipmentRatesQuery.data,
    shipmentRatesQuery.loading,
    shipmentRatesQuery.error,
  ]);

  const onTransportTypeChange = (transportMode) => {
    setTransportMode(transportMode);
  };

  const onPalletSubmitEvent = (pallets) => {
    setPalletInfo(pallets);
    onPalletSubmit && onPalletSubmit(pallets);
  };

  if (boxes?.length === 0) {
    return (
      <div className="mb-2 block text-center text-xl">
        <img
          className="m-auto"
          src="https://hopstack-pub.s3.us-east-1.amazonaws.com/Celebration.svg"
        />
        <div className="pt-5">
          Boxes are required to get the rates, please select it from the
          previous screen or update the carrier manually
        </div>
      </div>
    );
  }

  return (
    <>
      {carrierIntegration && carrierIntegration.carrier && (
        <>
          <h1 className="mb-4 text-xl font-bold">
            Carrier Details From {orderSource}
          </h1>
          <div className="border-1 mb-4 flex items-center space-x-3 rounded-md border border-2 border-[#417492] bg-[#eff6ff] p-3">
            <span className="text-2xl text-blue-400">&#9432;</span>{" "}
            <div>
              <p>
                {carrierIntegration.carrier &&
                  carrierIntegration.carrier.length > 0 && (
                    <>
                      <span className="font-bold">Carrier:</span>{" "}
                      <span>{carrierIntegration.carrier}</span>
                    </>
                  )}
                {carrierIntegration.carrier &&
                  carrierIntegration.carrier.length > 0 &&
                  carrierIntegration.carrierService &&
                  carrierIntegration.carrierService.length > 0 &&
                  ", "}
                {carrierIntegration.carrierService &&
                  carrierIntegration.carrierService.length > 0 && (
                    <>
                      <span className="font-bold">Service:</span>{" "}
                      <span>{carrierIntegration.carrierService}</span>
                    </>
                  )}
              </p>
              {carrierIntegration?.notes && (
                <p className="text-gray-500">{carrierIntegration.notes}</p>
              )}
            </div>
            <br />
          </div>
        </>
      )}
      {showLTLRates && (
        <>
          <h1 className="mb-4 text-xl font-bold">
            Select Shipment Type<span className="text-red-500">*</span>
          </h1>
          <div className="mb-5 flex">
            <div className="grow">
              <div className="inline-block cursor-pointer">
                <input
                  name="transport-type"
                  type="radio"
                  id="small-parcel-shipment-type"
                  className="mr-4 cursor-pointer text-2C7695"
                  checked={transportMode === "SP"}
                  onChange={() => onTransportTypeChange("SP")}
                />
                <label
                  className="cursor-pointer"
                  htmlFor="small-parcel-shipment-type">
                  SP (Small Parcel)
                </label>
              </div>
            </div>
            <div className="grow">
              <div className="inline-block cursor-pointer">
                <input
                  name="transport-type"
                  type="radio"
                  id="ltl-shipment-type"
                  className="mr-4 cursor-pointer text-2C7695"
                  checked={transportMode === "LTL"}
                  onChange={() => onTransportTypeChange("LTL")}
                />
                <label className="cursor-pointer" htmlFor="ltl-shipment-type">
                  LTL
                </label>
              </div>
            </div>
          </div>
        </>
      )}
      {transportMode === "LTL" && (
        <RateShoppingLtlPallet
          onPalletSubmit={onPalletSubmitEvent}
          palletInfo={palletInfo}
        />
      )}

      {(recommendedRates.length > 0 || otherRates.length > 0) && (
        <>
          <h1 className="mb-4 text-xl font-bold">
            Select Carrier<span className="text-red-500">*</span>
          </h1>
          <div className="flex items-center space-x-3 rounded-lg border-2 border-yellow-300 bg-yellow-50 p-4">
            {/* Warning Icon */}
            <span className="text-2xl text-yellow-400">&#x26A0;</span>{" "}
            {/* Unicode Warning Symbol */}
            {/* Warning Text */}
            <span className="text-gray-500">
              The rates and delivery dates are just approx figures and are
              subjected to change later depending on the time of shipping and
              other factors.
            </span>
          </div>
        </>
      )}
      {ratesApiError && (
        <div className="mt-2 items-center space-x-3 rounded-lg border-2 border-red-300 bg-red-50 p-4">
          {/* Warning Icon */}
          <span className="text-2xl text-red-400">&#x26A0;</span>{" "}
          {/* Unicode Warning Symbol */}
          {/* Warning Text */}
          <div className="block text-gray-500">{ratesApiError?.message}</div>
          <span className="block text-gray-500">
            <ul className="list-disc pl-10">
              {(ratesApiError?.errors || []).map((error) => (
                <li>{error.message}</li>
              ))}
            </ul>
          </span>
        </div>
      )}
      {errors.map((error) => (
        <div className="mt-2 items-center space-x-3 rounded-lg border-2 border-red-300 bg-red-50 p-4">
          {/* Warning Icon */}
          <span className="text-2xl text-red-400">&#x26A0;</span>{" "}
          {/* Unicode Warning Symbol */}
          {/* Warning Text */}
          <span className="text-gray-500">{error.source}</span>
          <div className="block text-gray-500">{error?.message}</div>
          <span className="block text-gray-500">
            <ul className="list-disc pl-10">
              {error?.errors.map((errorMessage) => (
                <li>{errorMessage}</li>
              ))}
            </ul>
          </span>
        </div>
      ))}
      {shipmentRatesQuery.loading && <RateShoppingLoader />}
      {preSelectedRate && (
        <h2 className="mb-4 mt-6 text-xl font-bold">Pre-selected</h2>
      )}

      {preSelectedRate && (
        <div>
          <div
            className={`border-1 mb-4 flex cursor-pointer items-center rounded-md border border-2 p-3 hover:border-[#417492] hover:bg-[#f5faff] ${
              selectedRate?.selectedShipmentRateId === preSelectedRate.id
                ? "border-[#417492] bg-[#f5faff]"
                : ""
            }`}
            onClick={() => {
              onRateSelect({
                selectedShipmentRateId: preSelectedRate.id,
                selectedShipmentSource: preSelectedRate.source,
                transportMode,
                selectedCarrierRate: preSelectedRate,
                carrier: `${preSelectedRate?.carrier} - ${preSelectedRate?.service}`,
              });
            }}>
            <input
              type="radio"
              name="rate"
              className="mr-4 text-2C7695"
              checked={
                selectedRate?.selectedShipmentRateId === preSelectedRate.id
              }
            />
            <div className="flex w-full justify-between">
              <div>
                <p className="font-bold">
                  {preSelectedRate.carrier} {preSelectedRate.service}
                  {preSelectedRate?.labels?.map((label) => (
                    <span
                      className={`ml-4 inline-block px-3 py-1 text-sm font-light leading-none ${labelClass?.[label]} rounded-full`}>
                      {label}
                    </span>
                  ))}
                </p>
                <p className="text-gray-500">
                  Provided By: {preSelectedRate.source}
                </p>
              </div>
              <div>
                {/* TODO: Make currency dynamic */}
                <p className="text-right font-bold">
                  {getCurrencySymbol(preSelectedRate.currency)}
                  {preSelectedRate.price}
                </p>
                <p className="text-gray-500">
                  Estimated Delivery Date:{" "}
                  {preSelectedRate.estimated_delivery_date || "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {recommendedRates.length > 0 && (
        <h2 className="mb-4 mt-6 text-xl font-bold">Recommended</h2>
      )}
      {recommendedRates.map((recommendedRate) => (
        <div>
          <div
            className={`border-1 mb-4 flex cursor-pointer items-center rounded-md border border-2 p-3 hover:border-[#417492] hover:bg-[#f5faff] ${
              selectedRate?.selectedShipmentRateId === recommendedRate.id
                ? "border-[#417492] bg-[#f5faff]"
                : ""
            }`}
            onClick={() => {
              onRateSelect({
                selectedShipmentRateId: recommendedRate.id,
                selectedShipmentSource: recommendedRate.source,
                transportMode,
                selectedCarrierRate: recommendedRate,
                carrier: `${recommendedRate?.carrier} - ${recommendedRate?.service}`,
              });
            }}>
            <input
              type="radio"
              name="rate"
              className="mr-4 text-2C7695"
              checked={
                selectedRate?.selectedShipmentRateId === recommendedRate.id
              }
            />
            <div className="flex w-full justify-between">
              <div>
                <p className="font-bold">
                  {recommendedRate.carrier} {recommendedRate.service}
                  {recommendedRate.labels.map((label) => (
                    <span
                      className={`ml-4 inline-block px-3 py-1 text-sm font-light leading-none ${labelClass?.[label]} rounded-full`}>
                      {label}
                    </span>
                  ))}
                </p>
                <p className="text-gray-500">
                  Provided By: {recommendedRate.source}
                </p>
              </div>
              <div>
                {/* TODO: Make currency dynamic */}
                <p className="text-right font-bold">
                  {getCurrencySymbol(recommendedRate.currency)}
                  {recommendedRate.price}
                </p>
                <p className="text-gray-500">
                  Estimated Delivery Date:{" "}
                  {recommendedRate.estimated_delivery_date || "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}

      {otherRates.length > 0 && (
        <h2 className="mb-4 mt-6 text-xl font-bold">Other Carriers</h2>
      )}

      {otherRates.map((groupedRate) => {
        return (
          <div className="mt-4">
            <div className="rounded-md border-2 pb-4 pt-4">
              <div className="ms-2 pb-4 pl-4 pr-4 pt-2">
                <p className="font-bold">{groupedRate.carrier}</p>
                <p className="text-gray-500">
                  Provided By: {groupedRate.source}
                </p>
              </div>
              {groupedRate.rates.map((rate) => (
                <div
                  className={`flex cursor-pointer items-center pb-4 pl-4 pr-4 pt-2 hover:bg-[#f5faff] ${
                    selectedRate?.selectedShipmentRateId === rate.id
                      ? "bg-[#f5faff]"
                      : ""
                  }`}
                  onClick={() => {
                    onRateSelect({
                      selectedShipmentRateId: rate.id,
                      selectedShipmentSource: rate.source,
                      transportMode,
                      selectedCarrierRate: rate,
                      carrier: `${rate?.carrier} - ${rate?.service}`,
                    });
                  }}>
                  {/* Radio Button */}
                  <input
                    type="radio"
                    name="rate"
                    className="mr-4 mt-1 self-start"
                    checked={selectedRate?.selectedShipmentRateId === rate.id}
                  />

                  {/* Carrier, Service, Price and Delivery Date */}
                  <div className="flex w-full justify-between">
                    <div>
                      <p className="font-bold text-gray-500">{rate.service}</p>
                    </div>
                    <div>
                      <p className="text-right font-bold">
                        {getCurrencySymbol(rate.currency)}
                        {rate.price}
                      </p>
                      <p className="text-gray-500">
                        Estimated Delivery Date:{" "}
                        {rate.estimated_delivery_date || "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </>
  );
};

RateShopping.propTypes = {
  customer: PropTypes.string.isRequired,
  warehouse: PropTypes.string.isRequired,
  pallets: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
      weight: PropTypes.number.isRequired,
    }),
  ).isRequired,
  shippingAddress: PropTypes.shape({
    line1: PropTypes.string.isRequired,
    line2: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
  }).isRequired,
  boxes: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
      weight: PropTypes.number.isRequired,
    }),
  ).isRequired.isRequired,
  orderSource: PropTypes.string.isRequired,
  storedTransportMode: PropTypes.string.isRequired,
  onPalletSubmit: PropTypes.func.isRequired,
  onRateSelect: PropTypes.func.isRequired,
  selectedRate: PropTypes.shape({
    selectedShipmentRateId: PropTypes.string,
    selectedShipmentSource: PropTypes.string,
    transportMode: PropTypes.string,
  }),
  setIsFetchingRates: PropTypes.func.isRequired,
  validateAddress: PropTypes.bool,
};

export default RateShopping;
