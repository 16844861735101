import { useContext, useEffect } from "react";
import SlideOverModal from "#components/common/SlideOverModal";
import UploadBulk from "#newUiComponents/bulkUploadV2/UploadBulk";
import PastUploads from "#newUiComponents/bulkUploadV2/PastUploads";
import { ModalContext } from "#newUiComponents/bulkUploadV2/useReducer";
import { AppStateContext } from "#contexts/appState";
import CustomNotification from "#newUiComponents/commons/CustomNotification";
import { useQuery } from "#hooks/useQuery";
import moment from "moment-timezone";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { PrinterIcon } from "@heroicons/react/solid";
import { DOWNLOAD_PRESIGNED_URL_FILES } from "#queries";
import {
  BULK_UPLOAD_PRESIGNED_URL,
  BULK_UPLOAD_UPDATE,
  GET_PAST_BULK_UPLOADS,
} from "#mutations";
import {
  ACTIONS_TYPE_ENUM,
  GET_METHOD,
  PUT_METHOD,
  EXPIRATION_IN_SECONDS,
  STATUS_ENUM,
  BULK_UPLOAD_KEYS,
  BULK_UPLOAD_ENTITY_TYPES,
} from "#newUiComponents/bulkUploadV2/useReducer";

const BulkUploadSlideOverModal = ({
  dashboardFields,
  selectedActionType,
  setShowBulkUpload,
  showBulkUpload,
  bulkUploadDuplicateValidation,
  pastUploadsList,
  setSelectedActionType,
  bulkUploadDuplicateRowValidation,
}) => {
  const notify = CustomNotification();
  const appState = useContext(AppStateContext);
  const bulkUploadEntity = useContext(ModalContext);
  const bulkUploadPresignedURLQuery = useQuery(BULK_UPLOAD_PRESIGNED_URL);
  const bulkUploadUpdateQuery = useQuery(BULK_UPLOAD_UPDATE);
  const downloadPresignedUrlFilesQuery = useQuery(DOWNLOAD_PRESIGNED_URL_FILES);
  const listPastBulkUploadQuery = useQuery(GET_PAST_BULK_UPLOADS);

  const getBulkUploadPresignedUrl = async (metadata) => {
    if (bulkUploadEntity?.selectedFile && metadata) {
      try {
        appState.setLoading();
        const bulkUploadPresignedUrlInput = {
          fileName: bulkUploadEntity?.selectedFile?.name,
          fileSize: bulkUploadEntity?.selectedFile?.size,
          fileType: bulkUploadEntity?.selectedFile?.type,
          entityType: bulkUploadEntity?.bulkUploadEntityType,
        };
        const getBulkUploadPresignedUrlResponse =
          await bulkUploadPresignedURLQuery.fetchData({
            createBulkUploadInput: bulkUploadPresignedUrlInput,
          });
        appState.removeLoading();
        if (getBulkUploadPresignedUrlResponse.error) {
          console.log(getBulkUploadPresignedUrlResponse.error);
          bulkUploadEntity?.setModuleContentDetails({
            submitBulkUpload: false,
          });
          notify.error("An internal error occurred. Please try again.");
        } else if (getBulkUploadPresignedUrlResponse.data) {
          uploadFileOnS3Bucket(
            getBulkUploadPresignedUrlResponse.data?.createBulkUpload?.url,
            getBulkUploadPresignedUrlResponse.data?.createBulkUpload
              ?.bulkUploadId,
            metadata,
          );
        }
      } catch (error) {
        console.error(error);
        bulkUploadEntity?.setModuleContentDetails({
          submitBulkUpload: false,
        });
        notify.error("An internal error occurred. Please try again.");
      }
    }
  };

  const uploadFileOnS3Bucket = async (
    s3PresignedURL,
    bulkUploadId,
    metadata,
  ) => {
    if (s3PresignedURL && bulkUploadId && bulkUploadEntity?.selectedFile) {
      try {
        appState.setLoading();
        const fileBuffer = await bulkUploadEntity?.selectedFile.arrayBuffer();
        const fileUploadResponse = await fetch(s3PresignedURL, {
          method: PUT_METHOD,
          body: fileBuffer,
          headers: {
            "Content-Type": bulkUploadEntity?.bulkUploadFileContentType,
            "Content-Length": fileBuffer.byteLength.toString(),
          },
        });
        appState.removeLoading();
        if (fileUploadResponse.ok) {
          updateBulkUpload(bulkUploadId, metadata);
        } else {
          bulkUploadEntity?.setModuleContentDetails({
            submitBulkUpload: false,
          });
          notify.error("An error while uploading file. Please try again.");
        }
      } catch (error) {
        console.error(error);
        appState.removeLoading();
        bulkUploadEntity?.setModuleContentDetails({
          submitBulkUpload: false,
        });
        notify.error("An error while uploading file. Please try again.");
      }
    }
  };

  const updateBulkUpload = async (bulkUploadId, metadata) => {
    if (bulkUploadId && metadata) {
      try {
        appState.setLoading();
        const bulkUploadUpdateInput = {
          id: bulkUploadId,
          metadata: metadata,
          type: "metadata",
        };
        const getBulkUploadUpdateResponse =
          await bulkUploadUpdateQuery.fetchData({
            updateBulkUploadInput: bulkUploadUpdateInput,
          });
        appState.removeLoading();
        if (getBulkUploadUpdateResponse.error) {
          console.log(getBulkUploadUpdateResponse.error);
          bulkUploadEntity?.setModuleContentDetails({
            submitBulkUpload: false,
          });
          notify.error("An error while processing file. Please try again.");
        } else if (getBulkUploadUpdateResponse.data) {
          bulkUploadEntity?.resetModal();
          setSelectedActionType(null);
          setShowBulkUpload(false);
          if (
            bulkUploadEntity?.bulkUploadEntityType ===
            BULK_UPLOAD_ENTITY_TYPES?.WAREHOUSE
          ) {
            localStorage.setItem(
              BULK_UPLOAD_KEYS?.WAREHOUSE_ID,
              getBulkUploadUpdateResponse?.data?.updateBulkUpload?.bulkUploadId,
            );
            localStorage.setItem(
              BULK_UPLOAD_KEYS?.WAREHOUSE_FILE,
              bulkUploadEntity?.selectedFile?.name,
            );
          } else if (
            bulkUploadEntity?.bulkUploadEntityType ===
            BULK_UPLOAD_ENTITY_TYPES?.PRODUCT
          ) {
            localStorage.setItem(
              BULK_UPLOAD_KEYS?.PRODUCT_ID,
              getBulkUploadUpdateResponse?.data?.updateBulkUpload?.bulkUploadId,
            );
            localStorage.setItem(
              BULK_UPLOAD_KEYS?.PRODUCT_FILE,
              bulkUploadEntity?.selectedFile?.name,
            );
          } else if (
            bulkUploadEntity?.bulkUploadEntityType ===
            BULK_UPLOAD_ENTITY_TYPES?.CONSIGNMENT
          ) {
            localStorage.setItem(
              BULK_UPLOAD_KEYS?.CONSIGNMENT_ID,
              getBulkUploadUpdateResponse?.data?.updateBulkUpload?.bulkUploadId,
            );
            localStorage.setItem(
              BULK_UPLOAD_KEYS?.CONSIGNMENT_FILE,
              bulkUploadEntity?.selectedFile?.name,
            );
          }
          appState.showNewConfirmation(
            <div className="flex flex-row items-center justify-start gap-4">
              <CheckCircleIcon className="h-8 w-8 text-green-800" />
              <p>Your data is scheduled for upload successfully!</p>
            </div>,
            <div className="ml-12 flex flex-row items-center justify-start">
              <p>
                You will be notified via the system about the status of your
                upload. If the validation is successful, your upload will be
                completed.
              </p>
            </div>,
            () => {},
            () => {
              appState.hideNewConfirmation();
            },
            "Cancel",
            "Confirm",
            false,
          );
        }
      } catch (error) {
        console.error(error);
        appState.removeLoading();
        bulkUploadEntity?.setModuleContentDetails({
          submitBulkUpload: false,
        });
        notify.error("An error while processing file. Please try again.");
      }
    }
  };

  const menuItems = (bulkUpload) => {
    const arr = [];
    if (bulkUpload?.fileUploadStatus === STATUS_ENUM.UPLOADED) {
      arr.push({
        title: "Download Uploaded File",
        icon: <PrinterIcon className="mr-2 h-5 w-5" />,
        onClick: (fileDetails) =>
          downloadS3Files(fileDetails?.s3Key, fileDetails?.fileName),
        disabled: false,
      });
    }
    if (
      bulkUpload?.s3keyError &&
      bulkUpload?.fileUploadStatus === STATUS_ENUM.UPLOADED
    ) {
      arr.push({
        title: "Download Error Records",
        icon: <PrinterIcon className="mr-2 h-5 w-5" />,
        onClick: (fileDetails) => downloadS3Files(fileDetails?.s3keyError),
        disabled: false,
      });
    }
    return arr;
  };

  const downloadS3Files = async (fileKey, customFilename = null) => {
    try {
      appState.setLoading();
      const downloadFileInput = {
        key: fileKey,
        method: GET_METHOD,
        expirationInSeconds: EXPIRATION_IN_SECONDS,
      };
      const downloadFilesResponse =
        await downloadPresignedUrlFilesQuery.fetchData(downloadFileInput);
      appState.removeLoading();
      if (downloadFilesResponse.error) {
        console.log(downloadFilesResponse.error);
        notify.error("File download failed. Please try again later");
      } else if (downloadFilesResponse.data) {
        if (downloadFilesResponse.data?.getS3PresignedUrl?.url) {
          if (customFilename) {
            downloadFileWithCustomName(
              downloadFilesResponse.data?.getS3PresignedUrl?.url,
              customFilename,
            );
          } else {
            window.location =
              downloadFilesResponse.data?.getS3PresignedUrl?.url;
          }
        }
      }
    } catch (error) {
      console.error(error);
      notify.error("File download failed. Please try again later");
    }
  };

  const downloadFileWithCustomName = async (presignedUrl, customFilename) => {
    if (presignedUrl && customFilename) {
      try {
        appState.setLoading();
        // Fetch the file content
        const response = await fetch(presignedUrl);
        const blob = await response.blob();

        // Create a temporary URL for the blob
        const blobUrl = window.URL.createObjectURL(blob);

        // Create an invisible anchor element
        const anchor = document.createElement("a");
        anchor.style.display = "none";
        anchor.href = blobUrl;
        anchor.download = customFilename;
        appState.removeLoading();
        // Append to the document, trigger click, and remove
        document.body.appendChild(anchor);
        anchor.click();
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(anchor);
      } catch (error) {
        console.error("Error downloading file:", error);
        notify.error("Error downloading file. Please try again later");
      }
    }
  };

  const openPastUploadsDetails = async () => {
    try {
      appState.setLoading();
      const listBulkUploadInput = {
        entityType: bulkUploadEntity?.bulkUploadEntityType,
      };
      const getPastBulkUploadsResponse =
        await listPastBulkUploadQuery.fetchData({
          listBulkUploadInput: listBulkUploadInput,
        });
      appState.removeLoading();
      if (getPastBulkUploadsResponse.error) {
        console.log(getPastBulkUploadsResponse.error);
        bulkUploadEntity?.setPastUploadsEntities({
          pastUploadsEntities: [],
        });
      } else if (getPastBulkUploadsResponse.data) {
        let pastBulkUploadsList = getPastBulkUploadsResponse?.data
          ?.listBulkUpload?.entities
          ? getPastBulkUploadsResponse?.data?.listBulkUpload?.entities
          : [];
        pastBulkUploadsList = pastBulkUploadsList.map((bulkUpload) => {
          return {
            ...bulkUpload,
            dateOfUpload: bulkUpload?.dateOfUpload
              ? moment
                  .utc(bulkUpload?.dateOfUpload)
                  .local()
                  .format("MMM DD, YYYY, hh:mm:ss A")
              : null,
            s3keyError: bulkUpload?.s3keyError
              ? bulkUpload?.s3keyError?.split("/")?.slice(1)?.join("/")
              : null,
            s3Key: bulkUpload?.s3Key
              ? bulkUpload?.s3Key?.split("/")?.slice(1)?.join("/")
              : null,
          };
        });
        bulkUploadEntity?.setPastUploadsEntities({
          pastUploadsEntities: pastBulkUploadsList,
        });
      }
    } catch (error) {
      console.error(error);
      bulkUploadEntity?.setPastUploadsEntities({
        pastUploadsEntities: [],
      });
    }
  };

  useEffect(() => {
    if (
      selectedActionType &&
      selectedActionType?.value === ACTIONS_TYPE_ENUM?.UPLOAD_FILE
    ) {
      if (bulkUploadEntity?.submitBulkUpload) {
        getBulkUploadPresignedUrl({
          mapping: bulkUploadEntity?.mappingFields,
        });
      }
    }
  }, [bulkUploadEntity?.submitBulkUpload]);

  useEffect(() => {
    if (
      selectedActionType &&
      selectedActionType?.value === ACTIONS_TYPE_ENUM?.PAST_UPLOADS
    ) {
      openPastUploadsDetails();
    }
  }, [selectedActionType]);

  const Titles = ({}) => {
    if (
      selectedActionType &&
      bulkUploadEntity?.selectedStep?.id &&
      selectedActionType?.value === ACTIONS_TYPE_ENUM?.UPLOAD_FILE
    ) {
      let selectedStep = selectedActionType?.steps;
      selectedStep =
        selectedStep && selectedStep?.length !== 0
          ? selectedStep.filter(
              (step) => step["id"] === bulkUploadEntity?.selectedStep?.id,
            )
          : [];
      if (selectedStep && selectedStep?.length !== 0) {
        return (
          <span className="text-2xl font-semibold">
            {selectedStep[0]?.title}
          </span>
        );
      }
    } else {
      return (
        <span className="text-2xl font-semibold">
          {selectedActionType?.name}
        </span>
      );
    }
    return null;
  };
  const SubTitles = ({}) => {
    if (
      selectedActionType &&
      bulkUploadEntity?.selectedStep?.id &&
      selectedActionType?.value === ACTIONS_TYPE_ENUM?.UPLOAD_FILE
    ) {
      let selectedStep = selectedActionType?.steps;
      selectedStep =
        selectedStep && selectedStep?.length !== 0
          ? selectedStep.filter(
              (step) => step["id"] === bulkUploadEntity?.selectedStep?.id,
            )
          : [];
      if (selectedStep && selectedStep?.length !== 0) {
        return (
          <span className="text-base font-light text-gray-400">
            {selectedStep[0]?.subTitle
              ? selectedStep[0]?.subTitle?.replace(
                  "moduleName",
                  bulkUploadEntity?.bulkUploadEntityType,
                )
              : selectedStep[0]?.subTitle}
          </span>
        );
      }
    } else {
      return (
        <span className="text-base font-light text-gray-400">
          {selectedActionType?.subTitle}
        </span>
      );
    }
    return null;
  };

  return (
    <SlideOverModal
      open={showBulkUpload}
      setOpen={setShowBulkUpload}
      title={<Titles />}
      subtitle={<SubTitles />}
      staticBackdrop={true}
      overflow={true}
      width="w-3/5"
      onClose={() => {
        if (!appState?.loading) {
          setShowBulkUpload(false);
        }
      }}>
      {selectedActionType &&
        selectedActionType?.value === ACTIONS_TYPE_ENUM?.UPLOAD_FILE && (
          <UploadBulk
            dashboardFields={dashboardFields}
            bulkUploadDuplicateValidation={bulkUploadDuplicateValidation}
            bulkUploadDuplicateRowValidation={bulkUploadDuplicateRowValidation}
            onClose={() => {
              if (!appState?.loading) {
                setShowBulkUpload(false);
              }
            }}
          />
        )}
      {selectedActionType &&
        selectedActionType?.value === ACTIONS_TYPE_ENUM?.PAST_UPLOADS && (
          <PastUploads
            pastUploadsList={pastUploadsList}
            menuItems={menuItems}
          />
        )}
    </SlideOverModal>
  );
};

export default BulkUploadSlideOverModal;
