import { useState, useRef, useEffect } from "react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  SearchIcon,
} from "@heroicons/react/outline";
import { CheckCircleIcon, XIcon } from "@heroicons/react/solid";

export default function AutocompleteSingleSelectDropdown({
  options = [],
  value,
  onChange,
  labelKey = "name",
  valueKey = "id",
  placeholder,
  onKeyDown,
  secondaryLabelKey,
  showValueAsSelected = false,
  showCheckedIndicator = true,
  clearSelection,
  sortOptions = true,
  textNormal = "",
  disabled = false,
}) {
  const [text, setText] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [cursor, setCursor] = useState(-1);
  const ref = useRef();

  const select = (option) => {
    onChange(option);
    setShowOptions(false);
    setText("");
  };

  const handleChange = (text) => {
    setText(text);
    if (onKeyDown) {
      onKeyDown(text);
    }
    setCursor(-1);
    if (!showOptions) {
      setShowOptions(true);
    }
  };

  const filteredOptions =
    options && options.length > 0
      ? sortOptions
        ? options
            .filter((option) =>
              option?.[labelKey]?.toLowerCase()?.includes(text?.toLowerCase()),
            )
            .sort(
              (a, b) =>
                b.frequency - a.frequency ||
                a[labelKey].localeCompare(b[labelKey]),
            )
        : options.filter((option) =>
            option?.[labelKey]?.toLowerCase()?.includes(text?.toLowerCase()),
          )
      : [];

  const moveCursorDown = () => {
    if (cursor < filteredOptions.length - 1) {
      setCursor((c) => c + 1);
    }
  };

  const moveCursorUp = () => {
    if (cursor > 0) {
      setCursor((c) => c - 1);
    }
  };

  const handleNav = (e) => {
    switch (e.key) {
      case "ArrowUp":
        moveCursorUp();
        break;
      case "ArrowDown":
        moveCursorDown();
        break;
      case "Enter":
        if (cursor >= 0 && cursor < filteredOptions.length) {
          select(filteredOptions[cursor][valueKey]);
        }
        break;
      case "Space":
        if (cursor >= 0 && cursor < filteredOptions.length) {
          select(filteredOptions[cursor][valueKey]);
        }
        break;
    }
  };

  useEffect(() => {
    const listener = (e) => {
      if (!ref || !ref.current || !ref.current.contains(e.target)) {
        setShowOptions(false);
        setCursor(-1);
        // also empty the search text
        setText("");
      }
    };

    document.addEventListener("click", listener);
    // document.addEventListener("focusin", listener);
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("click", listener);
      // document.removeEventListener("focusin", listener);
      document.addEventListener("mousedown", listener);
    };
  }, []);
  return (
    <div className="comboDropdown" ref={ref}>
      <div
        className="comboDropdownTextField"
        onClick={() => setShowOptions(!showOptions)}
        ref={ref}>
        <div className={`comboDropdownTextFieldLabel flex ${textNormal}`}>
          {(options &&
            options.find((o) => o[valueKey] === value)?.[labelKey]) ||
            placeholder}

          {(showValueAsSelected || value) && showCheckedIndicator && (
            <CheckCircleIcon className="comboDropdownChecked" />
          )}
        </div>
        <div className="comboDropdownTextFieldArrow">
          {clearSelection && value && (
            <div
              className="cursor-pointer font-medium"
              onClick={(e) => {
                e.stopPropagation();
                clearSelection();
              }}>
              <XIcon className="h-6 w-6" />
            </div>
          )}
        </div>
        <div className="comboDropdownTextFieldArrow pointer-events-none">
          {showOptions === true ? (
            <ChevronUpIcon className="h-6 w-6" />
          ) : (
            <ChevronDownIcon className="h-6 w-6" />
          )}
        </div>
      </div>
      {showOptions && (
        <div className="comboDropdownList">
          {/* Add a search field here */}
          {options && options.length > 0 && (
            <div className="comboDropdownSearchWrapper">
              <input
                type="search"
                placeholder="Search"
                className={`comboDropdownSearchField ${textNormal}`}
                onKeyDown={handleNav}
                onChange={(e) => handleChange(e.target.value)}
                autoFocus
              />
              <div className="comboDropdownTextFieldArrow">
                <SearchIcon className="h-6 w-6" />
              </div>
            </div>
          )}

          <div className="comboDropdownResults">
            {filteredOptions.length === 0 ? (
              <>
                <div className="comboDropdownNoResults">No results</div>
              </>
            ) : (
              filteredOptions.map((option, i, arr) => {
                const label = option[labelKey];
                const index = label.toLowerCase().indexOf(text.toLowerCase());
                const beforeMatch = label.substring(0, index);
                const match = label.substring(index, index + text.length);
                const afterMatch = label.substring(index + text.length);

                return (
                  <div
                    key={i}
                    className={`comboDropdownListItem ${
                      cursor === i && "activeItemScroll"
                    } ${textNormal}`}
                    onClick={() => {
                      select(option[valueKey]);
                    }}>
                    {beforeMatch}
                    <span className="bg-yellow-200 font-bold">{match}</span>
                    {afterMatch}{" "}
                    {secondaryLabelKey &&
                      option[secondaryLabelKey] &&
                      `(${option[secondaryLabelKey]})`}
                  </div>
                );
              })
            )}
          </div>
        </div>
      )}
    </div>
  );
}
