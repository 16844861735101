import React, { useState, Fragment, useEffect } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { TrashIcon } from "@heroicons/react/outline";
import { Transition } from "@headlessui/react";
import SearchAndAddProducts from "./SearchAndAddProducts";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";
import CustomPopconfirm from "../commons/CustomPopConfirm";
import CustomAlert from "../commons/CustomAlert";
import SuggestedChangesInfo from "./SuggestedChangesInfo";
import InputWithSelect from "#newUiComponents/commons/InputWithSelect";
import { v4 as uuidv4 } from "uuid";
import {
  PO_STRING_VALUES,
  PURCHASE_ORDER_STATUSES,
  UOM_VALUES_FOR_COMPONENTS,
  METAL_LOSS_PERCENTAGE_OPTIONS,
  handleMetalLossCost,
} from "./PoUtils";
import { Tooltip } from "antd";

const AddProductsMain = ({
  toCreate, // to identify if po is being edited or created
  currency,
  productForPurchase,
  fetchCatalogProduct,
  products,
  setProducts,
  charges,
  setCharges,
  suggestedChangesMerged,
  setShowSuggestedChanges,
  purchaseOrderDetailsToShow,
  parentProductForPurchase,
}) => {
  const [expandedProductId, setExpandedProductId] = useState(null);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [error, setError] = useState({ index: -1, message: "" });

  useEffect(() => {
    if (productForPurchase) {
      const updatedProducts = productForPurchase.map((product) => {
        return formatProductForPurchase(product);
      });
      if (updatedProducts.length > 0) {
        setProducts((prevProducts) => {
          const products = prevProducts.map((product) => {
            if (selectedProductIds.includes(product.id)) {
              return {
                ...product,
                quantity: product.quantity + 1,
                totalCost: product.cost * (product.quantity + 1),
              };
            }
            return product;
          });

          setSelectedProductIds([]);

          return [
            ...products,
            ...updatedProducts.filter(
              (product) =>
                !products.some(
                  (existingProduct) => existingProduct.id === product.id,
                ),
            ),
          ];
        });
      }
    }
  }, [productForPurchase]);

  useEffect(() => {
    setProducts((prevProducts) => {
      return prevProducts.map((product) => {
        if (
          (product.currency && currency.split("-")[0] !== product.currency) ||
          (product.costCurrency &&
            currency.split("-")[0] !== product.costCurrency)
        ) {
          setCharges((charges) => {
            return charges.map((charge) => {
              return {
                ...charge,
                value: 0,
              };
            });
          });
          return formatProductForPurchase(product);
        } else return product;
      });
    });
  }, [currency]);

  const formatProductForPurchase = (product) => {
    const defaultCurrency = "USD";
    // product.currency :- while editing the product we get currency in this key
    // product.costCurrency : - while creating new po while changing the format of the product we add this key
    // defaultCurrency :- if currency is not there then default will be "USD"

    const productCurrency =
      product.currency || product.costCurrency || defaultCurrency;

    const getUsageQuantity = (material) => {
      const uom =
        !toCreate && material.uom
          ? material.uom
          : material.attributes?.price_cost_showas
            ? material.attributes?.price_cost_showas
            : "pc";
      if (["gm", "ct"].includes(uom)) {
        return !toCreate && material.quantity
          ? material.quantity
          : material.attributes?.weight
            ? material.attributes?.weight
            : 0;
      } else if (uom === "pc") {
        return material.quantity ? material.quantity : 0;
      }
    };

    const getPricePerMaterial = (material) => {
      // this function is called after formatting materials
      const uom = material.uom || "pc";
      if (["gm", "ct"].includes(uom)) {
        return (
          material.unitPrice *
            material.quantity *
            material.masterData?.quantity || 0
        );
      } else if (uom === "pc") {
        return material.unitPrice * material.quantity || 0;
      }
    };

    const formattedMaterials = product.materials?.map((material) => {
      return {
        ...material,
        quantity: getUsageQuantity(material),
        uom:
          !toCreate && material.uom
            ? material.uom
            : material.attributes?.price_cost_showas
              ? material.attributes?.price_cost_showas
              : "pc",
        unitPrice: (() => {
          if (productCurrency !== currency?.split("-")[0]) return 0;
          return !toCreate && material.unitPrice
            ? Number(material.unitPrice).toFixed(2)
            : material.attributes?.cost_uom
              ? Number(material.attributes?.cost_uom).toFixed(2)
              : 0;
        })(),
        metalLossPercentage:
          !toCreate &&
          material.metalLossPercentage &&
          material.masterData.componentType === PO_STRING_VALUES.METAL_STRING
            ? material.metalLossPercentage
            : material.attributes?.component_type ===
                PO_STRING_VALUES.METAL_STRING
              ? METAL_LOSS_PERCENTAGE_OPTIONS[0].value
              : null,
        masterData: {
          quantity: material.masterData?.quantity
            ? material.masterData?.quantity
            : material.quantity
              ? material.quantity
              : 0,
          uom: material.masterData?.uom
            ? material.masterData?.uom
            : material.attributes?.price_cost_showas
              ? material.attributes?.price_cost_showas
              : "pc",
          unitPrice: material.masterData?.unitPrice
            ? material.masterData?.unitPrice
            : material.attributes?.cost_uom
              ? material.attributes?.cost_uom
              : 0,
          weight: material.masterData?.weight
            ? material.masterData?.weight
            : material.attributes?.weight
              ? material.attributes?.weight
              : 0,
          componentType: material.masterData?.componentType
            ? material.masterData?.componentType
            : material.attributes?.component_type
              ? material.attributes?.component_type
              : "",
        },
      };
    });

    const getMaterialCost = (material, productCurrency, propCurrency) => {
      // const price = material.pricing ? Number(material.pricing.totalPrice) : 0;
      let price = getPricePerMaterial(material);
      if (material.masterData.componentType === PO_STRING_VALUES.METAL_STRING) {
        price += handleMetalLossCost(material);
      }
      return productCurrency === propCurrency ? price : 0;
    };

    const materialsCost =
      formattedMaterials?.reduce(
        (acc, material) =>
          acc +
          getMaterialCost(material, productCurrency, currency?.split("-")[0]),
        0,
      ) || 0;

    const components =
      formattedMaterials?.map((material) => ({
        ...material,
        costCurrency: material.currency ?? defaultCurrency,
        cost: getMaterialCost(
          material,
          productCurrency,
          currency?.split("-")[0],
        ).toFixed(2),
      })) || [];

    const productCost =
      productCurrency === currency?.split("-")[0]
        ? product.materials?.length > 0
          ? materialsCost
          : product.pricing
            ? Number(product.pricing.computedPriceFields.totalPrice).toFixed(2)
            : 0
        : 0;

    return {
      ...product,
      baseUom: product.baseUom || product.uom || "",
      image: product.images?.[0]?.url || FALLBACK_IMAGE_URL,
      components,
      quantity: product.quantity || 1,
      costCurrency: productCurrency,
      cost: productCost,
      totalCost: productCost * (product.quantity || 1),
    };
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const handleDelete = (id) =>
    setProducts((prevProducts) =>
      prevProducts.filter((product) => product.id !== id),
    );

  const toggleComponents = (id) =>
    setExpandedProductId(expandedProductId === id ? null : id);

  const updateComponent = (productId, index, field, value) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) => {
        if (product.id === productId) {
          const updatedComponents = [...product.components];

          // Reset quantity and unitPrice if 'uom' field is changed and value is different from masterData.uom
          if (
            field === "uom" &&
            value !== updatedComponents[index]?.masterData?.uom
          ) {
            updatedComponents[index] = {
              ...updatedComponents[index],
              quantity: 0,
              unitPrice: 0,
            };
          }

          // Update the component based on the field and value
          updatedComponents[index] = {
            ...updatedComponents[index],
            [field]: value,
          };

          // Update the cost based on the updated component
          const cost = ["gm", "ct"].includes(updatedComponents[index].uom)
            ? updatedComponents[index].unitPrice *
              updatedComponents[index].quantity *
              updatedComponents[index].masterData.quantity
            : updatedComponents[index].unitPrice *
              updatedComponents[index].quantity;

          updatedComponents[index].cost = cost.toFixed(2);

          // Calculate the total cost for the product
          const componentCost = updatedComponents.reduce((acc, component) => {
            if (
              updatedComponents[index].masterData.componentType ===
              PO_STRING_VALUES.METAL_STRING
            )
              return (
                acc + Number(component.cost) + handleMetalLossCost(component)
              );
            else return acc + Number(component.cost);
          }, 0);

          return {
            ...product,
            components: updatedComponents,
            cost: componentCost,
            totalCost: componentCost * product.quantity,
          };
        }
        return product;
      }),
    );
  };

  const handleProductCostValue = (value) => {
    if (!/^0\./.test(value?.toString())) {
      return parseFloat(value.toString().replace(/^0+/, "")) || 0;
    }
    return value;
  };

  const updateProductCost = (productId, value) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) => {
        if (product.id === productId) {
          const cost = value === null ? null : parseFloat(value) || 0;
          return {
            ...product,
            cost,
            totalCost: cost === null ? null : cost * product.quantity,
          };
        }
        return product;
      }),
    );
  };

  const updateQuantity = (productId, value) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) => {
        if (product.id === productId) {
          const newQuantity = !value && value < 1 ? 1 : Number(value);
          const newTotalCost = product.cost * newQuantity;
          return { ...product, quantity: newQuantity, totalCost: newTotalCost };
        }
        return product;
      }),
    );
  };

  const handleEditCharge = (index) => {
    setCharges((charges) =>
      charges.map((charge, idx) =>
        idx === index ? { ...charge, default: false } : charge,
      ),
    );
  };

  const handleAddCharge = () => {
    const notNameEntered = charges.some((charge) => !charge.name);
    const notChargeEntered = charges.some((charge) => {
      return charge.value === "" || isNaN(Number(charge.value));
    });
    if (notNameEntered || notChargeEntered) {
      setError({ index: charges.length - 1, message: "Field cannot be empty" });
    } else {
      setError({ index: -1, message: "" });
      setCharges([...charges, { name: "", value: 0, default: false }]);
    }
  };

  const updateCharge = (index, field, value) => {
    const updatedCharges = [...charges];
    updatedCharges[index] = {
      ...updatedCharges[index],
      [field]: field === "value" && !value ? "" : value,
    };
    setCharges(updatedCharges);
  };

  const subTotal = products.reduce(
    (acc, product) => acc + (product.totalCost || 0),
    0,
  );
  const totalPO = charges.reduce(
    (acc, charge) =>
      acc +
      parseFloat(
        charge.name === "Discount" ? -1 * charge.value : charge.value || 0,
      ),
    subTotal,
  );

  const showCosts = (product) => true;

  return (
    <div className="overflow-auto p-4">
      {suggestedChangesMerged === false &&
        purchaseOrderDetailsToShow.status ===
          PURCHASE_ORDER_STATUSES.VENDOR_CHANGES_REQUESTED && (
          <div className="mb-4 w-full">
            <SuggestedChangesInfo
              setShowSuggestedChanges={setShowSuggestedChanges}
            />
          </div>
        )}
      <SearchAndAddProducts
        selectedProductIds={selectedProductIds}
        setSelectedProductIds={setSelectedProductIds}
        fetchCatalogProduct={fetchCatalogProduct}
        automaticallyFetchParentProductId={parentProductForPurchase?.id}
      />
      <div className="mt-4 min-w-full">
        <div
          style={{ gridTemplateColumns: "3fr 1fr 1fr 1fr 1fr 1fr" }}
          className="sticky top-0 z-10 grid h-14 grid-cols-6 items-center gap-4 rounded-md border border-borderGray bg-bgGray px-4 text-left text-sm font-medium tracking-wider text-gray-500">
          <div>Product Info</div>
          <div>UOM</div>
          <div>
            Quantity <span className="text-red-500">*</span>
          </div>
          <div>Item Cost/ Item</div>
          <div>Total Cost</div>
          <div>Action</div>
        </div>
        <div
          style={{ maxHeight: "40rem" }}
          className="min-w-full overflow-auto">
          {products.length > 0 &&
            products.map((product) => (
              <div key={product.id} className="border-b py-4">
                <div
                  style={{ gridTemplateColumns: "3fr 1fr 1fr 1fr 1fr 1fr" }}
                  className="grid grid-cols-6 items-start gap-4 p-4">
                  <div className="flex items-center">
                    <img
                      src={product.image}
                      alt={product.name}
                      className="mr-4 h-16 w-16"
                    />
                    <div>
                      <Tooltip title={product.name}>
                        <div className="w-48 truncate text-base font-medium">
                          {product.name}
                        </div>
                      </Tooltip>
                      <div className="text-gray-500">SKU: {product.sku}</div>
                      <div className="text-gray-500">
                        Variant: {product.variant}
                      </div>
                    </div>
                  </div>
                  <div className="rounded border bg-gray-100 p-2">
                    {product.baseUom ? product.baseUom : ""}
                  </div>
                  <input
                    type="number"
                    className="w-full rounded border p-2"
                    value={product.quantity}
                    onWheel={numberInputOnWheelPreventChange}
                    min={1}
                    onChange={(e) => updateQuantity(product.id, e.target.value)}
                  />
                  <div>
                    {showCosts(product) ? (
                      product.components?.length > 0 ? (
                        <span>
                          {currency.split("-")[1]}
                          {Number(product.cost).toFixed(2)}
                        </span>
                      ) : (
                        <div className="flex w-full">
                          <div className="w-1/4 rounded-l border p-2">
                            {currency.split("-")[1]}
                          </div>
                          <input
                            type="number"
                            className="w-3/4 rounded-r border p-2"
                            onWheel={numberInputOnWheelPreventChange}
                            value={
                              product.cost === null
                                ? null
                                : handleProductCostValue(product.cost || 0)
                            }
                            min="0"
                            onChange={(e) =>
                              updateProductCost(
                                product.id,
                                e.target.value === "" ? null : e.target.value,
                              )
                            }
                          />
                        </div>
                      )
                    ) : (
                      "-"
                    )}
                  </div>
                  <div>
                    {showCosts(product)
                      ? `${currency.split("-")[1]}${!product.totalCost ? "0" : Number(product.totalCost).toFixed(2)}`
                      : "-"}
                  </div>
                  <div>
                    <CustomPopconfirm
                      title="Are you sure to delete this product?"
                      onConfirm={() => handleDelete(product.id)}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                      okButtonProps={{
                        className: "bg-primaryAccent text-white",
                      }}
                      cancelButtonProps={{
                        className:
                          "bg-white border rounded border-primaryAccent text-primaryAccent",
                      }}>
                      <TrashIcon className="h-6 w-6 cursor-pointer text-red-500" />
                    </CustomPopconfirm>
                  </div>
                </div>
                {product.components?.length > 0 && (
                  <button
                    onClick={() => toggleComponents(product.id)}
                    className="mt-2 flex items-center font-semibold text-primaryAccent underline">
                    {expandedProductId === product.id
                      ? "Hide Components"
                      : "View Components"}
                    {expandedProductId === product.id ? (
                      <ChevronUpIcon className="mr-1 h-5 w-5" />
                    ) : (
                      <ChevronDownIcon className="mr-1 h-5 w-5" />
                    )}
                  </button>
                )}
                <Transition
                  as={Fragment}
                  show={expandedProductId === product.id}
                  enter="transition ease-out duration-200 transform"
                  enterFrom="-translate-y-10 opacity-0"
                  enterTo="translate-y-0 opacity-100"
                  leave="transition ease-in duration-150 transform"
                  leaveFrom="translate-y-0 opacity-100"
                  leaveTo="-translate-y-10 opacity-0">
                  <div className="mt-2 w-full border-t p-4 pt-2">
                    <div className="mb-2 w-full text-gray-600">
                      <CustomAlert
                        id="alertForComponent"
                        type="info"
                        message="Please note that any update made to the component pricing will only be applicable to the current PO."
                        options={{
                          defaultColors: true,
                        }}
                      />
                      <div
                        style={{
                          gridTemplateColumns: "2fr 1fr 1fr 1.5fr 1fr 1fr",
                        }}
                        className="grid h-14 w-full items-center gap-4 rounded-md border border-borderGray bg-bgGray px-4 text-left text-sm font-medium tracking-wider text-gray-500">
                        <div>Component Info</div>
                        <div>Quantity</div>
                        <div>Recipe Unit</div>
                        <div>Used Unit</div>
                        <div>Price/Unit</div>
                        <div>Cost</div>
                      </div>
                      {product.components?.map((component, index) => (
                        <Fragment key={index}>
                          <div
                            style={{
                              gridTemplateColumns: "2fr 1fr 1fr 1.5fr 1fr 1fr",
                            }}
                            className="grid w-full items-start gap-4 p-4">
                            <div
                              className={`flex h-full flex-col items-start gap-3`}>
                              <Tooltip title={component.name}>
                                <span className="w-48 truncate">
                                  {" "}
                                  {component.name}
                                </span>
                              </Tooltip>
                              {component.masterData?.componentType ===
                                PO_STRING_VALUES.METAL_STRING && (
                                <div>
                                  <p className="text-sm">
                                    Metal loss Percentage
                                  </p>
                                  <p className="mt-3 flex flex-wrap items-center gap-2 text-xs">
                                    <span>Select Metal Loss %: </span>
                                    <select
                                      value={component.metalLossPercentage}
                                      onChange={(e) => {
                                        updateComponent(
                                          product.id,
                                          index,
                                          "metalLossPercentage",
                                          e.target.value === ""
                                            ? null
                                            : e.target.value,
                                        );
                                      }}
                                      className={`w-20 cursor-pointer rounded border-gray-300 bg-white p-2 focus:outline-none`}
                                      id="">
                                      {METAL_LOSS_PERCENTAGE_OPTIONS.map(
                                        (option) => (
                                          <option
                                            key={uuidv4()}
                                            value={option.value}>
                                            {option.label}
                                          </option>
                                        ),
                                      )}
                                    </select>
                                  </p>
                                </div>
                              )}
                            </div>
                            <div
                              className={`flex h-full w-full ${component.masterData.componentType === PO_STRING_VALUES.METAL_STRING ? "items-start" : "items-center"}`}>
                              <span>
                                {component.masterData.quantity
                                  ? component.masterData.quantity
                                  : 0}
                              </span>
                            </div>
                            <div
                              className={`flex h-full w-full ${component.masterData.componentType === PO_STRING_VALUES.METAL_STRING ? "items-start" : "items-center"}`}>
                              <span>
                                {(["gm", "ct"].includes(
                                  component.masterData?.uom,
                                )
                                  ? component.masterData?.weight
                                  : component.masterData.quantity) || 0}
                              </span>
                              <span className="ml-2">
                                {UOM_VALUES_FOR_COMPONENTS[
                                  component.masterData?.uom
                                ] || "Pcs"}
                              </span>
                            </div>
                            <div
                              className={`flex h-full w-full flex-col gap-3 ${component.masterData.componentType === PO_STRING_VALUES.METAL_STRING ? "items-start" : "items-center"}`}>
                              <InputWithSelect
                                inputProps={{
                                  type: "number",
                                  value: component.quantity
                                    ? component.quantity
                                    : 0,
                                  onChange: (e) => {
                                    updateComponent(
                                      product.id,
                                      index,
                                      "quantity",
                                      e.target.value === ""
                                        ? null
                                        : e.target.value,
                                    );
                                  },
                                  disabled: false,
                                }}
                                selectProps={{
                                  value: component.uom ? component.uom : "",
                                  onChange: (e) => {
                                    updateComponent(
                                      product.id,
                                      index,
                                      "uom",
                                      e.target.value === ""
                                        ? null
                                        : e.target.value,
                                    );
                                  },
                                  disabled: false,
                                  options: [
                                    { value: "pc", label: "Pcs" },
                                    { value: "gm", label: "Gms" },
                                    { value: "ct", label: "Cts" },
                                  ],
                                }}
                              />
                              {component.masterData?.componentType ===
                                PO_STRING_VALUES.METAL_STRING && (
                                <div className="flex h-full flex-wrap items-center gap-2">
                                  <p className="text-xs">
                                    Metal loss used unit:
                                  </p>
                                  <p>
                                    {Number(
                                      component.quantity *
                                        (component.metalLossPercentage / 100),
                                    ).toFixed(2)}{" "}
                                    {UOM_VALUES_FOR_COMPONENTS[component.uom]}
                                  </p>
                                </div>
                              )}
                            </div>
                            <div
                              className={`flex h-full w-full ${component.masterData.componentType === PO_STRING_VALUES.METAL_STRING ? "items-start" : "items-center"}`}>
                              <div className="w-1/4 rounded-l border p-2">
                                {currency.split("-")[1]}
                              </div>
                              <input
                                type="number"
                                className="w-3/4 rounded-r border p-2"
                                onWheel={numberInputOnWheelPreventChange}
                                value={
                                  component.unitPrice === null
                                    ? null
                                    : handleProductCostValue(
                                        component.unitPrice || 0,
                                      )
                                }
                                min="0"
                                onChange={(e) =>
                                  updateComponent(
                                    product.id,
                                    index,
                                    "unitPrice",
                                    e.target.value === ""
                                      ? null
                                      : e.target.value,
                                  )
                                }
                              />
                            </div>
                            <div
                              className={`flex h-full flex-col items-center gap-3`}>
                              <div className="flex h-1/2 w-full justify-start">
                                {" "}
                                <span>{currency.split("-")[1]}</span>
                                <span>
                                  {component.cost === null
                                    ? null
                                    : handleProductCostValue(
                                        component.cost || 0,
                                      )}
                                </span>
                              </div>
                              {component.masterData.componentType ===
                                PO_STRING_VALUES.METAL_STRING && (
                                <div className="flex w-full flex-wrap items-center">
                                  <p className="text-xs">Metal loss cost:</p>
                                  <span className="ml-1">
                                    {currency.split("-")[1]}
                                    {Number(
                                      handleMetalLossCost(component),
                                    ).toFixed(2)}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            style={{ height: "1px" }}
                            className="w-full bg-gray-200"></div>
                        </Fragment>
                      ))}
                    </div>
                  </div>
                </Transition>
              </div>
            ))}
          {products.length === 0 && (
            <div className="flex h-16 w-full items-center">
              <span className="ml-6 text-sm">
                No products found , add products to proceed with purchase order
                creation.
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="mt-4 border-t pr-4 pt-4">
        <div className="flex justify-end">
          <div className="w-full">
            <div className="mb-2 flex justify-between gap-4">
              <div className="flex w-3/4 items-center justify-end">
                Purchase Order Sub-Total:
              </div>
              <div className="flex w-1/4 items-end justify-end font-medium">
                {currency.split("-")[1]}
                {subTotal.toFixed(2)}
              </div>
            </div>
            {charges.map((charge, index) => (
              <div className="mb-2 flex justify-between gap-4" key={index}>
                <div className="flex w-3/4 items-center justify-end">
                  {charge.default ? (
                    <div>{charge.name}:</div>
                  ) : (
                    <div className="flex w-3/4 items-center justify-end">
                      <input
                        type="text"
                        className="w-1/2 rounded border p-2"
                        value={charge.name}
                        placeholder="Enter additional charge here..."
                        onChange={(e) =>
                          updateCharge(index, "name", e.target.value)
                        }
                        onBlur={(e) =>
                          !e.target.value &&
                          setError({
                            index,
                            message: "Field cannot be empty",
                          })
                        }
                      />
                      <span>:</span>
                    </div>
                  )}
                </div>
                <div className="flex w-1/4 items-center justify-end">
                  <div className="w-1/6 rounded-l border p-2">
                    {currency.split("-")[1]}
                  </div>
                  <input
                    type="number"
                    className="w-5/6 rounded-r border p-2"
                    value={charge.value}
                    placeholder="Charge amount"
                    onWheel={numberInputOnWheelPreventChange}
                    min={0}
                    onChange={(e) =>
                      updateCharge(index, "value", e.target.value)
                    }
                  />
                  {!charge.default && (
                    <TrashIcon
                      onClick={() => {
                        setCharges(charges.filter((_, i) => i !== index));
                        if (error.index === index)
                          setError({ index: -1, message: "" });
                      }}
                      className="ml-2 h-5 w-5 cursor-pointer text-red-500"
                    />
                  )}
                </div>
              </div>
            ))}
            {error.message && error.index >= 0 && (
              <div className="mb-2 flex justify-end text-red-500">
                <div className="flex w-3/4 justify-end pr-4">
                  {error.message}
                </div>
                <div className="w-1/4"></div>
              </div>
            )}
            <div className="mb-2 mt-4 flex items-center justify-end gap-4">
              <button
                onClick={handleAddCharge}
                className="font-semibold text-primaryAccent underline">
                Add New
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 border-b border-t py-4 pr-4">
        <div className="flex justify-end">
          <div className="mt-2 flex w-full justify-between gap-4">
            <div className="flex w-3/4 justify-end text-right">
              Purchase Order Total:
            </div>
            <div className="flex w-1/4 justify-end text-right font-semibold">
              {currency.split("-")[1]}
              {totalPO.toFixed(2)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProductsMain;
