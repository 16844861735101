import React from "react";
import { Button } from "antd";
import { twMerge } from "tailwind-merge";

export default function PrimaryButton({
  children,
  onClick = () => {},
  variant = "",
  primaryColor = "primaryAccent",
  textColor = "white",
  disabled = false,
  loading = false, // Add the loading prop
  className = "mt-2 text-sm font-medium",
  filledClasses = "border-none border text-white",
  outlinedClasses = "",
  width = "",
  height = "",
  maxWidth = "",
  minWidth = "",
  borderColor = "primaryAccent",
  ...props
}) {
  const filled = `${className} bg-${primaryColor} text-${textColor} ${filledClasses} flex cursor-pointer items-center justify-center rounded-md p-2`;
  const outlined = `${className} border-2 border-${borderColor} text-${primaryColor} ${outlinedClasses} flex cursor-pointer items-center justify-center rounded-md p-2`;

  return (
    <Button
      type={variant}
      onClick={onClick}
      style={{
        width,
        height,
        maxWidth,
        minWidth,
      }}
      disabled={disabled}
      loading={loading} // Integrate the loading prop
      className={twMerge(`${variant === "primary" ? filled : outlined}`)}
      {...props}>
      {children}
    </Button>
  );
}
