import _ from "lodash";
import {
  ExclamationIcon,
  TrashIcon,
  PrinterIcon,
} from "@heroicons/react/solid";
import SearchSortFilter from "#components/common/SearchSortFilter";
import { Menu } from "@headlessui/react";
import {
  DotsVerticalIcon,
  LinkIcon,
  PencilAltIcon,
  DownloadIcon,
  CollectionIcon,
  FolderIcon,
} from "@heroicons/react/outline";
import { Popover } from "@headlessui/react";
import { useContext, useEffect, useState } from "react";
import {
  getHeaderObject,
  getUpdatedHeaders,
} from "../../utils/getHeaderObject";
import { AuthContext } from "../../contexts/auth";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import cellStyleForTable from "../common/CellStyleForTable";
import QuickFilters from "../common/QuickFilters";
import ReactTooltip from "react-tooltip";
import SlideOverModal from "#components/common/SlideOverModal";
import LinkProduct from "./LinkProduct";
import { ProductAliasModal } from "./ProductAliasModal";
const PRODUCTS_TABLE_NAME = "productsManagement";

const ProductsList = ({
  products,
  noValuesText,
  getSku,
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearKeyword,
  setSort,
  sort,
  setShowFilters,
  clearFilters,
  getSpecificProduct,
  customers,
  onChangeFilter,
  writable,
  deleteProduct,
  multiAccountSupportEnabled,
  warehouses,
  printFnSkuLabels,
  downloadFnSkuLabels,
  productCategories,
  convertToBundle,
  tenant,
  perPage,
  parentProducts,
  selectedParentProduct,
  setSelectedParentProduct,
  getParentSku,
  getParentSkuForEdit,
  deleteParentProduct,
}) => {
  if (parentProducts.length > 0) {
    products = [
      ...parentProducts.map((i) => ({ ...i, isParentProduct: true })),
      ...products,
    ];
  }
  const [productBeingLinked, setProductBeingLinked] = useState(null);

  const menuItems = (product) => {
    const arr = [
      {
        title: "Edit",
        icon: PencilAltIcon,
        onClick: product.isParentProduct
          ? getParentSkuForEdit
          : getSpecificProduct,
        vars: "id",
      },
      {
        title: "Delete",
        icon: TrashIcon,
        onClick: product.isParentProduct ? deleteParentProduct : deleteProduct,
        vars: "id",
      },
    ];

    if (product.isParentProduct === true) {
      return arr;
    }

    arr.push(
      {
        title: "Print Labels",
        icon: PrinterIcon,
        onClick: printFnSkuLabels,
        vars: "id",
      },
      {
        title: "Download Labels",
        icon: DownloadIcon,
        onClick: downloadFnSkuLabels,
        vars: "id",
      },
    );

    if (product.typeOfProduct !== "BUNDLE") {
      arr.push({
        title: "Convert to Bundle",
        icon: CollectionIcon,
        onClick: convertToBundle,
        vars: "id",
      });
      if (tenant?.settings?.isProductLinkageAllowed === true) {
        arr.push({
          title: "Link Products",
          icon: LinkIcon,
          onClick: () => {
            setProductBeingLinked(product);
          },
          vars: "id",
        });
      }
    }

    return arr;
  };
  const prepCenter =
    warehouses?.findIndex(
      (item) =>
        item.typeOfWarehouse && item.typeOfWarehouse.includes("PREP CENTER"),
    ) !== -1;

  const initialHeaders = [
    getHeaderObject("SKU", "sku"),
    getHeaderObject("Name", "name"),
    getHeaderObject("Description", "description"),
    getHeaderObject("Source", "source"),
    getHeaderObject("Client", "client"),
    getHeaderObject("Action", "action"),
    getHeaderObject("UPC", "upc"),
    getHeaderObject("Category", "category"),
  ];

  if (prepCenter) {
    initialHeaders.push(getHeaderObject("FnSKU", "fnSku"));
  }

  const [headers, setHeaders] = useState(initialHeaders);
  const [showHeaders, setShowHeaders] = useState(headers);
  const auth = useContext(AuthContext);
  useEffect(() => {
    if (multiAccountSupportEnabled) {
      const MULTI_REGION_HEADERS_INDEX = 3;
      setHeaders([
        ...headers.slice(0, MULTI_REGION_HEADERS_INDEX),
        getHeaderObject("Marketplace", "marketplace"),
        getHeaderObject("Seller", "seller"),
        ...headers.slice(MULTI_REGION_HEADERS_INDEX),
      ]);
    }
  }, [multiAccountSupportEnabled]);

  useEffect(() => {
    const updatedHeaders = getUpdatedHeaders(
      auth,
      headers,
      PRODUCTS_TABLE_NAME,
    );
    if (updatedHeaders) {
      setHeaders(updatedHeaders);
      setShowHeaders(updatedHeaders.filter((header) => header.enabled));
    }
  }, [auth.user]);

  const renderExclamationIcon = (product) => {
    if (product.source === "FBA" && product.partOfBundle === false) {
      if (
        !product.asin ||
        !product.fnSku ||
        product.asin.trim() === "" ||
        product.fnSku.trim() === ""
      ) {
        return (
          <>
            <Popover className="relative bg-white">
              <Popover.Button>
                <ExclamationIcon className="text-xs text-red-600" width={30} />
              </Popover.Button>

              <Popover.Panel className="absolute z-50 bg-white">
                <div className="w-48 border border-gray-600 bg-white p-4">
                  {(!product.asin || product.asin.trim() === "") && (
                    <div>ASIN is missing</div>
                  )}
                  {(!product.fnSku || product.fnSku.trim() === "") && (
                    <div>FNSKU is missing</div>
                  )}
                </div>

                <img src="/solutions.jpg" alt="" />
              </Popover.Panel>
            </Popover>
          </>
        );
      }
    }
    if (product.source === "FBM" && product.partOfBundle === false) {
      if (!product.asin || product.asin.trim() === "") {
        return (
          <Popover className="relative bg-white">
            <Popover.Button>
              <ExclamationIcon className="text-xs text-red-600" width={30} />
            </Popover.Button>

            <Popover.Panel className="absolute z-50 bg-white">
              <div className="w-48 border border-gray-600 bg-white p-4">
                {(!product.asin || product.asin.trim() === "") && (
                  <div>ASIN is missing</div>
                )}
              </div>

              <img src="/solutions.jpg" alt="" />
            </Popover.Panel>
          </Popover>
        );
      }
    }

    return null;
  };

  const handleSkuClick = (product) => {
    if (!product.isParentProduct) {
      getSku(product.id);
    } else {
      getParentSku(product.id);
    }
  };

  const renderVariantsTable = (headersToUse, productsToUse) => {
    return (
      <CustomTableWithTheme>
        <thead className="sticky left-0 top-0 rounded-full bg-primaryAccent p-4">
          <tr className="font-montserratborder-left text-textWhite">
            {headersToUse.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header.name}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header.name}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {productsToUse && productsToUse.length === 0 ? (
            <tr className="bg-white">
              {showHeaders.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="tracking-widerrounded-tl rounded-bl border-l-8 border-goldenSand p-5 text-left font-semibold text-primaryAccent"
                    key={headerIdx}>
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                    key={headerIdx}></td>
                ),
              )}
            </tr>
          ) : null}
          {productsToUse &&
            productsToUse.map((product, rowIndex) => (
              <tr
                key={rowIndex}
                className={`${
                  product.active === false
                    ? "bg-red-300"
                    : `${rowIndex % 2 === 0 ? "bg-white" : "bg-gray-100"}`
                }`}>
                {showHeaders.map((header, columnIndex) => {
                  let value = header.correspondingValue;
                  let cellStyle = cellStyleForTable(
                    value,
                    ["sku"],
                    columnIndex,
                    rowIndex,
                  );
                  if (value === "sku") {
                    return (
                      <td className={cellStyle}>
                        {renderExclamationIcon(product)}
                        <div className="flex items-center gap-x-2">
                          {product.isParentProduct && (
                            <div
                              className="h-6 w-6 text-primaryAccent"
                              title={`Product with variants`}>
                              <FolderIcon />
                            </div>
                          )}
                          {product.typeOfProduct === "BUNDLE" && (
                            <BundleCubeIcon />
                          )}

                          <div onClick={() => handleSkuClick(product)}>
                            {product.sku}
                          </div>
                          <ProductAliasModal
                            aliases={product?.aliases}
                            customers={customers}
                          />
                        </div>
                      </td>
                    );
                  }
                  if (value === "name") {
                    return (
                      <td className={cellStyle}>{truncate(product.name)} </td>
                    );
                  }
                  if (value === "fnSku") {
                    return (
                      <td className={cellStyle}>{truncate(product?.fnSku)}</td>
                    );
                  }
                  if (value === "upc") {
                    return (
                      <td className={cellStyle}>
                        {truncate(product?.upc?.join(", "))}
                      </td>
                    );
                  }
                  if (value === "category") {
                    return (
                      <td className={cellStyle}>
                        {product.category
                          ? productCategories?.find(
                              (i) => i.id === product.category,
                            )?.name || "-"
                          : "-"}
                      </td>
                    );
                  }
                  if (value === "description") {
                    return (
                      <td
                        className={cellStyle}
                        data-tip={product.description}
                        data-for={product.id}>
                        {truncate(product.description)}
                        {product.description &&
                          product.description.length > 40 && (
                            <ReactTooltip
                              id={product.id}
                              place="top"
                              type="info"
                              effect="solid">
                              <div className="h-fit max-w-[400px] whitespace-pre-line">
                                {product.description
                                  ? product.description
                                  : null}
                              </div>
                            </ReactTooltip>
                          )}
                      </td>
                    );
                  }

                  if (value === "marketplace") {
                    return (
                      <td className={cellStyle}>
                        {
                          product.marketplaceAttributes?.attributes
                            ?.marketplaceCountryCode
                        }
                      </td>
                    );
                  }

                  if (value === "seller") {
                    return (
                      <td className={cellStyle}>
                        {product.marketplaceAttributes?.attributes?.sellerId}
                      </td>
                    );
                  }

                  if (value === "source") {
                    return <td className={cellStyle}>{product.source}</td>;
                  }
                  if (value === "client") {
                    return (
                      <td className={cellStyle}>
                        {customers &&
                          customers.find((item) => item.id === product.customer)
                            ?.name}
                      </td>
                    );
                  }
                  if (value === "action") {
                    const isLastRow = rowIndex === perPage - 1;
                    const isLastColumn = columnIndex === showHeaders.length - 1;

                    return (
                      <td className={cellStyle}>
                        {writable && (
                          <Menu as="div" className="inline-block text-left">
                            <div>
                              <Menu.Button>
                                <DotsVerticalIcon className="h-8 w-8 rounded bg-actionMenuBG p-2 text-base" />
                              </Menu.Button>
                            </div>
                            <Menu.Items
                              className={`${isLastRow && "bottom-0"} ${isLastColumn && "right-0"} absolute z-10 divide-y divide-gray-100 rounded-md bg-primaryAccent shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                              <div className="">
                                {menuItems(product).map((menuItem, idx) => (
                                  <Menu.Item key={idx}>
                                    {({ active }) => (
                                      <button
                                        className={`relative flex w-full cursor-pointer select-none items-center border-b border-50BFC3 py-4 pl-4 pr-4 text-white hover:bg-EBEBEB hover:text-2C7695`}
                                        onClick={() => {
                                          menuItem["onClick"](
                                            product[menuItem["vars"]],
                                          );
                                        }}>
                                        {menuItem.icon && (
                                          <menuItem.icon className="h-8 w-8 pr-2" />
                                        )}
                                        {menuItem.title}
                                      </button>
                                    )}
                                  </Menu.Item>
                                ))}
                              </div>
                            </Menu.Items>
                          </Menu>
                        )}
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
        </tbody>
        <SlideOverModal
          width="w-1/2"
          open={!!productBeingLinked}
          onClose={() => setProductBeingLinked(null)}
          title={"Link/ De Link Product"}
          slideOverPaddingClass="p-4 pb-2"
          staticBackdrop={true}>
          <LinkProduct
            product={productBeingLinked}
            customers={customers}
            onClose={() => {
              setProductBeingLinked(null);
            }}
          />
        </SlideOverModal>
      </CustomTableWithTheme>
    );
  };

  if (selectedParentProduct) {
    return (
      <div>
        {/* Add a button to go back to the parent product */}
        <button
          className="mb-4 text-xl font-bold text-primaryAccent transition-transform duration-300 ease-in-out hover:scale-105"
          onClick={() => setSelectedParentProduct(null)}>
          {"< Back"}
        </button>
        <div className="min-w-max rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20 transition-all duration-300 ease-in-out">
          {renderVariantsTable(showHeaders, selectedParentProduct.variants)}
        </div>
      </div>
    );
  }

  return (
    <div className="min-w-max rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20">
      {customers.length > 1 && (
        <QuickFilters
          customerFilterName={"customer"}
          customers={customers}
          filters={filters}
          onChangeFilter={onChangeFilter}
        />
      )}

      <SearchSortFilter
        onChangeSearchKeyword={onChangeSearchKeyword}
        filters={filters}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setSort={setSort}
        sort={sort}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        tableName={PRODUCTS_TABLE_NAME}
        headers={headers}
        setShowHeaders={setShowHeaders}
        choices={[
          {
            name: "Added Date",
            value: "createdAt",
          },
          {
            name: "SKU",
            value: "sku",
          },
          {
            name: "Name",
            value: "name",
          },
        ]}
      />
      {renderVariantsTable(showHeaders, products)}
    </div>
  );
};

const truncate = (str) => {
  return str && str.length > 40 ? str.substring(0, 40) + "..." : str;
};

export const BundleCubeIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <title>Bundle</title>
      <path
        d="M20 7L12 3L4 7M20 7L12 11M20 7V17L12 21M12 11L4 7M12 11V21M4 7V17L12 21"
        stroke="#224E72"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProductsList;
