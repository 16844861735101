import AddImageDropDown from "./AddImageDropDown";
import { useState, useEffect } from "react";
import Modal from "#components/utils/Modal";
import { ArrowsExpandIcon, XIcon } from "@heroicons/react/outline";
import { usePresignedURL } from "#hooks/usePresignedURL";

const ProductImages = ({ product, onChange, deleteImage }) => {
  const [images, setImages] = useState([]);
  const { getPresignedURLForS3Bucket } = usePresignedURL();

  useEffect(() => {
    if (product.images) setImages(JSON.parse(JSON.stringify(product.images)));
  }, [product.images]);

  useEffect(() => {
    if (images.length > 0) {
      for (const image of images.filter((i) => i.url && !isValidURL(i.url))) {
        (async () => {
          try {
            const resp = await getPresignedURLForS3Bucket.fetchData({
              key: image.url,
              method: "GET",
            });
            console.log(resp);
            if (
              isValidURL(
                resp?.data?.getPresignedUrlForS3BucketContainingProductImages
                  ?.url,
              )
            ) {
              image.url =
                resp.data.getPresignedUrlForS3BucketContainingProductImages.url;
              setImages([...images]);
            } else {
              image.url = null;
              setImages([...images]);
            }
          } catch (err) {
            image.url = null;
            setImages([...images]);
          }
        })();
      }
    }
  }, [images]);

  const [showImage, setShowImage] = useState(null);

  return (
    <>
      <AddImageDropDown selectedProduct={product} onChange={onChange} />
      <div className="container mx-auto grid grid-cols-4 space-y-2">
        {images?.length > 0 &&
          images.map((image, idx) => {
            return (
              <div
                className="product-image relative col-span-1 w-1/2 w-40 rounded"
                key={idx}>
                <img src={image.url} />
                <ArrowsExpandIcon
                  className="absolute bottom-0 right-0 h-10 w-10 cursor-pointer p-2 text-red-600"
                  onClick={() => setShowImage(image.url)}
                />
                <XIcon
                  className="absolute bottom-0 left-0 h-10 w-10 cursor-pointer p-2 text-red-600"
                  onClick={() => deleteImage(idx)}
                />
              </div>
            );
          })}
      </div>
      {showImage && (
        <Modal
          negativeAction={() => setShowImage(null)}
          title={`Image Preview`}
          noPadding={true}
          positiveAction={() => setShowImage(null)}
          positiveText="OK">
          <div className="space-y-4 p-4">
            <div>
              <img
                className="mx-auto h-1/2 w-1/2 overflow-hidden rounded-lg object-contain"
                src={showImage}
                alt="No Image"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

function isValidURL(string) {
  try {
    new URL(string);
  } catch (_) {
    return false;
  }
  return true;
}

export default ProductImages;
