import React from "react";
import PropTypes from "prop-types"; // Import PropTypes

const CustomBadge = ({ color, label, bgColor, textColor, textSize }) => {
  const BG_COLOR = color ? `bg-${color}-100` : "";
  const TEXT_COLOR = color ? `text-${color}-800` : "";

  return (
    <span
      style={{ backgroundColor: bgColor, color: textColor }}
      className={`rounded-full px-3 py-2 ${BG_COLOR} ${TEXT_COLOR} ${textSize || ""}`}>
      {label}
    </span>
  );
};

// Add PropTypes validation
CustomBadge.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  textSize: PropTypes.string,
};

export default CustomBadge;
