import { useState } from "react";
// import ScanOrEnterManually from "#components/putaway/consignment/ScanOrEnterManually";
// import ConsignmentItemsList from "#components/putaway/consignment/ConsignmentItemsList";
import { Tab } from "@headlessui/react";
import ScanOrEnterManually from "#components/putaway/bundle/ScanOrEnterManually";
import BundleItemsList from "#components/putaway/bundle/BundleItemsList";

const BundleItemsSelection = ({
  customer,
  warehouse,
  setCurrentSku,
  currentTask,
  singlePutawayItem,
  putawayAvailableQty,
  setPutawayAvailableQty,
  //   currentScan,
  scanBarcode,
  //   cancelCurrentCustomerAndTracking,
  //   specificConsignments,
  appState,
}) => {
  //   const isConsignmentSelectionSkipped =
  //     currentScan.skipConsignmentSelection || false;

  //   if (isConsignmentSelectionSkipped) {
  //     return (
  //       <>
  //         <div className="left-0 w-full bg-black text-center text-2xl text-white">
  //           {customers.find((item) => item.id === currentScan.customer)?.name}
  //         </div>
  //         <div className="pt-48">
  //           <ScanOrEnterManually
  //             text="Scan an Item/Tote Code"
  //             onSubmit={(e) =>
  //               scanBarcode({
  //                 data: e.data,
  //               })
  //             }
  //             onCancel={() =>
  //               cancelCurrentCustomerAndTracking("CONSIGNMENTS_ITEMS_SELECTION")
  //             }
  //           />
  //         </div>
  //       </>
  //     );
  //   }

  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabs = {
    "Detail View": (
      <div className="flex">
        <ScanOrEnterManually
          onSubmit={scanBarcode}
          text="Scan an Item Code"
          appState={appState}
        />
        {/* <ScanOrEnterManually
          text="Scan an Item/Tote Code"
          onSubmit={scanBarcode}
          onCancel={() =>
            cancelCurrentCustomerAndTracking("CONSIGNMENTS_ITEMS_SELECTION")
          }
        />  */}
      </div>
    ),
    "List View": (
      <BundleItemsList
        customer={customer}
        warehouse={warehouse}
        setCurrentSku={setCurrentSku}
        currentTask={currentTask}
        scanBarcode={scanBarcode}
        singlePutawayItem={singlePutawayItem}
        putawayAvailableQty={putawayAvailableQty}
        setPutawayAvailableQty={setPutawayAvailableQty}
      />
      //   <ConsignmentItemsList
      //     currentScan={currentScan}
      //     scanBarcode={scanBarcode}
      //   />
    ),
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // Show the consignment id at the header if the consignment is directly scanned.
  // For the case where they provide tracking number, multiple consignments can exists for same tracking number.
  // In that case we additionally have extra column in the list view showing the order id for the items.
  //   const displayConsignmentOrderId =
  //     specificConsignments?.length === 1 && specificConsignments[0].id
  //       ? specificConsignments[0].orderId
  //       : null;

  return (
    <>
      {/* <div className="left-0 w-full bg-black text-center text-2xl text-white"> */}
      {/* {customers.find((item) => item.id === currentScan.customer)?.name} */}
      {/* {displayConsignmentOrderId && ` - ${displayConsignmentOrderId}`} */}
      {/* </div> */}

      <div className="flex-col pt-36">
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List className="flex items-center justify-center">
            <div className="space-x-2 rounded-full bg-primaryAccent p-2">
              {tabs &&
                Object.keys(tabs).map((tab) => (
                  <Tab
                    key={tab}
                    className={({ selected }) =>
                      classNames(
                        "font-regular rounded-full px-6 py-2.5 leading-5",
                        "font-montserrat text-lg focus:outline-none focus:ring-0",
                        selected
                          ? "bg-white font-medium text-primaryAccent"
                          : "rounded-full border border-primaryAccent bg-transparent text-white",
                      )
                    }>
                    {tab}
                  </Tab>
                ))}
            </div>
          </Tab.List>
          <div className="block">
            <Tab.Panels className="mt-2">
              {tabs &&
                Object.values(tabs).map((tabInner, idx) => (
                  <Tab.Panel
                    key={idx}
                    className="flex flex-col items-center bg-transparent p-4">
                    {tabInner}
                  </Tab.Panel>
                ))}
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </>
  );
};

export default BundleItemsSelection;
