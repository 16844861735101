import React from "react";
import { Alert } from "antd";
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/outline";

const defaultStyles = {
  success: {
    bgColor: "bg-green-50",
    titleColor: "text-green-700",
    textColor: "text-green-600",
    borderColor: "border-green-700",
    titleSize: "text-base",
    descriptionSize: "text-sm",
  },
  error: {
    bgColor: "bg-red-50",
    titleColor: "text-red-700",
    textColor: "text-red-600",
    borderColor: "border-red-700",
    titleSize: "text-base",
    descriptionSize: "text-sm",
  },
  info: {
    bgColor: "bg-[#EAF2FA]", // Adjust this color if necessary
    titleColor: "text-[#224E73]",
    textColor: "text-[#34678C]",
    borderColor: "border-[#224E73]",
    titleSize: "text-base",
    descriptionSize: "text-sm",
  },
  warning: {
    bgColor: "bg-yellow-50",
    titleColor: "text-yellow-700",
    textColor: "text-yellow-600",
    borderColor: "border-yellow-700",
    titleSize: "text-base",
    descriptionSize: "text-sm",
  },
};

const CustomAlert = ({
  type,
  message,
  id = "custom-alert",
  options = {},
  ...rest
}) => {
  const {
    bgColor,
    textColor,
    titleColor,
    borderColor,
    defaultColors = false,
    iconStyles = "w-5 h-5 mr-2",
  } = options;

  const styles = defaultColors
    ? defaultStyles[type]
    : { bgColor, textColor, titleColor, borderColor };

  const iconMap = {
    success: (
      <CheckCircleIcon className={iconStyles + ` ${styles.titleColor}`} />
    ),
    error: <XCircleIcon className={iconStyles + ` ${styles.titleColor}`} />,
    info: (
      <InformationCircleIcon className={iconStyles + ` ${styles.titleColor}`} />
    ),
    warning: (
      <ExclamationCircleIcon className={iconStyles + ` ${styles.titleColor}`} />
    ),
  };

  return (
    <div
      className={`${styles.bgColor} ${styles.borderColor} mb-4 rounded-lg border-2`}
      style={{ display: "inline-block", width: "100%", borderStyle: "solid" }}
      id={id}>
      <Alert
        message={
          <div className={"flex items-center " + styles.titleSize}>
            {iconMap[type]}
            <span className={`${styles.titleColor}`}>{message}</span>
          </div>
        }
        type={type}
        showIcon={false} // We are using custom icons, so disable the default icons
        className="w-full"
        afterClose={() => {
          document.getElementById(id).style.display = "none";
        }}
        {...rest}
        closable
      />
    </div>
  );
};

export default CustomAlert;
