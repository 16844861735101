import React from "react";

const CustomBadge = ({ color, label, bgColor, textColor, textSize }) => {
  const BG_COLOR = color ? `bg-${color}-100` : "";
  const TEXT_COLOR = color ? `text-${color}-800` : "";

  return (
    <span
      style={{ backgroundColor: bgColor, color: textColor }}
      className={`rounded-full px-3 py-2 ${BG_COLOR} ${TEXT_COLOR} ${textSize || ""}`}>
      {label}
    </span>
  );
};

export default CustomBadge;
