import React, { useState } from "react";
import Modal from "../utils/Modal";
import { getHeaderObject } from "../../utils/getHeaderObject";
import PropTypes from "prop-types";

/**
 * This function represents a component which shows order details inside a modal.
 *
 * @param {Object} props - The properties passed to the component
 * @param {Array} props.items - The items to display
 * @param {function} props.setItems - Function to set the items
 * @param {function} props.setSelectedConsignmentItemNotes - Function to set the selected consignment item notes
 * @returns {React.Component}
 */
const OrderDetailsModal = ({
  items,
  setItems,
  setSelectedConsignmentItemNotes,
  showConsignmentDetails,
  closeConsignmentDetails,
}) => {
  const [showHeaders, _] = useState([
    getHeaderObject("SKU", "sku"),
    getHeaderObject("Name", "productName"),
    getHeaderObject("Quantity", "quantity"),
    getHeaderObject("Received Qty", "receivedQty"),
    getHeaderObject("Form Factor", "formFactor"),
    getHeaderObject("Notes", "notes"),
  ]);
  return (
    <>
      <Modal
        onClose={() => {}}
        negativeAction={() => {
          setItems(null);
          closeConsignmentDetails();
        }}
        title={`Items in consignment (${items.length})`}>
        <>
          {showConsignmentDetails?.orderType === "STO" && (
            <div className="items-left flex items-center p-2">
              <div className="text-xl text-gray-500">
                Stock Transfer OrderId : &nbsp;
              </div>
              <div className="text-xl text-black">
                {showConsignmentDetails?.stoOrderId}
              </div>
            </div>
          )}
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="rounded-full bg-primaryAccent p-4">
              <tr className="border-left font-montserrat text-textWhite">
                {showHeaders.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <th
                      scope="col"
                      className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                      key={headerIdx}>
                      {header.name}
                    </th>
                  ) : (
                    <th
                      scope="col"
                      className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                      key={headerIdx}>
                      {header.name}
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr
                  key={item.sku}
                  className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                  {showHeaders &&
                    showHeaders.map((header) => {
                      if (header.correspondingValue == "sku") {
                        return (
                          <td
                            className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                              index % 2 === 0
                                ? "border-F4C261"
                                : "border-primaryAccent"
                            }`}>
                            {item.sku}
                          </td>
                        );
                      }
                      if (header.correspondingValue == "productName")
                        return (
                          <td className="max-w-2xl px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                            {item.productName}
                          </td>
                        );
                      if (header.correspondingValue == "quantity")
                        return (
                          <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                            {item.quantity}
                          </td>
                        );
                      if (header.correspondingValue == "receivedQty")
                        return (
                          <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                            {item.quantity - item.availableQuantity}
                          </td>
                        );
                      if (header.correspondingValue == "formFactor")
                        return (
                          <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                            {item.formFactor}
                          </td>
                        );
                      if (header.correspondingValue == "notes")
                        return (
                          <td
                            className="cursor-pointer whitespace-nowrap px-1 py-1 pl-4 text-left font-bold tracking-wider text-primaryAccent"
                            onClick={() =>
                              setSelectedConsignmentItemNotes({
                                index,
                                ...item,
                              })
                            }>
                            {item.notes ? "View / Add" : "Add"}
                          </td>
                        );
                    })}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      </Modal>
    </>
  );
};

OrderDetailsModal.propTypes = {
  items: PropTypes.array.isRequired,
  setItems: PropTypes.func.isRequired,
  setSelectedConsignmentItemNotes: PropTypes.func.isRequired,
};

export default OrderDetailsModal;
