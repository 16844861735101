import React from "react";

const InputWithSelect = ({ inputProps = {}, selectProps = {} }) => {
  return (
    <div className="inline-flex w-full">
      <input
        {...inputProps}
        className={`w-20 rounded-l border-r border-gray-300 p-2 text-center focus:outline-none ${
          inputProps.disabled ? "bg-gray-200" : "bg-white"
        }`}
      />
      <select
        {...selectProps}
        className={`w-20 cursor-pointer rounded-r border-gray-300 p-2 focus:outline-none ${
          selectProps.disabled ? "bg-gray-200" : "bg-white"
        }`}>
        {selectProps.options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default InputWithSelect;
