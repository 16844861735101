import React from "react";
import { Popconfirm, Button } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

const CustomPopconfirm = ({
  title,
  onConfirm,
  onCancel,
  okText = "Yes",
  cancelText = "No",
  icon = <QuestionCircleOutlined />,
  okButtonProps = {},
  cancelButtonProps = {},
  placement = "top",
  trigger = "click",
  children,
  ...rest
}) => {
  return (
    <Popconfirm
      title={title}
      onConfirm={onConfirm}
      onCancel={onCancel}
      okText={okText}
      cancelText={cancelText}
      icon={icon}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      placement={placement}
      trigger={trigger}
      {...rest}>
      {children}
    </Popconfirm>
  );
};

export default CustomPopconfirm;
