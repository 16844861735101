import { useState, useEffect } from "react";
import TextField from "#components/utils/TextField";
import _ from "lodash";
import Wizard from "#components/utils/Wizard";
import Toggle from "#components/utils/Toggle";
import WizardProductSelector from "../utils/WizardProductSelector";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";
import Autocomplete from "#components/utils/Autocomplete";
import OrderRateShopping from "./OrderRateShopping";
import OrderBoxDimensions from "./OrderBoxDimensions";
import Dropdown from "#components/utils/Dropdown";
import { Country, State } from "country-state-city";
import { Tooltip } from "antd";

const OrderFormWizard = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  selectedOrder,
  onSubmit,
  warehouses,
  customers,
  inventorySearchKeyword,
  onChangeInventorySearch,
  availableInventory,
  addItem,
  removeItem,
  getMarketplaceProducts,
  carrierList,
  carrierServiceList,
  tenant,
  shipstationList,
  eHubList,
  setSelectedOrder,
  nonEditableFieldsOfOrder = [],
  fetchProductSkuBinMappings,
  productSkuBinMappings,
  lotIdSelectionEnabled,
  lpnSelectionEnabled,
  showTrackingNumberInput = true,
  multiAccountSupportEnabled,
  productSkuBinMappingsWithLpn,
  productSkuBinMappingsWithSerialNumber,
  productSkuBinMappingsWithPalletId,
}) => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [filteredStates, setFilteredStates] = useState([]);
  const [isAdding, setIsAdding] = useState(null);
  const [isFetchingRates, setIsFetchingRates] = useState(false);
  const isShipmentAddressDisabled =
    nonEditableFieldsOfOrder.includes("shipmentAddress");

  let displayedTabs;
  let clientWarehouses = [];

  if (selectedOrder?.orderType === "STO") {
    displayedTabs = ["Tenant Info", "Basic Details", "Select Products"];

    if (selectedOrder?.customer) {
      clientWarehouses = customers.find(
        (customer) => customer.id === selectedOrder.customer,
      )?.warehouses;
    }
  } else {
    displayedTabs = [
      "Tenant Info",
      "Basic Details",
      "Select Products",
      "Specify Address",
      "Box Dimensions",
      "Tracking",
    ];
  }
  let selectedCarrierList = carrierList;
  let carrierKeyName = "carrier";
  let onCarrierChangeHandler = (updatedCarrier) => {
    onChangeDropdown("carrier", updatedCarrier);
  };

  if (shipstationList && Object.keys(shipstationList).length > 0) {
    selectedCarrierList = Object.keys(shipstationList).map((item) => ({
      name: item.toUpperCase(),
      id: item,
    }));
  } else if (eHubList && eHubList.length > 0) {
    selectedCarrierList = eHubList.map((item) => ({
      name: `${item.carrier_name} ${item.service}`,
      id: `${item.service_id}`,
    }));

    carrierKeyName = "carrierService";

    onCarrierChangeHandler = (updatedCarrier) => {
      const selectedCarrier = eHubList.find(
        (carrier) => `${carrier.service_id}` === updatedCarrier,
      );
      setSelectedOrder({
        ...selectedOrder,
        carrier: selectedCarrier.carrier_code,
        carrierService: `${updatedCarrier}`,
      });
    };
  }

  const { shippingAddress } = selectedOrder;
  useEffect(() => {
    if (selectedOrder) {
      setSelectedCountry(shippingAddress?.country || "");
      setSelectedState(shippingAddress?.state || "");
    }
  }, [shippingAddress]);

  useEffect(() => {
    if (selectedCountry) {
      const states = State.getStatesOfCountry(selectedCountry);
      setFilteredStates(states);
      if (!states.some((state) => state.isoCode === selectedState)) {
        setSelectedState("");
      }
    } else {
      setFilteredStates([]);
      setSelectedState("");
    }
  }, [selectedCountry]);

  const handleCountryChange = (e) => {
    const newCountry = e;
    setSelectedCountry(newCountry);
    setSelectedState("");
    setFilteredStates(State.getStatesOfCountry(newCountry));
    onChange({
      target: {
        name: "shippingAddress",
        value: {
          ...(selectedOrder?.shippingAddress || {}),
          country: newCountry,
        },
      },
    });
  };

  const handleStateChange = (e) => {
    const newState = e;
    const states = State.getStatesOfCountry(selectedCountry);
    setSelectedState(newState);
    setFilteredStates(states);
    onChange({
      target: {
        name: "shippingAddress",
        value: {
          ...(selectedOrder?.shippingAddress || {}),
          state: newState,
        },
      },
    });
  };

  const steps = [
    ...(displayedTabs.includes("Tenant Info") &&
    selectedOrder?.orderType === "Regular"
      ? [
          {
            name: "Tenant Info",
            href: "#",
            render: (
              <>
                {
                  <>
                    <div>
                      <Autocomplete
                        options={warehouses?.filter(
                          (item) => item.name !== "All",
                        )}
                        labelKey="name"
                        valueKey="id"
                        onChange={(selectedOption) => {
                          onChangeDropdown("warehouse", selectedOption);
                        }}
                        value={selectedOrder.warehouse}
                        placeholder={"Select Warehouse"}
                        id="SELECT_WAREHOUSE"
                      />
                    </div>
                  </>
                }
                {
                  <>
                    <div className="w-full">
                      <Autocomplete
                        options={getFilteredCustomerList(
                          selectedOrder.warehouse,
                          customers,
                        )}
                        labelKey="name"
                        valueKey="id"
                        onChange={(selectedOption) => {
                          onChangeDropdown("customer", selectedOption);
                        }}
                        value={selectedOrder.customer}
                        placeholder={"Select Client"}
                        id="SELECT_CLIENT"
                      />
                    </div>
                  </>
                }
              </>
            ),
          },
        ]
      : []),
    ...(displayedTabs.includes("Tenant Info") &&
    selectedOrder?.orderType === "STO"
      ? [
          {
            name: "Tenant Info",
            href: "#",
            render: (
              <>
                {
                  <>
                    <div className="w-full">
                      <Autocomplete
                        options={customers}
                        labelKey="name"
                        valueKey="id"
                        onChange={(selectedOption) => {
                          onChangeDropdown("customer", selectedOption);
                        }}
                        value={selectedOrder.customer}
                        placeholder={"Select Client"}
                        id="SELECT_CLIENT"
                      />
                    </div>
                  </>
                }
                {
                  <>
                    <div>
                      <Autocomplete
                        options={warehouses?.filter((item) =>
                          clientWarehouses?.includes(item.id),
                        )}
                        labelKey="name"
                        valueKey="id"
                        onChange={(selectedOption) => {
                          onChangeDropdown("warehouse", selectedOption);
                        }}
                        value={selectedOrder.warehouse}
                        placeholder={"Select From Warehouse"}
                        id="SELECT_WAREHOUSE"
                        disabled={!selectedOrder.customer}
                      />
                    </div>
                  </>
                }
                {
                  <>
                    <div>
                      <Autocomplete
                        options={warehouses?.filter((item) =>
                          clientWarehouses?.includes(item.id),
                        )}
                        labelKey="name"
                        valueKey="id"
                        onChange={(selectedOption) => {
                          onChangeDropdown("toWarehouse", selectedOption);
                        }}
                        value={selectedOrder.toWarehouse}
                        placeholder={"Select To Warehouse"}
                        id="SELECT_TO_WAREHOUSE"
                        disabled={!selectedOrder.customer}
                      />
                    </div>
                  </>
                }
              </>
            ),
          },
        ]
      : []),
    ...(displayedTabs.includes("Basic Details")
      ? [
          {
            name: "Basic Details",
            href: "#",
            render: (
              <>
                <div className="mb-4">
                  <TextField
                    type="text"
                    id="name"
                    label="Order ID (leave blank to auto-generate)"
                    placeholder=" "
                    onChange={onChange}
                    value={selectedOrder.orderId}
                    name="orderId"
                    disabled={nonEditableFieldsOfOrder.includes("orderId")}
                  />
                </div>
                <div>
                  <TextField
                    type="date"
                    id="orderDate"
                    label="Order Date(leave blank to use current date and time)"
                    placeholder=" "
                    value={selectedOrder.orderDate}
                    name="orderDate"
                    onChange={onChange}
                    disabled={nonEditableFieldsOfOrder.includes("orderDate")}
                  />
                </div>
              </>
            ),
          },
        ]
      : []),
    ...(displayedTabs.includes("Select Products")
      ? [
          {
            name: "Select Products",
            href: "#",
            render: (
              <div>
                <WizardProductSelector
                  warehouses={warehouses}
                  selectedEntity={{
                    ...selectedOrder,
                    items: selectedOrder.orderLineItems,
                  }}
                  removeItem={removeItem}
                  onChangeInventorySearch={onChangeInventorySearch}
                  availableInventory={availableInventory}
                  submitAddedProduct={addItem}
                  getMarketplaceProducts={getMarketplaceProducts}
                  isMultiFormFactor={false}
                  addingProductCallback={(isAdding) => {
                    setIsAdding(isAdding);
                    fetchProductSkuBinMappings(isAdding);
                  }}
                  multipleFormFactors={false}
                  lotIdSelectionEnabled={lotIdSelectionEnabled}
                  lpnSelectionEnabled={lpnSelectionEnabled}
                  productSkuBinMappings={productSkuBinMappings}
                  productSkuBinMappingsWithLpn={productSkuBinMappingsWithLpn}
                  productSkuBinMappingsWithSerialNumber={
                    productSkuBinMappingsWithSerialNumber
                  }
                  productSkuBinMappingsWithPalletId={
                    productSkuBinMappingsWithPalletId
                  }
                  multiAccountSupportEnabled={multiAccountSupportEnabled}
                />
              </div>
            ),
            footer: isAdding ? <></> : null,
          },
        ]
      : []),
    ...(displayedTabs.includes("Specify Address")
      ? [
          {
            name: "Specify Address",
            href: "#",
            render: (
              <>
                <div className="mb-3 grid grid-cols-3 gap-3">
                  <div>
                    <TextField
                      type="text"
                      id="name"
                      label="Name"
                      placeholder=" "
                      onChange={(e) =>
                        onChange({
                          target: {
                            name: "shippingAddress",
                            value: {
                              ...(selectedOrder?.shippingAddress || {}),
                              name: e.target.value,
                            },
                          },
                        })
                      }
                      value={selectedOrder?.shippingAddress?.name}
                      name="shippingAddress.name"
                      disabled={isShipmentAddressDisabled}
                    />
                  </div>
                  <div>
                    <TextField
                      type="text"
                      id="email"
                      label="Email"
                      placeholder=" "
                      onChange={(e) =>
                        onChange({
                          target: {
                            name: "shippingAddress",
                            value: {
                              ...(selectedOrder?.shippingAddress || {}),
                              email: e.target.value,
                            },
                          },
                        })
                      }
                      value={selectedOrder?.shippingAddress?.email}
                      name="shippingAddress.email"
                      disabled={isShipmentAddressDisabled}
                    />
                  </div>
                  <div>
                    <TextField
                      type="text"
                      id="phone"
                      label="Phone"
                      placeholder=" "
                      onChange={(e) =>
                        onChange({
                          target: {
                            name: "shippingAddress",
                            value: {
                              ...(selectedOrder?.shippingAddress || {}),
                              phone: e.target.value,
                            },
                          },
                        })
                      }
                      value={selectedOrder?.shippingAddress?.phone}
                      name="shippingAddress.phone"
                      disabled={isShipmentAddressDisabled}
                    />
                  </div>
                </div>
                <div className="mb-3 grid grid-cols-3 gap-3">
                  <div>
                    <TextField
                      type="text"
                      id="address_line_1"
                      label="Address Line 1"
                      placeholder=" "
                      onChange={(e) =>
                        onChange({
                          target: {
                            name: "shippingAddress",
                            value: {
                              ...(selectedOrder?.shippingAddress || {}),
                              line1: e.target.value,
                            },
                          },
                        })
                      }
                      value={selectedOrder?.shippingAddress?.line1}
                      name="shippingAddress.line1"
                      disabled={isShipmentAddressDisabled}
                    />
                  </div>
                  <div>
                    <TextField
                      type="text"
                      id="address_line_2"
                      label="Address Line 2"
                      placeholder=" "
                      onChange={(e) =>
                        onChange({
                          target: {
                            name: "shippingAddress",
                            value: {
                              ...(selectedOrder?.shippingAddress || {}),
                              line2: e.target.value,
                            },
                          },
                        })
                      }
                      value={selectedOrder?.shippingAddress?.line2}
                      name="shippingAddress.line2"
                      disabled={isShipmentAddressDisabled}
                    />
                  </div>
                  <div>
                    <TextField
                      type="text"
                      id="city"
                      label="City"
                      placeholder=" "
                      onChange={(e) =>
                        onChange({
                          target: {
                            name: "shippingAddress",
                            value: {
                              ...(selectedOrder?.shippingAddress || {}),
                              city: e.target.value,
                            },
                          },
                        })
                      }
                      value={selectedOrder?.shippingAddress?.city}
                      name="shippingAddress.city"
                      disabled={isShipmentAddressDisabled}
                    />
                  </div>
                </div>
                <div className="mb-3 grid grid-cols-3 gap-3">
                  <div>
                    <Tooltip
                      title={
                        !selectedCountry ? "Please select a country first" : ""
                      }
                      placement="top">
                      <div style={{ display: "inline-block", width: "100%" }}>
                        <Autocomplete
                          options={filteredStates}
                          labelKey="name"
                          valueKey="isoCode"
                          onChange={handleStateChange}
                          value={selectedOrder?.shippingAddress?.state}
                          placeholder="Select a State"
                          id="state"
                          disabled={isShipmentAddressDisabled}
                        />
                      </div>
                    </Tooltip>
                  </div>
                  <div>
                    <Autocomplete
                      options={Country.getAllCountries()}
                      labelKey="name"
                      valueKey="isoCode"
                      onChange={handleCountryChange}
                      value={selectedOrder?.shippingAddress?.country || ""}
                      placeholder="Select a Country"
                      id="country"
                      disabled={isShipmentAddressDisabled}
                    />
                  </div>
                  <div>
                    <TextField
                      type="text"
                      id="pincode"
                      label="Pincode"
                      placeholder=" "
                      onChange={(e) =>
                        onChange({
                          target: {
                            name: "shippingAddress",
                            value: {
                              ...(selectedOrder?.shippingAddress || {}),
                              zip: e.target.value,
                            },
                          },
                        })
                      }
                      value={selectedOrder?.shippingAddress?.zip}
                      name="shippingAddress.zip"
                      disabled={isShipmentAddressDisabled}
                    />
                  </div>
                </div>
                <div className="iustify-center flex flex-row py-2">
                  <div className="self-center pr-4 text-lg">
                    Validate address
                  </div>
                  <div>
                    <Toggle
                      setEnabled={(e) =>
                        onChange({
                          target: {
                            name: "toValidAddress",
                            value: e,
                          },
                        })
                      }
                      enabled={selectedOrder?.toValidAddress}
                      disabled={nonEditableFieldsOfOrder.includes(
                        "toValidAddress",
                      )}
                    />
                  </div>
                </div>
              </>
            ),
          },
        ]
      : []),
    ...(displayedTabs.includes("Box Dimensions") &&
    tenant?.features?.rateShopping === true
      ? [
          {
            name: "Box Dimensions",
            href: "#",
            render: (
              <div className="flex-col space-y-4">
                <OrderBoxDimensions
                  order={selectedOrder}
                  setSelectedOrder={setSelectedOrder}
                />
              </div>
            ),
            skipStep: ({ setNextStep }) => {
              setSelectedOrder((selectedOrder) => ({
                ...selectedOrder,
                estimatedBoxes: null,
              }));
              setNextStep();
            },
          },
        ]
      : []),
    ...(displayedTabs.includes("Tracking")
      ? [
          {
            name: "Tracking",
            href: "#",
            render: (
              <>
                {tenant?.features?.rateShopping === true ? (
                  <div className="flex-col space-y-4">
                    <OrderRateShopping
                      order={selectedOrder}
                      setSelectedOrder={setSelectedOrder}
                      tenant={tenant}
                      nonEditableFieldsOfOrder={nonEditableFieldsOfOrder}
                      onChangeDropdown={onChangeDropdown}
                      onChange={onChange}
                      setIsFetchingRates={setIsFetchingRates}
                      showTrackingNumberInput={showTrackingNumberInput}
                    />
                  </div>
                ) : (
                  <>
                    <div className="flex-col space-y-4">
                      <div className="mb-1">
                        <Dropdown
                          placeholder={"Select Carrier"}
                          list={selectedCarrierList}
                          labelKey="name"
                          valueKey="id"
                          name="carrier"
                          setSelected={onCarrierChangeHandler}
                          selectedValue={selectedOrder[carrierKeyName]}
                          disabled={nonEditableFieldsOfOrder.includes(
                            "carrier",
                          )}
                        />
                      </div>
                      {selectedOrder.carrier &&
                        carrierServiceList[selectedOrder.carrier] &&
                        carrierServiceList[selectedOrder.carrier].length >
                          0 && (
                          <div>
                            <Dropdown
                              placeholder={"Select Service"}
                              list={carrierServiceList[
                                selectedOrder.carrier
                              ]?.map((item) => ({
                                name: item.toUpperCase(),
                                id: item,
                              }))}
                              labelKey="name"
                              valueKey="id"
                              name="carrierService"
                              setSelected={(e) => {
                                onChangeDropdown("carrierService", e);
                              }}
                              selectedValue={selectedOrder.carrierService}
                              disabled={nonEditableFieldsOfOrder.includes(
                                "carrierService",
                              )}
                            />
                          </div>
                        )}
                      <div>
                        <TextField
                          type="text"
                          id="trackingNumber"
                          label="Tracking Number"
                          placeholder=" "
                          onChange={onChange}
                          value={selectedOrder.trackingNumber}
                          name="trackingNumber"
                          disabled={nonEditableFieldsOfOrder.includes(
                            "trackingNumber",
                          )}
                        />
                      </div>

                      {["uat", "tosprep"].includes(tenant?.subdomain) && (
                        <div>
                          <Dropdown
                            placeholder={"Is Insurance Required?"}
                            list={[
                              { name: "Yes", id: true },
                              { name: "No", id: false },
                            ]}
                            labelKey="name"
                            valueKey="id"
                            name="insuranceRequired"
                            setSelected={(e) => {
                              onChangeDropdown("insuranceRequired", e);
                            }}
                            selectedValue={selectedOrder.insuranceRequired}
                            disabled={nonEditableFieldsOfOrder.includes(
                              "insuranceRequired",
                            )}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </>
            ),
          },
        ]
      : []),
  ];

  return (
    <Wizard
      title={"Order Details"}
      steps={steps.map(({ render, ...s }) => ({
        ...s,
      }))}
      onSubmit={onSubmit}
      onClose={onClose}
      addOverlay={isFetchingRates}>
      {(id) => {
        const step = steps.find((_, idx) => idx === id);
        if (step)
          return (
            <div>
              {step.render}
              {step.footer}
            </div>
          );
        return steps[0].render;
      }}
    </Wizard>
  );
};

export default OrderFormWizard;
