import React, { useState, useEffect, useContext } from "react";
import { Tooltip } from "antd";
import { ExclamationCircleIcon as ExclamationCircleOutlineIcon } from "@heroicons/react/outline";
import { Tab } from "@headlessui/react";
import currencyToSymbolMap from "currency-symbol-map/map";
import BasicInfoForm from "#newUiComponents/purchaseOrder/BasicInfoForm";
import AddProductsMain from "#newUiComponents/purchaseOrder/AddProductsMain";
import TextArea from "#newUiComponents/commons/TextArea";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "../../contexts/auth";
import { useQuery } from "#hooks/useQuery";
import PrimaryButton from "../commons/PrimaryButton";
import { ADD_PURCHASE_ORDER } from "../../mutations";
import AddressSelector from "./AddressSelector";
import NewModal from "../commons/NewModal";
import CustomNotification from "../commons/CustomNotification";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";
import PurchaseOrderView from "./PurchaseOrderView";
import { useHistory } from "react-router-dom";
import {
  SHOW_SUGGESTED_CHANGES_TIMEOUT,
  PURCHASE_ORDER_STATUSES,
  VENDOR_CLASSIFICATIONS,
  USER_ROLE_CLASSIFICATIONS,
  handleGetNextStatus,
  handleCheckForDateAndFormat,
  handleGetTitleAndDescForConfirmation,
  handleGetRequiredOptions,
  handleGetCreateActionCtaLabel,
  checkEligibilityForSaveChanges,
  handleCheckDisabledStatusForSubmitCta,
  REQUEST_ACTIONS_ON_PO,
  handleformatNumericValue,
} from "./PoUtils";
import EnterReadyToShipDetails from "./EnterReadyToShipDetails";
import TrackingDetails from "./TrackingDetails";
import { getFilteredCustomerList } from "#utils/getFilteredCustomerList";

const PurchaseOrderMain = ({
  productForPurchase = [],
  setProductForPurchase,
  fetchCatalogProduct,
  setShowPurchaseOrderSlide,
  handleTriggerHandleGetPoOrders,
  toCreate = false, // to know if po is being created first time.
  setPurchaseOrderDetailsToShow,
  setShowSuggestedChanges,
  suggestedChangesPopupOnClick,
  setSuggestedChangesPopupOnClick,
  showReadyToShipDetailsScreen,
  setShowReadyToShipDetailsScreen,
  purchaseOrderRequests,
  userInfo,
  warehouses,
  vendors,
  purchaseOrderDetailsToShow = {},
  posInDescendingOrder = [],
  toView = false,
  setToView,
  parentProductForPurchase,
}) => {
  const addPurchaseOrderQuery = useQuery(ADD_PURCHASE_ORDER);
  const appState = useContext(AppStateContext);
  const auth = useContext(AuthContext);
  const history = useHistory();
  const notify = CustomNotification();

  const [products, setProducts] = useState([]);
  const [purchaseOrderLoading, setPurchaseOrderLoading] = useState(false);
  const [poErrors, setPoErrors] = useState({
    vendorError: "Vendor selection required",
    warehouseError: "",
  });
  const [charges, setCharges] = useState([
    { name: "Discount", value: 0, default: true },
  ]);
  const [vendorDetails, setVendorDetails] = useState({
    addresses: [],
    name: "",
    classification: "",
  });

  const [selectedVendorAddress, setSelectedVendorAddress] = useState({});
  const [basicInfoFormDetails, setBasicInfoFormDetails] = useState({
    selectedVendor: "",
    selectedWareshouse: "",
    selectedCurrency: "USD-$",
    selectedPriorityTag: "",
    note: "",
    expectedShipDate: null,
    expectedDeliveryDate: null,
  });
  const [selectedAddressIdx, setSelectedAddressIdx] = useState(0);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(0);

  const [carrier, setCarrier] = useState("");
  const [trackingNumbers, setTrackingNumbers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [isTrackingDetailsOpen, setIsTrackingDetailsOpen] = useState(false);
  const [shippingPurchaseOrder, setShippingPurchaseOrder] = useState({});

  const handleOpenAddressModal = () => {
    setIsAddressModalOpen(true);
  };
  const handleCloseAddressModal = () => {
    setIsAddressModalOpen(false);
  };
  const handleOpenTrackingDetailsModal = () => {
    setIsTrackingDetailsOpen(true);
  };
  const handleCloseTrackingDetailsModal = () => {
    setIsTrackingDetailsOpen(false);
    setCarrier("");
    setTrackingNumbers([]);
    setSelectedCustomer("");
  };

  const handleAddressChange = (index) => {
    setSelectedAddressIdx(index);
    setSelectedVendorAddress(vendorDetails.addresses[index]);
  };

  const tabs = posInDescendingOrder?.map((po) => {
    return {
      label: po.versionName,
    };
  });

  const handleAddOrEditPurchaseOrder = async (
    products,
    basicInfoFormDetails,
    additionalCharges,
    status,
    poId,
    vendorNotes = "",
  ) => {
    const purchaseOrder = {
      poId: poId || null,
      vendorId: basicInfoFormDetails.selectedVendor || "",
      vendorAddress: selectedVendorAddress,
      warehouseId: basicInfoFormDetails.selectedWareshouse || "",
      currency: basicInfoFormDetails.selectedCurrency.split("-")[0],
      priority: basicInfoFormDetails.selectedPriorityTag || 0,
      poNotes: basicInfoFormDetails.note
        ? [
            {
              note: basicInfoFormDetails.note,
              timestamp: new Date(),
              user: auth?.user?.name || "System",
            },
          ]
        : [],
      expectedShipDate: basicInfoFormDetails.expectedShipDate || "",
      expectedDeliveryDate: basicInfoFormDetails.expectedDeliveryDate || "",
      status: status,
      additionalCharges: additionalCharges.map((charge) => {
        return {
          name: charge.name || "",
          amount: charge.value
            ? charge.name === "Discount"
              ? -1 * parseFloat(handleformatNumericValue(charge.value))
              : parseFloat(handleformatNumericValue(charge.value))
            : 0,
        };
      }),
      lineItems: products.map((product) => {
        return {
          lineItemId: product.lineItemId ?? null,
          productId: product.id,
          sku: product.sku || "",
          name: product.name || "",
          quantity: Number(product.quantity) || 1,
          uom: product.baseUom || "",
          unitPrice: parseFloat(handleformatNumericValue(product.cost)) || 0,
          size: product.size || "",
          components: product.components.map((component) => {
            return {
              sku: component.sku || "",
              name: component.name || "",
              uom: component.uom || "",
              quantity:
                parseFloat(handleformatNumericValue(component.quantity)) || 0,
              unitPrice:
                parseFloat(handleformatNumericValue(component.unitPrice)) || 0,
              metalLossPercentage:
                parseFloat(
                  handleformatNumericValue(component.metalLossPercentage),
                ) || 0,
              masterData: {
                ...component.masterData,
                unitPrice:
                  parseFloat(
                    handleformatNumericValue(component.masterData.unitPrice),
                  ) || 0,
                quantity:
                  parseFloat(
                    handleformatNumericValue(component.masterData.quantity),
                  ) || 0,
                weight:
                  parseFloat(
                    handleformatNumericValue(component.masterData.weight),
                  ) || 0,
                uom: component.uom || "",
                componentType: component?.masterData?.componentType || "",
              },
            };
          }),
        };
      }),
    };
    if (vendorNotes) {
      purchaseOrder.vendorNote = {
        user: auth?.user?.name || "System",
        note: vendorNotes,
        timestamp: new Date(),
      };
    }
    setPurchaseOrderLoading(true);
    const addPurchaseQueryResponse = await addPurchaseOrderQuery.fetchData({
      purchaseOrderInput: purchaseOrder,
    });
    setPurchaseOrderLoading(false);
    if (addPurchaseQueryResponse.data) {
      if (!toCreate) {
        notify.success(
          addPurchaseQueryResponse.data.handlePoOps.message,
          `PO Id:- ${addPurchaseQueryResponse.data.handlePoOps.poId}`,
        );
      } else {
        notify.success(
          addPurchaseQueryResponse.data.handlePoOps.message,
          <div className="flex flex-col justify-start gap-2">
            <p>
              <span className="font-semibold">PO Id:-</span>
              {` ${addPurchaseQueryResponse.data.handlePoOps.poId}`}
            </p>
            <p>
              Purchase Order has been created successfully and can be viewed in
              <span
                onClick={() => {
                  history.push("/purchaseOrders");
                }}
                className="mx-1 cursor-pointer font-semibold text-primaryAccent underline">
                Purchase Order
              </span>{" "}
              Section
            </p>
          </div>,
        );
      }
      setShowPurchaseOrderSlide(false);
      if (purchaseOrderDetailsToShow.poId) {
        setToView(true);
        handleTriggerHandleGetPoOrders(1);
      }
    } else if (addPurchaseQueryResponse.error) {
      notify.error(addPurchaseQueryResponse.error.message || "");
    }
  };

  const handleConfirmShipmentTrackingDetails = (shippingPurchaseOrder) => {
    purchaseOrderRequests(
      {
        ...shippingPurchaseOrder,
        clientId: selectedCustomer,
        trackingNumbers: trackingNumbers,
        carrier: carrier,
      },
      REQUEST_ACTIONS_ON_PO.SHIP_PO,
    );
  };

  // this function is called when user tries to edit the po which is already created and backend data differs how edit fields needed.
  // this formatted data again will be formatted in AddProductsMain component , to reduce duplication of functions this function will return the data how AddProductsMain component needs
  //? carefully handle this function

  const handleConvertBackToEditFormat = (purchaseOrder) => {
    const formatProduct = (lineItem) => {
      const materialsCost =
        lineItem?.components?.reduce((acc, material) => {
          const price = material.unitPrice ? Number(material.unitPrice) : 0;
          return acc + (isNaN(price) ? 0 : price);
        }, 0) || 0;

      const materials =
        lineItem?.components?.map((material) => ({
          ...material,
          currency: purchaseOrder.currency,
        })) || [];

      const cost =
        lineItem?.components?.length > 0
          ? materialsCost
          : handleformatNumericValue(lineItem.unitPrice) || 0;

      return {
        ...lineItem,
        id: lineItem.productId,
        baseUom: lineItem.uom,
        materials,
        quantity: lineItem.quantity || 1,
        currency: purchaseOrder.currency,
        pricing: {
          computedPriceFields: {
            totalPrice: handleformatNumericValue(lineItem.unitPrice),
          },
        },
        totalCost: cost * (lineItem.quantity || 1),
        image: FALLBACK_IMAGE_URL,
      };
    };

    const products = purchaseOrder.lineItems.map((lineItem) =>
      formatProduct(lineItem),
    );
    const charges = purchaseOrder.additionalCharges.map((charge) => ({
      name: charge.name,
      value: charge.name === "Discount" ? -1 * charge.amount : charge.amount,
      default: charge.name === "Discount",
    }));

    return { products, charges };
  };

  const findMatchingAddressIndex = (addresses, incomingAddress) => {
    return addresses.findIndex(
      (address) =>
        address.line1 === incomingAddress.line1 &&
        address.line2 === incomingAddress.line2 &&
        address.city === incomingAddress.city &&
        address.state === incomingAddress.state &&
        address.zip === incomingAddress.zip &&
        address.country === incomingAddress.country &&
        address.phone === incomingAddress.phone &&
        address.email === incomingAddress.email,
    );
  };

  const handleBasicFormDetails = (purchaseOrderDetailsToShow) => {
    setBasicInfoFormDetails((prevDetails) => ({
      ...prevDetails,
      selectedVendor: purchaseOrderDetailsToShow.vendorId || "",
      poId: purchaseOrderDetailsToShow.poId || "",
      vendorName: purchaseOrderDetailsToShow.vendorName || "",
      vendorClassification:
        purchaseOrderDetailsToShow.vendorClassification || "",
      selectedWareshouse: purchaseOrderDetailsToShow.warehouse.id || "",
      warehouseName: purchaseOrderDetailsToShow.warehouse.name || "",
      selectedCurrency:
        `${purchaseOrderDetailsToShow.currency}-${currencyToSymbolMap[purchaseOrderDetailsToShow.currency]}` ||
        "USD-$",
      selectedPriorityTag: purchaseOrderDetailsToShow.priority || 0,
      priority:
        handleGetRequiredOptions("PRIORITY_TAGS").find(
          (tag) => tag.value === purchaseOrderDetailsToShow.priority,
        )?.label || "",
      note: purchaseOrderDetailsToShow.poNotes?.[0]?.note || "",
      expectedShipDate: handleCheckForDateAndFormat(
        purchaseOrderDetailsToShow.expectedShipDate,
      ),
      expectedDeliveryDate: handleCheckForDateAndFormat(
        purchaseOrderDetailsToShow.expectedDeliveryDate,
      ),
      status: purchaseOrderDetailsToShow.status || "",
    }));
  };

  useEffect(() => {
    if (Object.keys(purchaseOrderDetailsToShow).length > 0) {
      setShippingPurchaseOrder(purchaseOrderDetailsToShow);
      const { products, charges } = handleConvertBackToEditFormat(
        purchaseOrderDetailsToShow,
      );
      setProductForPurchase(products);
      setProducts([]);
      setCharges(charges);
      handleBasicFormDetails(purchaseOrderDetailsToShow);
      if (
        suggestedChangesPopupOnClick &&
        toView &&
        selectedVersion === 0 &&
        purchaseOrderDetailsToShow.suggestedChangesMerged === false
      ) {
        setTimeout(() => {
          setShowSuggestedChanges(true);
        }, SHOW_SUGGESTED_CHANGES_TIMEOUT);
      }
    }
  }, [purchaseOrderDetailsToShow, toView]);

  useEffect(() => {
    if (basicInfoFormDetails.selectedVendor && !toView) {
      const vendorData = vendors.find(
        (vendor) => vendor.id === basicInfoFormDetails.selectedVendor,
      );
      if (vendorData) {
        setVendorDetails({
          ...vendorDetails,
          name: vendorData.name,
          classification: vendorData.classification,
          addresses: vendorData.addresses,
        });
        if (purchaseOrderDetailsToShow?.vendor?.address) {
          setSelectedVendorAddress(
            vendorData.addresses[
              findMatchingAddressIndex(
                vendorData.addresses,
                purchaseOrderDetailsToShow.vendor.address,
              )
            ],
          );
          setSelectedAddressIdx(
            findMatchingAddressIndex(
              vendorData.addresses,
              purchaseOrderDetailsToShow.vendor.address,
            ),
          );
        } else {
          setSelectedVendorAddress(vendorData.addresses[0]);
          setSelectedAddressIdx(0);
        }
      }
    } else {
      setVendorDetails({
        ...vendorDetails,
        name: "",
        classification: "",
        addresses: [],
      });
      setSelectedVendorAddress({});
    }
  }, [basicInfoFormDetails.selectedVendor, toView, vendors]);

  useEffect(() => {
    if (!toView) {
      const validateFormDetails = () => {
        setPoErrors((prev) => ({
          ...prev,
          vendorError: basicInfoFormDetails.selectedVendor
            ? ""
            : "Vendor selection required",
        }));
      };
      validateFormDetails();
    }
  }, [basicInfoFormDetails, toView]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleAddOrUpdatePurchaseOrder = () => {
    if (
      PURCHASE_ORDER_STATUSES.PENDING_INTERNAL_APPROVAL ===
        purchaseOrderDetailsToShow.status &&
      !basicInfoFormDetails.selectedWareshouse
    ) {
      setPoErrors((prev) => ({
        ...prev,
        warehouseError: "Warehouse selection required",
      }));
      notify.error("Warehouse selection required");
      return;
    }
    const addOrUpdatePurchaseOrder = (vendorNotes = "") => {
      handleAddOrEditPurchaseOrder(
        products,
        basicInfoFormDetails,
        charges,
        purchaseOrderDetailsToShow.status
          ? handleGetNextStatus(
              purchaseOrderDetailsToShow.status,
              userInfo,
              toView === false,
              toCreate,
            )
          : PURCHASE_ORDER_STATUSES.PENDING_INTERNAL_APPROVAL,
        purchaseOrderDetailsToShow.poId,
        vendorNotes,
      );
    };
    if (purchaseOrderDetailsToShow.status) {
      const titleDesc = handleGetTitleAndDescForConfirmation(
        purchaseOrderDetailsToShow.status,
        userInfo,
      );
      let vendorNotes = "";
      const finalDescription =
        purchaseOrderDetailsToShow.status ===
          PURCHASE_ORDER_STATUSES.PENDING_VENDOR_REVIEW &&
        userInfo.role === USER_ROLE_CLASSIFICATIONS.VENDOR &&
        userInfo.classification ===
          VENDOR_CLASSIFICATIONS.THIRD_PARTY_VENDOR ? (
          <div>
            <p>{titleDesc[1]}</p>
            <div>
              <TextArea
                label="Notes"
                placeholder="Enter notes here ..."
                onChange={(e) => {
                  vendorNotes = e.target.value;
                }}
                error=""
                infoText="Enter a notes for buyer"
                parentClasses="mb-4"
                labelClasses="text-base text-gray-500"
                rows={4}
              />
            </div>
          </div>
        ) : (
          titleDesc[1]
        );
      appState.showNewConfirmation(
        titleDesc[0],
        finalDescription,
        () => {
          addOrUpdatePurchaseOrder(vendorNotes);
        },
        appState.hideConfirmation,
      );
    } else {
      appState.showNewConfirmation(
        "Create PO",
        "Ready to create this Purchase Order? Clicking 'Confirm' will route it for internal approval.",
        addOrUpdatePurchaseOrder,
        appState.hideConfirmation,
      );
    }
  };

  const handleSaveChangesAction = () => {
    const addOrUpdatePurchaseOrder = () => {
      handleAddOrEditPurchaseOrder(
        products,
        basicInfoFormDetails,
        charges,
        purchaseOrderDetailsToShow.status
          ? purchaseOrderDetailsToShow.status
          : PURCHASE_ORDER_STATUSES.DRAFT,
        purchaseOrderDetailsToShow.poId,
      );
    };
    if (purchaseOrderDetailsToShow.status) {
      appState.showNewConfirmation(
        "Save Changes",
        "Are you sure you want to save the changes for the PO? Please note that the PO will remain in the same status.",
        addOrUpdatePurchaseOrder,
        appState.hideConfirmation,
      );
    } else addOrUpdatePurchaseOrder();
  };

  const disabledSaveChanges =
    purchaseOrderLoading || !products || products.length === 0;

  return (
    <div className="max-w-lg p-8 py-0 font-inter">
      {toView && tabs.length > 1 && (
        <Tab.Group
          selectedIndex={selectedVersion}
          onChange={(index) => {
            setSelectedVersion(index);
            setSuggestedChangesPopupOnClick(false);
            setPurchaseOrderDetailsToShow(posInDescendingOrder[index]);
          }}>
          <Tab.List className="mb-4 flex space-x-1 border-b border-gray-200">
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                className={({ selected }) =>
                  classNames(
                    "w-24 py-2.5 text-sm font-medium leading-5 text-gray-500",
                    "focus:outline-none",
                    selected
                      ? "rounded border-b-4 border-primaryAccent text-primaryAccent"
                      : "text-gray-500",
                  )
                }>
                {tab.label}
              </Tab>
            ))}
          </Tab.List>
        </Tab.Group>
      )}
      {toView ? (
        showReadyToShipDetailsScreen ? (
          <>
            <EnterReadyToShipDetails
              products={shippingPurchaseOrder.lineItems}
              setShippingPurchaseOrder={setShippingPurchaseOrder}
              shippingDetails={shippingPurchaseOrder.shippingDetails}
            />
            <NewModal
              isOpen={isTrackingDetailsOpen}
              onClose={handleCloseTrackingDetailsModal}
              title="Ready to Ship">
              <div>
                <TrackingDetails
                  customers={
                    auth?.user?.customersList
                      ? getFilteredCustomerList(
                          purchaseOrderDetailsToShow.warehouse.id,
                          auth.user.customersList.filter((customer) => {
                            return customer.settings?.workflows?.inbound
                              ?.enabled;
                          }),
                        )
                      : []
                  }
                  selectedCustomer={selectedCustomer}
                  setSelectedCustomer={setSelectedCustomer}
                  carrier={carrier}
                  setCarrier={setCarrier}
                  trackingNumbers={trackingNumbers}
                  setTrackingNumbers={setTrackingNumbers}
                  handleCloseTrackingDetailsModal={
                    handleCloseTrackingDetailsModal
                  }
                  handleConfirmTrackingDetails={() =>
                    handleConfirmShipmentTrackingDetails(shippingPurchaseOrder)
                  }
                />
              </div>
            </NewModal>
          </>
        ) : (
          <PurchaseOrderView
            basicInfoDetails={basicInfoFormDetails}
            purchaseOrderDetails={purchaseOrderDetailsToShow}
            setShowSuggestedChanges={setShowSuggestedChanges}
          />
        )
      ) : (
        <>
          <div>
            <h3 className="text-lg font-semibold">Basic Info</h3>
            <BasicInfoForm
              userInfo={userInfo}
              basicInfoFormDetails={basicInfoFormDetails}
              setBasicInfoFormDetails={setBasicInfoFormDetails}
              poErrors={poErrors}
              data={{
                warehousesOptions: handleGetRequiredOptions(
                  "WAREHOUSES",
                  warehouses,
                ),
                vendorsOptions: handleGetRequiredOptions("VENDORS", vendors),
                currenciesOptions: handleGetRequiredOptions(
                  "CURRENCY",
                  currencyToSymbolMap,
                ),
                priorityTagsOptions: handleGetRequiredOptions("PRIORITY_TAGS"),
                selectedVendorAddress: selectedVendorAddress,
                vendorDetails: vendorDetails,
                poStatus: purchaseOrderDetailsToShow.status,
              }}
              handleOpenAddressModal={handleOpenAddressModal}
            />
          </div>
          <div>
            <h3 className="flex items-center text-lg font-semibold">
              Add Products{" "}
              <Tooltip
                placement="top"
                style={{ minWidth: "25rem", width: "auto" }}
                title="Add multiple products to a PO. Adjust component prices or directly
              edit product costs if there are no components. Changes apply only to
              this PO. Costs are displayed in the selected currency, if available.">
                <ExclamationCircleOutlineIcon className="ml-2 h-5 w-5 text-gray-400" />
              </Tooltip>
            </h3>

            <AddProductsMain
              toCreate={toCreate}
              currency={basicInfoFormDetails.selectedCurrency}
              productForPurchase={productForPurchase}
              fetchCatalogProduct={fetchCatalogProduct}
              products={products}
              setProducts={setProducts}
              charges={charges}
              setCharges={setCharges}
              setShowSuggestedChanges={setShowSuggestedChanges}
              suggestedChangesMerged={
                purchaseOrderDetailsToShow.suggestedChangesMerged
              }
              purchaseOrderDetailsToShow={purchaseOrderDetailsToShow}
              parentProductForPurchase={parentProductForPurchase}
            />
          </div>
          <NewModal
            isOpen={isAddressModalOpen}
            onClose={handleCloseAddressModal}
            title="Select Vendor Address"
            subtitle="To add new address please go to vendor management and update.">
            <div>
              <AddressSelector
                addresses={vendorDetails.addresses}
                vendorName={vendorDetails.name}
                handleAddressChange={handleAddressChange}
                selectedAddressIdx={selectedAddressIdx}
                handleCloseAddressModal={handleCloseAddressModal}
              />
            </div>
          </NewModal>
        </>
      )}
      {(!toView || showReadyToShipDetailsScreen) && (
        <div className="sticky bottom-0 z-30 flex w-full items-center justify-between bg-white p-4">
          <div className="mb-2 mt-4 flex items-center justify-end gap-4">
            <button
              onClick={() => {
                productForPurchase?.length > 0 && handleSaveChangesAction();
              }}
              disabled={disabledSaveChanges}
              className="flex items-center">
              <span
                className={`font-semibold underline ${
                  disabledSaveChanges ? "text-gray-400" : "text-primaryAccent"
                }`}>
                {purchaseOrderDetailsToShow.status &&
                checkEligibilityForSaveChanges(
                  purchaseOrderDetailsToShow.status,
                  userInfo,
                )
                  ? "Save Changes"
                  : !purchaseOrderDetailsToShow.status
                    ? "Save as Draft"
                    : ""}
              </span>
              {((purchaseOrderDetailsToShow.status &&
                checkEligibilityForSaveChanges(
                  purchaseOrderDetailsToShow.status,
                  userInfo,
                )) ||
                !purchaseOrderDetailsToShow.status) && (
                <ExclamationCircleOutlineIcon className="ml-2 h-4 w-4 text-gray-400" />
              )}
            </button>
          </div>
          <div className="flex gap-6">
            <PrimaryButton
              height="3rem"
              width="7rem"
              className="mt-2 text-lg font-medium"
              onClick={() => {
                if (toCreate) {
                  setShowPurchaseOrderSlide(false);
                } else {
                  setProducts([]);
                  setProductForPurchase([]);
                  setShowPurchaseOrderSlide(false);
                  setShowSuggestedChanges(false);
                  setSuggestedChangesPopupOnClick(false);
                  setToView(true);
                }
              }}>
              Cancel
            </PrimaryButton>
            <PrimaryButton
              height="3rem"
              minWidth="7rem"
              maxWidth="15rem"
              variant="primary"
              className="mt-2 text-lg font-medium"
              loading={purchaseOrderLoading}
              disabled={(() => {
                if (showReadyToShipDetailsScreen) return false;
                return (
                  products.length === 0 ||
                  !basicInfoFormDetails.selectedVendor ||
                  handleCheckDisabledStatusForSubmitCta(
                    purchaseOrderDetailsToShow.status,
                    userInfo,
                  )
                );
              })()}
              onClick={() => {
                if (showReadyToShipDetailsScreen) {
                  handleOpenTrackingDetailsModal();
                  return;
                }
                handleAddOrUpdatePurchaseOrder();
              }}>
              {handleGetCreateActionCtaLabel(
                purchaseOrderDetailsToShow.status,
                userInfo,
              )}
            </PrimaryButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default PurchaseOrderMain;
