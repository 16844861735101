import { useState } from "react";
import withProductsLogic from "#components/HOC/withProductsLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import { UserAddIcon } from "@heroicons/react/outline";
import ProductsList from "#components/products/ProductsList";
import ExpandedSku from "#components/inventory/ExpandedSku";
import Pagination from "#components/common/Pagination";
import ParentProductForm from "#components/products/ParentProductForm";
import ProductForm from "#components/products/ProductForm";
import BundleAsProductForm from "#components/products/BundleAsProductForm";
import ProductFilters from "#components/products/ProductFilters";
import UploadBulk from "../components/bulkUpload/UploadBulk";
import CustomEntityForm from "#components/common/CustomEntityForm";
import Modal from "#components/utils/Modal";
import Accordian from "#components/utils/Accordian";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { Tooltip } from "antd";
import FnSkuLabelOptions from "#components/orders/FnSkuLabelOptions";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "#constants/feature-flags";
import Popover from "#components/utils/Popover";
import BulkUploadSlideOverModal from "#newUiComponents/bulkUploadV2/BulkUploadSlideOverModal";
import {
  BULK_UPLOAD_DUPLICATE_VALIDATION,
  BULK_UPLOAD_DUPLICATE_ROW_VALIDATION,
} from "#components/HOC/withProductsLogic";
import { BULK_UPLOAD_ENTITY_TYPES } from "#newUiComponents/bulkUploadV2/useReducer";
import ExportBanner, {
  BANNER_TYPE_ENUM,
} from "#newUiComponents/commons/ExportBanner";

const Products = ({
  displayProducts,
  fetchedSku,
  setFetchedSku,
  saveProduct,
  getSku,
  total,
  pageNumber,
  productSizes,
  productShapes,
  productColors,
  productCategories,
  productsTypes,
  AttributeValues,
  deleteAttributes,
  perPage,
  setPerPage,
  onSubmitCustomEntity,
  checkPagination,
  sort,
  setSort,
  filters,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  showFilters,
  setShowFilters,
  clearFilters,
  getSpecificProduct,
  addCustomEntity,
  setAddCustomEntity,
  customers,
  warehouses,
  onChangeFilter,
  onChangeAttributes,
  addNewProduct,
  selectedProduct,
  setSelectedProduct,
  onChange,
  onChangeDropdown,
  writable,
  deleteProduct,
  productDashboardFields,
  saveBulkUpload,
  errorMessage,
  successMessage,
  validate,
  validationResult,
  filterQueryOperator,
  setFilterQueryOperator,
  deleteImage,
  showProductForm,
  setShowProductForm,
  showBundleAsProductForm,
  setShowBundleAsProductForm,
  addBundleProduct,
  removeBundleProduct,
  availableInventory,
  onChangeInventorySearch,
  getMarketplaceProducts,
  customAttributes,
  setCustomAttributes,
  multiAccountSupportEnabled,
  getMarketplaces,
  sellerIds,
  fnSkuOptions,
  setFnSkuOptions,
  submitFnSkuOptions,
  downloadFnSkuLabels,
  printFnSkuLabels,
  isPrintLabel,
  createProductIdentifiers,
  productHasInventory,
  addNewCategory,
  setAddNewCategory,
  submitCategoryForm,
  tenant,
  convertToBundle,
  fetchedSkuAvailable,
  setFetchedSkuAvailable,
  forDownloadingLabel,
  setForDownloadingLabel,
  newParentProduct,
  setNewParentProduct,
  parentProducts,
  selectedParentProduct,
  setSelectedParentProduct,
  getParentSku,
  getParentSkuForEdit,
  deleteParentProduct,
  actionTypes,
  handleManageActionClick,
  showBulkUpload,
  setShowBulkUpload,
  selectedActionType,
  setSelectedActionType,
  pastUploadsList,
  showBanner,
  typeOfBanner,
  bulkuploadFileName,
  closeBanner,
  productDashboardFieldsV2,
}) => {
  const ldClient = useLDClient();
  const bulkUploadV2Enabled =
    ldClient?.variation(featureFlags.BULK_UPLOAD_V2_ENABLED, false) ?? false;
  const isBrand = tenant?.typeOfCustomer?.includes("Brand");

  const [showNewAdditionModal, setShowNewAdditionModal] = useState(false);
  const newAdditionModalDetails = [
    {
      title: "Product",
      isActive: true,
      single: {
        text: "Create Single Product",
        onClick: () =>
          addNewProduct({ typeOfProduct: "STANDALONE", active: true }),
      },
      bulk: {
        dashboardFields: { ...productDashboardFields },
        saveBulkUpload: (rows) => saveBulkUpload(rows),
        errorMessage: errorMessage,
        successMessage: successMessage,
        validate: (rows) => validate(rows),
        validationResult: validationResult,
        uploadBttnText: "Bulk Upload Product Variants",
        templateLink:
          "https://templates-onboarding.s3.amazonaws.com/prod/Products.xlsx",
      },
    },
    {
      title: "Bundle / Kit",
      single: {
        text: "Create Single Bundle",
        onClick: () => addNewProduct({ typeOfProduct: "BUNDLE", active: true }),
      },
    },
  ];

  if (isBrand) {
    newAdditionModalDetails.push({
      title: "Parent Product",
      isActive: false,
      single: {
        text: "Create Parent Product",
        onClick: () =>
          setNewParentProduct({ active: true, source: "Hopstack" }),
      },
    });
  }

  const ProductManagementActions = () => {
    return (
      <>
        {writable && (
          <div className="flex items-center justify-end space-x-2">
            <AddButton
              disabled={false}
              text={"Add New Product / Bundle / Kit"}
              onClick={() => setShowNewAdditionModal(true)}
              icon={UserAddIcon}
            />

            {filters &&
              filters["customer"] &&
              filters["customer"].length === 1 && (
                <AddButton
                  disabled={false}
                  text="Sync Products"
                  onClick={getMarketplaceProducts}
                />
              )}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="w-full p-5">
      <div className="mb-4 flex items-center">
        <div className="flex-1">
          <PageTitle>Product Management</PageTitle>
        </div>

        {!bulkUploadV2Enabled && <ProductManagementActions />}
        {bulkUploadV2Enabled && (
          <div className="mb-4 flex items-center justify-end space-x-2">
            {actionTypes && (
              <Popover
                title={"Manage Actions"}
                showChevron={true}
                disabled={!writable}
                panelClassName="mt-2 bg-bgWhite z-10 
                overflow-auto rounded-lg p-1 
                border border-borderGray w-[14rem]"
                showOverlay={true}>
                {actionTypes.map((action) => {
                  return (
                    <div
                      key={action.name}
                      className="w-full cursor-pointer rounded-lg p-3 font-medium text-unselectedTextGray hover:bg-hoverHighlight hover:text-primaryAccent"
                      onClick={() => handleManageActionClick(action.value)}>
                      {action.name}
                    </div>
                  );
                })}
              </Popover>
            )}
          </div>
        )}
      </div>

      {showBanner && (
        <ExportBanner
          content={
            typeOfBanner === BANNER_TYPE_ENUM?.INITIATED ? (
              <span className="pb-2">
                Upload Scheduled! Your file {bulkuploadFileName} has been
                scheduled for upload. You will be notified when it is ready.
              </span>
            ) : typeOfBanner === BANNER_TYPE_ENUM?.COMPLETED ? (
              <span className="pb-2">
                Upload Successful! Your file {bulkuploadFileName} has been
                uploaded. You can check{" "}
                <span
                  className="cursor-pointer underline"
                  onClick={(e) => {
                    e.preventDefault();
                    handleManageActionClick("PAST_UPLOADS");
                  }}>
                  Past Uploads
                </span>{" "}
                for further information.
              </span>
            ) : typeOfBanner === BANNER_TYPE_ENUM?.FAILED ? (
              <span className="pb-2">
                Upload Failed! Your file {bulkuploadFileName} couldn't be
                uploaded. You can check{" "}
                <span
                  className="cursor-pointer underline"
                  onClick={(e) => {
                    e.preventDefault();
                    handleManageActionClick("PAST_UPLOADS");
                  }}>
                  Past Uploads
                </span>{" "}
                for further information.
              </span>
            ) : typeOfBanner === BANNER_TYPE_ENUM?.PARTIAL_COMPLETE ? (
              <span className="pb-2">
                Uploaded Partially! Your file {bulkuploadFileName} has been
                uploaded partially. You can check{" "}
                <span
                  className="cursor-pointer underline"
                  onClick={(e) => {
                    e.preventDefault();
                    handleManageActionClick("PAST_UPLOADS");
                  }}>
                  Past Uploads
                </span>{" "}
                for further information.
              </span>
            ) : null
          }
          typeOfBanner={typeOfBanner}
          onClose={closeBanner}
        />
      )}

      <ProductsList
        products={displayProducts}
        noValuesText="No Products found"
        onChangeSearchKeyword={onChangeSearchKeyword}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        sort={sort}
        setSort={setSort}
        filters={filters}
        getSku={getSku}
        fetchedSkuAvailable={fetchedSkuAvailable}
        setFetchedSkuAvailable={setFetchedSkuAvailable}
        getSpecificProduct={getSpecificProduct}
        customers={customers}
        onChangeFilter={onChangeFilter}
        writable={writable}
        deleteProduct={deleteProduct}
        multiAccountSupportEnabled={multiAccountSupportEnabled}
        warehouses={warehouses}
        printFnSkuLabels={printFnSkuLabels}
        downloadFnSkuLabels={downloadFnSkuLabels}
        productCategories={productCategories}
        convertToBundle={convertToBundle}
        tenant={tenant}
        perPage={perPage}
        parentProducts={parentProducts}
        selectedParentProduct={selectedParentProduct}
        setSelectedParentProduct={setSelectedParentProduct}
        getParentSku={getParentSku}
        getParentSkuForEdit={getParentSkuForEdit}
        deleteParentProduct={deleteParentProduct}
      />

      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
        searchEnabled={true}
      />

      {showFilters && (
        <ProductFilters
          negativeAction={() => setShowFilters(false)}
          onChangeFilter={onChangeFilter}
          filters={filters}
          onSubmit={submitFilters}
          customers={customers}
          productSize={productSizes}
          productShape={productShapes}
          productColor={productColors}
          productCategory={productCategories}
          productType={productsTypes}
          onChangeAttributes={onChangeAttributes}
          filterQueryOperator={filterQueryOperator}
          setFilterQueryOperator={setFilterQueryOperator}
        />
      )}

      {showProductForm && (
        <ProductForm
          selectedProduct={selectedProduct}
          onChange={onChange}
          onChangeDropdown={onChangeDropdown}
          title={selectedProduct.id ? "Edit Product" : "Add Product"}
          onClose={() => {
            setShowProductForm(false);
            setSelectedProduct(null);
          }}
          onSubmit={saveProduct}
          customers={customers}
          warehouses={warehouses}
          setSelectedProduct={setSelectedProduct}
          productSizes={productSizes}
          productShapes={productShapes}
          productColors={productColors}
          productCategories={productCategories}
          productsTypes={productsTypes}
          AttributeValues={AttributeValues}
          deleteAttributes={deleteAttributes}
          deleteImage={deleteImage}
          customAttributes={customAttributes}
          setCustomAttributes={setCustomAttributes}
          multiAccountSupportEnabled={multiAccountSupportEnabled}
          getMarketplaces={getMarketplaces}
          sellerIds={sellerIds}
          productHasInventory={productHasInventory}
          addNewCategory={addNewCategory}
          setAddNewCategory={setAddNewCategory}
          submitCategoryForm={submitCategoryForm}
          setAddCustomEntity={setAddCustomEntity}
          addCustomEntity={addCustomEntity}
        />
      )}

      {showBundleAsProductForm && (
        <BundleAsProductForm
          selectedProduct={selectedProduct}
          onChange={onChange}
          onChangeDropdown={onChangeDropdown}
          title={selectedProduct.id ? "Edit Bundle" : "Add Bundle"}
          onClose={() => {
            setShowBundleAsProductForm(false);
            setSelectedProduct(null);
          }}
          onSubmit={saveProduct}
          customers={customers}
          warehouses={warehouses}
          setSelectedProduct={setSelectedProduct}
          productSizes={productSizes}
          productShapes={productShapes}
          productColors={productColors}
          productCategories={productCategories}
          productsTypes={productsTypes}
          deleteImage={deleteImage}
          addBundleProduct={addBundleProduct}
          removeBundleProduct={removeBundleProduct}
          availableInventory={availableInventory}
          onChangeInventorySearch={onChangeInventorySearch}
          getMarketplaceProducts={getMarketplaceProducts}
          customAttributes={customAttributes}
          setCustomAttributes={setCustomAttributes}
          productHasInventory={productHasInventory}
          tenant={tenant}
        />
      )}

      {addCustomEntity && (
        <CustomEntityForm
          addCustomEntity={addCustomEntity}
          setAddCustomEntity={setAddCustomEntity}
          onSubmit={onSubmitCustomEntity}
          showCode={false}
        />
      )}

      {fetchedSkuAvailable && !forDownloadingLabel && (
        <ExpandedSku
          fetchedSku={fetchedSku}
          negativeAction={() => {
            setFetchedSku(null);
            setFetchedSkuAvailable(false);
          }}
          customers={customers}
        />
      )}

      {fnSkuOptions && isPrintLabel && fetchedSku && (
        <FnSkuLabelOptions
          fnSkuLabelOptions={fnSkuOptions}
          setFnSkuLabelOptions={setFnSkuOptions}
          setForDownloadingLabel={setForDownloadingLabel}
          setFetchedSkuAvailable={setFetchedSkuAvailable}
          positiveAction={submitFnSkuOptions}
          isSelectLabel={true}
          productIdentifiers={createProductIdentifiers(fetchedSku)}
        />
      )}

      {showNewAdditionModal && (
        <Modal
          title={`Add New Product / Bundle / Kit`}
          negativeAction={() => setShowNewAdditionModal(false)}
          minWidth={"450px"}>
          {newAdditionModalDetails.map((details) => (
            <Accordian title={details.title} isActive={details.isActive}>
              <div className="flex flex-col items-center">
                <AddButton
                  text={details.single.text}
                  onClick={details.single.onClick}
                  styles={["w-1/2", "mb-2"]}
                />
                {details.bulk && (
                  <div className="flex w-full justify-center">
                    <UploadBulk
                      dashboardFields={details.bulk.dashboardFields}
                      saveBulkUpload={details.bulk.saveBulkUpload}
                      errorMessage={details.bulk.errorMessage}
                      successMessage={details.bulk.successMessage}
                      validate={details.bulk.validate}
                      validationResult={details.bulk.validationResult}
                      uploadBttnText={details.bulk.uploadBttnText}
                      uploadBttnStyles={["ml-10", "w-1/2"]}
                    />
                    <Tooltip
                      title={
                        <span>
                          Click{" "}
                          <a
                            href={details.bulk.templateLink}
                            style={{ textDecoration: "underline" }}>
                            here
                          </a>{" "}
                          to download template
                        </span>
                      }>
                      <QuestionMarkCircleIcon className="ml-2 h-8 w-8" />
                    </Tooltip>
                  </div>
                )}
              </div>
            </Accordian>
          ))}
        </Modal>
      )}
      {newParentProduct && (
        <ParentProductForm
          selectedProduct={newParentProduct}
          setSelectedProduct={setNewParentProduct}
          onClose={() => setNewParentProduct(null)}
          setAddCustomEntity={setAddCustomEntity}
          addCustomEntity={addCustomEntity}
          customers={customers}
          onChangeDropdown={onChangeDropdown}
        />
      )}

      {showBulkUpload && (
        <BulkUploadSlideOverModal
          dashboardFields={productDashboardFieldsV2}
          showBulkUpload={showBulkUpload}
          setShowBulkUpload={setShowBulkUpload}
          selectedActionType={selectedActionType}
          setSelectedActionType={setSelectedActionType}
          pastUploadsList={pastUploadsList}
          bulkUploadDuplicateValidation={BULK_UPLOAD_DUPLICATE_VALIDATION}
          bulkUploadDuplicateRowValidation={
            BULK_UPLOAD_DUPLICATE_ROW_VALIDATION
          }
        />
      )}
    </div>
  );
};

export default withProductsLogic(Products);
