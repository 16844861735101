import { useState, useContext } from "react";
import withPackerLogic from "#components/HOC/withPackerLogic";
import PickerBagCode from "#components/picker/common/PickerBagCode";
import SelectBoxType from "#components/packer/SelectBoxType";
import PackerBatch from "#components/packer/PackerBatch";
import MultipleSelectStationForm from "#components/common/MultipleSelectStationForm";
import ConfirmShipmentType from "#components/packer/ConfirmShipmentType";
import OrdersListView from "#components/prepper/OrdersListView";
import SetOrderNotesForm from "#components/packer/SetOrderNotesForm";
import { Tab } from "@headlessui/react";
import { AppStateContext } from "#contexts/appState";
import FnSkuLabel2 from "#components/common/FnSkuLabel2";

const Packer = ({
  currentBatch,
  scanBarcode,
  currentItemMismatch,
  setCurrentItemMismatch,
  confirmPackItem,
  confirmDropoff,
  loading,
  currentUser,
  selectedStation,
  stations,
  onSubmitStation,
  onSelectStation,
  printShippingLabel,
  simulateTote,
  simulateItemScan,
  weight,
  setWeight,
  boxTypes,
  submitBoxes,
  addBox,
  setAddBox,
  getNewBatch,
  workflow,
  customers,
  order,
  changeQuantity,
  subdomain,
  setBoxTypes,
  onChangeValue,
  submitOrderLineItem,
  cancelItem,
  restartActivity,
  pauseActivity,
  confirmTransportInformation,
  getFbaLabelGenerationStatus,
  transportLoading,
  confirmTypeOfShipment,
  switchTypeOfShipment,
  printBoxLabels,
  submitPalletInformation,
  markHazmatConfirmed,
  masterData,
  submitTrackingInformation,
  orders,
  checkOrderById,
  ordersData,
  filters,
  setFilters,
  checkPagination,
  submitFilters,
  markOrderException,
  orderNote,
  setOrderNote,
  submitNotes,
  removeBox,
  unpackItem,
  packItems,
  itemsToPack,
  setItemsToPack,
  confirmBoxes,
  setWeightOfBox,
  duplicateBox,
  handleFindAndPack,
  isAddItemQuantityExceed,
  isSetAddItemQuantityExceed,
  itemsToUnPack,
  setItemsToUnPack,
  handleFindAndUnpackItem,
  fetchSpecificOrder,
  currentProduct,
  groupedCasesBySku,
  setGroupedCasesBySku,
  tenant,
  reprintShippingLabel,
  printPackingLabels,
  packingLabelsEnabled,
  warehouses,
  associateUsernameInputEnabled,
  submitUsername,
  username,
  setUsername,
  showAssociateUsernameInput,
  setShowAssociateUsernameInput,
  print2DBarcode,
  downloadBoxLabels,
  print2DBarcodes,
  download2DBarcodes,
  numberOfBoxesToDuplicate,
  setNumberOfBoxesToDuplicate,
  prepEnabled,
  fbaLabelGenerationStatus,
  navigateToBoxContentsConfirmation,
  getCurrentBatch,
}) => {
  console.log("Packer component called :::::::");
  const appState = useContext(AppStateContext);

  if (currentUser && !currentUser.station) {
    return (
      <MultipleSelectStationForm
        title={"Please choose station."}
        selectedStation={selectedStation}
        onSubmit={onSubmitStation}
        stations={stations}
        onChangeDropdown={onSelectStation}
      />
    );
  }

  if (orderNote !== null) {
    return (
      <SetOrderNotesForm
        orderNote={orderNote}
        setOrderNote={setOrderNote}
        onSubmit={submitNotes}
        negativeAction={() => setOrderNote(null)}
        title="Report order"
      />
    );
  }

  if (!loading && !currentBatch) {
    const [selectedIndexTop, setSelectedIndexTop] = useState(0);
    const tabs = {
      Scan: (
        <>
          <PickerBagCode
            scanBarcode={scanBarcode}
            simulateTote={simulateTote}
            getNewBatch={getNewBatch}
            subdomain={subdomain}
            tabView={true}
            text="Scan a tote to get started"
            associateUsernameInputEnabled={associateUsernameInputEnabled}
            username={username}
            setUsername={setUsername}
            showAssociateUsernameInput={showAssociateUsernameInput}
            setShowAssociateUsernameInput={setShowAssociateUsernameInput}
            submitUsername={submitUsername}
          />
        </>
      ),
      Orders: (
        <OrdersListView
          orders={orders}
          checkOrderById={checkOrderById}
          filters={filters}
          setFilters={setFilters}
          submitFilters={submitFilters}
          ordersData={ordersData}
          checkPagination={checkPagination}
        />
      ),
    };
    function classNames(...classes) {
      return classes.filter(Boolean).join(" ");
    }
    return (
      <>
        <div className="flex-col pt-36">
          <Tab.Group
            selectedIndex={selectedIndexTop}
            onChange={setSelectedIndexTop}>
            <Tab.List className="flex items-center justify-center">
              <div className="space-x-2 rounded-full bg-primaryAccent p-2">
                {tabs &&
                  Object.keys(tabs).map((tab) => (
                    <Tab
                      key={tab}
                      className={({ selected }) =>
                        classNames(
                          "font-regular rounded-full px-6 py-2.5 leading-5",
                          "font-montserrat text-lg focus:outline-none focus:ring-0",
                          selected
                            ? "bg-white font-medium text-primaryAccent"
                            : "rounded-full border border-primaryAccent bg-transparent text-white",
                        )
                      }>
                      {tab}
                    </Tab>
                  ))}
              </div>
            </Tab.List>
            <div className="block">
              <Tab.Panels className="mt-2">
                {tabs &&
                  Object.values(tabs).map((tabInner, idx) => (
                    <Tab.Panel
                      key={idx}
                      className="flex flex-col items-center bg-transparent p-4">
                      {tabInner}
                    </Tab.Panel>
                  ))}
              </Tab.Panels>
            </div>
          </Tab.Group>
        </div>
      </>
    );
  }

  if (!appState?.tenant) {
    return (
      <div className="flex w-full justify-center">
        <div className="max-w-1000 mx-auto mb-8 mt-32 rounded-lg border-solid bg-white p-10">
          Loading...
        </div>
      </div>
    );
  }

  // if (
  //   order &&
  //   order.boxInformationNeeded !== false &&
  //   order.source === "FBA" &&
  //   currentBatch &&
  //   !order.confirmedTypeOfShipment &&
  //   appState?.tenant?.features?.rateShopping !== true
  // ) {
  //   return (
  //     <>
  //       {order.customer &&
  //         customers &&
  //         customers.findIndex((item) => item.id === order.customer) !== -1 && (
  //           <div className="text-2xl bg-black left-0 w-full text-center text-white">
  //             {customers.find((item) => item.id === order.customer).name} -{" "}
  //             {order ? order.orderId : ""}
  //           </div>
  //         )}

  //       <ConfirmShipmentType
  //         order={order}
  //         confirmTypeOfShipment={confirmTypeOfShipment}
  //         switchTypeOfShipment={switchTypeOfShipment}
  //       />
  //     </>
  //   );
  // }

  if (
    order &&
    order.boxInformationNeeded !== false &&
    (order.source === "FBA" ||
      tenant?.settings?.activities?.packing?.boxSelection === true) &&
    currentBatch &&
    (!currentBatch.boxes || currentBatch.boxes.length === 0 || addBox)
  ) {
    return (
      <>
        {order.customer &&
          customers &&
          customers.findIndex((item) => item.id === order.customer) !== -1 && (
            <div className="left-0 w-full bg-black text-center text-2xl text-white">
              {customers.find((item) => item.id === order.customer).name} -{" "}
              {order ? order.orderId : ""}
            </div>
          )}
        <SelectBoxType
          boxTypes={boxTypes}
          weight={weight}
          submitBoxes={submitBoxes}
          addBox={addBox}
          setAddBox={setAddBox}
          overflow={
            weight >= 50 &&
            subdomain !== "ware2go" &&
            subdomain !== "wase" &&
            subdomain !== "tym"
          }
          setBoxTypes={setBoxTypes}
          setWeight={setWeight}
          setWeightOfBox={setWeightOfBox}
          order={order}
          restartActivity={restartActivity}
          pauseActivity={pauseActivity}
        />
      </>
    );
  }

  if (
    order &&
    order.source === "FBA" &&
    currentBatch &&
    weight >= 50 &&
    !workflow &&
    subdomain !== "ware2go" &&
    subdomain !== "wase" &&
    subdomain !== "tym"
  ) {
    return (
      <>
        {order.customer &&
          customers &&
          customers.findIndex((item) => item.id === order.customer) !== -1 && (
            <div className="left-0 w-full bg-black text-center text-2xl text-white">
              {customers.find((item) => item.id === order.customer).name} -{" "}
              {order ? order.orderId : ""}
            </div>
          )}
        <SelectBoxType
          boxTypes={boxTypes}
          overflow={true}
          weight={weight}
          submitBoxes={submitBoxes}
          setBoxTypes={setBoxTypes}
          setWeight={setWeight}
          setWeightOfBox={setWeightOfBox}
          order={order}
          restartActivity={restartActivity}
          pauseActivity={pauseActivity}
        />
      </>
    );
  }

  if (currentBatch) {
    return (
      <>
        {order?.customer &&
          customers &&
          customers.findIndex((item) => item.id === order.customer) !== -1 && (
            <div className="relative left-0 w-full bg-black text-center text-2xl text-white">
              {customers.find((item) => item.id === order.customer).name}
              {order ? ` - ${order.orderId}` : ""}
            </div>
          )}
        <div>
          <PackerBatch
            currentBatch={currentBatch}
            scanBarcode={scanBarcode}
            currentItemMismatch={currentItemMismatch}
            setCurrentItemMismatch={setCurrentItemMismatch}
            confirmPackItem={confirmPackItem}
            confirmDropoff={confirmDropoff}
            printShippingLabel={printShippingLabel}
            simulateItemScan={simulateItemScan}
            weight={weight}
            setAddBox={setAddBox}
            setWeight={setWeight}
            setWeightOfBox={setWeightOfBox}
            order={order}
            workflow={workflow}
            changeQuantity={changeQuantity}
            subdomain={subdomain}
            onChangeValue={onChangeValue}
            submitOrderLineItem={submitOrderLineItem}
            cancelItem={cancelItem}
            restartActivity={restartActivity}
            confirmTransportInformation={confirmTransportInformation}
            getFbaLabelGenerationStatus={getFbaLabelGenerationStatus}
            fbaLabelGenerationStatus={fbaLabelGenerationStatus}
            transportLoading={transportLoading}
            printBoxLabels={printBoxLabels}
            submitPalletInformation={submitPalletInformation}
            markHazmatConfirmed={markHazmatConfirmed}
            masterData={masterData}
            submitTrackingInformation={submitTrackingInformation}
            markOrderException={markOrderException}
            pauseActivity={pauseActivity}
            removeBox={removeBox}
            unpackItem={unpackItem}
            packItems={packItems}
            itemsToPack={itemsToPack}
            setItemsToPack={setItemsToPack}
            confirmBoxes={confirmBoxes}
            duplicateBox={duplicateBox}
            handleFindAndPack={handleFindAndPack}
            isAddItemQuantityExceed={isAddItemQuantityExceed}
            isSetAddItemQuantityExceed={isSetAddItemQuantityExceed}
            itemsToUnPack={itemsToUnPack}
            setItemsToUnPack={setItemsToUnPack}
            handleFindAndUnpackItem={handleFindAndUnpackItem}
            fetchSpecificOrder={fetchSpecificOrder}
            currentProduct={currentProduct}
            groupedCasesBySku={groupedCasesBySku}
            setGroupedCasesBySku={setGroupedCasesBySku}
            tenant={tenant}
            reprintShippingLabel={reprintShippingLabel}
            printPackingLabels={printPackingLabels}
            packingLabelsEnabled={packingLabelsEnabled}
            warehouses={warehouses}
            print2DBarcode={print2DBarcode}
            downloadBoxLabels={downloadBoxLabels}
            print2DBarcodes={print2DBarcodes}
            download2DBarcodes={download2DBarcodes}
            numberOfBoxesToDuplicate={numberOfBoxesToDuplicate}
            setNumberOfBoxesToDuplicate={setNumberOfBoxesToDuplicate}
            prepEnabled={prepEnabled}
            navigateToBoxContentsConfirmation={
              navigateToBoxContentsConfirmation
            }
            getCurrentBatch={getCurrentBatch}
          />
        </div>
        {prepEnabled &&
          order?.source === "FBA" &&
          currentBatch?.workingList &&
          currentBatch.workingList.map((item, idx) => (
            <div
              className="z-50"
              style={{ position: "fixed", marginTop: 1000 }}
              key={idx}>
              <img
                id={`label-placeholder-${item.fnSku}`}
                style={{ position: "fixed", top: 10000 }}
              />
              <FnSkuLabel2
                id={item.fnSku}
                value={item.fnSku}
                text1={truncate(item.productName || item.name)}
                text2={item.bestByDate ? `Best by: ${item.bestByDate}` : null}
              />
            </div>
          ))}
      </>
    );
  }
  return null;
};

const truncate = (str) => {
  return str && str.length > 30 ? str.substring(0, 30) + "..." : str;
};

export default withPackerLogic(Packer);
