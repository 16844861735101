import { useState, useEffect, useContext } from "react";
import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import _ from "lodash";
import Toggle from "#components/utils/Toggle";
import { GET_ENTITY_TYPES } from "#queries";
import { UPSERT_PARENT_PRODUCT } from "#mutations";
import { useQuery } from "#hooks/useQuery";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import AutocompleteSingleSelectDropdown from "#components/utils/AutocompleteSingleSelectDropdown";
import { AppStateContext } from "#contexts/appState";

const PRODUCT_VARIANT_CHARACTERISTIC = "PRODUCT_VARIANT_CHARACTERISTIC";
const ALERT_TIMEOUT_IN_MS = 5000;

const ParentProductForm = ({
  onClose,
  selectedProduct,
  setSelectedProduct,
  setAddCustomEntity,
  addCustomEntity,
  customers,
}) => {
  const appState = useContext(AppStateContext);
  const masterVariantCharacteristicsQuery = useQuery(GET_ENTITY_TYPES);
  const upsertParentProductQuery = useQuery(UPSERT_PARENT_PRODUCT);
  const [masterVariantCharacteristics, setMasterVariantCharacteristics] =
    useState([]);
  useEffect(() => {
    if (selectedProduct?.hasVariants && addCustomEntity === null) {
      fetchEntityTypes();
    }
  }, [selectedProduct?.hasVariants, addCustomEntity]);

  async function fetchEntityTypes() {
    const filtersSet = {
      entityParent: [PRODUCT_VARIANT_CHARACTERISTIC],
    };
    const response = await masterVariantCharacteristicsQuery.fetchData({
      filters: filtersSet,
    });
    if (response.data) {
      setMasterVariantCharacteristics(response.data.entityTypes);
    }

    if (response.error) {
      setMasterVariantCharacteristics([]);
    }
  }

  const onSubmit = async () => {
    // VALIDATE MANDATORY FIELDS BEING PRESENT AND VALID
    // IF VALID, SUBMIT THE PRODUCT
    // IF ERROR, SHOW ERROR MESSAGE
    // IF SUCCESS, CLOSE THE MODAL AND REFRESH THE PRODUCTS
    if (!selectedProduct.customer) {
      return appState.setAlert(
        "Please select a client",
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
    }
    if (!selectedProduct.sku || selectedProduct.sku.trim() === "") {
      return appState.setAlert(
        "Please enter a SKU",
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
    }
    if (!selectedProduct.name || selectedProduct.name.trim() === "") {
      return appState.setAlert(
        "Please enter a name",
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
    }
    if (
      (!selectedProduct.variantCharacteristics ||
        selectedProduct.variantCharacteristics.length === 0) &&
      selectedProduct.hasVariants
    ) {
      return appState.setAlert(
        "A product with variants must have at least one variant characteristic",
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
    }

    const response = await upsertParentProductQuery.fetchData({
      productInput: {
        name: selectedProduct.name,
        sku: selectedProduct.sku,
        description: selectedProduct.description,
        customer: selectedProduct.customer,
        variantCharacteristics: selectedProduct.variantCharacteristics,
        active: selectedProduct.active,
        hasVariants: selectedProduct.hasVariants,
        source: selectedProduct.source || "Hopstack",
        id: selectedProduct.id || null,
      },
    });

    if (response.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }

    if (response.data) {
      appState.setAlert(
        response.data.upsertParentProduct.message,
        "success",
        ALERT_TIMEOUT_IN_MS,
      );
      onClose();
    }

    if (response.error) {
      appState.setAlert(response.error.message, "error", ALERT_TIMEOUT_IN_MS);
    }
  };

  return (
    <Modal
      title={selectedProduct.id ? `Edit Product` : `Add Product`}
      negativeAction={onClose}
      positiveAction={onSubmit}>
      <div className="space-y-4">
        <div>
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Product Details
          </span>
        </div>
        {!selectedProduct.id && (
          <div>
            <AutocompleteSingleSelectDropdown
              options={customers}
              labelKey={"name"}
              valueKey={"id"}
              onChange={(value) => {
                setSelectedProduct({
                  ...selectedProduct,
                  customer: value,
                });
              }}
              value={selectedProduct.customer}
              placeholder={"Select Client"}
              disabled={!!selectedProduct.id}
            />
          </div>
        )}
        <div>
          <TextField
            type="text"
            id="sku"
            label="SKU*"
            placeholder="SKU*"
            onChange={(e) =>
              !selectedProduct.id &&
              setSelectedProduct({
                ...selectedProduct,
                sku: e.target.value,
              })
            }
            value={selectedProduct.sku}
            disabled={!!selectedProduct.id}
          />
        </div>
        <div>
          <TextField
            type="text"
            id="name"
            label="Name*"
            placeholder="Name*"
            onChange={(e) =>
              setSelectedProduct({
                ...selectedProduct,
                name: e.target.value,
              })
            }
            value={selectedProduct.name}
          />
        </div>
        <div>
          <TextField
            type="text"
            id="description"
            label="Description"
            placeholder="Description"
            onChange={(e) =>
              setSelectedProduct({
                ...selectedProduct,
                description: e.target.value,
              })
            }
            value={selectedProduct.description}
          />
        </div>

        <div className="flex items-center space-x-4 px-2">
          <div>
            <Toggle
              enabled={selectedProduct.hasVariants}
              setEnabled={(e) =>
                setSelectedProduct({
                  ...selectedProduct,
                  hasVariants: e,
                })
              }
            />
          </div>
          <div className="text-lg">This product has variants</div>
        </div>
        {masterVariantCharacteristicsQuery.loading && (
          <div className="flex">
            <div className="h-6 w-6 animate-spin rounded-full border-b-2 border-t-2 border-primaryAccent"></div>
          </div>
        )}
        {selectedProduct.hasVariants && (
          <>
            <div id="selectingVariantCharacteristics">
              <label className="mb-2 block text-left">
                <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                  Select Variant Characteristics
                </span>
              </label>
              <div className="flex items-center space-x-4 px-2">
                <MultiSelectAutoComplete
                  options={masterVariantCharacteristics || []}
                  labelKey={"name"}
                  valueKey={"name"}
                  setValues={(e) =>
                    setSelectedProduct({
                      ...selectedProduct,
                      variantCharacteristics: e,
                    })
                  }
                  values={selectedProduct.variantCharacteristics || []}
                />
                <div className="text-lg">
                  <button
                    className="rounded-md bg-primaryAccent px-4 py-2 text-white"
                    onClick={() => {
                      setAddCustomEntity({
                        entityParent: PRODUCT_VARIANT_CHARACTERISTIC,
                        name: "",
                      });
                    }}>
                    Add New Characteristic
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="flex items-center space-x-4 px-2">
          <div>
            <Toggle
              enabled={selectedProduct.active}
              setEnabled={(e) =>
                setSelectedProduct({
                  ...selectedProduct,
                  active: e,
                })
              }
            />
          </div>
          <div className="text-lg">This product is active</div>
        </div>
      </div>
    </Modal>
  );
};

export default ParentProductForm;
