import PageTitle from "#components/utils/PageTitle";
import withBundlesLogic from "#components/HOC/withBundlesLogic";
import BundlesList from "#components/bundles/BundlesList";
import BundleProductsDetail from "#components/bundles/BundleProductsDetail";
import { UserAddIcon } from "@heroicons/react/outline";
import _ from "lodash";
import Pagination from "#components/common/Pagination";
import AddButton from "#components/utils/AddButton";
import BundleFilters from "#components/bundles/BundleFilters";
import BundleFormWizard from "../components/bundles/BundleFormWizard";
import UploadBulk from "../components/bulkUpload/UploadBulk";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "#constants/feature-flags";
import { Link } from "react-router-dom";

const Bundles = ({
  displayBundles,
  masterData,
  filters,
  onChangeFilter,
  setProducts,
  products,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  showFilters,
  setShowFilters,
  clearFilters,
  sort,
  setSort,
  customers,
  warehouses,
  addNewBundle,
  selectedBundle,
  setSelectedBundle,
  onChange,
  onChangeDropdown,
  saveBundle,
  addProduct,
  removeProduct,
  onChangeProduct,
  availableInventory,
  onChangeInventorySearch,
  fetchBundle,
  deleteButtonClicked,
  getMarketplaceProducts,
  writable,
  dashboardFields,
  saveBulkUpload,
  errorMessage,
  successMessage,
  validate,
  validationResult,
}) => {
  const ldClient = useLDClient();
  const usingNewStyleBundle =
    ldClient?.variation(featureFlags.TO_USE_NEW_STYLE_BUNDLE, false) ?? false;
  if (usingNewStyleBundle) {
    return (
      <div className="mt-4 flex h-full items-center justify-center text-2xl">
        <span className="text-center">
          <h1 className="mb-4 text-4xl font-bold">Page Retired</h1>
          The Bundle Management page has been retired and its functionality has
          been merged into the Product Management page.
          <br />
          Please visit the{" "}
          <Link to="/products" className="text-blue-600">
            Product Management
          </Link>{" "}
          page to access all bundle and product management features. For
          guidance on using the new combined interface, please refer to our
          updated user guide{" "}
          <a
            href="https://help.hopstack.io/home/creating-bundles"
            className="text-blue-600"
            target="_blank"
            rel="noopener noreferrer">
            Here
          </a>
        </span>
      </div>
    );
  }
  return (
    <div className="p-5">
      <div className="flex items-center pb-4">
        <div className="flex-1">
          <PageTitle>Bundles</PageTitle>
        </div>

        <div className="mb-4 flex items-center justify-end space-x-2">
          <AddButton
            text="Add Bundle"
            onClick={addNewBundle}
            icon={UserAddIcon}
            disabled={!writable}
          />
          <AddButton
            text="Download Template"
            onClick={() =>
              (window.location =
                "https://templates-onboarding.s3.amazonaws.com/uat/Bundles.xlsx")
            }
            disabled={!writable}
          />
          <UploadBulk
            dashboardFields={dashboardFields}
            saveBulkUpload={saveBulkUpload}
            errorMessage={errorMessage}
            successMessage={successMessage}
            validate={validate}
            validationResult={validationResult}
          />
        </div>
      </div>

      <BundlesList
        bundles={displayBundles}
        masterData={masterData}
        filters={filters}
        onChangeFilter={onChangeFilter}
        setProducts={setProducts}
        onChangeSearchKeyword={onChangeSearchKeyword}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        sort={sort}
        setSort={setSort}
        customers={customers}
        warehouses={warehouses}
        editButtonClicked={(e) => {
          fetchBundle(e.id);
        }}
        deleteButtonClicked={(e) => deleteButtonClicked(e)}
        writable={writable}
      />

      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
      />

      {showFilters && (
        <BundleFilters
          negativeAction={() => setShowFilters(false)}
          masterData={masterData}
          onChangeFilter={onChangeFilter}
          filters={filters}
          onSubmit={submitFilters}
          customers={customers}
          warehouses={warehouses}
          clearFilters={clearFilters}
        />
      )}

      {selectedBundle && (
        <BundleFormWizard
          selectedBundle={selectedBundle}
          onChange={onChange}
          onChangeDropdown={onChangeDropdown}
          title="Add Bundle"
          onClose={() => setSelectedBundle(null)}
          onSubmit={saveBundle}
          customers={customers}
          warehouses={warehouses}
          addProduct={addProduct}
          removeProduct={removeProduct}
          onChangeProduct={onChangeProduct}
          availableInventory={availableInventory}
          onChangeInventorySearch={onChangeInventorySearch}
          getMarketplaceProducts={getMarketplaceProducts}
        />
      )}

      {products && products.length > 0 && (
        <BundleProductsDetail
          products={products}
          onClose={() => setProducts(null)}
        />
      )}
    </div>
  );
};

export default withBundlesLogic(Bundles);
