import React from "react";
import PropTypes from "prop-types";
import { ArrowLeftIcon } from "@heroicons/react/outline";

function HeaderWithArrow({
  headerTitle,
  description,
  isLearnMore,
  learnMoreAction,
  href,
  arrowAction,
  isArrow = true,
}) {
  return (
    <div className="mb-4">
      <div className="flex items-center justify-start gap-3">
        {isArrow && (
          <ArrowLeftIcon
            onClick={arrowAction ?? (() => {})}
            className="h-6 w-6 cursor-pointer"
          />
        )}
        <h2 className="text-2xl font-semibold">{headerTitle}</h2>
      </div>

      <p className="text-base font-light text-gray-400">
        {description}{" "}
        {isLearnMore && (
          <a
            href={href ?? "#"}
            onClick={learnMoreAction ?? (() => {})}
            className="text-base font-medium text-primaryAccent underline">
            Learn More
          </a>
        )}
      </p>
    </div>
  );
}

// PropTypes validation
HeaderWithArrow.propTypes = {
  headerTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  description: PropTypes.string.isRequired,
  isLearnMore: PropTypes.bool,
  learnMoreAction: PropTypes.func,
  href: PropTypes.string,
  arrowAction: PropTypes.func,
  isArrow: PropTypes.bool,
};

export default HeaderWithArrow;
