import PageTitle from "#components/utils/PageTitle";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import _ from "lodash";
import { isDelmarTenant } from "#utils/tenantCheck";

const ListView = ({ scanBarcode, eligibleConsignments, tenant }) => {
  if (!eligibleConsignments || eligibleConsignments.length === 0) {
    return null;
  }

  const combinedConsignment = {
    ...eligibleConsignments[0],
    workingList: _.flatten(
      eligibleConsignments.map((consignment) => consignment.workingList),
    ),
    items: _.flatten(
      eligibleConsignments.map((consignment) => consignment.items),
    ),
  };

  const isPrepCenter = tenant?.typeOfCustomer?.includes("Prep Center") === true;

  const headers = () => {
    const returnHeaders = [
      "SKU",
      "Product Name",
      "Form Factor",
      "Expected / Received",
      "Action",
    ];

    if (
      combinedConsignment?.workingList?.some((item) => !!item.asin) &&
      isPrepCenter
    ) {
      returnHeaders.unshift("ASIN", "asin", false);
    }

    return returnHeaders;
  };

  return (
    <div className="w-screen flex-1 items-center justify-center text-center">
      <PageTitle>Total ({combinedConsignment?.workingList?.length})</PageTitle>
      <div className="m-auto mt-10 rounded-md bg-EBEBEB">
        <table className="m-auto -mt-4 divide-y divide-gray-200">
          <thead className="rounded-full p-4">
            <tr className="font-montserratborder-left text-primaryAccent">
              {headers().map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left text-lg font-medium tracking-wider"
                    key={headerIdx}>
                    {header}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left text-lg font-medium tracking-wider"
                    key={headerIdx}>
                    {header}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {combinedConsignment.workingList.map((item, index) => (
              <tr
                key={item.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td
                  className={`tracking-widerrounded-tl flex items-center rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                    index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                  }`}>
                  {/* TODO: THIS IS TEMPORARILY BEING ADDED JUST TO UNBLOCK DELMAR. 
                  THIS WILL BE REPLACED BY AN ACTUAL THUMBNAIL RENDERING OF THE PRODUCT FROM OUR BACKEND.
                  REASON WHY WE'RE TAKING THIS SEPARATELY IS BECAUSE WE'VE NEVER RENDERED IMAGES IN AN ARRAY OF PRODUCTS OTHER THAN ON THE CATALOG
                  THIS WILL NEED US TO FETCH PRODUCT IDS FROM THE LIST VIEW AND THEN MAKE AN API CALL TO GET ALL THOSE PRODUCTS FROM THE BACKEND */}
                  {isDelmarTenant(tenant?.subdomain) && (
                    <img
                      src={`https://partner.delmarintl.ca/catalog/${item.sku?.split("/")?.[0]}.jpg`}
                      className="h-10 w-10"
                    />
                  )}
                  {isPrepCenter &&
                  combinedConsignment.workingList.some(
                    (item) => !!item.asin,
                  ) ? (
                    <>
                      {buildMarketplaceHyperlink(
                        item.asin,
                        "ASIN",
                        item.source,
                      )}
                      <span className="ml-2">({item.sku})</span>
                    </>
                  ) : (
                    item.sku
                  )}
                </td>

                {!isPrepCenter &&
                  !combinedConsignment.workingList.some(
                    (item) => !!item.asin,
                  ) && (
                    <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                      {item.sku}
                    </td>
                  )}

                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {item.productName}
                </td>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {item.formFactor}
                </td>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B text-blue-500">
                  <div className="flex space-x-4">
                    <div>{item.quantity}</div>
                    <div>/</div>
                    <div
                      className={`${
                        item.quantity - item.availableQuantity > item.quantity
                          ? "text-red-500"
                          : "text-yellow-500"
                      }`}>
                      {item.quantity - item.availableQuantity}
                    </div>
                  </div>
                </td>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  <div
                    className="cursor-pointer rounded-full bg-blue-500 px-2 py-1 text-center text-lg text-white"
                    onClick={() => scanBarcode({ data: item.sku })}>
                    Scan
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListView;
