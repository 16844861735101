import React from "react";
import PropTypes from "prop-types";
import { Pagination } from "antd";

const CustomPagination = ({
  showDataCountOnly = false,
  currentPage,
  pageSize,
  totalDataCount,
  onPageChange,
  onPageSizeChange,
  currentDataCount, // The number of items on the current page
  pageSizeOptions,
}) => {
  const startCount = (currentPage - 1) * pageSize + 1;
  const endCount = startCount + currentDataCount - 1;

  const handlePageChange = (page, size) => {
    if (page != currentPage) onPageChange(page);
    else if (size != pageSize) onPageSizeChange(size);
  };

  return (
    <div className="custom-pagination flex w-full items-center justify-between p-4">
      {showDataCountOnly ? (
        <div className="results-info text-base text-gray-500">
          {currentDataCount > 0
            ? `Showing 1 to ${currentDataCount} of ${totalDataCount} results`
            : "No results"}
        </div>
      ) : (
        <div className="results-info text-base text-gray-500">
          {currentDataCount > 0
            ? `Showing ${startCount} to ${endCount} of ${totalDataCount} results`
            : "No results"}
        </div>
      )}
      {!showDataCountOnly && (
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={totalDataCount}
          onChange={handlePageChange}
          showSizeChanger
          onShowSizeChange={handlePageChange}
        />
      )}
    </div>
  );
};

CustomPagination.propTypes = {
  showDataCountOnly: PropTypes.bool,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalDataCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  currentDataCount: PropTypes.number.isRequired,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
};

export default CustomPagination;
