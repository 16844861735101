import dayjs from "dayjs";
import {
  ExclamationIcon,
  ChevronUpIcon,
  MenuAlt4Icon,
} from "@heroicons/react/solid";

export const SHOW_SUGGESTED_CHANGES_TIMEOUT = 800;
export const SEARCH_DEBOUNCE_TIME_IN_MS = 800;

export const PURCHASE_ORDER_STATUSES = {
  VERSION_UPDATED: "VersionUpdated",
  DRAFT: "Draft",
  PENDING_INTERNAL_APPROVAL: "PendingInternalApproval",
  INTERNALLY_APPROVED: "InternallyApproved",
  PENDING_VENDOR_REVIEW: "PendingVendorReview",
  ISSUED_TO_VENDOR: "IssuedToVendor",
  VENDOR_CHANGES_REQUESTED: "VendorChangesRequested",
  ACCEPTED_BY_VENDOR: "AcceptedByVendor",
  IN_PROGRESS: "InProgress",
  FULLY_RECEIVED: "FullyReceived",
  CLOSED: "Closed",
  CANCELLED: "Cancelled",
  UNFULFILLED: "Unfulfilled",
  PARTIALLY_FULFILLED: "PartiallyFullfilled",
  FULFILLED: "Fulfilled",
};

export const PO_STRING_VALUES = {
  INTERNAL: "Internal",
  THIRD_PARTY: "Third Party",
  LOW: "Low",
  MEDIUM: "Medium",
  URGENT: "Urgent",
  SEND_FOR_PENDING_INTERNAL_APPROVAL: "Send for Pending Internal Approval",
  SEND_FOR_PENDING_INTERNAL_APPROVAL_DESC:
    "Are you sure you want to send the PO for Internal Approval ? ",
  SEND_FOR_VENDOR_REVIEW: "Send for Vendor Review",
  SEND_FOR_VENDOR_REVIEW_DESC:
    "Are you sure you want to send the PO for Vendor Review ?",
  SUGGEST_THE_CHANGES: "Suggest the changes",
  SUGGEST_THE_CHANGES_DESC:
    "Are you sure you want to suggest the changes to the buyer ? Clicking 'Confirm' will route to the buyer for approve the changes.",
  ACCEPT_BY_VENDOR: "Accept by Vendor",
  ACCEPT_BY_VENDOR_DESC: "Are you sure you want to accept the PO ?",
  ACCEPT_THE_PO: "Accept The PO",
  ACCEPT_THE_PO_DESC: "Are you sure you want to accept the PO ?",
  METAL_STRING: "metal",
};

export const VENDOR_CLASSIFICATIONS = {
  INTERNAL_VENDOR: PO_STRING_VALUES.INTERNAL,
  THIRD_PARTY_VENDOR: PO_STRING_VALUES.THIRD_PARTY,
};

export const USER_ROLE_CLASSIFICATIONS = {
  ADMIN: "ADMIN",
  SUPER_ADMIN: "SuperAdmin",
  VENDOR: "VENDOR",
};

export const REQUEST_ACTIONS_ON_PO = {
  CANCEL_PO: "cancel_po",
  EDIT_STATUS: "edit_status",
  DELETE_PO: "delete_po",
  SHIP_PO: "ship_po",
};

export const getPriority = (value) => {
  switch (value) {
    case 1:
      return PO_STRING_VALUES.LOW;
    case 2:
      return PO_STRING_VALUES.MEDIUM;
    case 3:
      return PO_STRING_VALUES.URGENT;
    default:
      return "";
  }
};

export const UOM_VALUES_FOR_COMPONENTS = {
  gm: "Gms",
  ct: "Cts",
  pc: "Pcs",
};

export const handleMetalLossCost = (component) => {
  if (["gm", "ct"].includes(component.uom)) {
    return (
      component.unitPrice *
      component.quantity *
      (component.metalLossPercentage / 100)
    );
  } else
    return (
      (component.unitPrice *
        component.quantity *
        component.metalLossPercentage) /
      100
    );
};

export const METAL_LOSS_PERCENTAGE_OPTIONS = [
  {
    label: "0%",
    value: 0,
  },
  {
    label: "8%",
    value: 8,
  },
  {
    label: "10%",
    value: 10,
  },
  {
    label: "12%",
    value: 12,
  },
  {
    label: "20%",
    value: 20,
  },
];

export const columns = [
  { title: "Purchase Order ID", key: "orderId", isSort: true },
  { title: "Supplier/ Vendor", key: "vendorName", isSort: true },
  { title: "Warehouse", key: "warehouse", isSort: true },
  {
    title: "Products",
    key: "products",
    isSort: true,
    infoText: "Number of products",
  },
  { title: "Status", key: "status", isSort: true },
  { title: "Shipped", key: "shipped", isSort: false },
  { title: "Order Total", key: "orderTotal", isSort: true },
  { title: "Expected Ship Date", key: "expectedShipDate", isSort: true },
  {
    title: "Expected Delivery Date",
    key: "expectedDeliveryDate",
    isSort: true,
  },
  { title: "Priority", key: "priority", isSort: true },
  { title: "Notes", key: "notes", isSort: true },
  { title: "Action", key: "action", stickPosition: "right" },
];

export const columnsForLineItemsModal = [
  { title: "Product Info", key: "productInfo", isSort: false },
  { title: "Quantity", key: "quantity", isSort: false },
  { title: "Item Total", key: "itemTotal", isSort: false },
];

export const handleformatNumericValue = (value) => {
  let num = typeof value === "string" ? Number(value) : value;

  if (isNaN(num)) {
    throw new Error("Invalid input, cannot be converted to a number.");
  }

  // Return very small numbers (less than 0.01) as-is to preserve precision
  if (num > 0 && num < 0.01) {
    return num.toString(); // Ensure small numbers are returned as strings to preserve precision
  }

  // Rounding the number to avoid floating-point precision issues for larger numbers
  num = Math.round((num + Number.EPSILON) * 100) / 100;

  // If the number is greater than or equal to 1, or exactly 0, format with toFixed(2)
  if (num >= 1 || num === 0) {
    return num.toFixed(2);
  }

  // Otherwise, return the rounded number as a string with two decimal places for numbers >= 0.01 and < 1
  return num.toFixed(2);
};

export const handleGetNextStatus = (
  status,
  userInfo = {},
  isBeingEdited,
  isBeingCreated,
) => {
  if (status === PURCHASE_ORDER_STATUSES.DRAFT)
    return PURCHASE_ORDER_STATUSES.PENDING_INTERNAL_APPROVAL;
  if (status === PURCHASE_ORDER_STATUSES.PENDING_INTERNAL_APPROVAL)
    return PURCHASE_ORDER_STATUSES.INTERNALLY_APPROVED;
  if (status === PURCHASE_ORDER_STATUSES.PENDING_VENDOR_REVIEW) {
    if (userInfo.classification === VENDOR_CLASSIFICATIONS.THIRD_PARTY_VENDOR) {
      if (!isBeingCreated && isBeingEdited)
        return PURCHASE_ORDER_STATUSES.VENDOR_CHANGES_REQUESTED;
      else return PURCHASE_ORDER_STATUSES.ACCEPTED_BY_VENDOR;
    } else {
      return PURCHASE_ORDER_STATUSES.ACCEPTED_BY_VENDOR;
    }
  }
  if (status === PURCHASE_ORDER_STATUSES.ISSUED_TO_VENDOR) {
    if (userInfo.classification === VENDOR_CLASSIFICATIONS.THIRD_PARTY_VENDOR) {
      if (!isBeingCreated && isBeingEdited)
        return PURCHASE_ORDER_STATUSES.VENDOR_CHANGES_REQUESTED;
      else return PURCHASE_ORDER_STATUSES.ACCEPTED_BY_VENDOR;
    } else {
      return PURCHASE_ORDER_STATUSES.ACCEPTED_BY_VENDOR;
    }
  }
  if (status === PURCHASE_ORDER_STATUSES.VENDOR_CHANGES_REQUESTED) {
    return PURCHASE_ORDER_STATUSES.PENDING_VENDOR_REVIEW;
  }
  if (status === PURCHASE_ORDER_STATUSES.ACCEPTED_BY_VENDOR) {
    return PURCHASE_ORDER_STATUSES.PARTIALLY_FULFILLED;
  }
  if (status === PURCHASE_ORDER_STATUSES.PARTIALLY_FULFILLED) {
    return PURCHASE_ORDER_STATUSES.PARTIALLY_FULFILLED;
  }
};

export const handleGetBasicFormFieldDisabledStatus = (status, userInfo) => {
  if (
    (status === PURCHASE_ORDER_STATUSES.PENDING_VENDOR_REVIEW ||
      status === PURCHASE_ORDER_STATUSES.VENDOR_CHANGES_REQUESTED ||
      status === PURCHASE_ORDER_STATUSES.ISSUED_TO_VENDOR) &&
    userInfo.role === USER_ROLE_CLASSIFICATIONS.VENDOR
  ) {
    return true;
  }
  return false;
};

export const handleAddNewColumn = (status, userInfo) => {};

export const handleGetTitleAndDescForConfirmation = (status, userInfo = {}) => {
  if (status === PURCHASE_ORDER_STATUSES.DRAFT)
    return [
      PO_STRING_VALUES.SEND_FOR_PENDING_INTERNAL_APPROVAL,
      PO_STRING_VALUES.SEND_FOR_PENDING_INTERNAL_APPROVAL_DESC,
    ];
  if (status === PURCHASE_ORDER_STATUSES.PENDING_INTERNAL_APPROVAL)
    return [
      PO_STRING_VALUES.SEND_FOR_VENDOR_REVIEW,
      PO_STRING_VALUES.SEND_FOR_VENDOR_REVIEW_DESC,
    ];
  if (
    status === PURCHASE_ORDER_STATUSES.PENDING_VENDOR_REVIEW ||
    status === PURCHASE_ORDER_STATUSES.ISSUED_TO_VENDOR
  ) {
    if (userInfo.classification === VENDOR_CLASSIFICATIONS.THIRD_PARTY_VENDOR) {
      return [
        PO_STRING_VALUES.SUGGEST_THE_CHANGES,
        PO_STRING_VALUES.SUGGEST_THE_CHANGES_DESC,
      ];
    } else {
      return [
        PO_STRING_VALUES.ACCEPT_BY_VENDOR,
        PO_STRING_VALUES.ACCEPT_BY_VENDOR_DESC,
      ];
    }
  }
  if (status === PURCHASE_ORDER_STATUSES.VENDOR_CHANGES_REQUESTED) {
    return [
      PO_STRING_VALUES.ACCEPT_THE_PO,
      PO_STRING_VALUES.ACCEPT_THE_PO_DESC,
    ];
  }
};

export const handleGetCreateActionCtaLabel = (status, userInfo = {}) => {
  if (
    (status === PURCHASE_ORDER_STATUSES.PENDING_VENDOR_REVIEW ||
      status === PURCHASE_ORDER_STATUSES.ISSUED_TO_VENDOR) &&
    userInfo.classification === VENDOR_CLASSIFICATIONS.THIRD_PARTY_VENDOR
  )
    return "Suggest Changes";
  if (status) return "Submit";
  return "Create";
};

export const handleCheckForDateAndFormat = (date) => {
  if (date) {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    return formattedDate;
  } else return "";
};

export const handleGetTimeAndDate = (date) => {
  const parsedDate = dayjs(date);
  return parsedDate.format("YYYY-MM-DD HH:mm:ss");
};

export const handleInfoTextForSuggestedChanges = (userInfo) => {
  if (userInfo.role === USER_ROLE_CLASSIFICATIONS.VENDOR) {
    return "You have requested changes on this PO, click to view the suggested changes.";
  } else
    return "You have received some changes from vendor, please take action on it.";
};

export const checkEligibilityForSaveChanges = (status, userInfo) => {
  if (status === PURCHASE_ORDER_STATUSES.DRAFT) return true;
  else if (
    status === PURCHASE_ORDER_STATUSES.PENDING_VENDOR_REVIEW ||
    status === PURCHASE_ORDER_STATUSES.ISSUED_TO_VENDOR
  ) {
    if (userInfo.role === USER_ROLE_CLASSIFICATIONS.VENDOR) return false;
    else return true;
  }
  return false;
};

export const handleCheckDisabledStatusForSubmitCta = (
  status,
  userInfo = {},
) => {
  if (
    PURCHASE_ORDER_STATUSES.PENDING_VENDOR_REVIEW === status ||
    PURCHASE_ORDER_STATUSES.ISSUED_TO_VENDOR === status ||
    PURCHASE_ORDER_STATUSES.ACCEPTED_BY_VENDOR === status
  ) {
    if (userInfo.role === USER_ROLE_CLASSIFICATIONS.VENDOR) return false;
    else return true;
  } else return false;
};

export const CheckEligibilityForEditDeleteAndCancel = (
  po,
  actionType,
  userInfo = {},
) => {
  switch (actionType) {
    case "edit":
      if (
        po.status === PURCHASE_ORDER_STATUSES.DRAFT ||
        po.status === PURCHASE_ORDER_STATUSES.PENDING_INTERNAL_APPROVAL ||
        po.status === PURCHASE_ORDER_STATUSES.PENDING_VENDOR_REVIEW ||
        po.status === PURCHASE_ORDER_STATUSES.ISSUED_TO_VENDOR
      )
        return true;
      else return false;
    case "cancel":
      if (userInfo.role === USER_ROLE_CLASSIFICATIONS.VENDOR) return false;
      if (
        po.status === PURCHASE_ORDER_STATUSES.DRAFT ||
        po.status === PURCHASE_ORDER_STATUSES.PENDING_INTERNAL_APPROVAL ||
        po.status === PURCHASE_ORDER_STATUSES.PENDING_VENDOR_REVIEW ||
        po.status === PURCHASE_ORDER_STATUSES.VENDOR_CHANGES_REQUESTED ||
        po.status === PURCHASE_ORDER_STATUSES.ISSUED_TO_VENDOR
      ) {
        return true;
      } else return false;
    case "delete":
      if (po.status === PURCHASE_ORDER_STATUSES.DRAFT) return true;
      else return false;
    default:
      return false;
  }
};

export const handleCheckEligibilityForReadyToShip = (po, userInfo = {}) => {
  if (
    (po.status === PURCHASE_ORDER_STATUSES.ACCEPTED_BY_VENDOR ||
      po.status === PURCHASE_ORDER_STATUSES.PARTIALLY_FULFILLED) &&
    userInfo.role === USER_ROLE_CLASSIFICATIONS.VENDOR
  )
    return true;
  else return false;
};

export const handleCheckEligibilityForAuditTrail = (userInfo = {}) => {
  if (userInfo.role === USER_ROLE_CLASSIFICATIONS.VENDOR) return false;
  return true;
};

export const handleGetQuantityDetailOfLineItem = (
  lineItemId,
  lineItems,
  shippingDetails,
) => {
  const lineItem = lineItems.find((li) => li.lineItemId === lineItemId);
  const shippedQuantity =
    shippingDetails
      ?.filter((sd) =>
        sd.shippingLineItems.some((sli) => sli.lineItemId === lineItemId),
      )
      ?.flatMap((sd) => sd.shippingLineItems)
      ?.filter((sli) => sli.lineItemId === lineItemId)
      ?.reduce((acc, curr) => acc + curr.quantity, 0) ?? 0;

  return {
    expectedQuantity: lineItem?.quantity,
    shippedQuantity,
    remainingQuantity: lineItem?.quantity - shippedQuantity,
  };
};

export const handleGetTotalQuantityDetails = (lineItems, shippingDetails) => {
  const totalExpectedQuantity = lineItems.reduce((total, lineItem) => {
    return total + (lineItem?.quantity || 0);
  }, 0);

  const totalShippedQuantity =
    shippingDetails
      ?.flatMap((sd) => sd.shippingLineItems)
      ?.reduce((total, sli) => {
        const lineItem = lineItems.find(
          (li) => li.lineItemId === sli.lineItemId,
        );
        if (lineItem) {
          return total + (sli.quantity || 0);
        }
        return total;
      }, 0) ?? 0;

  return {
    totalExpectedQuantity,
    totalShippedQuantity,
  };
};

export const handleCheckEligibilityForSubmitToVendor = (po, userInfo = {}) => {
  if (
    po.status === PURCHASE_ORDER_STATUSES.PENDING_INTERNAL_APPROVAL &&
    po.isEditable
  )
    return true;
  else if (po.status === PURCHASE_ORDER_STATUSES.VENDOR_CHANGES_REQUESTED) {
    if (userInfo.role === USER_ROLE_CLASSIFICATIONS.VENDOR) return false;
    return true;
  } else return false;
};

export const handleCheckEligibilityForAcceptThePO = (po, userInfo = {}) => {
  if (po.status === PURCHASE_ORDER_STATUSES.ISSUED_TO_VENDOR) {
    if (
      userInfo.role === USER_ROLE_CLASSIFICATIONS.VENDOR &&
      userInfo.classification === VENDOR_CLASSIFICATIONS.THIRD_PARTY_VENDOR
    )
      return false;
    else if (
      userInfo.role === USER_ROLE_CLASSIFICATIONS.VENDOR &&
      userInfo.classification === VENDOR_CLASSIFICATIONS.INTERNAL_VENDOR
    )
      return true;
    else return false;
  }
  if (
    po.status === PURCHASE_ORDER_STATUSES.PENDING_VENDOR_REVIEW &&
    userInfo.role === USER_ROLE_CLASSIFICATIONS.VENDOR
  )
    return true;
  else return false;
};

export const handleGetStatusColor = (status) => {
  switch (status) {
    case PURCHASE_ORDER_STATUSES.VERSION_UPDATED:
      return { bgColor: "#BFDBFE", textColor: "#1E40AF" }; // light blue background, dark blue text
    case PURCHASE_ORDER_STATUSES.DRAFT:
      return { bgColor: "#EBF0FA", textColor: "#2264E5" }; // light gray background, dark gray text
    case PURCHASE_ORDER_STATUSES.PENDING_INTERNAL_APPROVAL:
      return { bgColor: "#FFF9E9", textColor: "#E3A400" }; // light yellow background, dark yellow text
    case PURCHASE_ORDER_STATUSES.INTERNALLY_APPROVED:
      return { bgColor: "#D1FAE5", textColor: "#065F46" }; // light green background, dark green text
    case PURCHASE_ORDER_STATUSES.ISSUED_TO_VENDOR:
      return { bgColor: "#D1FAE5", textColor: "#065F46" }; // light green background, dark green text
    case PURCHASE_ORDER_STATUSES.PENDING_VENDOR_REVIEW:
      return { bgColor: "#DFEEFD", textColor: "#224E73" }; // light sky blue background, dark sky blue text
    case PURCHASE_ORDER_STATUSES.VENDOR_CHANGES_REQUESTED:
      return { bgColor: "#FECACA", textColor: "#991B1B" }; // light red background, dark red text
    case PURCHASE_ORDER_STATUSES.ACCEPTED_BY_VENDOR:
      return { bgColor: "#CCFBF1", textColor: "#0F766E" }; // light teal background, dark teal text
    case PURCHASE_ORDER_STATUSES.IN_PROGRESS:
      return { bgColor: "#E9D5FF", textColor: "#6B21A8" }; // light purple background, dark purple text
    case PURCHASE_ORDER_STATUSES.FULLY_RECEIVED:
      return { bgColor: "#C7D2FE", textColor: "#3730A3" }; // light indigo background, dark indigo text
    case PURCHASE_ORDER_STATUSES.CLOSED:
      return { bgColor: "#000000", textColor: "#FFFFFF" }; // black background, white text
    case PURCHASE_ORDER_STATUSES.CANCELLED:
      return { bgColor: "#F1F1F1", textColor: "#6E7173" }; // light gray background, gray text
    case PURCHASE_ORDER_STATUSES.PARTIALLY_FULFILLED:
      return { bgColor: "#FEF3C7", textColor: "#D97706" }; // soft amber background, dark amber text (indicates partial fulfillment)
    case PURCHASE_ORDER_STATUSES.FULFILLED:
      return { bgColor: "#BBF7D0", textColor: "#15803D" }; // mint green background, dark green text (positive for completion)
    default:
      return { bgColor: "#FFFFFF", textColor: "#000000" }; // white background, black text
  }
};

export const handleGetRequiredOptions = (type, data) => {
  switch (type) {
    case "WAREHOUSES":
      return data?.map((warehouse) => ({
        label: warehouse.name,
        value: warehouse.id,
      }));
    case "VENDORS":
      return data?.map((vendor) => ({
        label: vendor.name,
        value: vendor.id,
      }));
    case "CURRENCY":
      return Object.keys(data).map((currency) => ({
        label: `${currency}-${data[currency]}`,
        value: `${currency}-${data[currency]}`,
      }));
    case "CUSTOMERS":
      return data?.map((customer) => ({
        label: customer.name,
        value: customer.id,
      }));
    case "PRIORITY_TAGS":
      return [
        {
          label: (
            <span className="flex items-center gap-1">
              <ExclamationIcon className="h-5 w-5 font-semibold text-red-700" />
              <span>Urgent</span>
            </span>
          ),
          value: 3,
          labelKey: "Urgent",
        },
        {
          label: (
            <span className="flex items-center gap-1">
              <ChevronUpIcon className="h-6 w-6 font-semibold text-red-300" />
              <span>Medium</span>
            </span>
          ),
          value: 2,
          labelKey: "Medium",
        },
        {
          label: (
            <span className="flex items-center gap-1">
              <MenuAlt4Icon className="h-4 w-4 font-semibold text-blue-500" />
              <span>Low</span>
            </span>
          ),
          value: 1,
          labelKey: "Low",
        },
      ];
    default:
      return [];
  }
};
