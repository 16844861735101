import { useState } from "react";
import PageTitle from "#components/utils/PageTitle";
import { ChevronDownIcon, ArrowLeftIcon } from "@heroicons/react/outline";

import CustomerSearchBar from "#components/salesManagement/CustomerSearchBar";
import AuditHistory from "#components/salesManagement/AuditHistory";
import SlideOverModal from "#components/common/SlideOverModal";
import { CreateCustomer } from "#components/salesManagement/CreateCustomer";
import CustomerDetails from "#components/salesManagement/CustomerDetails";

const SalesManagement = () => {
  const [showCreateCustomer, setShowCreateCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const unselectCustomer = () => setSelectedCustomer(null);
  const openModal = () => setShowCreateCustomer(true);
  const closeModal = () => setShowCreateCustomer(false);

  return (
    <>
      <div className="p-8">
        <div className="mb-8 flex items-start justify-between">
          <div>
            <PageTitle>
              Customer Details{" "}
              {selectedCustomer && ` >> ${selectedCustomer?.hrid}`}
            </PageTitle>
            {!selectedCustomer && (
              <p className="text-gray-600">
                Search and modify customer details from your CRM integration
                here
              </p>
            )}
          </div>
          <div className="flex justify-end">
            {selectedCustomer ? (
              <button
                onClick={unselectCustomer}
                className="inline-flex w-full cursor-pointer items-center gap-x-1 rounded-lg bg-primaryAccent px-3 py-2 font-medium text-white">
                <ArrowLeftIcon className="h-5 w-5" />
              </button>
            ) : (
              <button className="inline-flex w-full cursor-pointer items-center gap-x-1 rounded-lg bg-primaryAccent px-3 py-2 font-medium text-white">
                <span>Downloads</span>
                <ChevronDownIcon className="h-5 w-5" />
              </button>
            )}
          </div>
        </div>
        {!selectedCustomer && (
          <>
            <CustomerSearchBar
              setShowCreateCustomer={openModal}
              setSelectedCustomer={setSelectedCustomer}
            />
            <AuditHistory />
          </>
        )}
        {selectedCustomer && (
          <CustomerDetails crmCustomerData={selectedCustomer} />
        )}
      </div>
      <SlideOverModal
        open={showCreateCustomer}
        onClose={closeModal}
        title={"Add Customer"}>
        <CreateCustomer onCancel={setShowCreateCustomer} />
      </SlideOverModal>
    </>
  );
};

export default SalesManagement;
