import { useState, useEffect } from "react";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";
import _ from "lodash";
import AddButton from "#components/utils/AddButton";

const UOMConfiguration = ({
  selectedProduct,
  setSelectedProduct,
  appState,
}) => {
  const [activeUomLabels, setActiveUomLabels] = useState(
    appState.masterData?.uomLabels?.filter((label) => label.isActive),
  );

  const [baseUOM, setBaseUOM] = useState(
    activeUomLabels.find((i) => i.name === selectedProduct.baseUom),
  );

  const [possibleTargetUOMs, setPossibleTargetUOMs] = useState(
    activeUomLabels.filter((label) => label.name !== baseUOM.name),
  );

  const [showAddConfiguration, setShowAddConfiguration] = useState(true);

  useEffect(() => {
    const baseUOMFormFactor = activeUomLabels.find(
      (label) => label.name == selectedProduct.baseUom,
    );
    setBaseUOM(baseUOMFormFactor);
    const targetUoms = activeUomLabels.filter(
      (label) => label.name !== baseUOMFormFactor.name,
    );
    setPossibleTargetUOMs(targetUoms);
    setShowAddConfiguration(targetUoms.length > 0);
  }, [selectedProduct.baseUom]);

  const updateBaseUOM = (uom) => {
    setSelectedProduct({
      ...selectedProduct,
      baseUom: uom,
      uomConfiguration: [],
    });
    const baseUOMFormFactor = activeUomLabels.find(
      (formFactor) => formFactor.name === uom,
    );
    setBaseUOM(baseUOMFormFactor);
    const targetUoms = activeUomLabels.filter(
      (label) => label.name !== baseUOMFormFactor.name,
    );
    setPossibleTargetUOMs(targetUoms);
    setShowAddConfiguration(targetUoms.length > 0);
  };

  const changeBaseUom = (idx, name) => {
    const clonedProduct = { ...selectedProduct };
    clonedProduct.uomConfiguration[idx].baseUom = name;
    setSelectedProduct({ ...clonedProduct });
  };

  const changeBaseUomQuantity = (idx, e) => {
    const clonedProduct = { ...selectedProduct };
    clonedProduct.uomConfiguration[idx].baseUomQuantity =
      isNaN(parseInt(e.target.value)) === false
        ? parseInt(e.target.value)
        : null;
    setSelectedProduct({ ...clonedProduct });
  };

  const changeTargetUom = (idx, name) => {
    const clonedProduct = { ...selectedProduct };
    clonedProduct.uomConfiguration[idx].targetUom = name;
    setSelectedProduct({ ...clonedProduct });
    const labelsInUse = [
      clonedProduct.baseUom,
      ...clonedProduct.uomConfiguration.map((config) => config.targetUom),
    ];
    const targetUOMs = activeUomLabels.filter(
      (label) => labelsInUse.includes(label.name) === false,
    );
    setPossibleTargetUOMs(targetUOMs);
    setShowAddConfiguration(targetUOMs.length > 0);
  };

  const addConfiguration = () => {
    const existingUomConfigurations = selectedProduct.uomConfiguration;
    if (existingUomConfigurations.length > 0) {
      const previousUOMConfig =
        existingUomConfigurations[existingUomConfigurations.length - 1];
      if (
        !previousUOMConfig.baseUomQuantity ||
        isNaN(previousUOMConfig.baseUomQuantity) ||
        !previousUOMConfig.baseUom ||
        !previousUOMConfig.targetUom
      ) {
        appState.setAlert("Provide a valid UOM Configuration", "error", 5000);
        return;
      }
    }

    setSelectedProduct({
      ...selectedProduct,
      uomConfiguration: [
        ...existingUomConfigurations,
        {
          baseUom: "",
          baseUomQuantity: 1,
          targetUom: "",
        },
      ],
    });
    setShowAddConfiguration(
      existingUomConfigurations.length + 1 < possibleTargetUOMs.length,
    );
  };

  useEffect(() => {
    const labelsInUse = [
      selectedProduct.baseUom,
      ...selectedProduct.uomConfiguration.map((config) => config.targetUom),
    ];
    const targetUOMs = activeUomLabels.filter(
      (label) => labelsInUse.includes(label.name) === false,
    );
    setPossibleTargetUOMs(targetUOMs);
    setShowAddConfiguration(targetUOMs.length > 0);
  }, [selectedProduct.uomConfiguration]);

  const removeUomConfiguration = (idx) => {
    const clonedProduct = { ...selectedProduct };
    clonedProduct.uomConfiguration = clonedProduct.uomConfiguration.filter(
      (_, index) => idx !== index,
    );
    setSelectedProduct({ ...clonedProduct });
  };

  return (
    <>
      <div className="flex items-center space-x-4 px-2">
        <div>
          <Dropdown
            placeholder={"Base Unit of Measure"}
            list={activeUomLabels}
            labelKey="name"
            valueKey="name"
            name="weightMeasure"
            setSelected={(uom) => updateBaseUOM(uom)}
            selectedValue={selectedProduct.baseUom}
          />
        </div>
      </div>

      <>
        {selectedProduct?.uomConfiguration?.map((uomConfiguration, idx) => {
          let baseUOMFormFactorList = [];

          if (idx === 0) {
            baseUOMFormFactorList = [baseUOM];
          } else {
            const previousTargetUOM = activeUomLabels.find(
              (label) =>
                label.name ===
                selectedProduct.uomConfiguration[idx - 1].targetUom,
            );
            baseUOMFormFactorList = [previousTargetUOM];
          }

          const uomLabelsToExcludeInTargetList = [
            selectedProduct.baseUom,
            ...selectedProduct.uomConfiguration
              .filter((_, cIdx) => cIdx < idx)
              .map((config) => config.targetUom),
          ];

          const targetUOMFormFactorList = activeUomLabels.filter(
            (label) =>
              uomLabelsToExcludeInTargetList.includes(label.name) === false,
          );

          {
            /* why is this failig but above working? */
            /* const targetUOMFormFactorList = possibleTargetUOMs; */
          }

          return (
            <UOMConfig
              uomConfiguration={uomConfiguration}
              idx={idx}
              changeBaseUomQuantity={changeBaseUomQuantity}
              baseUOMFormFactorList={baseUOMFormFactorList}
              targetUOMFormFactorList={targetUOMFormFactorList}
              changeBaseUom={changeBaseUom}
              changeTargetUom={changeTargetUom}
              removeUomConfiguration={removeUomConfiguration}
              disableInteraction={
                selectedProduct.uomConfiguration.length - 1 !== idx
              }
              key={idx}
            />
          );
        })}
      </>

      {showAddConfiguration && (
        <AddButton text="Add Configuration" onClick={addConfiguration} />
      )}
    </>
  );
};

const UOMConfig = ({
  uomConfiguration,
  idx,
  changeBaseUomQuantity,
  baseUOMFormFactorList,
  changeBaseUom,
  targetUOMFormFactorList,
  changeTargetUom,
  removeUomConfiguration,
  disableInteraction,
}) => {
  return (
    <div className="flex items-center space-x-4">
      <div>
        <TextField
          type="number"
          id="quantity"
          label="Quantity"
          placeholder=" "
          onChange={(qty) => changeBaseUomQuantity(idx, qty)}
          value={parseInt(uomConfiguration.baseUomQuantity)}
          name="quantity"
          disabled={disableInteraction}
        />
      </div>
      <div>
        <Dropdown
          placeholder={"Base UOM"}
          list={baseUOMFormFactorList}
          labelKey="name"
          valueKey="name"
          name="baseUom"
          setSelected={(uom) => changeBaseUom(idx, uom)}
          selectedValue={uomConfiguration.baseUom}
          disabled={disableInteraction}
        />
      </div>
      <div className="flex items-center justify-center text-2xl">=</div>
      <div>
        <Dropdown
          placeholder={"Target UOM"}
          list={targetUOMFormFactorList}
          labelKey="name"
          valueKey="name"
          name="formFactor"
          setSelected={(uom) => changeTargetUom(idx, uom)}
          selectedValue={uomConfiguration.targetUom}
          disabled={disableInteraction}
        />
      </div>
      {!disableInteraction && (
        <div
          className="cursor-pointer pl-2 text-lg font-bold text-red-500"
          onClick={() => removeUomConfiguration(idx)}>
          Remove
        </div>
      )}
    </div>
  );
};

export default UOMConfiguration;
