import React, { createContext, useContext, useState, useEffect } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "#constants/feature-flags";

const FeatureFlagsContext = createContext();

export const useFeatureFlags = () => useContext(FeatureFlagsContext);

export const FeatureFlagsProvider = ({ children }) => {
  const [ldFlags, setLdFlags] = useState({
    isBranded: undefined,
    useNewStyleBundle: undefined,
    storageManagementEnabled: undefined,
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const ldClient = useLDClient();

  useEffect(() => {
    const fetchFlags = async () => {
      if (ldClient) {
        const isBranded = await ldClient.variation(
          featureFlags.IS_BRANDED,
          false,
        );
        const useNewStyleBundle = await ldClient.variation(
          featureFlags.TO_USE_NEW_STYLE_BUNDLE,
          false,
        );
        const storageManagementEnabled = await ldClient.variation(
          featureFlags.HIERARCHICAL_STORAGE_MANAGEMENT_ENABLED,
          false,
        );

        setLdFlags({
          isBranded,
          useNewStyleBundle,
          storageManagementEnabled,
        });
        setIsLoaded(true);
      }
    };

    fetchFlags();
  }, [ldClient]);

  useEffect(() => {
    const handleFlagChange = () => {
      const isBranded = ldClient.variation(featureFlags.IS_BRANDED, false);
      const useNewStyleBundle = ldClient.variation(
        featureFlags.TO_USE_NEW_STYLE_BUNDLE,
        false,
      );
      const storageManagementEnabled = ldClient.variation(
        featureFlags.HIERARCHICAL_STORAGE_MANAGEMENT_ENABLED,
        false,
      );

      setLdFlags({
        isBranded,
        useNewStyleBundle,
        storageManagementEnabled,
      });
    };

    if (ldClient) {
      ldClient.on("change", handleFlagChange);
    }

    return () => {
      if (ldClient) {
        ldClient.off("change", handleFlagChange);
      }
    };
  }, [ldClient]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <FeatureFlagsContext.Provider value={ldFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
