import {
  ArrowLeftIcon,
  InformationCircleIcon,
  DuplicateIcon,
  DotsVerticalIcon,
  PlusIcon,
  TrashIcon,
  PencilIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import HollowButton from "../../utils/hollowButton";
import FilledButton from "../../utils/filledButton";
import TextInput from "../common/TextInput";
import DropdownInput from "../common/DropdownInput";
import MessageDisplay from "../common/MessageDisplay";
import * as SlateTable from "../common/SlateTable";
import { Modal } from "../common/Modal";
import { useEffect, useState } from "react";
import _, { set } from "lodash";
import { Menu } from "@headlessui/react";
import { CheckboxInput } from "../common/CheckboxInput";
import { LocationCapacityUnitsEnum } from "../../constants/location-capacity";
import STORAGE_SECTION_STATUSES from "#constants/storage-section-statuses";
import ReactTooltip from "react-tooltip";
import { log } from "util";
import { UPDATE_STORAGE_SECTION } from "#mutations/index";
import { useQuery } from "#hooks/useQuery";
import LocationReservationMenu from "./LocationReservationMenu";

const CreateStorageSection = ({
  selectedStorageSection,
  setSelectedStorageSection,
  onChangeStorageSection,
  storageTypes,
  locationTree,
  setLocationTree,
  selectedHierarchy,
  setSelectedHierarchy,
  locationTypes,
  onChangeHierarchy,
  onChangeHierarchyLocation,
  submitSaveHierarchy,
  getCurrentHierarchyArray,
  deleteLocation,
  showAlert,
  submitCreateStorageSection,
  confirmSubmitCreateStorageSection,
  setConfirmSubmitCreateStorageSection,
  submitEditStorageSection,
  locationDeletions,
  setLocationDeletions,
  currentHierarchyArray,
  setSelectedLocations,
  selectedLocations,
  shouldSubmitEdit,
  setShouldSubmitEdit,
  submitStorageSectionStatusEdit,
  customers,
  reserveLocation,
  searchReservations,
  locationReservations,
}) => {
  const [showStorageStatusUpdate, setShowStorageStatusUpdate] = useState(false);
  const [
    showLocationDeletionConfirmation,
    setShowLocationDeletionConfirmation,
  ] = useState(false);
  const [storageLocation, setStorageLocation] = useState(null);
  const [locationsToDelete, setLocationsToDelete] = useState(null);
  const [locationStatusUpdates, setLocationStatusUpdates] = useState({});
  const [inactiveReason, setInactiveReason] = useState("unavailable");
  const [showReservationsMenu, setShowReservationsMenu] = useState(false);
  const hierarchyLocationMenu = [
    {
      icon: DuplicateIcon,
      text: "Copy Capacity to All",
      isShown: (location) => {
        return true;
      },
      action: (location) => {
        setSelectedHierarchy({
          ...selectedHierarchy,
          locations: selectedHierarchy.locations.map((locationIter) => ({
            ...locationIter,
            capacity: {
              ...location.capacity,
            },
          })),
        });
      },
    },
  ];

  const hierarchyMenu = [
    {
      icon: PlusIcon,
      text: "Add Sub Hierarchy",
      isShown: (location) => {
        return location.children.length === 0;
      },
      action: (location) => {
        const type = _.uniq(
          locationTypes.map((locationType) => locationType.category),
        )[0];
        const subType = locationTypes.find(
          (locationType) => locationType.category === type,
        ).id;
        setSelectedHierarchy({
          parent: location,
          type,
          subType,
          locations: [],
        });
      },
    },
    {
      icon: PencilIcon,
      text: "Edit Sub Hierarchy",
      isShown: (location) => {
        return location.children.length > 0;
      },
      action: (location) => {
        const locationType = locationTypes.find(
          (type) => type.id === location.children[0].type,
        );

        const type = locationType.category;
        const subType = locationType.id;

        const ranges = {};
        if (locationType.threeDimRange) {
          const lastChildCode =
            location.children[location.children.length - 1].code;
          const rangesFromLastChild = lastChildCode
            .split(location.code + "-")[1]
            .split(".")
            .slice(-3);
          ranges.rangeX = parseInt(rangesFromLastChild[0]);
          ranges.rangeY = parseInt(rangesFromLastChild[1]);
          ranges.rangeZ = parseInt(rangesFromLastChild[2]);
        } else {
          ranges.rangeX = location.children.length;
        }
        setSelectedHierarchy({
          edit: true,
          parent: location,
          type,
          subType,
          originalSubType: subType,
          ...ranges,
          locations: location.children.map((locationNode) => ({
            name: locationNode.name,
            code: locationNode.code.split(location.code + "-")[1],
            capacity: locationNode.capacity,
          })),
        });
      },
    },
    {
      icon: TrashIcon,
      text: "Delete Hierarchy",
      isShown: () => true,
      action: (location) => {
        setLocationsToDelete(location);
        setShowLocationDeletionConfirmation(true);
      },
    },
    {
      icon: RefreshIcon,
      text: "Update Status",
      isShown: () => true,
      action: (location) => {
        if (location?.inactiveReason?.reason) {
          setInactiveReason(location.inactiveReason.reason);
        } else {
          setInactiveReason("unavailable");
        }
        setSelectedLocations(null);
        setShowStorageStatusUpdate(true);
        setStorageLocation(location);
      },
    },
    {
      icon: DuplicateIcon,
      text: "Manage Reservations",
      isShown: () => true,
      action: async (location) => {
        setSelectedLocations(null);
        setStorageLocation(location);
        await searchReservations(location.backendId);
        setShowReservationsMenu(true);
      },
    },
  ];

  useEffect(() => {
    if (
      storageLocation &&
      typeof storageLocation.activationStatus === "boolean"
    ) {
      setLocationStatusUpdates((prevUpdates) => ({
        ...prevUpdates,
        [storageLocation.backendId]: storageLocation.activationStatus
          ? "available"
          : "unavailable",
      }));
    }
  }, [storageLocation]);

  const updateLocationStatus = async (location) => {
    let updatedLocations = selectedLocations ? [...selectedLocations] : [];
    const inactiveReasonType = inactiveReason || "unavailable";
    setInactiveReason(inactiveReasonType);

    const commonProperties = (loc, status, reason) => ({
      backendId: loc.backendId,
      parent: loc.parent,
      name: loc.name,
      code: loc.code,
      capacity: loc.capacity,
      activationStatus: status === "available",
      ...(status === "unavailable" && {
        inactiveReason: { inactiveType: "unavailable", reason: reason },
      }),
    });

    const getLocationStatus = (loc) =>
      locationStatusUpdates[loc.backendId] ||
      (loc.activationStatus ? "available" : "unavailable");

    const locationStatus = getLocationStatus(location);

    const updateChildrenStatus = (loc, status, reason) => {
      loc.children.forEach((child) => {
        updatedLocations = updatedLocations.filter(
          (l) => l.code !== child.code,
        );
        updatedLocations.push(commonProperties(child, status, reason));
        if (child.children.length > 0) {
          updateChildrenStatus(child, status, reason);
        }
      });
    };

    const updateParentStatus = (loc, status, reason) => {
      if (loc.parent) {
        let parentStatus;
        if (status === "available") {
          parentStatus = "available";
        } else {
          const allSiblingsUnavailable = loc.parent.children.every(
            (sibling) => sibling.code === loc.code || !sibling.activationStatus,
          );
          parentStatus = allSiblingsUnavailable ? "unavailable" : "available";
        }

        updatedLocations = updatedLocations.filter(
          (l) => l.code !== loc.parent.code,
        );
        updatedLocations.push(
          commonProperties(loc.parent, parentStatus, reason),
        );

        if (parentStatus !== loc.parent.activationStatus) {
          updateParentStatus(loc.parent, parentStatus, reason);
        }
      }
    };

    // Ensure reason is provided if marking unavailable
    if (locationStatus === "unavailable" && !inactiveReasonType) {
      showAlert(
        "Please provide a reason for marking the location as unavailable.",
      );
      return;
    }

    // Update the location itself
    updatedLocations = updatedLocations.filter(
      (loc) => loc.code !== location.code,
    );
    updatedLocations.push(
      commonProperties(location, locationStatus, inactiveReasonType),
    );
    const markedLocations = updatedLocations;
    // Propagate status change to children
    if (location.children.length > 0) {
      updateChildrenStatus(location, locationStatus, inactiveReasonType);
    }

    // // Propagate status change to parents
    updateParentStatus(location, locationStatus, inactiveReasonType);

    setSelectedLocations(markedLocations);
    setStorageLocation({
      ...location,
      activationStatus: locationStatus === "available",
    });

    setShouldSubmitEdit(true);

    // Check and set storage section status
    let allLocationsAreAvailable = true;
    let allLocationsAreUnavailable = true;

    await locationTree.dfsTraverseAsync(async (loc) => {
      const updatedLoc = updatedLocations.find((l) => l.code === loc.code);
      const locStatus = updatedLoc
        ? updatedLoc.activationStatus
        : loc.activationStatus;
      if (locStatus) {
        allLocationsAreUnavailable = false;
      } else {
        allLocationsAreAvailable = false;
      }
    });

    const storageSectionId = selectedStorageSection.id;

    if (allLocationsAreAvailable) {
      submitStorageSectionStatusEdit(
        storageSectionId,
        "available",
        inactiveReason,
      );
    } else if (allLocationsAreUnavailable) {
      submitStorageSectionStatusEdit(
        storageSectionId,
        "unavailable",
        inactiveReason,
      );
    } else {
      submitStorageSectionStatusEdit(storageSectionId, "available", null, true);
    }
  };

  const handleStatusChange = (e) => {
    const { value } = e.target;
    setLocationStatusUpdates((prevUpdates) => ({
      ...prevUpdates,
      [storageLocation.backendId]: value,
    }));
  };

  const handleLocationDeletion = async (location) => {
    if (showLocationDeletionConfirmation === true) {
      const deletions = [...locationDeletions, location];
      setLocationDeletions(deletions);
      setShowLocationDeletionConfirmation(false);
      setLocationsToDelete(null);
    }

    setShouldSubmitEdit(true);
  };

  return (
    <>
      <div className="min-h-screen bg-white px-3 py-5">
        <div className="flex w-full">
          <div className="flex">
            <ArrowLeftIcon
              className="h-6 w-6 cursor-pointer"
              onClick={() => setSelectedStorageSection(null)}
            />
            <div className="ml-4 text-xl font-semibold">
              {selectedStorageSection.edit ? "Edit" : "Create"} Storage Section
            </div>
          </div>
          <div className="ml-auto flex">
            <HollowButton
              text={"Cancel"}
              colour={"primaryAccent"}
              onClick={() => {
                setSelectedStorageSection(null);
              }}
            />
            <FilledButton
              text={"Submit"}
              colour={"primaryAccent"}
              onClick={() => {
                if (!selectedStorageSection.name) {
                  showAlert(
                    "Please fill in the Storage Section Name",
                    "error",
                    3000,
                  );
                  return;
                }
                if (!selectedStorageSection.code) {
                  showAlert(
                    "Please fill in the Storage Section Code",
                    "error",
                    3000,
                  );
                  return;
                }
                if (locationTree.isEmpty()) {
                  showAlert("Please add at least one hierarchy", "error", 3000);
                  return;
                }
                setConfirmSubmitCreateStorageSection(true);
              }}
              className="ml-4"
            />
          </div>
        </div>
        <div className="mt-6 text-base font-semibold">
          1. Storage Section Details
        </div>
        <div className="mt-4">
          <div className="flex">
            <div className="mr-8">
              <div className="flex">
                <div className="mt-1 text-sm font-normal text-gray-500">
                  Storage Section Name
                </div>
              </div>
              <TextInput
                name={"name"}
                onChange={onChangeStorageSection}
                value={selectedStorageSection["name"]}
                width={"w-80"}
              />
            </div>
            <div className="mr-8">
              <div className="flex">
                <div className="mt-1 text-sm font-normal text-gray-500">
                  Storage Section Code
                </div>
              </div>
              <TextInput
                name={"code"}
                onChange={onChangeStorageSection}
                value={selectedStorageSection["code"]}
                width={"w-44"}
                disabled={selectedStorageSection.edit}
              />
            </div>
            <div className="mr-8">
              <div className="flex">
                <div className="mt-1 text-sm font-normal text-gray-500">
                  Storage Section Charactersistics
                </div>
              </div>
              <DropdownInput
                name={"type"}
                onChange={onChangeStorageSection}
                value={selectedStorageSection["type"]}
                width={"w-80"}
                options={Object.values(storageTypes).map((type) => ({
                  name: type,
                }))}
                valueKey={"name"}
                labelKey={"name"}
              />
            </div>
          </div>
          <div className="mt-1 text-sm font-normal text-gray-500">
            Each Storage Type has a unique code and are exclusive to a single
            hierarchy
          </div>
          {selectedStorageSection.edit && (
            <div className="mt-1 text-sm font-normal text-gray-500">
              While editing, the Storage Section Code cannot be changed
            </div>
          )}
        </div>
        <div className="mt-6 text-base font-semibold">2. Hierarchy Details</div>
        <div className="flex">
          <MessageDisplay customStyles={"min-w-64 w-max h-20 ml-auto"}>
            <div className="leading-17 ml-4 mr-4 mt-4 text-left text-base font-medium tracking-normal text-gray-500">
              Location Hierarchy
            </div>
            <div className="leading-19 ml-4 mr-4 mt-1 text-left text-lg font-semibold tracking-normal">
              {locationTree
                ?.getHierarchyArray()
                ?.map((type) => locationTypes.find((t) => t.id === type).name)
                .length
                ? locationTree
                    ?.getHierarchyArray()
                    ?.map(
                      (type) => locationTypes.find((t) => t.id === type).name,
                    )
                    .join(" > ")
                : "-"}
            </div>
          </MessageDisplay>
        </div>
        <div className="mt-4 flex">
          <MessageDisplay customStyles={"flex w-full py-4 px-2"} border={true}>
            <InformationCircleIcon className="h-8 w-8" />
            <div className="leading-17 ml-2 text-left text-sm font-normal tracking-normal text-lightGray">
              As you build on the hierarchy, you can "Add Hierarchy" to add to
              the same level of Hierarchy. If you want to add another level
              below the current hierarchy, use "+" to build sub-levels.
            </div>
          </MessageDisplay>
        </div>
        <div className="mt-4 max-w-full overflow-x-auto">
          <SlateTable.SlateTable>
            <SlateTable.SlateTableHeader>
              <SlateTable.SlateTableHeaderCell first={true}>
                Type
              </SlateTable.SlateTableHeaderCell>
              <SlateTable.SlateTableHeaderCell>
                Sub Type
              </SlateTable.SlateTableHeaderCell>
              <SlateTable.SlateTableHeaderCell>
                Name
              </SlateTable.SlateTableHeaderCell>
              <SlateTable.SlateTableHeaderCell>
                Code
              </SlateTable.SlateTableHeaderCell>
              <SlateTable.SlateTableHeaderCell>
                Capacity
              </SlateTable.SlateTableHeaderCell>
              <SlateTable.SlateTableHeaderCell>
                Status
              </SlateTable.SlateTableHeaderCell>
              <SlateTable.SlateTableHeaderCell>
                Action
              </SlateTable.SlateTableHeaderCell>
            </SlateTable.SlateTableHeader>
            {locationTree.isEmpty() ? (
              <SlateTable.SlateTableBodyRow noBottomBorder={true}>
                <SlateTable.SlateTableBodyRowCell
                  className="mt-8 w-full"
                  colspan={6}>
                  <div className="flex h-max w-full">
                    <div className="m-auto">
                      <img
                        src="https://hopstack-pub.s3.amazonaws.com/ContentNotFound.png"
                        className="mx-auto mb-2"
                      />
                      <div className="mx-auto mb-2 text-center text-base font-semibold leading-5 tracking-normal">
                        No Hierarchy Created
                      </div>
                      <div className="mx-auto mb-2 text-center text-sm font-normal leading-4 tracking-normal text-lightGray">
                        Click below to start adding hierarchies
                      </div>
                      <div
                        className="cursor-pointer text-center text-sm font-semibold leading-6 tracking-normal text-blueBackground"
                        onClick={() => {
                          const type = _.uniq(
                            locationTypes.map(
                              (locationType) => locationType.category,
                            ),
                          )[0];
                          const subType = locationTypes.find(
                            (locationType) => locationType.category === type,
                          ).id;
                          setSelectedHierarchy({
                            parent: locationTree.root,
                            type,
                            subType,
                            locations: [],
                          });
                        }}>
                        <u>Add Hierarchy</u>
                      </div>
                    </div>
                  </div>
                </SlateTable.SlateTableBodyRowCell>
              </SlateTable.SlateTableBodyRow>
            ) : (
              <SlateTable.SlateTableBody>
                {locationTree.dfsTraverse((location) => {
                  const locationType = locationTypes.find(
                    (type) => type.id === location.type,
                  );
                  return (
                    <SlateTable.SlateTableBodyRow>
                      <SlateTable.SlateTableBodyRowCell
                        style={{
                          paddingLeft: `${location.depth * 20}px`,
                        }}>
                        {locationType.category}
                      </SlateTable.SlateTableBodyRowCell>
                      <SlateTable.SlateTableBodyRowCell
                        style={{
                          paddingLeft: `${location.depth * 20}px`,
                        }}>
                        {locationType.name}
                      </SlateTable.SlateTableBodyRowCell>
                      <SlateTable.SlateTableBodyRowCell
                        style={{
                          paddingLeft: `${location.depth * 20}px`,
                        }}>
                        {location.name}
                      </SlateTable.SlateTableBodyRowCell>
                      <SlateTable.SlateTableBodyRowCell
                        style={{
                          paddingLeft: `${location.depth * 20}px`,
                        }}>
                        {location.code}
                      </SlateTable.SlateTableBodyRowCell>
                      <SlateTable.SlateTableBodyRowCell
                        style={{
                          paddingLeft: `${location.depth * 20}px`,
                        }}>
                        {`${location.capacity.amount} ${location.capacity.unit}`}
                      </SlateTable.SlateTableBodyRowCell>
                      <SlateTable.SlateTableBodyRowCell
                        style={{
                          paddingLeft: `${location.depth * 20}px`,
                        }}>
                        <StorageSectionColorBadge
                          status={
                            location.activationStatus
                              ? "AVAILABLE"
                              : "UNAVAILABLE"
                          }
                          reason={
                            location.activationStatus
                              ? ""
                              : location.inactiveReason?.reason || "unavailable"
                          }
                        />
                      </SlateTable.SlateTableBodyRowCell>
                      <SlateTable.SlateTableBodyRowCell
                        style={{
                          paddingLeft: `${location.depth * 20}px`,
                        }}>
                        <Menu as="div" className="">
                          <Menu.Button>
                            <DotsVerticalIcon className="h-4 w-4 cursor-pointer" />
                          </Menu.Button>
                          <>
                            <Menu.Items
                              as="div"
                              className="absolute right-8 z-10 w-max origin-top-right rounded border border-solid border-grayBorder bg-white p-2">
                              {hierarchyMenu
                                .filter((menuItem) =>
                                  menuItem.isShown(location),
                                )
                                .map((menu) => (
                                  <Menu.Item
                                    as="div"
                                    className={`cursor-pointer`}
                                    onClick={() => {
                                      menu.action(location);
                                    }}>
                                    {({ active }) => (
                                      <div
                                        className={`flex rounded transition ${
                                          active ? "bg-lightBlueMenu" : ""
                                        } h-9`}>
                                        <menu.icon
                                          className="mx-2 my-1.5 h-6 w-6 cursor-pointer"
                                          color={active ? "#224E73" : "#737579"}
                                        />
                                        <span
                                          className={`my-2 mr-2 text-sm ${
                                            active
                                              ? "text-primaryAccent"
                                              : "text-grayText"
                                          }`}>
                                          {menu.text}
                                        </span>
                                      </div>
                                    )}
                                  </Menu.Item>
                                ))}
                            </Menu.Items>
                          </>
                        </Menu>
                      </SlateTable.SlateTableBodyRowCell>
                    </SlateTable.SlateTableBodyRow>
                  );
                })}
              </SlateTable.SlateTableBody>
            )}
          </SlateTable.SlateTable>
        </div>
      </div>
      {selectedHierarchy && (
        <Modal
          title={
            selectedHierarchy.parent.code === "root"
              ? `${selectedHierarchy.edit ? "Edit" : "Add"} Hierarchy`
              : `${selectedHierarchy.edit ? "Edit" : "Add"} Sub Hierarchy`
          }
          onClose={() => {
            setSelectedHierarchy(null);
          }}
          minWidth={"945px"}
          maxWidth={"945px"}>
          <div className="flex">
            <MessageDisplay
              customStyles={"flex w-full py-4 px-2"}
              border={true}>
              <InformationCircleIcon className="h-8 w-8" />
              <div className="leading-17 ml-2 text-left text-sm font-normal tracking-normal text-lightGray">
                Effortlessly initiate your warehouse hierarchy on our SaaS
                platform. Start with the first level, define ranges, assign
                names and codes for efficient location barcoding, streamlining
                operations for warehouse administrators
              </div>
            </MessageDisplay>
          </div>
          <div className="mt-8 flex">
            <div className="mr-8">
              <div className="flex">
                <div className="mt-1 text-sm font-normal text-gray-500">
                  Select Type
                </div>
              </div>
              <DropdownInput
                name={"type"}
                onChange={onChangeHierarchy}
                value={selectedHierarchy["type"]}
                width={"w-60"}
                options={_.uniq(
                  locationTypes.map((locationType) => locationType.category),
                )
                  .map((category) => ({
                    name: category,
                  }))
                  .sort((a, b) => a?.name?.localeCompare(b.name))}
                valueKey={"name"}
                labelKey={"name"}
              />
            </div>
            <div className="mr-8">
              <div className="flex">
                <div className="mt-1 text-sm font-normal text-gray-500">
                  Select Sub Type
                </div>
              </div>
              <DropdownInput
                name={"subType"}
                onChange={onChangeHierarchy}
                value={selectedHierarchy["subType"]}
                width={"w-60"}
                options={locationTypes.filter(
                  (locationType) =>
                    locationType.category === selectedHierarchy.type,
                )}
                valueKey={"id"}
                labelKey={"name"}
              />
            </div>
            <div className="mr-8">
              <div className="flex">
                <div className="mt-1 text-sm font-normal text-gray-500">
                  Select Range
                </div>
              </div>
              <div className="flex">
                <TextInput
                  name={"rangeX"}
                  onChange={onChangeHierarchy}
                  value={selectedHierarchy["rangeX"]}
                  width={"w-20"}
                  placeholder={"x"}
                  styles={"mr-2"}
                  type={"number"}
                  min={0}
                  step={1}
                />
                {locationTypes.find(
                  (locationType) =>
                    locationType.id === selectedHierarchy.subType,
                )?.threeDimRange && (
                  <>
                    <TextInput
                      name={"rangeY"}
                      onChange={onChangeHierarchy}
                      value={selectedHierarchy["rangeY"]}
                      width={"w-20"}
                      placeholder={"y"}
                      styles={"mr-2"}
                      type={"number"}
                      min={0}
                      step={1}
                    />
                    <TextInput
                      name={"rangeZ"}
                      onChange={onChangeHierarchy}
                      value={selectedHierarchy["rangeZ"]}
                      width={"w-20"}
                      placeholder={"z"}
                      styles={"mr-2"}
                      type={"number"}
                      min={0}
                      step={1}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="mt-8 h-96 max-h-96 overflow-y-auto">
            <SlateTable.SlateTable>
              <SlateTable.SlateTableHeader sticky={true}>
                <SlateTable.SlateTableHeaderCell>
                  Name
                </SlateTable.SlateTableHeaderCell>
                <SlateTable.SlateTableHeaderCell>
                  Code
                </SlateTable.SlateTableHeaderCell>
                <SlateTable.SlateTableHeaderCell>
                  Capacity
                </SlateTable.SlateTableHeaderCell>
                <SlateTable.SlateTableHeaderCell>
                  Actions
                </SlateTable.SlateTableHeaderCell>
              </SlateTable.SlateTableHeader>
              {selectedHierarchy.locations.length > 0 ? (
                <SlateTable.SlateTableBody>
                  {selectedHierarchy.locations.map((location, index) => (
                    <SlateTable.SlateTableBodyRow>
                      <SlateTable.SlateTableBodyRowCell>
                        <TextInput
                          name={"name"}
                          onChange={(e) => {
                            onChangeHierarchyLocation(e, index);
                          }}
                          value={location["name"]}
                          width={"w-40"}
                        />
                      </SlateTable.SlateTableBodyRowCell>
                      <SlateTable.SlateTableBodyRowCell>
                        <TextInput
                          name={"code"}
                          onChange={(e) => {
                            onChangeHierarchyLocation(e, index);
                          }}
                          value={location["code"]}
                          width={"w-40"}
                        />
                      </SlateTable.SlateTableBodyRowCell>
                      <SlateTable.SlateTableBodyRowCell>
                        <div className="flex">
                          <TextInput
                            name={"capacity-amount"}
                            onChange={(e) => {
                              onChangeHierarchyLocation(e, index);
                            }}
                            value={location["capacity"]["amount"]}
                            width={"w-24"}
                            type={"number"}
                            min={0}
                            styles={"rounded-l rounded-r-none"}
                          />
                          <DropdownInput
                            name={"capacity-unit"}
                            onChange={(e) => {
                              onChangeHierarchyLocation(e, index);
                            }}
                            value={location["capacity"]["unit"]}
                            options={Object.values(
                              LocationCapacityUnitsEnum,
                            ).map((unit) => ({
                              value: unit,
                            }))}
                            labelKey={"value"}
                            valueKey={"value"}
                            width={"w-40"}
                            styles={"rounded-r rounded-l-none ml-0"}
                          />
                        </div>
                      </SlateTable.SlateTableBodyRowCell>
                      <SlateTable.SlateTableBodyRowCell>
                        <Menu as="div" className="">
                          <Menu.Button>
                            <DotsVerticalIcon className="h-4 w-4 cursor-pointer" />
                          </Menu.Button>
                          <>
                            <Menu.Items
                              as="div"
                              className="absolute right-8 z-10 w-max origin-top-right rounded border border-solid border-grayBorder bg-white p-2">
                              {hierarchyLocationMenu.map((menu) =>
                                menu.isShown(location) ? (
                                  <Menu.Item
                                    key={menu.text}
                                    as="div"
                                    className={`cursor-pointer`}
                                    onClick={() => {
                                      menu.action(location);
                                    }}>
                                    {({ active }) => (
                                      <div
                                        className={`flex rounded transition ${
                                          active ? "bg-lightBlueMenu" : ""
                                        } h-9`}>
                                        <menu.icon
                                          className="mx-2 my-1.5 h-6 w-6 cursor-pointer"
                                          color={active ? "#224E73" : "#737579"}
                                        />
                                        <span
                                          className={`my-2 mr-2 text-sm ${
                                            active
                                              ? "text-primaryAccent"
                                              : "text-grayText"
                                          }`}>
                                          {menu.text}
                                        </span>
                                      </div>
                                    )}
                                  </Menu.Item>
                                ) : null,
                              )}
                            </Menu.Items>
                          </>
                        </Menu>
                      </SlateTable.SlateTableBodyRowCell>
                    </SlateTable.SlateTableBodyRow>
                  ))}
                </SlateTable.SlateTableBody>
              ) : (
                <SlateTable.SlateTableBodyRow noBottomBorder={true}>
                  <SlateTable.SlateTableBodyRowCell
                    className="mt-8 w-full"
                    colspan={6}>
                    <div className="flex h-max w-full">
                      <div className="m-auto mt-16">
                        <img
                          src="https://hopstack-pub.s3.amazonaws.com/ContentNotFound.png"
                          className="mx-auto mb-2"
                        />
                        <div className="mx-auto mb-2 text-center text-sm font-normal leading-4 tracking-normal text-lightGray">
                          Complete the fields above to proceed
                        </div>
                      </div>
                    </div>
                  </SlateTable.SlateTableBodyRowCell>
                </SlateTable.SlateTableBodyRow>
              )}
            </SlateTable.SlateTable>
          </div>
          <div>
            {selectedHierarchy.parent.code !== "root" &&
              !selectedHierarchy.edit && (
                <CheckboxInput
                  text={
                    "Apply the same hierarchy structure to the other parent hierarchies in this Sorage Type"
                  }
                  name="applyToSiblings"
                  value={selectedHierarchy.applyToSiblings}
                  onChange={onChangeHierarchy}
                />
              )}
          </div>
          <div className="flex w-full">
            <HollowButton
              text={"Cancel"}
              colour={"primaryAccent"}
              onClick={() => {
                setSelectedHierarchy(null);
              }}
              styles="ml-auto"
            />
            <FilledButton
              text={"Save"}
              colour={"primaryAccent"}
              onClick={submitSaveHierarchy}
              className="ml-4"
            />
          </div>
        </Modal>
      )}
      {confirmSubmitCreateStorageSection && (
        <Modal
          title="Confirm"
          onClose={() => {
            setConfirmSubmitCreateStorageSection(false);
          }}
          minWidth={"945px"}
          maxWidth={"945px"}>
          <div className="text-left text-base font-normal leading-6 tracking-normal">
            Are you sure you want to{" "}
            {selectedStorageSection.edit ? "save" : "create new"} storage type “
            <span className="font-semibold">{selectedStorageSection.name}</span>
            ” ?
          </div>
          <div className="mt-2 flex">
            <MessageDisplay customStyles={"min-w-64 w-max h-max pb-4"}>
              <div className="leading-17 ml-4 mr-4 mt-4 text-left text-base font-medium tracking-normal text-gray-500">
                Location Hierarchy
              </div>
              <div className="leading-19 ml-4 mr-4 mt-1 text-left text-lg font-semibold tracking-normal">
                {getCurrentHierarchyArray().length
                  ? getCurrentHierarchyArray().join(" > ")
                  : "-"}
              </div>
              <div className="ml-4 mr-4 mt-1 text-left text-base font-normal leading-5 tracking-normal">
                Ex:{" "}
                {`${selectedStorageSection.code} > ${getCurrentHierarchyArray()
                  .map((locationType) => {
                    const type = locationTypes.find(
                      (type) => type.name === locationType,
                    );
                    let code = type.codeType === "NUMERIC" ? "1" : "A";
                    if (type.threeDimRange) {
                      code = "1.1.1";
                    }
                    return `${locationType}-${code}`;
                  })
                  .join(" > ")}`}
              </div>
            </MessageDisplay>
          </div>
          <div className="flex w-full">
            <HollowButton
              text={"Cancel"}
              colour={"primaryAccent"}
              onClick={() => {
                setConfirmSubmitCreateStorageSection(false);
              }}
              styles="ml-auto"
            />
            <FilledButton
              text={"Submit"}
              colour={"primaryAccent"}
              onClick={
                selectedStorageSection.edit
                  ? submitEditStorageSection
                  : submitCreateStorageSection
              }
              className="ml-4"
            />
          </div>
        </Modal>
      )}
      {showStorageStatusUpdate && (
        <Modal
          title={`Update Status`}
          onClose={() => {
            setShowStorageStatusUpdate(false);
            setStorageLocation(null);
          }}
          minWidth={"845px"}
          maxWidth={"845px"}>
          <div className="flex flex-col">
            <div className="mb-4">
              <div className="leading-22 text-left text-base font-medium tracking-normal text-[#717679]">
                If a location is marked as available, it will be used for
                workflows; if marked as unavailable, it will be excluded, and
                you will be able to add reason for unavailability
              </div>
            </div>
            <div className="mb-4 flex items-center">
              <input
                type="radio"
                id="available"
                name="status"
                value="available"
                checked={
                  locationStatusUpdates[storageLocation.backendId] ===
                  "available"
                }
                onChange={handleStatusChange}
              />
              <label htmlFor="available" className="ml-2 mr-4">
                <span className="inline-block rounded-2xl bg-green-100 p-2 text-green-800">
                  Available
                </span>
              </label>
              <input
                type="radio"
                id="unavailable"
                name="status"
                value="unavailable"
                checked={
                  locationStatusUpdates[storageLocation.backendId] ===
                  "unavailable"
                }
                onChange={handleStatusChange}
              />
              <label htmlFor="unavailable" className="ml-2">
                <span className="inline-block rounded-2xl bg-red-100 p-2 text-red-800">
                  Unavailable
                </span>
              </label>
            </div>
            {locationStatusUpdates[storageLocation.backendId] ===
              "available" && (
              <div
                className="mb-4 rounded border p-4"
                style={{ borderColor: "#E3A400", backgroundColor: "#FFF9E9" }}>
                <div className="mb-2 flex items-center font-medium">
                  <i className="fas fa-info-circle mr-2"></i> Points to note
                </div>
                <ul className="ml-4 list-disc">
                  <li>
                    All the sub hierarchies under this location will be made
                    available
                  </li>
                </ul>
              </div>
            )}
            {locationStatusUpdates[storageLocation.backendId] ===
              "unavailable" && (
              <div>
                <div
                  className="mb-4 rounded border p-4"
                  style={{
                    borderColor: "#E3A400",
                    backgroundColor: "#FFF9E9",
                  }}>
                  <div className="mb-2 flex items-center font-medium">
                    <i className="fas fa-info-circle mr-2"></i> Points to note
                  </div>
                  <ul className="ml-4 list-disc">
                    <li>
                      All the sub hierarchies coming under this location will be
                      made unavailable.
                    </li>
                    <li>
                      Changing any sub hierarchies location will have an impact
                      on the main hierarchy if switched from unavailable to
                      available.
                    </li>
                  </ul>
                </div>
                <div className="mb-4">
                  <div className="leading-22 mb-2 text-left text-base font-medium tracking-normal">
                    Reason
                  </div>
                  <textarea
                    className="w-full rounded border p-2"
                    placeholder="Enter reason for making this section unavailable..."
                    onChange={(e) => {
                      setInactiveReason(e.target.value);
                    }}
                    value={inactiveReason || ""}
                  />
                </div>
              </div>
            )}
            <div className="flex w-full">
              <HollowButton
                text={"Cancel"}
                colour={"primaryAccent"}
                onClick={() => {
                  setShowStorageStatusUpdate(false);
                  setStorageLocation(null);
                }}
                styles="ml-auto"
              />
              <FilledButton
                text={"Save"}
                colour={"primaryAccent"}
                onClick={() => {
                  updateLocationStatus(storageLocation);
                  setShowStorageStatusUpdate(false);
                }}
                className="ml-4"
              />
            </div>
          </div>
        </Modal>
      )}
      {showLocationDeletionConfirmation && (
        <Modal
          title="Confirm"
          onClose={() => {
            setShowLocationDeletionConfirmation(false);
            setLocationsToDelete(null);
          }}
          minWidth={"845px"}
          maxWidth={"845px"}>
          <div className="text-left text-base font-normal leading-6 tracking-normal">
            Are you sure you want to delete the selected hierarchy?
          </div>
          <div className="flex w-full">
            <HollowButton
              text={"Cancel"}
              colour={"primaryAccent"}
              onClick={() => {
                setShowLocationDeletionConfirmation(false);
                setLocationsToDelete(null);
              }}
              styles="ml-auto"
            />
            <FilledButton
              text={"Delete"}
              colour={"primaryAccent"}
              onClick={() => {
                handleLocationDeletion(locationsToDelete);
              }}
              className="ml-4"
            />
          </div>
        </Modal>
      )}
      {showReservationsMenu && (
        <LocationReservationMenu
          storageLocation={storageLocation}
          showReservationsMenu={showReservationsMenu}
          setShowReservationsMenu={setShowReservationsMenu}
          setStorageLocation={setStorageLocation}
          customers={customers}
          reserveLocation={reserveLocation}
          locationReservations={locationReservations}
        />
      )}
    </>
  );
};

const StorageSectionColorBadge = ({ status, reason }) => {
  let modifiedStatusToDisplay = status.replace(/_/g, " ");
  // Capitalize every word
  modifiedStatusToDisplay = modifiedStatusToDisplay
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  switch (status) {
    case "AVAILABLE":
      return <GreenBadge text={modifiedStatusToDisplay} />;
    case "UNAVAILABLE":
      return (
        <div className="flex items-center">
          <RedBadge text={modifiedStatusToDisplay} />
          <div data-tip={reason}>
            <InformationCircleIcon className="ml-2 h-5 w-5 text-red-800" />
          </div>
          <ReactTooltip place="top" type="dark" effect="solid" />
        </div>
      );
    default:
      return null;
  }
};

const GreenBadge = ({ text }) => {
  return (
    <div className="me-2 h-8 w-max rounded-full bg-green-100 px-2.5 py-0.5 pt-1.5 text-sm font-medium text-green-800">
      {text}
    </div>
  );
};

const RedBadge = ({ text }) => {
  return (
    <div className="me-2 h-8 w-max rounded-full bg-red-100 px-2.5 py-0.5 pt-1.5 text-sm font-medium text-red-800">
      {text}
    </div>
  );
};

export default CreateStorageSection;
