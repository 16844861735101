import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_BUNDLES,
  GET_INVENTORY,
  GET_BUNDLE,
  GET_MARKETPLACE_PRODUCTS,
  GET_BUNDLES_FIELDS,
} from "#queries";
import { SAVE_BUNDLE, DELETE_BUNDLE, BULK_UPLOAD_BUNDLES } from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";
import { AuthContext } from "#contexts/auth";
import { BulkValidationQueryGenerator } from "../../queries/bulkValidationQueryGenerator";

const withBundlesLogic = (WrappedComponent) => {
  return (props) => {
    const [availableInventory, setAvailableInventory] = useState([]);
    const [selectedBundle, setSelectedBundle] = useState(null);
    const entity = useContext(EntityContext);
    const auth = useContext(AuthContext);
    const [products, setProducts] = useState(null);
    const appState = useContext(AppStateContext);
    const bundlesQuery = useQuery(GET_BUNDLES);
    const getBundleQuery = useQuery(GET_BUNDLE);
    const saveBundleQuery = useQuery(SAVE_BUNDLE);
    const inventoryQuery = useQuery(GET_INVENTORY);
    const deleteBundleQuery = useQuery(DELETE_BUNDLE);
    const getMarketplaceProductsQuery = useQuery(GET_MARKETPLACE_PRODUCTS);
    const [showFilters, setShowFilters] = useState(false);
    const getBundlesFields = useQuery(GET_BUNDLES_FIELDS);
    const uploadBulk = useQuery(BULK_UPLOAD_BUNDLES);
    const [dashboardFields, setDashboardFields] = useState(null);
    const [finalError, setFinalError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const bulkUploadValidation = useQuery(
      BulkValidationQueryGenerator({ keyword: "Bundles" }),
    );
    const [validationResult, setValidationResult] = useState(null);

    useEffect(() => {
      if (getBundlesFields.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }
      if (getBundlesFields.data) {
        setDashboardFields(getBundlesFields.data.bundleFields);
      }
    }, [
      getBundlesFields.loading,
      getBundlesFields.data,
      getBundlesFields.error,
    ]);

    useEffect(() => {
      if (uploadBulk.data) {
        bundlesQuery.fetchData({
          perPage: entity.perPage,
          filters: {},
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
        setSuccessMessage(uploadBulk.data.bulkUploadBundles.message);
      }
      if (uploadBulk.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (uploadBulk.error) {
        appState.removeLoading();
        setFinalError(uploadBulk.error.message);
      }
    }, [uploadBulk.loading, uploadBulk.data, uploadBulk.error]);
    useEffect(() => {
      if (bulkUploadValidation.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }

      if (bulkUploadValidation.data) {
        setValidationResult(
          bulkUploadValidation.data.validateBulkUploadBundles
            .inputValidationErrors,
        );
      }
    }, [
      bulkUploadValidation.loading,
      bulkUploadValidation.data,
      bulkUploadValidation.error,
    ]);

    useEffect(() => {
      if (getMarketplaceProductsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getMarketplaceProductsQuery.data) {
        appState.setAlert(
          getMarketplaceProductsQuery.data.marketplaceProducts.message,
          "success",
          5000,
        );
      }

      if (getMarketplaceProductsQuery.error) {
        appState.setAlert(
          getMarketplaceProductsQuery.error.message,
          "error",
          5000,
        );
      }
    }, [
      getMarketplaceProductsQuery.data,
      getMarketplaceProductsQuery.error,
      getMarketplaceProductsQuery.loading,
    ]);

    useEffect(() => {
      if (inventoryQuery.loading) {
      } else {
        appState.removeLoading();
      }
      if (inventoryQuery.data) {
        setAvailableInventory(inventoryQuery.data.inventory.entities);
      }

      if (inventoryQuery.error) {
        setAvailableInventory([]);
      }
    }, [inventoryQuery.loading, inventoryQuery.error, inventoryQuery.data]);

    useEffect(() => {
      if (
        auth &&
        auth.user &&
        auth.user.warehousesList &&
        auth.user.customersList
      ) {
        const warehouses = auth.user.warehousesList;
        const customers = auth.user.customersList;
        if (warehouses.length === 0 || customers.length === 0) {
          return appState.setAlert(
            `You don't have necessary permission to execute this action.`,
            "error",
            5000,
          );
        }

        loadFirstTimeData();
        getBundlesFields.fetchData();
      }
    }, []);

    useEffect(() => {
      return () => {
        entity.setFilters({});
        entity.resetEntities();
      };
    }, []);

    const loadFirstTimeData = () => {
      const filtersSet = {
        customers: auth.user.customersList.map((item) => item.id),
        warehouses: auth.user.warehousesList.map((item) => item.id),
      };
      entity.setFilters(filtersSet);
      bundlesQuery.fetchData({
        perPage: entity.perPage,
        filters: filtersSet,
        paginated: false,
        pageNumber: 1,
        sort: entity.sort,
      });
    };

    useEffect(() => {
      if (saveBundleQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveBundleQuery.error) {
        appState.setAlert(saveBundleQuery.error.message, "error", 5000);
      }

      if (saveBundleQuery.data) {
        appState.setAlert(saveBundleQuery.data.saveBundle.message);
        setSelectedBundle(null);
        setAvailableInventory([]);
        const filtersSet = {
          customers: auth.user.customersList.map((item) => item.id),
          warehouses: auth.user.warehousesList.map((item) => item.id),
        };
        bundlesQuery.fetchData({
          perPage: entity.perPage,
          filters: filtersSet,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
      }
    }, [saveBundleQuery.data, saveBundleQuery.loading, saveBundleQuery.error]);

    useEffect(() => {
      if (bundlesQuery.data && bundlesQuery.data.bundles) {
        entity.setEntities({
          ...bundlesQuery.data.bundles,
          ...bundlesQuery.variables,
        });
      }
    }, [bundlesQuery.loading, bundlesQuery.error, bundlesQuery.data]);

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return bundlesQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    const addNewBundle = () => {
      const warehouses = auth.user.warehousesList;
      const customers = auth.user.customersList;
      if (warehouses.length === 0 || customers.length === 0) {
        return appState.setAlert(
          `You don't have necessary permission to execute this action.`,
          "error",
          5000,
        );
      }
      let selectedWarehouse, selectedCustomer;
      let warehouseToBeSelected = true;
      let customerToBeSelected = true;
      if (warehouses.length === 1) {
        selectedWarehouse = warehouses[0].id;
        warehouseToBeSelected = false;
      }
      if (customers.length === 1) {
        selectedCustomer = customers[0].id;
        customerToBeSelected = false;
        inventoryQuery.fetchData({
          perPage: 10,
          filters: {
            customer: [customers[0].id],
          },
          paginated: false,
          pageNumber: 1,
          sort: "asin",
        });
      }
      setSelectedBundle({
        warehouse: selectedWarehouse,
        customer: selectedCustomer,
        warehouseToBeSelected,
        customerToBeSelected,
      });
    };

    const addProduct = (addedProduct) => {
      let currentSelectedBundle = selectedBundle;
      if (
        !currentSelectedBundle.products ||
        currentSelectedBundle.products.length === 0
      ) {
        currentSelectedBundle.products = [];
      }

      if (addedProduct.idx >= 0) {
        currentSelectedBundle.products[addedProduct.idx] = {
          ...addedProduct,
          idx: null,
          editing: false,
        };
      } else {
        currentSelectedBundle.products.push({ ...addedProduct });
      }

      setSelectedBundle({ ...currentSelectedBundle });
    };

    const removeProduct = (idx) => {
      let currentSelectedBundle = selectedBundle;
      if (
        !currentSelectedBundle.products ||
        currentSelectedBundle.products.length === 0
      ) {
        return;
      }

      currentSelectedBundle.products = currentSelectedBundle.products.filter(
        (product, index) => index !== idx,
      );
      setSelectedBundle({ ...currentSelectedBundle });
    };

    const onChangeProduct = (idx, e) => {
      let currentSelectedBundle = selectedBundle;
      const product = currentSelectedBundle.products[idx];
      product[e.target.name] = e.target.value;
      currentSelectedBundle.products[idx] = product;
      setSelectedBundle({ ...currentSelectedBundle });
    };

    const onChange = (e) => {
      const bundle = {
        ...selectedBundle,
      };

      if (e.target.type === "number") {
        bundle[e.target.name] = parseInt(e.target.value);
      } else if (e.target.name === "trackingNumber") {
        bundle[e.target.name] = e.target.value.split(",");
      } else {
        bundle[e.target.name] = e.target.value;
      }

      setSelectedBundle(bundle);
    };

    const onChangeMultiSelect = (field, value) => {
      const bundle = {
        ...selectedBundle,
      };
      bundle[field] = value;
      setSelectedBundle(bundle);
    };

    const onChangeDropdown = (field, value) => {
      const bundle = {
        ...selectedBundle,
      };

      if (field === "customer") {
        if (value !== bundle[field]) {
          inventoryQuery.fetchData({
            perPage: 10,
            filters: {
              customer: [value],
            },
            paginated: false,
            pageNumber: 1,
            sort: "asin",
          });
        }
      }

      bundle[field] = value;
      setSelectedBundle({ ...bundle });
    };

    useEffect(() => {
      if (getBundleQuery.data) {
        setSelectedBundle(getBundleQuery.data.bundle);
      }
      if (getBundleQuery.error) {
        setSelectedBundle(null);
      }
    }, [getBundleQuery.loading, getBundleQuery.data, getBundleQuery.error]);

    useEffect(() => {
      if (deleteBundleQuery.data) {
        appState.hideConfirmation();
        appState.setAlert(
          deleteBundleQuery.data.deleteBundle.message,
          "success",
          5000,
        );
        bundlesQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
        });
        setSelectedBundle(null);
      }
      if (deleteBundleQuery.error) {
        appState.setAlert(deleteBundleQuery.error.message, "error", 5000);
      }
    }, [
      deleteBundleQuery.loading,
      deleteBundleQuery.data,
      deleteBundleQuery.error,
    ]);

    const deleteButtonClicked = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this bundle?",
        () => {
          deleteBundleQuery.fetchData({ id });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    const onChangeInventorySearch = (keyword) => {
      inventoryQuery.fetchData({
        perPage: 10,
        pageNumber: 1,
        filters: {
          keyword,
          customer: [selectedBundle.customer],
          warehouse: [selectedBundle.warehouse],
        },
        paginated: false,
      });
    };

    const debouncedInventorySearch = _.debounce(onChangeInventorySearch, 1000);

    return (
      <WrappedComponent
        fetchBundle={(id) => getBundleQuery.fetchData({ id })}
        bundles={entity.entities}
        displayBundles={entity.displayEntities}
        total={entity.total}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        //
        dashboardFields={dashboardFields}
        saveBulkUpload={(rows) => {
          uploadBulk.fetchData({ rows });
        }}
        errorMessage={finalError}
        successMessage={successMessage}
        //
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          bundlesQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        submitFilters={() => {
          setShowFilters(false);
          bundlesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          bundlesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        onChangeFilter={(field, value, autoSubmit = false) => {
          entity.setFilters({
            ...entity.filters,
            [field]: value,
          });
          if (autoSubmit) {
            bundlesQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,
                [field]: value,
              },
              sort: entity.sort,
            });
          }
        }}
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          bundlesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={loadFirstTimeData}
        setProducts={setProducts}
        products={products}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        addNewBundle={addNewBundle}
        selectedBundle={selectedBundle}
        setSelectedBundle={setSelectedBundle}
        addProduct={addProduct}
        removeProduct={removeProduct}
        onChangeProduct={onChangeProduct}
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        availableInventory={availableInventory}
        saveBundle={() =>
          saveBundleQuery.fetchData({
            ...selectedBundle,
            products: selectedBundle.products.map((p) => {
              return {
                id: p.productId,
                quantity: p.formFactors[0].quantity,
              };
            }),
          })
        }
        onChangeInventorySearch={debouncedInventorySearch}
        deleteButtonClicked={deleteButtonClicked}
        getMarketplaceProducts={() =>
          getMarketplaceProductsQuery.fetchData({
            customer: selectedBundle.customer,
          })
        }
        writable={props.writable}
        validate={(rows) => {
          bulkUploadValidation.fetchData({ rows });
        }}
        validationResult={validationResult}
      />
    );
  };
};

export default withBundlesLogic;
