const RevampedSearch = ({
  activeTab,
  setActiveTab,
  searchTabs,
  searchResults,
}) => {
  return (
    <div className="overflow-y-hidden">
      <div className="flex gap-8 border-b border-gray-300">
        {Object.entries(searchTabs).map((val) => (
          <span
            key={val[0]}
            className={`${val[0] === activeTab ? "border-b-4 border-primaryAccent pb-3 font-semibold text-primaryAccent" : "text-gray-600"}`}
            role="button"
            onClick={() => setActiveTab(val[0])}>
            {val[1]}
          </span>
        ))}
      </div>

      <div className="flex overflow-y-scroll">{searchResults}</div>
    </div>
  );
};

export default RevampedSearch;
