export const pluralize = (count, noun, suffix = "s") => {
  if (count === 1) return `${count} ${noun}`;
  return `${count} ${noun}${suffix}`;
};

export const handlePositiveValue = (value, type = "int") => {
  if (isNaN(value) || parseFloat(value) < 0) {
    return 0;
  }

  return type === "int" ? parseInt(value) : parseFloat(value);
};

export const getBulkUploadTemplateURL = (fileName, subDomain) => {
  return `https://templates-onboarding.s3.amazonaws.com/${subDomain}/${fileName}.csv`;
};
