import moment from "moment-timezone";
import {
  PencilIcon,
  TrashIcon,
  AdjustmentsIcon,
  DotsVerticalIcon,
  ExclamationIcon,
  ArrowRightIcon,
} from "@heroicons/react/outline";
import SearchSortFilter from "#components/common/SearchSortFilter";
import _ from "lodash";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import {
  getHeaderObject,
  getSortableColumns,
  getUpdatedHeaders,
} from "../../utils/getHeaderObject";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/auth";
import cellStyleForTable from "../common/CellStyleForTable";
import QuickFilters from "../common/QuickFilters";
import { Menu } from "@headlessui/react";
import Breakdown from "#components/utils/icons/Breakdown";
import ReactTooltip from "react-tooltip";
import { expandStorageEntity as expandStorageEntityQuery } from "../inventory/InventoryExplanation";
import Modal from "#components/utils/Modal";
import { SkuBinMappingsTable } from "#components/skuBinMappings/BinTransferEligibilityModal";
import { EXPAND_STORAGE_ENTITY } from "#queries";
import { AppStateContext } from "#contexts/appState";
import { useQuery } from "#hooks/useQuery";

const SKU_BIN_MAPPING_TABLE_NAME = "skuBinMappingTable";
const ALERT_TIMEOUT_IN_MS = 5000;

const SkuBinMappingsList = ({
  skuBinMappings,
  editButtonClicked,
  deleteButtonClicked,
  noValuesText,
  onChangeSearchKeyword,
  getSpecificInventory,
  getUomBreakdown,
  filters,
  submitFilters,
  clearKeyword,
  setSort,
  sort,
  setShowFilters,
  clearFilters,
  getSku,
  customers,
  warehouses,
  writable,
  onChangeFilter,
  multiAccountSupportEnabled,
  checkTransferSkuBinMapping,
  tenant,
}) => {
  const appState = useContext(AppStateContext);
  const query = useQuery(EXPAND_STORAGE_ENTITY);
  const [expandedStorageEntity, setExpandedStorageEntity] = useState(null);

  const expandStorageEntity = async (item) => {
    const response = await expandStorageEntityQuery(item, query);

    if (response.error) {
      appState.setAlert(response.message, "error", ALERT_TIMEOUT_IN_MS);
    } else {
      setExpandedStorageEntity({
        ...response.data,
        requestedEntityDetails: item,
      });
    }
  };
  const initialHeaders = () => {
    let returnHeaders = [getHeaderObject("SKU", "sku")];
    if (multiAccountSupportEnabled) {
      returnHeaders.push(getHeaderObject("Marketplace", "marketplace"));
      returnHeaders.push(getHeaderObject("Seller", "seller"));
    }
    returnHeaders = returnHeaders.concat([
      getHeaderObject("UoM", "FormFactor", true, "formFactor"),
      getHeaderObject("Pallet ID", "PalletId", true, "palletId"),
      getHeaderObject("Lot ID/ Batch ID", "lotId"),
      getHeaderObject("Serial Number", "serialNumber"),
      getHeaderObject("Tote", "tote"),
      getHeaderObject("Bin Location", "binLocation"),
      getHeaderObject("Allocated Quantity", "allocatedQuantity"),
      getHeaderObject("Unallocated Quantity", "unallocatedQuantity"),
      getHeaderObject("Created At", "createdAt"),
      getHeaderObject("Expiry Date", "expiryDate", true, "bestByDate"),
      getHeaderObject("LPN Form Factor", "nestedFormFactor"),
      getHeaderObject("LPN", "nestedFormFactorId"),
    ]);

    if (customers?.length > 1) {
      returnHeaders.push(getHeaderObject("Client", "client"));
    }

    if (warehouses?.length > 1) {
      returnHeaders.push(getHeaderObject("Warehouse", "warehouse"));
    }

    return returnHeaders;
  };
  const [headers, setHeaders] = useState(initialHeaders());

  const [showHeaders, setShowHeaders] = useState(headers);
  const [choices, setChoices] = useState(getSortableColumns(headers));
  const auth = useContext(AuthContext);

  useEffect(() => {
    const updatedHeaders = getUpdatedHeaders(
      auth,
      headers,
      SKU_BIN_MAPPING_TABLE_NAME,
    );
    if (updatedHeaders) {
      setHeaders(updatedHeaders);
      setShowHeaders(updatedHeaders.filter((header) => header.enabled));
      setChoices(getSortableColumns(updatedHeaders));
    }
  }, [auth.user]);

  useEffect(() => {
    setChoices(getSortableColumns(showHeaders));
  }, [showHeaders]);

  const menuItems = [
    {
      title: "Edit",
      icon: <PencilIcon className="h-6 w-6" />,
      onClick: (skuBinMapping) => editButtonClicked(skuBinMapping),
      disabled: !writable,
      visible: true,
    },
    {
      title: "Adjust",
      icon: <AdjustmentsIcon className="h-6 w-6" />,
      onClick: (skuBinMapping) => getSpecificInventory(skuBinMapping.id),
      disabled: !writable,
      visible: true,
    },
    {
      title: "UOM Breakdown",
      icon: <Breakdown className="h-6 w-6" />,
      onClick: (skuBinMapping) => getUomBreakdown(skuBinMapping.id),
      disabled: !writable,
      visible: auth.user?.role?.toLowerCase() === "admin",
    },
    {
      title: "Delete",
      icon: <TrashIcon className="h-6 w-6" />,
      onClick: (skuBinMapping) => deleteButtonClicked(skuBinMapping.id),
      disabled: !writable,
      visible: true,
    },
  ];

  if (tenant?.settings?.moveSkuBinMappingEnabled || true) {
    menuItems.unshift({
      title: "Transfer",
      icon: <ArrowRightIcon className="h-6 w-6" />,
      onClick: (skuBinMapping) => checkTransferSkuBinMapping(skuBinMapping.id),
      disabled: !writable,
      visible: true,
    });
  }

  return (
    <>
      <div className="mt-4 rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20">
        {(customers.length > 1 || warehouses.length > 1) && (
          <QuickFilters
            warehouseFilterName={"warehouse"}
            customerFilterName={"customer"}
            customers={customers}
            warehouses={warehouses}
            filters={filters}
            onChangeFilter={onChangeFilter}
          />
        )}
        <SearchSortFilter
          onChangeSearchKeyword={onChangeSearchKeyword}
          filters={filters}
          submitFilters={submitFilters}
          clearKeyword={clearKeyword}
          setSort={setSort}
          sort={sort}
          setShowFilters={setShowFilters}
          clearFilters={clearFilters}
          headers={headers}
          setShowHeaders={setShowHeaders}
          tableName={SKU_BIN_MAPPING_TABLE_NAME}
          choices={choices}
        />
        <CustomTableWithTheme>
          <thead className="sticky left-0 top-0 bg-primaryAccent p-4">
            <tr className="border-left font-montserrat text-textWhite">
              {showHeaders.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                    key={headerIdx}>
                    {header.name}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                    key={headerIdx}>
                    {header.name}
                  </th>
                ),
              )}
              {writable && (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={showHeaders.length}>
                  Action
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {skuBinMappings.length === 0 ? (
              <tr className="bg-white">
                {showHeaders.map((_, headerIdx) =>
                  headerIdx === 0 ? (
                    <td
                      className="tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent"
                      key={headerIdx}>
                      {noValuesText}
                    </td>
                  ) : (
                    <td
                      className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                      key={headerIdx}></td>
                  ),
                )}
              </tr>
            ) : null}
            {skuBinMappings.map((skuBinMapping, rowIndex) => (
              <tr
                key={skuBinMapping.id}
                className={`${rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                {showHeaders.map((header, columnIndex) => {
                  let value = header.correspondingValue;
                  let cellStyle = cellStyleForTable(
                    value,
                    ["sku"],
                    columnIndex,
                    rowIndex,
                  );
                  if (value == "sku") {
                    return (
                      <td
                        className={cellStyle}
                        onClick={() => getSku(skuBinMapping.product)}>
                        {skuBinMapping.sku}
                      </td>
                    );
                  }
                  if (value == "marketplace") {
                    return (
                      <td className={cellStyle}>{skuBinMapping.marketplace}</td>
                    );
                  }
                  if (value == "seller") {
                    return (
                      <td className={cellStyle}>{skuBinMapping.sellerId}</td>
                    );
                  }

                  if (value === "PalletId") {
                    return (
                      <td
                        className={cellStyle}
                        onClick={() =>
                          skuBinMapping.palletId &&
                          expandStorageEntity({
                            code: skuBinMapping.palletId,
                            codeType: "palletId",
                            warehouseId: skuBinMapping.warehouse,
                          })
                        }>
                        <div className="cursor-pointer font-bold text-primaryAccent underline">
                          {skuBinMapping.palletId}
                        </div>
                      </td>
                    );
                  }

                  if (value === "FormFactor") {
                    return (
                      <td className={cellStyle}>{skuBinMapping.formFactor}</td>
                    );
                  }
                  if (value === "lotId") {
                    return (
                      <td
                        className={cellStyle}
                        onClick={() =>
                          skuBinMapping.lotId &&
                          expandStorageEntity({
                            code: skuBinMapping.lotId,
                            codeType: "lotId",
                            warehouseId: skuBinMapping.warehouse,
                          })
                        }>
                        <div className="cursor-pointer font-bold text-primaryAccent underline">
                          {skuBinMapping.lotId}
                        </div>
                      </td>
                    );
                  }
                  if (value == "serialNumber") {
                    return (
                      <td className={cellStyle}>
                        {skuBinMapping.serialNumber}
                      </td>
                    );
                  }
                  if (value === "tote") {
                    return (
                      <td
                        className={cellStyle}
                        onClick={() =>
                          skuBinMapping.tote &&
                          expandStorageEntity({
                            code: skuBinMapping.tote,
                            codeType: "tote",
                            warehouseId: skuBinMapping.warehouse,
                          })
                        }>
                        <div className="cursor-pointer font-bold text-primaryAccent underline">
                          {skuBinMapping.tote}
                        </div>
                      </td>
                    );
                  }
                  if (value == "binLocation") {
                    return (
                      <td className={cellStyle}>
                        <div className="flex items-center gap-x-2">
                          {skuBinMapping.canUse === false && (
                            <>
                              <ExclamationIcon
                                data-tip="Location unavailable"
                                className="h-5 w-5 text-red-500"
                              />
                              <ReactTooltip
                                place="top"
                                type="dark"
                                effect="solid"
                              />
                            </>
                          )}
                          <div>{skuBinMapping.binLocation}</div>
                        </div>
                      </td>
                    );
                  }
                  if (value == "allocatedQuantity") {
                    return (
                      <td className={cellStyle}>
                        {skuBinMapping.allocatedQuantity}
                      </td>
                    );
                  }
                  if (value == "unallocatedQuantity") {
                    return (
                      <td className={cellStyle}>
                        {skuBinMapping.unallocatedQuantity}
                      </td>
                    );
                  }
                  if (value == "createdAt") {
                    return (
                      <td className={cellStyle}>
                        {moment(skuBinMapping.createdAt).format(
                          "YYYY-MM-DD hh:mm A",
                        )}
                      </td>
                    );
                  }
                  if (value == "expiryDate") {
                    return (
                      <td className={cellStyle}>
                        {skuBinMapping.bestByDate &&
                          moment(skuBinMapping.bestByDate).format("YYYY-MM-DD")}
                      </td>
                    );
                  }
                  if (value === "nestedFormFactor") {
                    return (
                      <td className={cellStyle}>
                        {skuBinMapping.nestedFormFactor}
                      </td>
                    );
                  }
                  if (value === "nestedFormFactorId") {
                    return (
                      <td className={cellStyle}>
                        <div
                          className="cursor-pointer font-bold text-primaryAccent underline"
                          onClick={() =>
                            skuBinMapping.nestedFormFactorId &&
                            expandStorageEntity({
                              code: skuBinMapping.nestedFormFactorId,
                              warehouseId: skuBinMapping.warehouse,
                              codeType: "nestedFormFactorId",
                            })
                          }>
                          {skuBinMapping.nestedFormFactorId}
                        </div>
                      </td>
                    );
                  }
                  if (value == "client") {
                    return (
                      <td className={cellStyle}>
                        {customers &&
                          customers.find(
                            (item) => item.id === skuBinMapping.customer,
                          )?.name}
                      </td>
                    );
                  }
                  if (value === "warehouse") {
                    return (
                      <td className={cellStyle}>
                        {warehouses &&
                          warehouses.find(
                            (item) => item.id === skuBinMapping.warehouse,
                          )?.name}
                      </td>
                    );
                  }
                })}
                {writable && (
                  <td
                    className={cellStyleForTable(
                      "action",
                      ["sku"],
                      showHeaders.length,
                      rowIndex,
                    )}>
                    <div className="flex items-center space-x-4">
                      <Menu as="div" className="text-left">
                        <Menu.Button>
                          <DotsVerticalIcon className="text-md h-8 w-8 rounded bg-E1D3B8 p-2 font-montserrat text-5F666B" />
                        </Menu.Button>
                        <Menu.Items
                          className={`absolute right-0 z-10 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-primaryAccent shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                          <div className="max-h-96 overflow-y-scroll">
                            {menuItems.map((menuItem) => {
                              if (!menuItem.visible) return;

                              return (
                                <Menu.Item key={menuItem.title}>
                                  {() => (
                                    <button
                                      className={`relative flex w-full cursor-pointer select-none items-center gap-2 border-b border-50BFC3 py-4 pl-4 pr-4 text-white hover:bg-EBEBEB hover:text-2C7695`}
                                      onClick={() =>
                                        menuItem.onClick(skuBinMapping)
                                      }
                                      disabled={menuItem.disabled}>
                                      {menuItem.icon} {menuItem.title}
                                    </button>
                                  )}
                                </Menu.Item>
                              );
                            })}
                          </div>
                        </Menu.Items>
                      </Menu>
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </CustomTableWithTheme>
        {expandedStorageEntity && (
          <Modal
            negativeAction={() => setExpandedStorageEntity(null)}
            negativeText="< Back"
            title="LPN Details">
            <SkuBinMappingsTable tableData={expandedStorageEntity} />
          </Modal>
        )}
      </div>
    </>
  );
};

export default SkuBinMappingsList;
