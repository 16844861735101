import { useState } from "react";
import PageTitle from "#components/utils/PageTitle";
import moment from "moment-timezone";
import ListView from "#components/prepper/ListView";
import TextField from "#components/utils/TextField";
import _ from "lodash";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import WorkflowProductImages from "#components/products/WorkflowProductImages";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import BundlePrepDetails from "#components/prepper/BundlePrepDetails";
import NumberField from "#components/utils/NumberField";

const DetailedView = ({
  currentBatch,
  confirmItem,
  currentItemMismatch,
  setCurrentItemMismatch,
  confirmPrepItem,
  simulateItemScan,
  workflow,
  printAllFnSkus,
  changeQuantity,
  subdomain,
  cancelItem,
  scanBarcode,
  restartActivity,
  pauseActivity,
  updateBestByDate,
  currentProduct,
  onChangeMultiSelect,
  tenant,
}) => {
  const [manualValue, setManualValue] = useState(null);
  const prepOptions = [
    { name: "FNSKU" },
    { name: "Poly bag" },
    { name: "Bubble wrap" },
    { name: "Bundle" },
    { name: "Bubble & bundle" },
    { name: "Handling" },
    { name: "S+L" },
  ];

  if (!currentBatch.currentItem) {
    return (
      <>
        <div className="mt-20 flex w-full max-w-4xl items-center justify-center">
          <div className="flex-col">
            <div className="rounded-2xl border bg-white shadow-lg">
              <div className="flex items-center justify-center pb-5 pt-5">
                <div className="border-r border-gray-400 p-5">
                  <div className="rounded-full bg-primaryAccent p-6 shadow-md">
                    <img
                      src="https://hopstack-pub.s3.amazonaws.com/icons/barcode_scan.png"
                      className="w-14"
                    />
                  </div>
                </div>
                <div className="flex-col">
                  <div className="px-5 py-5 pb-1 text-xl font-medium text-454A4F">
                    Please Scan the Next Item
                  </div>
                  <div className="block px-5 py-0 text-xl font-medium text-454A4F">
                    Completion: {currentBatch.completion}%
                  </div>
                </div>
              </div>
              <div className="items-center text-center font-montserrat text-2xl">
                OR ENTER MANUALLY
              </div>
              <div className="flex-col items-center justify-center p-10 pb-5 pt-5">
                <div>
                  <TextField
                    type="text"
                    id="name"
                    label="Enter Value"
                    placeholder=" "
                    onChange={(e) => setManualValue(e.target.value)}
                    value={manualValue}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        scanBarcode(manualValue);
                        setManualValue("");
                      }
                    }}
                    autoFocus={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 flex items-center justify-center space-x-4">
          <div
            className="w-64 cursor-pointer rounded-md bg-red-600 py-4 text-center text-2xl text-white"
            onClick={restartActivity}>
            Restart Prepping
          </div>
          <div
            className="w-64 cursor-pointer rounded-md bg-red-600 py-4 text-center text-2xl text-white"
            onClick={pauseActivity}>
            Pause Prepping
          </div>
        </div>
      </>
    );
  }

  const { currentItem } = currentBatch;
  return (
    <div className="mx-auto max-w-7xl flex-col items-center justify-center">
      <div className="pt-8">
        <PageTitle>Product Details</PageTitle>
        <div className="grid grid-cols-3">
          <div
            className={`w-full flex-1 bg-gray-100 ${
              currentProduct?.images?.length > 0 ? "col-span-2" : "col-span-3"
            }`}>
            <div className="space-y-2 text-xl font-medium text-454A4F">
              <LineItemDesc title={"SKU"} value={currentItem.sku} idx={1} />
              {currentItem.productAlias && (
                <LineItemDesc
                  title={"Customer ID"}
                  value={currentItem.productAlias}
                  idx={1}
                />
              )}

              <LineItemDesc
                title={"Product"}
                value={truncate(currentItem.productName)}
                idx={2}
              />
              {currentItem.upc && (
                <LineItemDesc
                  value={currentItem?.upc?.join(", ")}
                  title="UPC"
                  idx={2}
                />
              )}

              <LineItemDesc
                title={"Total Quantity"}
                value={
                  currentBatch.workingList &&
                  _.sumBy(
                    currentBatch.workingList.filter(
                      (item) =>
                        item.sku.toString() === currentItem.sku.toString(),
                    ),
                    "quantity",
                  )
                }
                idx={2}
              />
              <LineItemDesc
                title={"Remaining Quantity"}
                value={
                  currentBatch.workingList &&
                  _.sumBy(
                    currentBatch.workingList.filter(
                      (item) =>
                        item.sku.toString() === currentItem.sku.toString() &&
                        item.status === "UNPROCESSED",
                    ),
                    "quantity",
                  )
                }
                idx={2}
              />
              {currentItem.fnSku && (
                <>
                  <LineItemDesc
                    title={"FN SKU"}
                    value={currentItem.fnSku}
                    idx={1}
                  />
                </>
              )}

              {currentItem.asin && (
                <LineItemDesc title={"ASIN"} value={currentItem.asin} idx={1} />
              )}

              {currentItem.prepInstructions &&
                currentItem.prepInstructions.length > 0 && (
                  <div className="flex w-full space-x-1 shadow-md">
                    <div
                      className={`border-F4C261" } flex w-64 items-end justify-end rounded-bl-md rounded-tl-md border-l-8 bg-white p-4 text-2C7695`}>
                      Amazon Prep Instructions
                    </div>
                    <div
                      className={`flex-1 space-y-2 rounded-br-md rounded-tr-md border bg-white p-4`}>
                      {currentItem.prepInstructions.map((item, idx) => (
                        <div key={idx} className="flex">
                          Instruction: {item.PrepInstruction} | Owner:{" "}
                          {item.PrepOwner}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
            </div>
          </div>
          {currentProduct?.images && (
            <div className="flex flex-1 items-center justify-center bg-gray-300">
              <WorkflowProductImages
                images={[
                  currentProduct.images.find(
                    (i) => i.purpose === "OPERATIONS",
                  ) || currentProduct.images[0],
                ].filter(Boolean)}
              />
            </div>
          )}
        </div>
      </div>
      {currentItem.validScans && currentItem.validScans.length > 1 && (
        <BundlePrepDetails
          currentItem={currentItem}
          currentBatch={currentBatch}
          simulateItemScan={simulateItemScan}
        />
      )}

      {tenant?.settings?.activities?.prepping?.isAdditionalPrepInstructions && (
        <div className="mt-10 flex items-center justify-center text-center align-top">
          <div className="pr-2 text-xl">Additional Prep Instructions</div>
          <MultiSelectAutoComplete
            options={prepOptions}
            labelKey={"name"}
            valueKey={"name"}
            setValues={(values) => {
              onChangeMultiSelect("additionalPrepInstructions", values);
            }}
            values={currentItem?.additionalPrepInstructions || []}
          />
        </div>
      )}

      <div className="mt-10 flex items-center justify-center text-center">
        <div className="pr-2 text-xl">Quantity</div>
        <NumberField
          onChange={(e) => changeQuantity(parseInt(e.target.value))}
          placeholder="Quantity"
          value={currentItem.quantity}
          className="p-4 text-2xl"
          min="0"
        />
      </div>
      <div className="mt-10 flex items-center justify-center text-center">
        <div className="pr-2 text-xl">Expiry Date</div>
        <input
          type="date"
          onChange={(e) => updateBestByDate(e.target.value)}
          placeholder="Select Expiry date"
          value={currentItem.bestByDate}
          className="rounded-md p-4 text-lg"
          min={moment().format("YYYY-MM-DD")}
        />
      </div>
      {currentItem.validScans &&
        currentItem.validScans.findIndex(
          (item) => item.scannedItem === false,
        ) === -1 &&
        currentItemMismatch === null && (
          <div className="mt-20 flex items-center justify-center space-x-4">
            <div className="w-64 py-4 text-center text-2xl text-black">
              Have you prepped this item?
            </div>
            <div
              className="w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
              onClick={() => setCurrentItemMismatch("CONFIRMED")}>
              Yes
            </div>
            <div
              className="w-64 rounded-md bg-red-600 py-4 text-center text-2xl text-white"
              onClick={cancelItem}>
              Cancel
            </div>
          </div>
        )}
      {currentItem.validScans &&
        currentItem.validScans.findIndex(
          (item) => item.scannedItem === false,
        ) === -1 &&
        currentItemMismatch !== null && (
          <div className="mt-20 flex items-center justify-center space-x-4">
            <div
              className="w-64 rounded-md bg-red-600 py-4 text-center text-2xl text-white"
              onClick={() => setCurrentItemMismatch(null)}>
              Back
            </div>
            <div
              className="w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
              onClick={() => confirmPrepItem(currentItem)}>
              Confirm Prep
            </div>
            {!workflow && (
              <div
                className="w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
                onClick={() => setCurrentItemMismatch("CONFIRMED", true)}>
                {tenant?.settings?.activities?.prepping
                  ?.disableAutomaticPrinting
                  ? "Print FNSKU"
                  : "Re-Print FNSKU"}
              </div>
            )}
          </div>
        )}
    </div>
  );
};

const LineItemDesc = ({ title, value, idx }) => (
  <div className="flex w-full space-x-1 shadow-md">
    <div
      className={`flex w-64 items-end justify-end rounded-bl-md rounded-tl-md border-l-8 bg-white p-4 text-2C7695 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className={`flex-1 rounded-br-md rounded-tr-md border bg-white p-4`}>
      {["ASIN"].includes(title)
        ? buildMarketplaceHyperlink(value, title)
        : value}
    </div>
  </div>
);

export default DetailedView;

const truncate = (str) => {
  return str && str.length > 40 ? str.substring(0, 40) + "..." : str;
};
