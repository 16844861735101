import withGlobalIntegrationsLogic from "#components/HOC/withGlobalIntegrationsLogic";
import Modal from "#components/utils/Modal";
import IntegrationForm from "#components/integrations/IntegrationForm";
import IntegrationProviders from "./IntegrationProviders";
import ManageIntegrationProvider from "./ManageIntegrationProvider";

const GlobalIntegrations = ({
  integrationProviders,
  getProducts,
  connectIntegration,
  integrations,
  selectedIntegration,
  setSelectedIntegration,
  selectedIntegrationToManage,
  setSelectedIntegrationToManage,
  selectedIntegrationToConfigure,
  setSelectedIntegrationToConfigure,
  configureIntegrationSubmit,
  onChange,
  onChangeDropdown,
  saveIntegration,
  logoutIntegration,
  testIntegration,
  testResults,
  setTestResults,
  writable,
}) => (
  <>
    {selectedIntegrationToManage ? (
      <ManageIntegrationProvider
        integrations={integrations.filter(
          (item) => item.integrationType === selectedIntegrationToManage,
        )}
        integrationProvider={integrationProviders.find(
          (item) => item.name === selectedIntegrationToManage,
        )}
        setSelectedIntegrationToManage={setSelectedIntegrationToManage}
        selectedIntegrationToConfigure={selectedIntegrationToConfigure}
        setSelectedIntegrationToConfigure={setSelectedIntegrationToConfigure}
        writable={writable}
        connectIntegration={connectIntegration}
        logoutIntegration={logoutIntegration}
        testIntegration={testIntegration}
        getProducts={getProducts}
        configureIntegrationSubmit={configureIntegrationSubmit}
      />
    ) : (
      <IntegrationProviders
        integrations={integrations}
        integrationProviders={integrationProviders}
        connectIntegration={connectIntegration}
        logoutIntegration={logoutIntegration}
        testIntegration={testIntegration}
        getProducts={getProducts}
        selectedCustomer={null}
        setSelectedIntegrationToManage={setSelectedIntegrationToManage}
        selectedIntegrationToConfigure={selectedIntegrationToConfigure}
        setSelectedIntegrationToConfigure={setSelectedIntegrationToConfigure}
        writable={writable}
        multiAccountSupportEnabled={false}
        configureIntegrationSubmit={configureIntegrationSubmit}
      />
    )}

    {selectedIntegration && (
      <IntegrationForm
        title={selectedIntegration.id ? "Edit Integration" : "Add Integration"}
        isGlobal={true}
        selectedIntegration={selectedIntegration}
        integrationProviders={integrationProviders}
        setSelectedIntegration={setSelectedIntegration}
        onChange={onChange}
        onClose={() => setSelectedIntegration(null)}
        onSubmit={saveIntegration}
        customers={[]}
        onChangeDropdown={onChangeDropdown}
        selectedCustomer={null}
        setSelectedCustomer={() => {}}
        multiAccountSupportEnabled={false}
        shopifyLocations={[]}
        shipstationCarriers={[]}
      />
    )}

    {testResults && (
      <Modal
        title={`Connection Result`}
        negativeAction={() => setTestResults(null)}>
        <div className="space-y-4">
          <div>
            <span className="pb-4 font-montserrat text-lg font-bold text-454A4F">
              Tested retrieval of 1 product. Success.
            </span>
          </div>
          {Object.keys(testResults).map((item, idx) => (
            <div key={idx}>
              <span className="pb-4 font-montserrat text-lg font-bold text-454A4F">
                {item} : {testResults[item]}
              </span>
            </div>
          ))}
        </div>
      </Modal>
    )}
  </>
);

export default withGlobalIntegrationsLogic(GlobalIntegrations);
