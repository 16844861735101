import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";
import _ from "lodash";
import { useState } from "react";

const UomBreakdownForm = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  fetchedReceivedSku,
  onSubmit,
  updateUomBreakdown,
  appState,
}) => {
  const [inputError, setInputError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const isSubmitDisabled =
    !fetchedReceivedSku?.adjustmentUom ||
    !fetchedReceivedSku?.quantity ||
    inputError;

  return (
    <Modal
      title={title}
      negativeAction={onClose}
      positiveAction={() => {
        setFormSubmitted(true);
        onSubmit();
        onClose();
      }}
      disabled={isSubmitDisabled}>
      <div className="space-y-4">
        <div>
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            SKU Details
          </span>
        </div>

        <div className="grid grid-cols-4 items-start">
          <div className="font-montserrat text-lg font-medium">
            Bin Location:
          </div>
          <div className="col-span-3 font-montserrat text-lg font-medium">
            {fetchedReceivedSku?.binLocation}
          </div>
        </div>

        <div className="grid grid-cols-4 items-start">
          <div className="font-montserrat text-lg font-medium">SKU:</div>
          <div className="col-span-3 font-montserrat text-lg font-medium">
            {fetchedReceivedSku?.sku}
          </div>
        </div>

        <div className="grid grid-cols-4 items-start">
          <div className="font-montserrat text-lg font-medium">Stock:</div>
          <div className="col-span-3 font-montserrat text-lg font-medium">
            {fetchedReceivedSku?.unallocatedQuantity}{" "}
            {fetchedReceivedSku?.formFactor}
          </div>
        </div>

        <div className="flex flex-col gap-5">
          <div className="flex items-center gap-3">
            <div className="w-1/2">
              <TextField
                type="number"
                id="quantity"
                label="Quantity"
                placeholder=""
                onChange={onChange}
                name="quantity"
                value={fetchedReceivedSku?.quantity}
                min="1"
                validate={(value) => {
                  if (value > fetchedReceivedSku?.unallocatedQuantity) {
                    setInputError(true);
                    return "Quantity can't be greater than stock.";
                  } else {
                    setInputError(false);
                  }
                }}
                disabled={fetchedReceivedSku?.formFactor === "Each"}
              />
            </div>

            <div className="font-medium">{fetchedReceivedSku?.formFactor}</div>
          </div>
          <div>
            <Dropdown
              // TODO: HOP-6031
              placeholder={"Select Adjustment UOM"}
              list={appState.masterData.uomLabels?.filter(
                (label) => label.isActive,
              )}
              labelKey="name"
              valueKey="name"
              name="adjustmentUom"
              setSelected={(e) => {
                onChangeDropdown("adjustmentUom", e);
              }}
              selectedValue={fetchedReceivedSku?.adjustmentUom}
              disabled={fetchedReceivedSku?.formFactor === "Each"}
            />
          </div>
        </div>

        {formSubmitted &&
          !updateUomBreakdown.loading &&
          updateUomBreakdown.error?.message && (
            <p className="text-red-500">
              Error: {updateUomBreakdown.error?.message}
            </p>
          )}
      </div>
    </Modal>
  );
};

export default UomBreakdownForm;
