import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import _ from "lodash";
import UploadFileWithSource from "#components/common/UploadFileWithSource";
import Dropdown from "#components/utils/Dropdown";
import Toggle from "#components/utils/Toggle";

const OrderForm = ({
  onClose,
  title,
  trackingUpdate,
  setTrackingUpdate,
  onSubmit,
  uploadFile,
  bolUploadEnabled,
}) => (
  <Modal
    title={title}
    negativeAction={onClose}
    positiveAction={onSubmit}
    id="orderFormModal">
    <div>
      <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
        Tracking Details
      </span>
    </div>
    {bolUploadEnabled && (
      <div>
        <Dropdown
          placeholder={"Shipment Type"}
          list={[
            { name: "Small Parcel", id: "SP" },
            { name: "LTL/FTL", id: "LTL" },
          ]}
          labelKey="name"
          valueKey="id"
          name="insuranceRequired"
          setSelected={(e) => {
            setTrackingUpdate({
              ...trackingUpdate,
              typeOfShipment: e,
            });
          }}
          selectedValue={trackingUpdate.typeOfShipment}
        />
      </div>
    )}
    <div className="mt-4 space-y-4">
      {bolUploadEnabled && trackingUpdate.typeOfShipment === "LTL" ? (
        <div>
          <TextField
            type="text"
            id="proNumber"
            label="Pro Number"
            placeholder=" "
            onChange={(e) => {
              setTrackingUpdate({
                ...trackingUpdate,
                proNumber: e.target.value,
              });
            }}
            value={trackingUpdate.proNumber}
            name="proNumber"
            key="proNumber"
          />
        </div>
      ) : (
        <div>
          <TextField
            type="text"
            id="trackingNumber"
            label="Tracking Number"
            placeholder=" "
            onChange={(e) => {
              setTrackingUpdate({
                ...trackingUpdate,
                trackingNumber: e.target.value,
              });
            }}
            value={trackingUpdate.trackingNumber}
            name="trackingNumber"
            key="trackingNumber"
          />
        </div>
      )}
      {bolUploadEnabled && trackingUpdate.source === "FBA" ? (
        <div className="space-y-4">
          <div>
            <Dropdown
              placeholder={"Carrier"}
              list={carrierList(trackingUpdate.shippingAddress?.country)}
              labelKey="name"
              valueKey="id"
              name="carrier"
              setSelected={(e) => {
                setTrackingUpdate({
                  ...trackingUpdate,
                  carrier: e,
                });
              }}
              selectedValue={trackingUpdate.carrier}
            />
            {trackingUpdate.carrier?.toLowerCase() === "other" && (
              <div className="mt-4">
                <TextField
                  type="text"
                  id="requestedCarrier"
                  label="Specify Carrier"
                  placeholder=" "
                  onChange={(e) => {
                    setTrackingUpdate({
                      ...trackingUpdate,
                      requestedCarrier: e.target.value,
                    });
                  }}
                  value={trackingUpdate.requestedCarrier}
                  name="requestedCarrier"
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <TextField
            type="text"
            id="carrier"
            label="Carrier"
            placeholder=" "
            onChange={(e) => {
              setTrackingUpdate({
                ...trackingUpdate,
                carrier: e.target.value,
              });
            }}
            value={trackingUpdate.carrier}
            name="carrier"
          />
        </div>
      )}

      <div>
        <TextField
          type="text"
          id="trackingUrl"
          label="Tracking URL(optional)"
          placeholder=" "
          onChange={(e) => {
            setTrackingUpdate({
              ...trackingUpdate,
              trackingUrl: e.target.value,
            });
          }}
          value={trackingUpdate.trackingUrl}
          name="trackingUrl"
        />
      </div>

      {trackingUpdate.source === "eHub FBM" && (
        <div>
          <TextField
            type="text"
            id="carrierService"
            label="Carrier Service"
            placeholder=" "
            onChange={(e) => {
              setTrackingUpdate({
                ...trackingUpdate,
                carrierService: e.target.value,
              });
            }}
            value={trackingUpdate.carrierService}
            name="carrierService"
          />
        </div>
      )}

      {trackingUpdate.source === "eHub FBM" && (
        <div>
          <TextField
            type="text"
            id="shippingCost"
            label="Shipping Cost"
            placeholder=" "
            onChange={(e) => {
              setTrackingUpdate({
                ...trackingUpdate,
                shippingCost: e.target.value,
              });
            }}
            value={trackingUpdate.shippingCost}
            name="shippingCost"
          />
        </div>
      )}

      <div>
        <TextField
          type="date"
          id="dateOfShipping"
          label="Date of Shipping"
          placeholder=" "
          onChange={(e) => {
            setTrackingUpdate({
              ...trackingUpdate,
              dateOfShipping: e.target.value,
            });
          }}
          value={trackingUpdate.dateOfShipping}
          name="dateOfShipping"
        />
      </div>

      <div>
        <TextField
          type="date"
          id="deliveryDate"
          label="Delivery Date"
          placeholder=" "
          value={trackingUpdate.deliveryDate}
          name="deliveryDate"
          onChange={(e) => {
            setTrackingUpdate({
              ...trackingUpdate,
              deliveryDate: e.target.value,
            });
          }}
        />
      </div>
      <div>
        <TextField
          type="text"
          id="thirdPartyFreightChargesBill"
          label="Third Party Freight Charges Bill To"
          placeholder=" "
          onChange={(e) => {
            setTrackingUpdate({
              ...trackingUpdate,
              thirdPartyFreightChargesBill: e.target.value,
            });
          }}
          value={trackingUpdate.thirdPartyFreightChargesBill}
          name="thirdPartyFreightChargesBill"
          key="thirdPartyFreightChargesBill"
        />
      </div>

      {bolUploadEnabled && trackingUpdate.source === "FBA" && (
        <div className="flex items-center space-x-4 px-2">
          <Toggle
            enabled={trackingUpdate.postUpdateToMarketplace}
            setEnabled={(e) =>
              setTrackingUpdate({
                ...trackingUpdate,
                postUpdateToMarketplace: e,
              })
            }
          />
          <div className="text-lg">Post Update to Amazon</div>
        </div>
      )}

      <UploadFileWithSource
        fileData={trackingUpdate}
        setFileData={setTrackingUpdate}
        label={"Shipping Label"}
        uploadFile={uploadFile}
      />
      {bolUploadEnabled && trackingUpdate.typeOfShipment === "LTL" && (
        <>
          <UploadFileWithSource
            fileData={trackingUpdate.bolData}
            setFileData={(data) => {
              setTrackingUpdate({
                ...trackingUpdate,
                bolData: {
                  ...data,
                },
              });
            }}
            label={"BOL"}
            uploadFile={(e) => uploadFile(e, "bol")}
            noSystemGenerated={true}
          />
          <div>
            <TextField
              type="text"
              id="bolNumber"
              label="BOL Number"
              placeholder=" "
              onChange={(e) =>
                setTrackingUpdate({
                  ...trackingUpdate,
                  bolNumber: e.target.value,
                })
              }
              value={trackingUpdate?.bolNumber}
              name="bolNumber"
            />
          </div>
        </>
      )}
    </div>
  </Modal>
);

export default OrderForm;

// USED TO DETERMINE THE MASTER LIST OF CARRIERS FOR FBA. IT VARIES FROM REGION TO REGION.
function carrierList(country) {
  if (country === "US" || !country) {
    return [
      {
        name: "FedEx",
        id: "FEDERAL_EXPRESS_CORP",
      },
      {
        name: "UPS",
        id: "UNITED_PARCEL_SERVICE_INC",
      },
      {
        name: "USPS",
        id: "UNITED_STATES_POSTAL_SERVICE",
      },
      {
        name: "DHL",
        id: "DHL_EXPRESS_USA_INC",
      },
      {
        name: "Other",
        id: "Other",
      },
    ];
  }

  return [
    {
      name: "DHL",
      id: "DHL_UK",
    },
    {
      name: "UPS",
      id: "UNITED_PARCEL_SERVICE_INC",
    },
    {
      name: "Yodel",
      id: "YODEL",
    },
    {
      name: "TNT",
      id: "TNT",
    },
    {
      name: "Parcelforce",
      id: "PARCELFORCE",
    },
    {
      name: "DHL Airways Inc",
      id: "DHL_AIRWAYS_INC",
    },

    {
      name: "DPD",
      id: "DPD",
    },
    {
      name: "Royal Mail",
      id: "UNITED_KINGDOM_ROYAL_MAIL",
    },
    {
      name: "Other",
      id: "OTHER",
    },
  ];
}
