import AddButton from "#components/utils/AddButton";
import Modal from "#components/utils/Modal";
import Checkbox from "#components/utils/Checkbox";
import ModalButton from "#components/utils/ModalButton";
import TextField from "#components/utils/TextField";
import { useState } from "react";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";

import {
  useDimensionUnitAbbreviation,
  useWeightUnitAbbreviation,
} from "../../utils/Metrics";

const CurrentPackBoxes = ({
  setAddBox,
  currentBatch,
  order,
  weight,
  duplicateBox,
  removeBox,
  unpackItem,
  packItems,
  itemsToPack,
  setItemsToPack,
  setWeight,
  setWeightOfBox,
  handleFindAndPack,
  isAddItemQuantityExceed,
  isSetAddItemQuantityExceed,
  itemsToUnPack,
  setItemsToUnPack,
  handleFindAndUnpackItem,
  print2DBarcode,
  numberOfBoxesToDuplicate,
  setNumberOfBoxesToDuplicate,
}) => {
  const [boxName, setBoxName] = useState("");
  const [viewItems, setViewItems] = useState(null);
  const [addingItems, setAddingItems] = useState(false);
  const [keyword, setKeyword] = useState("");

  const filteredResults = () => {
    if (
      !keyword ||
      keyword.trim() === "" ||
      !currentBatch ||
      !currentBatch.workingList
    ) {
      return currentBatch?.workingList.filter(
        (item) => item.status === "UNPROCESSED",
      );
    }
    return currentBatch.workingList.filter(
      (item) =>
        item.status === "UNPROCESSED" &&
        ((item.productName &&
          item.productName.toLowerCase().indexOf(keyword.toLowerCase()) !==
            -1) ||
          (item.asin &&
            item.asin.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) ||
          (item.sku &&
            item.sku.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) ||
          (item.fnSku &&
            item.fnSku.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) ||
          (item.upc &&
            item.upc.length > 0 &&
            item.upc
              .map((i) => i.toLowerCase())
              .includes(keyword.toLowerCase())) ||
          (item.lpn &&
            item.lpn.length > 0 &&
            item.lpn
              .map((i) => i.toLowerCase())
              .includes(keyword.toLowerCase()))),
    );
  };
  /**
   * @typedef DataArray
   * @property {string} sku
   * @property {number} quantity
   */
  /**
   * Consolidates an array of data objects, summing the quantities for each SKU.
   * @param {DataArray[]} data
   * @returns {DataArray[]} An array of consolidated data objects. Each object will represent a unique SKU from the input
   * 'data', with a summed 'quantity'.
   *
   * @example
   *  consolidateData([
   *    { sku: "123", quantity: 2 },
   *    { sku: "123", quantity: 3 },
   *    { sku: "456", quantity: 1 },
   *  ]);
   *  // Returns: [{ sku: "123", quantity: 5 }, { sku: "456", quantity: 1 }]
   */
  function consolidateData(data) {
    let consolidated = {};

    for (let obj of data) {
      let sku = obj.sku;
      if (consolidated[sku]) {
        consolidated[sku].quantity += obj.quantity;
      } else {
        consolidated[sku] = {
          ...obj,
        };
      }
    }
    return Object.values(consolidated);
  }
  /**
   * Consolidates an array of data objects, summing the quantities for each SKU where the status is "CONFIRMED".
   * @param {DataArray[]} data
   * @returns {DataArray[]} An array of consolidated data objects. Each object will represent a unique SKU from the input
   * 'data', with a summed 'quantity' for SKUs with "CONFIRMED" status.
   *
   * @example
   *  consolidateDataUnpack([
   *    { sku: "123", quantity: 2, status: "CONFIRMED" },
   *    { sku: "123", quantity: 3, status: "CONFIRMED" },
   *    { sku: "456", quantity: 1, status: "UNPROCESSED" },
   *  ]);
   *  // Returns: [{ sku: "123", quantity: 5, status: "CONFIRMED" }]
   */
  function consolidateDataUnpack(data) {
    let consolidated = {};

    for (let obj of data) {
      let sku = obj.sku;
      let boxName = obj.boxName;
      if (consolidated[`${sku}-${boxName}`] && obj.status === "CONFIRMED") {
        consolidated[`${sku}-${boxName}`].quantity += obj.quantity;
      } else if (obj.status === "CONFIRMED") {
        consolidated[`${sku}-${boxName}`] = {
          ...obj,
        };
      }
    }
    return Object.values(consolidated);
  }

  const preferredDimensionUnit = useDimensionUnitAbbreviation();
  const preferredWeightUnit = useWeightUnitAbbreviation();
  const headers = [
    "Name",
    `Dimensions(${preferredDimensionUnit})`,
    "# items",
    `Weight(${preferredWeightUnit})`,
  ];
  return (
    <>
      {!setAddBox && (
        <div className="p-2 text-center text-2xl">Confirm Box Contents</div>
      )}
      <div className="mt-4 rounded-2xl border bg-white shadow-lg">
        <div className="flex items-center justify-center pb-5 pt-5">
          <div className="flex-col">
            <div className="flex items-center px-4 pb-8">
              <div className="flex-1 text-xl font-medium text-454A4F">
                Current Boxes
              </div>
              {setAddBox && (
                <div className="ml-4 justify-end text-right">
                  <AddButton text="Add Box" onClick={() => setAddBox(true)} />
                </div>
              )}
            </div>

            <table className="-mt-4 min-w-full divide-y divide-gray-200 border border-gray-400">
              <thead className="rounded-full p-4">
                <tr className="font-montserratborder-left text-primaryAccent">
                  {headers.map((header, headerIdx) =>
                    headerIdx === 0 ? (
                      <th
                        scope="col"
                        className="px-1 py-3 pl-4 text-left text-lg font-medium tracking-wider"
                        key={headerIdx}>
                        {header}
                      </th>
                    ) : (
                      <th
                        scope="col"
                        className="px-1 py-3 pl-4 text-left text-lg font-medium tracking-wider"
                        key={headerIdx}>
                        {header}
                      </th>
                    ),
                  )}
                </tr>
              </thead>
              <tbody>
                {currentBatch.boxes &&
                  currentBatch.boxes.map((item, index) => (
                    <tr
                      key={item.name}
                      className={`${
                        index % 2 === 0 ? "bg-white" : "bg-gray-50"
                      }`}>
                      <td
                        className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                          index % 2 === 0
                            ? "border-F4C261"
                            : "border-primaryAccent"
                        }`}>
                        {item.name}
                      </td>
                      <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                        {item.length}x{item.width}x{item.height}
                      </td>
                      <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                        <div
                          className="cursor-pointer font-bold text-primaryAccent"
                          onClick={() => {
                            setViewItems(item.name);
                            setBoxName(item.name);
                          }}>
                          {
                            currentBatch.workingList.filter(
                              (i) => i.boxName === item.name,
                            )?.length
                          }
                        </div>
                      </td>
                      <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                        <input
                          type="number"
                          value={item.weight}
                          onChange={(e) =>
                            setWeightOfBox(index, e.target.value)
                          }
                          placeholder="Weight"
                          min="0"
                        />
                      </td>
                      {setNumberOfBoxesToDuplicate && (
                        <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                          <AddButton
                            text="Duplicate"
                            onClick={() =>
                              setNumberOfBoxesToDuplicate({
                                boxName: item.name,
                                countOfBoxes: 1,
                              })
                            }
                          />
                        </td>
                      )}
                      {order?.shipmentPlan?.transportType === "2D_BARCODE" &&
                        print2DBarcode && (
                          <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                            <AddButton
                              text="Print 2D Barcode"
                              onClick={() => print2DBarcode(item.name)}
                              styles={["bg-primaryAccent"]}
                            />
                          </td>
                        )}
                      {order?.shipmentPlan?.transportType === "2D_BARCODE" &&
                        print2DBarcode && (
                          <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                            <AddButton
                              text="Download 2D Barcode"
                              onClick={() => print2DBarcode(item.name, true)}
                              styles={["bg-primaryAccent"]}
                            />
                          </td>
                        )}
                      {removeBox && (
                        <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                          <AddButton
                            text="Remove"
                            onClick={() => removeBox(item.name)}
                            styles={["bg-red-600"]}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {numberOfBoxesToDuplicate && (
        <Modal
          negativeAction={() => setNumberOfBoxesToDuplicate(null)}
          positiveAction={() =>
            duplicateBox(
              numberOfBoxesToDuplicate.boxName,
              numberOfBoxesToDuplicate.countOfBoxes,
            )
          }
          positiveText={"Duplicate"}
          title={`Duplicate Box`}>
          <TextField
            type="number"
            id="unPackQuantity"
            placeholder="Number of Boxes"
            max={numberOfBoxesToDuplicate.countOfBoxes}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
            }}
            onChange={(e) => {
              setNumberOfBoxesToDuplicate({
                ...numberOfBoxesToDuplicate,
                countOfBoxes:
                  isNaN(parseInt(e.target.value)) === false
                    ? parseInt(e.target.value)
                    : null,
              });
            }}
            value={numberOfBoxesToDuplicate.countOfBoxes}
          />
        </Modal>
      )}
      {viewItems && (
        <Modal
          negativeAction={() => setViewItems(null)}
          positiveAction={() => unpackItem()}
          positiveText={"Re-do"}
          title={`Items(${
            currentBatch.workingList.filter((i) => i.boxName === viewItems)
              .length
          }) in ${viewItems}`}>
          {currentBatch.workingList.filter((i) => i.status === "UNPROCESSED") &&
            setItemsToPack && (
              <AddButton
                text="Add Items"
                onClick={() => setAddingItems(true)}
              />
            )}

          <div className="h-96 overflow-auto">
            <table className="mt-2 min-w-full divide-y divide-gray-200">
              <thead className="rounded-full p-4">
                <tr className="font-montserratborder-left bg-primaryAccent text-white">
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                    SKU
                  </th>
                  {order && order.source === "FBA" && (
                    <th
                      scope="col"
                      className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                      ASIN
                    </th>
                  )}
                  {order && order.source === "FBA" && (
                    <th
                      scope="col"
                      className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                      FNSKU
                    </th>
                  )}
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                    Packed Qty
                  </th>
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                    Redo
                  </th>
                </tr>
              </thead>
              <tbody>
                {consolidateDataUnpack(currentBatch.workingList)
                  ?.filter((i) => i.boxName === viewItems)
                  .map((item, index) => (
                    <tr
                      key={item.id}
                      className={`${
                        index % 2 === 0 ? "bg-white" : "bg-gray-50"
                      }`}>
                      <td
                        className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                          index % 2 === 0
                            ? "border-F4C261"
                            : "border-primaryAccent"
                        }`}>
                        {item.sku}
                      </td>
                      {order && order.source === "FBA" && (
                        <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                          {buildMarketplaceHyperlink(
                            item.asin,
                            "ASIN",
                            item.source,
                          )}
                        </td>
                      )}
                      {order && order.source === "FBA" && (
                        <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                          {item.fnSku}
                        </td>
                      )}
                      <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                        {item.productName}
                      </td>
                      <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                        {item.quantity}
                      </td>
                      <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                        <TextField
                          type="number"
                          id="unPackQuantity"
                          placeholder="Unpack Qty"
                          max={item.quantity}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              "",
                            );
                          }}
                          onChange={(e) => {
                            if (e.target.value <= item.quantity) {
                              handleFindAndUnpackItem(
                                currentBatch.workingList?.filter(
                                  (i) => i.boxName === viewItems,
                                ),
                                item.sku,
                                e.target.value,
                                item.boxName,
                              );
                            } else {
                              e.target.value = 0;
                              isSetAddItemQuantityExceed(true);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {/* {
            <div
              className={`pt-4 w-full bg-2C7695 p-4 flex items-center justify-center space-x-4 rounded-bl-3xl rounded-br-3xl`}>
              <ModalButton
                onClick={() => setViewItems(null)}
                transparent={true}
                className={"bg-red-600"}
                text={"Cancel"}
              />
              <ModalButton
                onClick={() => unpackItem()}
                className={"bg-primaryAccent"}
                text={"Re-do"}
              />
            </div>
          } */}
        </Modal>
      )}
      {addingItems && (
        <Modal
          negativeAction={() => setAddingItems(false)}
          positiveAction={() =>
            packItems ? packItems(boxName) : setAddingItems(false)
          }
          positiveText={packItems ? "Add to Box" : "OK"}
          title={`Items(${filteredResults().length})`}
          noPadding={true}>
          <div className="relative p-2">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <svg
                aria-hidden="true"
                className="h-5 w-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="text-md block w-full rounded-lg border border-gray-300 bg-gray-50 p-4 pl-10 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              placeholder={
                order?.source === "FBA"
                  ? "Search by ASIN/SKU/FNSKU/UPC/LPN/Name"
                  : "Search by SKU/UPC/LPN/Name"
              }
              onChange={(e) => setKeyword(e.target.value)}
              value={keyword}
            />
          </div>
          <div className="h-96 overflow-auto">
            <table className="mt-2 min-w-full divide-y divide-gray-200">
              <thead className="rounded-full p-4">
                <tr className="font-montserratborder-left bg-primaryAccent text-white">
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                    SKU
                  </th>
                  {order && order.source === "FBA" && (
                    <th
                      scope="col"
                      className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                      ASIN
                    </th>
                  )}
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                    Available Qty
                  </th>
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                    Qty
                  </th>
                </tr>
              </thead>
              <tbody>
                {consolidateData(filteredResults())?.map((item, index) => (
                  <tr
                    key={item.id}
                    className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                    <td
                      className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                        index % 2 === 0
                          ? "border-F4C261"
                          : "border-primaryAccent"
                      }`}>
                      {item.sku}
                    </td>
                    {order && order.source === "FBA" && (
                      <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                        {buildMarketplaceHyperlink(
                          item.asin,
                          "ASIN",
                          item.source,
                        )}
                      </td>
                    )}
                    <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                      {item.productName}
                    </td>
                    <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                      {item.quantity}
                    </td>
                    <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                      <TextField
                        type="number"
                        id="quantity"
                        placeholder="Quantity"
                        max={item.quantity}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            "",
                          );
                        }}
                        onChange={(e) => {
                          if (e.target.value <= item.quantity) {
                            handleFindAndPack(
                              filteredResults(),
                              item.sku,
                              e.target.value,
                            );
                          } else {
                            e.target.value = 0;
                            isSetAddItemQuantityExceed(true);
                          }
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* {packItems && (
            <div
              className={`pt-4 w-full bg-2C7695 p-4 flex items-center justify-center space-x-4 rounded-bl-3xl rounded-br-3xl`}>
              <ModalButton
                onClick={() => setAddingItems(false)}
                transparent={true}
                className={"bg-red-600"}
                text={"Cancel"}
              />
              <ModalButton
                onClick={() => packItems(boxName)}
                className={"bg-primaryAccent"}
                text={"Add to Box"}
              />
            </div>
          )}

          {!packItems && (
            <div
              className={`pt-4 w-full bg-2C7695 p-4 flex items-center justify-center space-x-4 rounded-bl-3xl rounded-br-3xl`}>
              <ModalButton
                onClick={() => setAddingItems(false)}
                transparent={true}
                className={"bg-primaryAccent"}
                text={"OK"}
              />
            </div>
          )} */}
        </Modal>
      )}
    </>
  );
};

export default CurrentPackBoxes;
