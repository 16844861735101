import withReceivingsLogic from "#components/HOC/withReceivingsLogic";
import moment from "moment-timezone";
import _ from "lodash";
import Stats from "#components/common/Stats";
import Pagination from "#components/common/Pagination";
import SearchSortFilter from "#components/common/SearchSortFilter";
import ReceivingsStats from "#components/receivings/CustomReceivingsStats";
import BatchFilters from "#components/batches/outbound/BatchFilters";
import ExpandedBatch from "#components/batches/outbound/ExpandedBatch";
import ModalV2 from "../utils/ModalV2";
import MinimalModal from "../utils/MinimalModal";
import { useContext, useEffect, useState } from "react";
import {
  getHeaderObject,
  getSortableColumns,
  getUpdatedHeaders,
} from "../../utils/getHeaderObject";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import { AuthContext } from "../../contexts/auth";
import cellStyleForTable from "../common/CellStyleForTable";
import QuickFilters from "../common/QuickFilters";
const noValuesText = "No Receivings";

const RECEIVING_TABLE_NAME = "receivingsList";

const ReceivingsList = ({
  total,
  perPage,
  setPerPage,
  pageNumber,
  checkPagination,
  allData,
  filters,
  sort,
  setSort,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  setShowFilters,
  clearFilters,
  users,
  displayBatches,
  expandedBatch,
  setExpandedBatch,
  showFilters,
  onChangeFilter,
  handleDateRangeClear,
  expandBatch,
  stats,
  setStats,
  productivityStats,
  setProductivityStats,
  fetchBatchesForStats,
  fetchBatchesForProductivityStats,
  setSelectedAssociate,
  selectedAssociate,
  customers,
  warehouses,
  usersMap,
}) => {
  const [headers, setHeaders] = useState([
    getHeaderObject("Batch ID", "id", false),
    getHeaderObject("User", "name", false),
    getHeaderObject("Assigned Time", "assignedTime"),
    getHeaderObject(
      "First Received",
      "firstCompletedTime",
      true,
      "attributes.firstCompletedTime",
    ),
    getHeaderObject(
      "Last Received",
      "lastCompletedTime",
      true,
      "attributes.lastCompletedTime",
    ),
    getHeaderObject("# items", "quantity", false),
    getHeaderObject("Completion", "completion"),
    getHeaderObject("Status", "status"),
    getHeaderObject("Form Factor", "formFactor"),
    getHeaderObject("LPN Form Factor", "nestedFormFactor"),
    getHeaderObject("LPN", "nestedFormFactorId"),
  ]);

  const [showHeaders, setShowHeaders] = useState(headers);
  const [choices, setChoices] = useState(getSortableColumns(headers));
  const auth = useContext(AuthContext);
  const [showDamageInfoModal, setShowDamageInfoModal] = useState(null);
  const [showImageModal, setShowImageModal] = useState(null);

  const DAMAGED_STATUSES = [
    "DAMAGED",
    "BOX_DAMAGED",
    "NO_BOX",
    "USED",
    "WRONG_ITEM",
  ];
  useEffect(() => {
    const updatedHeaders = getUpdatedHeaders(
      auth,
      headers,
      RECEIVING_TABLE_NAME,
    );
    if (updatedHeaders) {
      setHeaders(updatedHeaders);
      setShowHeaders(updatedHeaders.filter((header) => header.enabled));
      setChoices(getSortableColumns(updatedHeaders));
    }
  }, [auth.user]);

  useEffect(() => {
    setChoices(getSortableColumns(showHeaders));
  }, [showHeaders]);

  return (
    <>
      <Stats
        stats={[
          {
            name: "Total Receivings",
            renderValue: () => allData.total,
            setShowView: () => {
              setStats(null);
              setProductivityStats(null);
            },
          },
          {
            name: "Completion",
            stringValue: true,
            renderValue: () =>
              allData.totalCompleted && allData.total
                ? allData.totalCompleted?.toString() +
                  " / " +
                  parseInt(
                    (allData.totalCompleted / allData.total) * 100,
                  ).toString() +
                  "%"
                : "",
          },
          {
            name: "Receivings Per Hour",
            renderValue: () => allData.completionPerHour,
            view: "RECEIVINGS_STATS",
            setShowView: () => {
              fetchBatchesForStats();
              fetchBatchesForProductivityStats();
            },
          },
          {
            name: "Items in Error",
            renderValue: () => allData.itemsInError,
          },
        ]}
      />
      {filters?.startEndDate && (
        <div className="flex items-end justify-end text-2xl font-bold text-primaryAccent">
          Date Range: {filters.startEndDate[0] || "Beginning"} ~{" "}
          {filters.startEndDate[1] || "Now"}
        </div>
      )}
      {showDamageInfoModal && (
        <>
          {showImageModal && (
            <MinimalModal
              noPadding={true}
              onClose={() => setShowImageModal(null)}>
              <img src={showImageModal}></img>
            </MinimalModal>
          )}
          <ModalV2
            onClose={() => setShowDamageInfoModal(null)}
            xIconClicked={() => setShowDamageInfoModal(null)}
            title={`Damage Info`}
            contextText={`Retrieve damaged items, photos, and notes for specific products captured during receiving`}
            noPadding={true}>
            <div className="p-2">
              <table className="min-w-full">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500">
                      SKU
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500">
                      Product Info
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500">
                      Images
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500">
                      Damaged Qty
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500">
                      Notes
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {
                    <tr>
                      <td className="text-black-500 whitespace-nowrap px-6 py-4 text-base font-medium">
                        {showDamageInfoModal?.items?.[0]?.sku}
                      </td>
                      <td className="whitespace px-6 py-4 text-sm font-normal">
                        <div
                          className="text-gray-900"
                          style={{
                            maxWidth: "500px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}>
                          {showDamageInfoModal?.items?.[0]?.name}
                        </div>
                        {showDamageInfoModal?.items?.[0]?.asin && (
                          <div>
                            <br />
                            <div className="text-black-500 font-medium">
                              ASIN: {showDamageInfoModal?.items?.[0]?.asin}
                            </div>
                          </div>
                        )}
                      </td>
                      <td>
                        <div className="flex space-x-2">
                          {showDamageInfoModal?.workflowImages?.[0]?.signedImageUrls?.map(
                            (image, index) => (
                              <div className="cursor-pointer">
                                <img
                                  key={index}
                                  src={image}
                                  className="h-10 w-10 rounded-md object-cover"
                                  onClick={() => {
                                    setShowImageModal(image);
                                  }}
                                />
                              </div>
                            ),
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="flex justify-center space-x-2">
                          {showDamageInfoModal.quantity}
                        </div>
                      </td>
                      <td className="text-black-500 whitespace-nowrap px-6 py-4 text-sm">
                        {showDamageInfoModal.remarks}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </ModalV2>
        </>
      )}

      {stats && productivityStats ? (
        <ReceivingsStats
          stats={stats}
          productivityStats={productivityStats}
          setSelectedAssociate={setSelectedAssociate}
          selectedAssociate={selectedAssociate}
          onChangeFilter={onChangeFilter}
          submitFilters={submitFilters}
          filters={filters}
          fetchBatchesForStats={fetchBatchesForStats}
          fetchBatchesForProductivityStats={fetchBatchesForProductivityStats}
        />
      ) : (
        <div className="rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20">
          {(customers?.length > 1 || warehouses?.length > 1) && (
            <QuickFilters
              warehouseFilterName={"warehouses"}
              customerFilterName={"customers"}
              customers={customers}
              warehouses={warehouses}
              filters={filters}
              onChangeFilter={onChangeFilter}
            />
          )}
          <SearchSortFilter
            onChangeSearchKeyword={onChangeSearchKeyword}
            filters={filters}
            submitFilters={submitFilters}
            clearKeyword={clearKeyword}
            setSort={setSort}
            sort={sort}
            setShowFilters={setShowFilters}
            clearFilters={clearFilters}
            choices={choices}
            headers={headers}
            setShowHeaders={setShowHeaders}
            tableName={RECEIVING_TABLE_NAME}
          />

          <CustomTableWithTheme>
            <thead className="sticky left-0 top-0 bg-primaryAccent p-4">
              <tr className="border-left font-montserrat text-textWhite">
                {showHeaders.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <th
                      scope="col"
                      className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                      key={headerIdx}>
                      {header.name}
                    </th>
                  ) : (
                    <th
                      scope="col"
                      className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                      key={headerIdx}>
                      {header.name}
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {displayBatches?.length === 0 ? (
                <tr className="bg-white">
                  {showHeaders.map((header, headerIdx) =>
                    headerIdx === 0 ? (
                      <td
                        className="tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent"
                        key={headerIdx}>
                        {noValuesText}
                      </td>
                    ) : (
                      <td
                        className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                        key={headerIdx}></td>
                    ),
                  )}
                </tr>
              ) : null}
              {displayBatches &&
                displayBatches.map((receiving, rowIndex) => (
                  <tr
                    key={receiving.id}
                    className={`${
                      rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"
                    }`}>
                    {showHeaders.map((header, columnIndex) => {
                      let value = header.correspondingValue;
                      let cellStyle = cellStyleForTable(
                        value,
                        ["id"],
                        columnIndex,
                        rowIndex,
                      );
                      if (value == "id") {
                        return (
                          <td
                            className={cellStyle}
                            onClick={() => expandBatch(receiving.id)}>
                            {receiving.id}
                          </td>
                        );
                      }
                      if (value == "name") {
                        return (
                          <td className={cellStyle}>
                            {users &&
                              users.find(
                                (item) => item.id.toString() === receiving.user,
                              )?.name}
                          </td>
                        );
                      }
                      if (value == "assignedTime") {
                        return (
                          <td className={cellStyle}>
                            {moment(
                              receiving.assignedTime
                                ? receiving.assignedTime
                                : receiving.createdAt,
                            ).format("MMM Do YYYY, h:mm a")}
                          </td>
                        );
                      }
                      if (value == "firstCompletedTime") {
                        return (
                          <td className={cellStyle}>
                            {receiving.attributes?.firstCompletedTime &&
                              moment(
                                receiving.attributes.firstCompletedTime,
                              ).format("MMM Do YYYY, h:mm a")}
                          </td>
                        );
                      }
                      if (value == "lastCompletedTime") {
                        return (
                          <td className={cellStyle}>
                            {receiving.attributes?.lastCompletedTime &&
                              moment(
                                receiving.attributes.lastCompletedTime,
                              ).format("MMM Do YYYY, h:mm a")}
                          </td>
                        );
                      }
                      if (value == "quantity") {
                        return (
                          <td className={cellStyle}>{receiving.quantity}</td>
                        );
                      }
                      if (value == "completion") {
                        return (
                          <td className={cellStyle}>
                            {receiving.completion && `${receiving.completion}%`}
                          </td>
                        );
                      }
                      if (value == "status") {
                        let shouldShowDamageInfo =
                          DAMAGED_STATUSES.includes(receiving.status) &&
                          (receiving?.workflowImages?.[0]?.signedImageUrls
                            ?.length > 0 ||
                            receiving.remarks);
                        return (
                          <td className={cellStyle}>
                            {receiving.status}
                            {shouldShowDamageInfo && (
                              <div
                                className="cursor-pointer text-sm text-primaryAccent underline"
                                onClick={() => {
                                  setShowDamageInfoModal(receiving);
                                }}>
                                Damage Info
                              </div>
                            )}
                          </td>
                        );
                      }
                      if (value == "formFactor") {
                        return (
                          <td className={cellStyle}>{receiving.formFactor}</td>
                        );
                      }
                      if (value == "nestedFormFactor") {
                        return (
                          <td className={cellStyle}>
                            {receiving.nestedFormFactor}
                          </td>
                        );
                      }
                      if (value == "nestedFormFactorId") {
                        return (
                          <td className={cellStyle}>
                            {receiving.nestedFormFactorId}
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
            </tbody>
          </CustomTableWithTheme>
        </div>
      )}
      {!stats && (
        <Pagination
          showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
          showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
          showingTotal={total}
          perPage={perPage}
          setPerPage={setPerPage}
          pageNumber={pageNumber}
          checkPagination={checkPagination}
        />
      )}
      {expandedBatch && (
        <ExpandedBatch
          batchItems={expandedBatch.items}
          negativeAction={() => setExpandedBatch(null)}
          tableName={"receiving.orderDetails"}
          batch={expandedBatch}
          usersMap={usersMap}
        />
      )}
      {showFilters && (
        <BatchFilters
          dateRangeLabel="Select Receiving Date Range"
          negativeAction={() => setShowFilters(false)}
          onChangeFilter={onChangeFilter}
          showDateRangePicker={true}
          filters={filters}
          onSubmit={submitFilters}
          handleDateRangeClear={handleDateRangeClear}
          users={
            users
              ? users.filter(
                  (item) =>
                    item.hopstackModules &&
                    item.hopstackModules.includes("Receiving"),
                )
              : []
          }
          statusOptions={[
            { name: "UNPROCESSED" },
            { name: "IN-PROCESS" },
            { name: "COMPLETED" },
            { name: "PUTAWAY_COMPLETED" },
          ]}
          customers={customers}
          warehouses={warehouses}
        />
      )}
    </>
  );
};

export default withReceivingsLogic(ReceivingsList);
