import React, { useState, useRef } from "react";
import { DatePicker as AntdDatePicker } from "antd";
import { ExclamationCircleIcon, CalendarIcon } from "@heroicons/react/outline";
import { Tooltip } from "antd";
import { twMerge } from "tailwind-merge";

export default function DatePicker({
  label,
  required = false,
  infoText = "",
  value,
  onChange,
  error = "",
  parentClasses = "",
  disabled = false,
  placeholder = "Select Date",
  labelClasses = "text-gray-700",
  shouldBeHighLighted = false,
  ...props
}) {
  const [visible, setVisible] = useState(false);
  const inputRef = useRef(null);

  const handleDateChange = (date, dateString) => {
    onChange(dateString);
    setVisible(false);
  };

  return (
    <div className={`relative ${parentClasses}`}>
      {label && (
        <div className="mb-2 flex items-center">
          <div className={labelClasses}>
            {label}
            {required && <span className="text-red-500">*</span>}
            {infoText && (
              <Tooltip title={infoText}>
                <ExclamationCircleIcon className="ml-2 inline-block h-4 w-4 text-gray-400" />
              </Tooltip>
            )}
          </div>
        </div>
      )}
      <div className="relative">
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          readOnly
          disabled={disabled}
          ref={inputRef}
          className={twMerge(
            `block w-full rounded border bg-white p-2 text-gray-700 transition-colors duration-300 focus:border-primaryAccent focus:ring-primaryAccent ${
              error && shouldBeHighLighted
                ? "border-red-500"
                : "border-gray-400"
            }`,
          )}
          onClick={() => setVisible(true)}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <CalendarIcon className="h-5 w-5 text-gray-400" />
        </div>
        <AntdDatePicker
          open={visible}
          onOpenChange={setVisible}
          onChange={handleDateChange}
          {...props}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
            opacity: 0,
          }}
        />
      </div>
      {error && <div className="mt-1 text-sm text-red-600">{error}</div>}
    </div>
  );
}
