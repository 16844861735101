import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

export default function CheckBox({
  children,
  label,
  required = false,
  infoText = "",
  checked,
  onChange,
  error = "",
  parentClasses = "",
  disabled = false,
  labelClasses = "",
  primaryColor = "primaryAccent",
  className = "",
  width = "w-6",
  height = "h-6",
  ...props
}) {
  return (
    <div className={`relative ${parentClasses}`}>
      {label && (
        <div className="mb-2 flex items-center">
          <div className={`text-gray-700 ${labelClasses}`}>
            {label}
            {required && <span className="text-red-500">*</span>}
            {infoText && (
              <Tooltip title={infoText}>
                <ExclamationCircleIcon className="ml-2 inline-block h-4 w-4 text-gray-400" />
              </Tooltip>
            )}
          </div>
        </div>
      )}
      <div className="flex flex-col">
        <div className="flex items-center gap-1">
          <input
            type="checkbox"
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            style={{
              width: width,
              height: height,
            }}
            className={`text-${primaryColor} cursor-pointer rounded border-gray-400 focus:ring-0 ${className}`}
            {...props}
          />
          {children}
        </div>
        {error && <div className="ml-1 mt-2 text-sm text-red-600">{error}</div>}
      </div>
    </div>
  );
}

CheckBox.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
  required: PropTypes.bool,
  infoText: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  parentClasses: PropTypes.string,
  disabled: PropTypes.bool,
  labelClasses: PropTypes.string,
  primaryColor: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

CheckBox.defaultProps = {
  required: false,
  infoText: "",
  error: "",
  parentClasses: "",
  disabled: false,
  labelClasses: "",
  primaryColor: "primaryAccent",
  className: "",
  width: "w-6",
  height: "h-6",
};
