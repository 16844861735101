import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import Modal from "#components/utils/Modal";
import TableFieldButton from "#components/utils/TableFieldButton";
import {
  AdjustmentsIcon,
  ArrowRightIcon,
  EyeIcon,
} from "@heroicons/react/solid";
import { EXPLAIN_INVENTORY, EXPAND_STORAGE_ENTITY } from "#queries";
import { Tab } from "@headlessui/react";
import { SkuBinMappingsTable } from "#components/skuBinMappings/BinTransferEligibilityModal";
import { AppStateContext } from "#contexts/appState";

const ALERT_TIMEOUT_IN_MS = 5000;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const expandStorageEntity = async (item, query) => {
  const response = await query.fetchData({
    code: item.code,
    codeType: item.codeType,
    warehouse: item.warehouseId,
  });

  if (response.data) {
    return { data: response.data.expandStorageEntity, error: false };
  }

  if (response.error) {
    return { error: true, message: response.error.message };
  }
};

const InventoryExplanation = ({
  inventoryExplanation,
  index,
  setInventoryExplanations,
  inventoryExplanations,
  explainInventory,
  getSpecificInventory,
  writable,
  checkTransferSkuBinMapping,
  filters,
}) => {
  const expandLpnQuery = useQuery(EXPAND_STORAGE_ENTITY);
  const [expandedLpn, setExpandedLpn] = useState(null);
  const [inventoryExplanationObject, setInventoryExplanationObject] =
    useState(null);
  const noValuesText = "No Data";

  const expandLpn = async (item) => {
    const response = await expandStorageEntity(item, expandLpnQuery);

    if (response) {
      setExpandedLpn({ ...response.data, requestedEntityDetails: item });
    } else {
      setExpandedLpn(null);
    }
  };

  return (
    <Modal
      title={`Inventory Breakdown`}
      noPadding={true}
      negativeAction={() => {
        setInventoryExplanations(
          inventoryExplanations.filter((item, idx) => idx !== index),
        );
      }}
      negativeText="< Back">
      <div className="overflow-auto bg-EFE9DC p-4 pt-0">
        {!inventoryExplanation.typeOfInnerBreakdown ? (
          <Tab.Group>
            <Tab.List className="mt-4 flex items-center">
              <div className="flex-1 space-x-4 rounded-xl p-1 px-0">
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "font-regular rounded-full px-6 py-2.5 text-sm leading-5",
                      "bg-E1D3B8 font-montserrat font-semibold text-primaryAccent focus:outline-none focus:ring-0",
                      selected ? "border border-black" : "",
                    )
                  }>
                  UoM
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "font-regular rounded-full px-6 py-2.5 text-sm leading-5",
                      "bg-E1D3B8 font-montserrat font-semibold text-primaryAccent focus:outline-none focus:ring-0",
                      selected ? "border border-black" : "",
                    )
                  }>
                  LPN
                </Tab>
              </div>
            </Tab.List>
            <Tab.Panels className="mt-2">
              <Tab.Panel className="textWhite-300 mt-4 flex flex-col">
                <InventoryTable
                  inventoryExplanation={inventoryExplanation}
                  explainInventory={explainInventory}
                  getSpecificInventory={getSpecificInventory}
                  writable={writable}
                  checkTransferSkuBinMapping={checkTransferSkuBinMapping}
                  noValuesText={noValuesText}
                  expandLpn={expandLpn}
                />
              </Tab.Panel>
              <Tab.Panel className="textWhite-300 mt-4 flex flex-col">
                <LpnBreakdown
                  filters={filters}
                  inventoryExplanation={inventoryExplanation}
                  explainInventory={explainInventory}
                  getSpecificInventory={getSpecificInventory}
                  writable={writable}
                  checkTransferSkuBinMapping={checkTransferSkuBinMapping}
                  noValuesText={noValuesText}
                  inventoryExplanationObject={inventoryExplanationObject}
                  setInventoryExplanationObject={setInventoryExplanationObject}
                  expandLpn={expandLpn}
                />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        ) : (
          <div className="mt-4">
            <InventoryTable
              inventoryExplanation={inventoryExplanation}
              explainInventory={explainInventory}
              getSpecificInventory={getSpecificInventory}
              writable={writable}
              checkTransferSkuBinMapping={checkTransferSkuBinMapping}
              noValuesText={noValuesText}
              expandLpn={expandLpn}
            />
          </div>
        )}
      </div>
      {/* If there's an expanded LPN, render a table with headers in a new modal */}
      {expandedLpn && (
        <Modal
          negativeAction={() => setExpandedLpn(null)}
          negativeText="< Back"
          title="Storage Entity Details">
          <SkuBinMappingsTable tableData={expandedLpn} />
        </Modal>
      )}
    </Modal>
  );
};

const LpnBreakdown = ({
  filters,
  inventoryExplanation,
  explainInventory,
  getSpecificInventory,
  writable,
  checkTransferSkuBinMapping,
  noValuesText,
  inventoryExplanationObject,
  setInventoryExplanationObject,
  expandLpn,
}) => {
  const explainInventoryQuery = useQuery(EXPLAIN_INVENTORY);
  useEffect(() => {
    if (!inventoryExplanationObject) {
      fetchLpnExplanation();
    }
  }, []);

  const fetchLpnExplanation = async () => {
    const response = await explainInventoryQuery.fetchData({
      typeOfBreakdown: "storageLpnQuantity",
      filters,
      id: inventoryExplanation.mainId,
    });
    if (response.data) {
      setInventoryExplanationObject(response.data.explainInventory);
    }
  };
  if (!inventoryExplanationObject) {
    // RENDER A LOADING INDICATOR with Tailwind animation
    return (
      <div className="flex items-center justify-center">
        <div
          className="spinner-border inline-block h-8 w-8 animate-spin rounded-full border-4 border-b-transparent border-l-transparent border-r-transparent border-t-primaryAccent"
          role="status"></div>
      </div>
    );
  }
  return (
    <InventoryTable
      inventoryExplanation={inventoryExplanationObject}
      explainInventory={explainInventory}
      getSpecificInventory={getSpecificInventory}
      writable={writable}
      checkTransferSkuBinMapping={checkTransferSkuBinMapping}
      noValuesText={noValuesText}
      expandLpn={expandLpn}
    />
  );
};

const InventoryTable = ({
  inventoryExplanation,
  explainInventory,
  getSpecificInventory,
  writable,
  checkTransferSkuBinMapping,
  noValuesText,
  expandLpn,
}) => {
  return (
    <table className="min-w-full divide-y divide-gray-200 px-2">
      <thead className="rounded-full bg-primaryAccent p-4 px-12">
        <tr className="border-left border-l-8 border-primaryAccent px-12 font-montserrat text-textWhite">
          {inventoryExplanation.explanations.headers.map(
            (header, headerIdx) => (
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                key={headerIdx}>
                {header}
              </th>
            ),
          )}
        </tr>
      </thead>
      <tbody>
        {inventoryExplanation &&
        inventoryExplanation.explanations?.entities?.length === 0 ? (
          <tr className="bg-white">
            {inventoryExplanation.explanations.headers.map(
              (header, headerIdx) => (
                <td
                  className={`${
                    headerIdx === 0
                      ? "tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent"
                      : "px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                  }`}
                  key={headerIdx}>
                  {headerIdx === 0 ? noValuesText : ""}
                </td>
              ),
            )}
          </tr>
        ) : null}
        {inventoryExplanation?.explanations?.entities?.map((item, idx) => (
          <tr
            key={idx}
            className={`${idx % 2 === 0 ? "bg-white" : "bg-gray-50"} p-2`}>
            {inventoryExplanation.explanations.headers
              .filter((item) => item !== "Action" && item !== "warehouseId")
              .map((header, headerIdx) => (
                <td
                  className="rounded-br rounded-tr px-1 py-2 pl-4 text-left font-medium tracking-wider text-5F666B"
                  key={headerIdx}>
                  <RenderHeaderValue
                    header={header}
                    headerIdx={headerIdx}
                    explainInventory={explainInventory}
                    inventoryExplanation={inventoryExplanation}
                    item={item}
                    expandLpn={expandLpn}
                  />
                </td>
              ))}
            {writable &&
              ["storageQuantity", "problemQuantity"].includes(
                inventoryExplanation.typeOfBreakdown,
              ) &&
              inventoryExplanation.typeOfInnerBreakdown && (
                <td className="flex space-x-4 rounded-br rounded-tr px-1 py-4 pl-4 text-left font-medium tracking-wider text-5F666B">
                  <TableFieldButton
                    text={<AdjustmentsIcon className="h-6 w-6" />}
                    onClick={() => getSpecificInventory(item.id)}
                    tooltipText="Adjust Stock"
                  />
                  <TableFieldButton
                    text={<ArrowRightIcon className="h-6 w-6" />}
                    onClick={() => checkTransferSkuBinMapping(item.id)}
                    tooltipText="Transfer Stock"
                  />
                </td>
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const RenderHeaderValue = ({
  header,
  headerIdx,
  explainInventory,
  inventoryExplanation,
  item,
  expandLpn,
}) => {
  if (
    headerIdx === 0 &&
    ["storageQuantity", "problemQuantity", "storageLpnQuantity"].includes(
      inventoryExplanation.typeOfBreakdown,
    ) &&
    !inventoryExplanation.typeOfInnerBreakdown
  ) {
    const quantityToFetch =
      inventoryExplanation.typeOfBreakdown === "storageQuantity"
        ? "storageQuantity"
        : inventoryExplanation.typeOfBreakdown === "storageLpnQuantity"
          ? "storageLpnQuantity"
          : "problemQuantity";
    return (
      <div
        className="cursor-pointer font-bold text-primaryAccent hover:underline"
        onClick={() =>
          explainInventory(
            inventoryExplanation.mainId,
            quantityToFetch,
            item[header],
          )
        }>
        {item[header]}
      </div>
    );
  }

  if (
    headerIdx === 0 &&
    inventoryExplanation.typeOfBreakdown === "storageLpnQuantity" &&
    inventoryExplanation.typeOfInnerBreakdown
  ) {
    return (
      <div
        className="cursor-pointer font-bold text-primaryAccent hover:underline"
        onClick={() => expandLpn(item)}>
        {item[header]}
      </div>
    );
  }

  if (["Consignment Info"].includes(header)) {
    return (
      <div className="group relative cursor-pointer">
        <p className="truncate font-medium" data-tip data-for={`${item.id}`}>
          {item.consignmentInfo.orderId ||
            item.consignmentInfo.consignmentNumber}
        </p>
      </div>
    );
  }

  return <>{item[header]}</>;
};

export default InventoryExplanation;
