import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";
import AddButton from "#components/utils/AddButton";
import Autocomplete from "#components/utils/Autocomplete";
import AutocompleteDropdown from "#components/utils/AutocompleteDropdown";
import _ from "lodash";
import Select from "react-select";
import Wizard from "../utils/Wizard";
import WizardProductSelector from "../utils/WizardProductSelector";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";
import { useState } from "react";

const BundleFormWizard = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  selectedBundle,
  onSubmit,
  customers,
  warehouses,
  addProduct,
  removeProduct,
  onChangeProduct,
  availableInventory,
  onChangeInventorySearch,
  getMarketplaceProducts,
}) => {
  const [isAdding, setIsAdding] = useState();
  const steps = [
    {
      name: "Client Info",
      href: "#",
      render: (
        <>
          {selectedBundle.warehouseToBeSelected && (
            <>
              <div>
                <Dropdown
                  placeholder={"Select Warehouse"}
                  list={warehouses}
                  labelKey="name"
                  valueKey="id"
                  name="warehouse"
                  setSelected={(selectedOption) => {
                    onChangeDropdown("warehouse", selectedOption);
                  }}
                  selectedValue={selectedBundle.warehouse}
                />
              </div>
            </>
          )}
          {selectedBundle.customerToBeSelected && (
            <>
              <div>
                <Dropdown
                  placeholder={"Select Client"}
                  list={getFilteredCustomerList(
                    selectedBundle.warehouse,
                    customers,
                  )}
                  labelKey="name"
                  valueKey="id"
                  name="customer"
                  setSelected={(selectedOption) => {
                    onChangeDropdown("customer", selectedOption);
                  }}
                  selectedValue={selectedBundle.customer}
                />
              </div>
            </>
          )}
        </>
      ),
    },
    {
      name: "Basic Details",
      href: "#",
      render: (
        <>
          <div className="mb-2">
            <TextField
              type="text"
              id="name"
              label="Name"
              placeholder=" "
              onChange={onChange}
              value={selectedBundle.name}
              name="name"
            />
          </div>
          <div className="mb-2">
            <TextField
              type="text"
              id="uniqueIdentifier"
              label="Unique Identifier / ASIN"
              placeholder=" "
              onChange={onChange}
              value={selectedBundle.uniqueIdentifier}
              name="uniqueIdentifier"
            />
          </div>
        </>
      ),
    },
    {
      name: "Select Products",
      href: "#",
      render: (
        <div>
          <WizardProductSelector
            warehouses={warehouses}
            selectedEntity={{
              ...selectedBundle,
              items: selectedBundle.products,
            }}
            removeItem={removeProduct}
            onChangeInventorySearch={onChangeInventorySearch}
            availableInventory={availableInventory}
            submitAddedProduct={addProduct}
            getMarketplaceProducts={getMarketplaceProducts}
            isMultiFormFactor={false}
            addingProductCallback={(isAdding) => {
              setIsAdding(isAdding);
            }}
            multipleFormFactors={false}
          />
        </div>
      ),
      footer: isAdding ? <></> : null,
    },
  ];
  return (
    <Wizard
      title={"Bundle Details"}
      steps={steps.map(({ render, ...s }) => ({
        ...s,
        // addOnActions: (
        //   <div
        //     onClick={() => {}}
        //     className={`rounded-md cursor-pointer  inline-flex items-center text-lg justify-center px-2 py-2 text-md font-medium text-white font-montserrat font-medium border C2D2DF-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none ${
        //       selectedStep?.id === steps[steps.length - 1]?.id
        //         ? "bg-gray-200 text-gray-400"
        //         : "bg-FC8862"
        //     }`}>
        //     Save Draft
        //   </div>
        // ),
      }))}
      onSubmit={onSubmit}
      onClose={onClose}>
      {(id) => {
        const step = steps.find((_, idx) => idx === id);
        if (step)
          return (
            <div>
              {step.render}
              {step.footer}
            </div>
          );
        return steps[0].render;
      }}
    </Wizard>
  );
  return (
    <Modal
      title={title}
      negativeAction={onClose}
      positiveAction={onSubmit}
      id="userFormModal">
      <div className="space-y-4">
        {selectedBundle.warehouseToBeSelected && (
          <>
            <div>
              <Dropdown
                placeholder={"Select Warehouse"}
                list={warehouses}
                labelKey="name"
                valueKey="id"
                name="warehouse"
                setSelected={(e) => {
                  onChangeDropdown("warehouse", e);
                }}
                selectedValue={selectedBundle.warehouse}
              />
            </div>
          </>
        )}
        {selectedBundle.customerToBeSelected && (
          <>
            <div>
              <Dropdown
                placeholder={"Select Client"}
                list={getFilteredCustomerList(
                  selectedBundle.warehouse,
                  customers,
                )}
                labelKey="name"
                valueKey="id"
                name="customer"
                setSelected={(e) => {
                  onChangeDropdown("customer", e);
                }}
                selectedValue={selectedBundle.customer}
              />
            </div>
          </>
        )}

        <div>
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Bundle Details
          </span>
        </div>
        <div>
          <TextField
            type="text"
            id="name"
            label="Name"
            placeholder=" "
            onChange={onChange}
            value={selectedBundle.name}
            name="name"
          />
        </div>
        <div>
          <TextField
            type="text"
            id="uniqueIdentifier"
            label="Unique Identifier / ASIN"
            placeholder=" "
            onChange={onChange}
            value={selectedBundle.uniqueIdentifier}
            name="uniqueIdentifier"
          />
        </div>

        {selectedBundle.products &&
          selectedBundle.products.map((product, idx) => (
            <div className="flex items-center space-x-2" key={idx}>
              <div>
                <Autocomplete
                  options={
                    availableInventory &&
                    availableInventory.map((item) => ({
                      name: `${item.sku} - ${truncate(item.name)}`,
                      id: item.id,
                    }))
                  }
                  labelKey="name"
                  valueKey="id"
                  onChange={(e) => {
                    onChangeProduct(idx, {
                      target: {
                        name: "id",
                        value: e,
                      },
                    });
                  }}
                  value={product.id}
                  placeholder="Product"
                  name="product"
                  onKeyDown={onChangeInventorySearch}
                  id={`PRODUCT_${idx}`}
                />
              </div>{" "}
              <div>
                <TextField
                  type="number"
                  id="quantity"
                  label="Quantity"
                  placeholder=" "
                  onChange={(e) => onChangeProduct(idx, e)}
                  value={parseInt(product.quantity)}
                  name="quantity"
                  min="0"
                />
              </div>
              <span
                className="cursor-pointer pl-2 text-lg font-bold text-red-500"
                onClick={() => removeProduct(idx)}>
                Remove
              </span>
            </div>
          ))}
        <AddButton text="Add Product" onClick={addProduct} />

        {selectedBundle.customer && (
          <div className="flex items-center space-x-2">
            <div>Unable to find product?</div>
            <div
              onClick={getMarketplaceProducts}
              className={`C2D2DF-300 inline-flex cursor-pointer items-center justify-center rounded rounded-full border bg-FC8862 px-2 py-2 font-montserrat text-sm font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0`}>
              {"Sync Products"}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default BundleFormWizard;

const truncate = (str) => {
  return str && str.length > 40 ? str.substring(0, 40) + "..." : str;
};
