import Modal from "#components/utils/Modal";
import { useContext, useEffect, useState } from "react";
const config = require("config");
import QRCode from "react-qr-code";
import { AuthContext } from "#contexts/auth";
import { getHeaderObject } from "../../../utils/getHeaderObject";
import { renderStatus } from "../../../utils/renderStatus";
import moment from "moment-timezone";

const ExpandedBatch = ({
  batchItems,
  negativeAction,
  tableName,
  orderIds,
  batch,
  usersMap,
  toShowActivity = true,
}) => {
  const [headers, setHeaders] = useState(
    orderIds
      ? [
          getHeaderObject("SKU", "sku"),
          getHeaderObject("Bin Location", "binLocation"),
          getHeaderObject("Order ID", "orderId"),
          getHeaderObject("Name", "name"),
          getHeaderObject("Order Qty", "quantity"),
          getHeaderObject("Fulfilled Qty", "availableQuantity"),
          getHeaderObject("Status", "status"),
        ]
      : [
          getHeaderObject("SKU", "sku"),
          getHeaderObject("Bin Location", "binLocation"),
          getHeaderObject("Name", "name"),
          getHeaderObject("Order Qty", "quantity"),
          getHeaderObject("Fulfilled Qty", "availableQuantity"),
          getHeaderObject("Status", "status"),
        ],
  );

  const auth = useContext(AuthContext);
  useEffect(() => {
    if (!!getTablePreferences()) {
      setHeaders(getTablePreferences());
      setShowHeaders(getTablePreferences().filter((header) => header.enabled));
    }
  }, [auth.user, headers]);

  function getTablePreferences() {
    return (
      auth.user &&
      auth.user.tablePreferences &&
      auth.user.tablePreferences.find((table) => table.name == tableName)
        ?.columns
    );
  }

  const [editColumns, setEditColumns] = useState(false);
  const [showHeaders, setShowHeaders] = useState(headers);

  const activityHeaders = () => {
    const returnHeaders = [
      { name: "User", correspondingValue: "user" },
      { name: "First Activity", correspondingValue: "firstCompletedTime" },
      { name: "Last Activity", correspondingValue: "lastCompletedTime" },
      { name: "Tote", correspondingValue: "tote" },
    ];

    if (batch.typeOfBatch === "PICKING") {
      returnHeaders.push({
        name: "Requested Dropoff Packing Station",
        correspondingValue: "requestedDropoffPackingStation",
      });
      returnHeaders.push({
        name: "Dropoff Station",
        correspondingValue: "dropoffStation",
      });
    }

    returnHeaders.push({ name: "Status", correspondingValue: "status" });

    return returnHeaders;
  };
  return (
    <>
      <Modal
        onClose={() => {}}
        negativeAction={negativeAction}
        title={`Batch Details`}
        noPadding={true}
        positiveAction={negativeAction}
        positiveText="OK">
        {toShowActivity && (
          <div className="bg-EFE9DC p-4">
            <div className="pb-2 text-xl text-black">Activity</div>
            <table className="min-w-full divide-y divide-gray-200 px-2">
              <thead className="rounded-full bg-primaryAccent p-4 px-12">
                <tr className="border-left px-12 font-montserrat text-textWhite">
                  {activityHeaders().map((header, headerIdx) =>
                    headerIdx === 0 ? (
                      <th
                        scope="col"
                        className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                        key={headerIdx}>
                        {header.name}
                      </th>
                    ) : (
                      <th
                        scope="col"
                        className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                        key={headerIdx}>
                        {header.name}
                      </th>
                    ),
                  )}
                </tr>
              </thead>
              <tbody>
                <tr key={batch.id} className="bg-white">
                  {activityHeaders().map((header, idx) => {
                    let value = header.correspondingValue;
                    let cellStyle =
                      (value === "id"
                        ? "text-primaryAccent cursor-pointer"
                        : "text-5F666B") +
                      (idx === 0
                        ? ` p-5 text-left font-semibold tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261
                        }`
                        : " pl-4 px-1 py-1 text-left font-medium  tracking-wider rounded-br rounded-tr");
                    if (value === "tote") {
                      return <td className={cellStyle}>{batch.tote || "-"}</td>;
                    }
                    if (value === "user") {
                      return (
                        <td className={cellStyle}>
                          {batch.username ||
                            (usersMap && usersMap[batch.user]?.name) ||
                            "-"}
                        </td>
                      );
                    }
                    if (value === "requestedDropoffPackingStation") {
                      return (
                        <td className={cellStyle}>
                          {batch.attributes?.requestedDropoffPackingStation ||
                            "-"}
                        </td>
                      );
                    }
                    if (value === "dropoffStation") {
                      return (
                        <td className={cellStyle}>
                          {batch.attributes?.dropoffStation || "-"}
                        </td>
                      );
                    }
                    if (value === "status") {
                      return (
                        <td className={cellStyle}>
                          {renderStatus(batch.status) || "-"}
                        </td>
                      );
                    }

                    if (value === "firstCompletedTime") {
                      return (
                        <td className={cellStyle}>
                          {batch.attributes?.firstCompletedTime &&
                            moment(batch.attributes.firstCompletedTime).format(
                              "MMM Do YYYY, h:mm a",
                            )}
                        </td>
                      );
                    }
                    if (value === "lastCompletedTime") {
                      return (
                        <td className={cellStyle}>
                          {batch.attributes?.lastCompletedTime &&
                            moment(batch.attributes.lastCompletedTime).format(
                              "MMM Do YYYY, h:mm a",
                            )}
                        </td>
                      );
                    }

                    return <td className={cellStyle}>-</td>;
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <div className="bg-EFE9DC p-4">
          <div className="pb-2 text-xl text-black">
            Line Items ({batchItems.length})
          </div>
          <table className="min-w-full divide-y divide-gray-200 px-2">
            <thead className="rounded-full bg-primaryAccent p-4 px-12">
              <tr className="border-left px-12 font-montserrat text-textWhite">
                {showHeaders.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <th
                      scope="col"
                      className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                      key={headerIdx}>
                      {header.name}
                    </th>
                  ) : (
                    <th
                      scope="col"
                      className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                      key={headerIdx}>
                      {header.name}
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {batchItems.map((item, index) => {
                return (
                  <tr
                    key={item.id}
                    className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                    {showHeaders.map((header, idx) => {
                      let value = header.correspondingValue;
                      let cellStyle =
                        (value == "id"
                          ? "text-primaryAccent cursor-pointer"
                          : "text-5F666B") +
                        (idx == 0
                          ? ` p-5 text-left font-semibold tracking-widerrounded-tl rounded-bl border-l-8  ${
                              index % 2 === 0
                                ? "border-F4C261"
                                : "border-primaryAccent"
                            }`
                          : " pl-4 px-1 py-1 text-left font-medium  tracking-wider rounded-br rounded-tr");
                      if (value === "sku") {
                        return <td className={cellStyle}>{item.sku}</td>;
                      }
                      if (value === "binLocation") {
                        return (
                          <td className={cellStyle}>{item.binLocation}</td>
                        );
                      }
                      if (value === "name") {
                        return <td className={cellStyle}>{item.name}</td>;
                      }
                      if (value === "quantity") {
                        return <td className={cellStyle}>{item.quantity}</td>;
                      }
                      if (value === "availableQuantity") {
                        return (
                          <td className={cellStyle}>
                            {item.availableQuantity}
                          </td>
                        );
                      }
                      if (value === "status") {
                        return (
                          <td className={cellStyle}>
                            {renderStatus(item.status)}
                          </td>
                        );
                      }
                      if (value === "orderId") {
                        return (
                          <td className={cellStyle}>
                            {batch?.attributes?.humanReadableOrderMapping?.[
                              item.order
                            ] ||
                              batch?.attributes?.humanReadableOrderIdMapping?.[
                                item.order
                              ] ||
                              `-`}
                          </td>
                        );
                      }
                      if (
                        config.NODE_ENV === "development" &&
                        value === "binLocation"
                      ) {
                        return (
                          <td className={cellStyle}>
                            <QRCode value={item.binLocation} size="100" />
                          </td>
                        );
                      }
                      if (
                        config.NODE_ENV === "development" &&
                        value == "qrCode"
                      ) {
                        return (
                          <td className={cellStyle}>
                            <QRCode value={item.sku} size="100" />
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};
export default ExpandedBatch;
